import React, {useCallback, useMemo, useState} from "react";
import {Box, Button, Dialog, DialogActions, DialogContent, MenuItem, TextField} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import Call from "../../hocs/call";
import {ItemCategoryTemplateDto} from "../../model/item-containers-models/itemCategoryTemplateDto";
import {NodeCatalogModelView} from "../../model/item-containers-models/nodeCatalogModelView";
import {LocalCategoryProvider} from "../../model/LocalCategoryProvider";
import {getNodeCatalogUrl} from "../../serverApi/urls";
import CustomDialogTitle from "../custom-dialog-title";
import MaterialTree, {SINGLE_SELECT_MODE} from "../material-tree";
import useApi from "../../state/api/useApi";
import {hubNodesSelector} from "../../state/hub/hubSelectors";
import {initRequest} from "../../middlewares/request/requestActions";
import {getAgencyAndCategoryFromFullId, getPathToSelectedCategory} from "../../utils/catalog";

function getFullIdFromAgencyAndCategory(agencyId: string, categoryId: string) {
  return `${agencyId};${categoryId}`;
}

function getElementsFromPath(path: string) {
  if (path.includes("+")) {
    return path.split("+");
  } else {
    return [path];
  }
}

function getAgencyAndCategoryFromTreeSelection(
  selection: string[],
  catalog: NodeCatalogModelView
): {agencyId: string; categoryId: string} {
  const lastSelectedValue = selection[0];
  if (!lastSelectedValue) {
    return null;
  } else {
    const pathElement = getElementsFromPath(lastSelectedValue);
    return {
      agencyId: catalog.categoryGroups.length > 1 ? pathElement[0] : catalog.categoryGroups[0].id,
      categoryId: pathElement[pathElement.length - 1]
    };
  }
}

interface CategorySelectionDialogProps {
  open: boolean;
  onClose: () => void;
  handleCategoryChange: (selectedCatalog: NodeCatalogModelView, fullId: string, nodeId: number) => void;
  item: ItemCategoryTemplateDto;
}

export const CategorySelectionDialog = ({open, onClose, handleCategoryChange, item}: CategorySelectionDialogProps) => {
  const {t} = useTranslation();
  const nodes = useSelector(hubNodesSelector);
  const [selectedNode, setSelectedNode] = useState(nodes.find(n => n.nodeId === item?.nodeId) || nodes[0]);
  const [selectedCatalog, setSelectedCatalog] = useState<NodeCatalogModelView>(item.catalog);

  const {call, request, loading} = useApi<NodeCatalogModelView>(
    initRequest(`item-container/category/fetch-node-catalog`, undefined, undefined, undefined, t => ({
      onStart: t("domains.catalog.messages.fetch.start")
    })),
    {
      onSuccess: data => {
        setSelectedCatalog({...data, datasetUncategorized: []});
      },
      onError: () => {
        setSelectedCatalog(null);
      },
      cache: true,
      getCacheKey: r => r.url
    }
  );

  const [selectedCategoryAndAgency, setSelectedCategoryAndAgency] = useState<{agencyId: string; categoryId: string}>(
    item.category ? getAgencyAndCategoryFromFullId(item.category) : null
  );

  const selectionCategoryNodeChangeHandler = (event: any) => {
    const [id] = event.target.value.split(",");
    const node = nodes.find(v => v.nodeId === +id);
    setSelectedNode(node);
    setSelectedCategoryAndAgency(null);
  };

  const agency = selectedCatalog?.categoryGroups?.find(agency => agency.id === selectedCategoryAndAgency?.agencyId);
  const selectedCategoryPath =
    selectedCategoryAndAgency?.categoryId && selectedCategoryAndAgency?.agencyId
      ? selectedCatalog?.categoryGroups?.length > 1
        ? [agency.id, ...getPathToSelectedCategory(selectedCategoryAndAgency.categoryId, agency).map(v => v.id)]
        : getPathToSelectedCategory(selectedCategoryAndAgency.categoryId, agency).map(v => v.id)
      : [];

  const formatCategory = useCallback((category, parentUid?: string) => {
    const uid = parentUid ? `${parentUid}+${category.id}` : category.id;
    return {
      ...category,
      children:
        category.childrenCategories && category.childrenCategories.length > 0
          ? category.childrenCategories.map(childCategory => formatCategory(childCategory, uid))
          : null,
      childrenCategories: null,
      uid: uid
    };
  }, []);

  const formatCatalogTree = useCallback(
    catalog => {
      return catalog.categoryGroups.length > 1
        ? catalog.categoryGroups.map(categoryGroup => ({
            ...categoryGroup,
            children: categoryGroup.categories.map(category => formatCategory(category, categoryGroup.id)),
            categories: null,
            uid: categoryGroup.id
          }))
        : catalog.categoryGroups[0].categories.map(category => formatCategory(category));
    },
    [formatCategory]
  );

  const catalog = useMemo(() => {
    return selectedNode && selectedCatalog
      ? new LocalCategoryProvider(
          selectedCatalog.categoryGroups as any,
          selectedCatalog.datasetMap as any,
          selectedCatalog.datasetUncategorized as any,
          selectedNode.nodeId,
          selectedCatalog.catalogLayers as any,
          t,
          true
        )
      : null;
  }, [t, selectedCatalog, selectedNode]);

  let catalogTree = useMemo(() => {
    return catalog ? formatCatalogTree(catalog) : [];
  }, [catalog, formatCatalogTree]);

  return (
    <Dialog
      id="item-container-builder__category-selection-dialog"
      open={open}
      fullWidth
      disableEnforceFocus
      maxWidth="md"
      onClose={onClose}
    >
      <CustomDialogTitle onClose={onClose}>
        {t("components.itemContainerBuilder.modals.category.title")}
      </CustomDialogTitle>
      <DialogContent>
        <Call
          cb={node => call({...request, url: getNodeCatalogUrl(node.nodeId)})}
          cbParam={selectedNode}
          disabled={!selectedNode}
        >
          <TextField
            id="item-container-builder__category-selection-dialog__selected-node"
            select
            fullWidth
            variant="outlined"
            label={t("components.itemContainerBuilder.modals.category.selectedNode")}
            value={selectedNode?.nodeId + "," + selectedNode?.code}
            onChange={selectionCategoryNodeChangeHandler}
            sx={{mt: "8px"}}
          >
            {nodes
              .sort((a, b) => a.order - b.order)
              .map(n => {
                return (
                  <MenuItem key={n.nodeId + "," + n.code} value={n.nodeId + "," + n.code}>
                    {n.name != null ? n.name : n.code}
                  </MenuItem>
                );
              })}
          </TextField>
          {selectedNode && selectedCatalog && !loading && (
            <Box
              sx={{
                maxHeight: "480px",
                overflow: "auto",
                mt: "16px"
              }}
            >
              <MaterialTree
                treeData={catalogTree}
                idKey={"uid"}
                pathSeparator={"@"}
                childrenKey={"children"}
                labelKey={"label"}
                selectMode={SINGLE_SELECT_MODE}
                defaultSelectedNodeIds={[selectedCategoryPath.join("+")]}
                defaultExpandedNodeIds={[selectedCategoryPath.slice(0, -1).join("+")]}
                onNodeSelect={(selection: string[]) => {
                  const agencyAndCategory = getAgencyAndCategoryFromTreeSelection(selection, selectedCatalog);
                  setSelectedCategoryAndAgency(agencyAndCategory);
                }}
                height={400}
                showFolderIcon
              />
            </Box>
          )}
        </Call>
      </DialogContent>
      <DialogActions>
        <Button
          id="item-container-builder__category-selection-dialog__cancel-btn"
          onClick={() => {
            onClose();
          }}
        >
          {t("commons.confirm.cancel")}
        </Button>
        <Button
          id="item-container-builder__category-selection-dialog__confirm-btn"
          disabled={!selectedCategoryAndAgency?.agencyId}
          onClick={() => {
            handleCategoryChange(
              selectedCatalog,
              getFullIdFromAgencyAndCategory(selectedCategoryAndAgency.agencyId, selectedCategoryAndAgency.categoryId),
              selectedNode.nodeId
            );
            onClose();
          }}
        >
          {t("commons.confirm.confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
