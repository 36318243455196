import {ReducersMapObject} from "redux";
import apiReducer, {AllApiState} from "./api/apiReducer";
import appReducer, {AppState} from "./app/appReducer";
import appConfigReducer, {ConfigState as AppConfigState} from "./appConfig/appConfigReducer";
import cacheReducer, {DataflowCacheState} from "./cache/cacheReducer";
import catalogReducer, {CatalogState} from "./catalog/catalogReducer";
import configReducer, {ConfigState} from "./config/configReducer";
import dashboardConfigReducer, {DashboardConfigState} from "./dashboardConfig/dashboardConfigReducer";
import datasetReducer from "./dataset/datasetReducer";
import deleteConflictModalReducer, {DeleteConflictModalState} from "./delete-conflict-modal/deleteConflictModalReducer";
import detailLevelReducer, {DetailLevelState} from "./detailLevel/detailLevelReducer";
import hubReducer, {HubState} from "./hub/hubReducer";
import hubConfigReducer, {HubConfigState} from "./hubConfig/hubConfigReducer";
import itemContainerItemReducer, {ItemContainerItemState} from "./itemContainerItem/itemContainerItemReducer";
import mapsReducer, {MapsState} from "./maps/mapsReducer";
import miscellaneousReducer, {MiscellaneousState} from "./miscellaneous/miscellaneousReducer";
import nodeReducer, {NodeState} from "./node/nodeReducer";
import nodePermissionsConfigReducer, {
  NodePermissionsConfigState
} from "./nodePermissionsConfig/nodePermissionsConfigReducer";
import nodesConfigReducer, {NodesConfigState} from "./nodesConfig/nodesConfigReducer";
import nodeTemplatesConfigReducer, {
  NodesConfigState as NodesTemplateConfigState
} from "./noteTemplatesConfig/nodeTemplatesConfigReducer";
import otherConfigReducer, {OtherConfigState} from "./otherConfig/otherConfigReducer";
import pendingRequestReducer, {PendingRequestState} from "./pending-request/pendingRequestReducer";
import searchDialogReducer, {SearchDialogState} from "./search-dialog/searchDialogReducer";
import spinnerReducer, {SpinnerState} from "./spinner/spinnerReducer";
import userReducer, {UserState} from "./user/userReducer";
import usersConfigReducer, {UsersConfigState} from "./usersConfig/usersConfigReducer";

export type RootState = {
  pendingRequest: PendingRequestState;
  spinner: SpinnerState;
  appConfig: AppConfigState;
  config: ConfigState;
  dashboardFilterConfig: DashboardConfigState;
  itemContainerFilterConfig: ItemContainerItemState;
  app: AppState;
  hub: HubState;
  node: NodeState;
  nodeTemplatesConfig: NodesTemplateConfigState;
  catalog: CatalogState;
  dataset: ReturnType<typeof datasetReducer>;
  hubConfig: HubConfigState;
  nodesConfig: NodesConfigState;
  cacheConfig: DataflowCacheState;
  otherConfig: OtherConfigState;
  user: UserState;
  usersConfig: UsersConfigState;
  nodePermissionsConfig: NodePermissionsConfigState;
  deleteConflictModal: DeleteConflictModalState;
  maps: MapsState;
  detailLevel: DetailLevelState;
  searchDialog: SearchDialogState;
  api: AllApiState;
  miscellaneous: MiscellaneousState;
};

const rootReducer: ReducersMapObject<RootState> = {
  pendingRequest: pendingRequestReducer,
  spinner: spinnerReducer,
  appConfig: appConfigReducer,
  config: configReducer,
  dashboardFilterConfig: dashboardConfigReducer,
  itemContainerFilterConfig: itemContainerItemReducer,
  app: appReducer,
  hub: hubReducer,
  node: nodeReducer,
  nodeTemplatesConfig: nodeTemplatesConfigReducer,
  catalog: catalogReducer,
  dataset: datasetReducer,
  hubConfig: hubConfigReducer,
  nodesConfig: nodesConfigReducer,
  cacheConfig: cacheReducer,
  otherConfig: otherConfigReducer,
  user: userReducer,
  usersConfig: usersConfigReducer,
  nodePermissionsConfig: nodePermissionsConfigReducer,
  deleteConflictModal: deleteConflictModalReducer,
  maps: mapsReducer,
  detailLevel: detailLevelReducer,
  searchDialog: searchDialogReducer,
  api: apiReducer,
  miscellaneous: miscellaneousReducer
};

export default rootReducer;
