import React, {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import {useTranslation} from "react-i18next";
import {isNumeric} from "../../../utils/validator";
import {
  FILTER_TYPE_OBS,
  OBS_FILTER_OPERATOR_AND,
  OBS_FILTER_OPERATOR_EQUAL,
  OBS_FILTER_OPERATOR_GREATER,
  OBS_FILTER_OPERATOR_GREATER_OR_EQUAL,
  OBS_FILTER_OPERATOR_LESS,
  OBS_FILTER_OPERATOR_LESS_OR_EQUAL,
  OBS_FILTER_OPERATOR_NOT_EQUAL,
  OBS_FILTER_OPERATOR_OR
} from "./utils";

const emptyFilter = {
  type: FILTER_TYPE_OBS,
  operator: OBS_FILTER_OPERATOR_AND,
  entity1: {
    operator: OBS_FILTER_OPERATOR_EQUAL,
    filterValue: ""
  },
  entity2: {
    operator: OBS_FILTER_OPERATOR_EQUAL,
    filterValue: ""
  }
};

const ObservationFilterForm = ({initialFilter, onApply, onRemove}) => {
  const {t} = useTranslation();

  const [filter, setFilter] = useState(emptyFilter);

  useEffect(() => {
    if (initialFilter) {
      setFilter(initialFilter);
    }
  }, [initialFilter]);

  const handleApply = () => {
    if (isNumeric(filter.entity1.filterValue) && isNumeric(filter.entity2.filterValue)) {
      onApply(filter);
    } else if (isNumeric(filter.entity1.filterValue)) {
      onApply({
        ...emptyFilter,
        entity1: filter.entity1
      });
    } else if (isNumeric(filter.entity2.filterValue)) {
      onApply({
        ...emptyFilter,
        entity1: filter.entity2
      });
    } else {
      onRemove();
    }
  };

  const {operator, entity1, entity2} = filter;

  return (
    filter && (
      <Grid id="table__observation-filter-form" container spacing={1}>
        <Grid item xs={12}>
          {t("components.table.observationFilter.info")}
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Select
                value={entity1.operator}
                variant="outlined"
                fullWidth
                onChange={({target}) =>
                  setFilter({
                    ...filter,
                    entity1: {
                      ...filter.entity1,
                      operator: target.value
                    }
                  })
                }
                SelectDisplayProps={{"aria-haspopup": true}}
              >
                <MenuItem value={OBS_FILTER_OPERATOR_EQUAL}>
                  {t("components.table.observationFilter.operator.equal")}
                </MenuItem>
                <MenuItem value={OBS_FILTER_OPERATOR_NOT_EQUAL}>
                  {t("components.table.observationFilter.operator.notEqual")}
                </MenuItem>
                <MenuItem value={OBS_FILTER_OPERATOR_GREATER_OR_EQUAL}>
                  {t("components.table.observationFilter.operator.greaterOrEqual")}
                </MenuItem>
                <MenuItem value={OBS_FILTER_OPERATOR_GREATER}>
                  {t("components.table.observationFilter.operator.greater")}
                </MenuItem>
                <MenuItem value={OBS_FILTER_OPERATOR_LESS_OR_EQUAL}>
                  {t("components.table.observationFilter.operator.lessOrEqual")}
                </MenuItem>
                <MenuItem value={OBS_FILTER_OPERATOR_LESS}>
                  {t("components.table.observationFilter.operator.less")}
                </MenuItem>
              </Select>
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={entity1.filterValue}
                type="number"
                variant="outlined"
                fullWidth
                onChange={({target}) =>
                  setFilter({
                    ...filter,
                    entity1: {
                      ...filter.entity1,
                      filterValue: target.value
                    }
                  })
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1} justifyContent="center">
            <Grid item xs={4}>
              <Select
                value={operator}
                variant="outlined"
                fullWidth
                onChange={({target}) =>
                  setFilter({
                    ...filter,
                    operator: target.value
                  })
                }
                SelectDisplayProps={{"aria-haspopup": true}}
              >
                <MenuItem value={OBS_FILTER_OPERATOR_AND}>
                  {t("components.table.observationFilter.operator.and")}
                </MenuItem>
                <MenuItem value={OBS_FILTER_OPERATOR_OR}>
                  {t("components.table.observationFilter.operator.or")}
                </MenuItem>
              </Select>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Select
                value={entity2.operator}
                variant="outlined"
                fullWidth
                onChange={({target}) =>
                  setFilter({
                    ...filter,
                    entity2: {
                      ...filter.entity2,
                      operator: target.value
                    }
                  })
                }
                SelectDisplayProps={{"aria-haspopup": true}}
              >
                <MenuItem value={OBS_FILTER_OPERATOR_EQUAL}>
                  {t("components.table.observationFilter.operator.equal")}
                </MenuItem>
                <MenuItem value={OBS_FILTER_OPERATOR_NOT_EQUAL}>
                  {t("components.table.observationFilter.operator.notEqual")}
                </MenuItem>
                <MenuItem value={OBS_FILTER_OPERATOR_GREATER_OR_EQUAL}>
                  {t("components.table.observationFilter.operator.greaterOrEqual")}
                </MenuItem>
                <MenuItem value={OBS_FILTER_OPERATOR_GREATER}>
                  {t("components.table.observationFilter.operator.greater")}
                </MenuItem>
                <MenuItem value={OBS_FILTER_OPERATOR_LESS_OR_EQUAL}>
                  {t("components.table.observationFilter.operator.lessOrEqual")}
                </MenuItem>
                <MenuItem value={OBS_FILTER_OPERATOR_LESS}>
                  {t("components.table.observationFilter.operator.less")}
                </MenuItem>
              </Select>
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={entity2.filterValue}
                type="number"
                variant="outlined"
                fullWidth
                onChange={({target}) =>
                  setFilter({
                    ...filter,
                    entity2: {
                      ...filter.entity2,
                      filterValue: target.value
                    }
                  })
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="flex-end" spacing={1}>
            <Grid item>
              <Button onClick={onRemove} color="primary">
                {t("components.table.observationFilter.remove")}
              </Button>
              <Button onClick={handleApply} color="primary">
                {t("components.table.observationFilter.apply")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  );
};

export default ObservationFilterForm;
