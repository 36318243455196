import React, {Fragment} from "react";
import {DialogActions} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import {useTranslation} from "react-i18next";
import CustomDialogTitle from "../custom-dialog-title";
import FullscreenDialog from "../fullscreen-dialog";

const SettingsDialog = ({
  id = null,
  title,
  children,
  onSubmit,
  onClose,
  open,
  maxWidth,
  hasSubmit,
  noMinHeight,
  customSubmitLabel,
  noFullScreen
}) => {
  const {t} = useTranslation();

  const dialogContent = (
    <Fragment>
      <CustomDialogTitle onClose={onClose}>{title}</CustomDialogTitle>
      <DialogContent
        style={{
          minHeight: noMinHeight ? undefined : 400,
          paddingBottom: noMinHeight ? 16 : undefined
        }}
      >
        {children}
      </DialogContent>
      <DialogActions>
        {hasSubmit ? (
          <Fragment>
            <Button className="settings-dialog__confirm-cancel-btn" onClick={onClose}>
              {t("commons.confirm.cancel")}
            </Button>
            <Button className="settings-dialog__confirm-submit-btn" onClick={onSubmit}>
              {customSubmitLabel || t("commons.confirm.submit")}
            </Button>
          </Fragment>
        ) : (
          <Button className="settings-dialog__confirm-close-btn" onClick={onClose}>
            {t("commons.confirm.close")}
          </Button>
        )}
      </DialogActions>
    </Fragment>
  );

  return noFullScreen ? (
    <Dialog
      id={id}
      className="settings-dialog"
      open={open || false}
      disableEnforceFocus
      maxWidth={maxWidth || "md"}
      fullWidth
      onClose={onClose}
    >
      {dialogContent}
    </Dialog>
  ) : (
    <FullscreenDialog id={id} open={open || false} disableEnforceFocus onClose={onClose}>
      {dialogContent}
    </FullscreenDialog>
  );
};

export default SettingsDialog;
