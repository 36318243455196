/** chart types **/
export const CHART_TYPE_BAR = "bar";
export const CHART_TYPE_HORIZONTAL_BAR = "horizontalBar";
export const CHART_TYPE_LINE = "line";
export const CHART_TYPE_AREA = "area";
export const CHART_TYPE_DOUGHNUT = "doughnut";
export const CHART_TYPE_PIE = "pie";
export const CHART_TYPE_RADAR = "radar";
export const CHART_TYPE_POLAR_AREA = "polarArea";
export const CHART_TYPE_PYRAMID = "pyramid";

export const CHART_CATEGORY_TYPE_CARTESIAN = "cartesian";
export const CHART_CATEGORY_TYPE_RADIAL = "radial";

export const getChartType = type => {
  switch (type) {
    case CHART_TYPE_BAR:
      return "bar";
    case CHART_TYPE_HORIZONTAL_BAR:
      return "bar";
    case CHART_TYPE_LINE:
      return "line";
    case CHART_TYPE_AREA:
      return "line";
    case CHART_TYPE_DOUGHNUT:
      return "doughnut";
    case CHART_TYPE_PIE:
      return "pie";
    case CHART_TYPE_RADAR:
      return "radar";
    case CHART_TYPE_POLAR_AREA:
      return "polarArea";
    case CHART_TYPE_PYRAMID:
      return "bar";
    default:
      return type;
  }
};

export const getVariationChartType = type => {
  switch (type) {
    case CHART_TYPE_BAR:
      return "line";
    case CHART_TYPE_LINE:
      return "bar";
    default:
      return null;
  }
};

export const getCategoryChartType = type => {
  if (getVariationChartType(type)) {
    return CHART_CATEGORY_TYPE_CARTESIAN;
  } else {
    return CHART_CATEGORY_TYPE_RADIAL;
  }
};

/** legend positions **/
export const CHART_LEGEND_POSITION_TOP = "top";
export const CHART_LEGEND_POSITION_RIGHT = "right";
export const CHART_LEGEND_POSITION_BOTTOM = "bottom";
export const CHART_LEGEND_POSITION_lEFT = "left";

/** label types **/
export const CHART_DATA_LABEL_TYPE_NONE = "none";
export const CHART_DATA_LABEL_TYPE_VALUE = "value";
export const CHART_DATA_LABEL_TYPE_PERCENTAGE = "percentage";

/** chart export size **/
export const CHART_EXPORT_SIZE_ORIGINAL = "original";
export const CHART_EXPORT_SIZE_3000X2000 = "3000x2000";

/** chart extremities Cartesian Axes **/
export const DEFAULT = "Default";
export const AUTOMATIC = "Automatic";
export const FROM_ZERO = "FromZero";

// Chart sorting
export const CHART_DIM_ORDER_SELECTOR_VALUE_DEFAULT = "DEFAULT";
export const CHART_DIM_ORDER_SELECTOR_VALUE_ASC = "ASC";
export const CHART_DIM_ORDER_SELECTOR_VALUE_DESC = "DESC";

export const CHART_SORTING_VALUE_DEFAULT = "";

export const initialChartSortingCartesian = Object.freeze({
  obsSortingOrderCartesian: CHART_DIM_ORDER_SELECTOR_VALUE_DEFAULT,
  obsSortingValueCartesian: CHART_SORTING_VALUE_DEFAULT
});

export const initialChartSortingRadial = Object.freeze({
  obsSortingOrderRadial: CHART_DIM_ORDER_SELECTOR_VALUE_DEFAULT,
  obsSortingValueRadial: CHART_SORTING_VALUE_DEFAULT
});
