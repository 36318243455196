import React, {useContext, useState} from "react";
import {Box, Button, DialogActions, DialogContent, Tab, Tabs} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import CustomDialogTitle from "../../custom-dialog-title";
import CustomTabPanel from "../../custom-tab-panel";
import FullscreenDialog from "../../fullscreen-dialog";
import ItemContainerBuilder from "../../item-container-builder";
import CustomPageNodeSettings from "./CustomPageNodeSettings";
import {ItemContainerSettingsFormContext} from "./ItemContainerSettingsFormProvider";
import PreviewDialog from "./PreviewDialog";
import {languagesSelector} from "../../../state/app/appSelectors";
import {itemContainerItemSelector} from "../../../state/itemContainerItem/itemContainerItemSelector";
import {
  submitOtherConfigItemContainerCreate,
  submitOtherConfigItemContainerUpdate
} from "../../../state/otherConfig/otherConfigActions";
import {currentItemContainerSelector, itemContainersSelector} from "../../../state/otherConfig/otherConfigSelectors";
import {validateI18nObj} from "../../../utils/i18n";
import {ITEMCONTAINER_ELEM_TYPE_VALUE_VIEW, ITEMCONTAINER_ELEM_VALUE_KEY} from "../../../utils/itemContainers";

interface ItemContainerConfigurationDialogProps {
  itemContainerId?: number;
  onClose?: () => void;
}

const ItemContainerConfigurationDialog = ({itemContainerId, onClose}: ItemContainerConfigurationDialogProps) => {
  const {asDashboard} = useContext(ItemContainerSettingsFormContext);
  const [selectedTabIndex, setTabIndex] = useState(0);
  const {t} = useTranslation();
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };
  const itemContainer = useSelector(currentItemContainerSelector);
  const otherItemContainers = useSelector(itemContainersSelector).filter(i => i.id !== itemContainer.id);
  const itemContainerFilterConfig = useSelector(itemContainerItemSelector);
  const appLanguages = useSelector(languagesSelector);
  const [isPreviewVisible, setPreviewVisibility] = useState(false);
  const dispatch = useDispatch();

  const handleItemContainerSubmit = () => {
    if (itemContainerId) {
      dispatch(
        submitOtherConfigItemContainerUpdate(
          itemContainerId,
          itemContainer,
          itemContainerFilterConfig,
          asDashboard ? "dashboard" : "customPages"
        )
      );
    } else {
      dispatch(
        submitOtherConfigItemContainerCreate(
          itemContainer,
          itemContainerFilterConfig,
          asDashboard ? "dashboard" : "customPages"
        )
      );
    }
    onClose();
  };

  const isTitleDuplicate = (): boolean => {
    return (otherItemContainers ?? []).some(ic =>
      appLanguages.some(
        lang => (itemContainer.title?.[lang] ?? "").length > 0 && itemContainer.title[lang] === ic.title?.[lang]
      )
    );
  };

  const isItemContainerNonValid = () => {
    if (isTitleDuplicate()) {
      return true;
    }
    if (itemContainer.item.length === 0) {
      return true;
    }
    if (
      itemContainer.item.some((subArray: any[]) =>
        subArray.some(
          element =>
            element.type === null ||
            (element.type === ITEMCONTAINER_ELEM_TYPE_VALUE_VIEW && element[ITEMCONTAINER_ELEM_VALUE_KEY] === null)
        )
      )
    ) {
      return true;
    }
    return false;
  };

  return (
    <>
      <FullscreenDialog open={itemContainer !== null} onClose={onClose}>
        <CustomDialogTitle onClose={onClose}>
          {itemContainerId
            ? !asDashboard
              ? t("scenes.customPagesSettings.modals.updateCustomPage.title")
              : t("scenes.dashboardsSettings.modals.updateDashboard.title")
            : !asDashboard
              ? t("scenes.customPagesSettings.modals.createCustomPage.title")
              : t("scenes.dashboardsSettings.modals.createDashboard.title")}
        </CustomDialogTitle>
        <DialogContent>
          <Box sx={{width: "100%"}}>
            <Box sx={{borderBottom: 1, borderColor: "divider"}}>
              {!asDashboard && (
                <Tabs value={selectedTabIndex} onChange={handleTabChange} aria-label="basic tabs example">
                  <Tab label={t("components.customPageConfiguration.tabs.general")} />
                  <Tab label={t("components.customPageConfiguration.tabs.nodeSettings")} />
                </Tabs>
              )}
            </Box>
            <CustomTabPanel value={selectedTabIndex} index={0}>
              <ItemContainerBuilder isTitleDuplicate={isTitleDuplicate} />
            </CustomTabPanel>
            {!asDashboard && (
              <CustomTabPanel value={selectedTabIndex} index={1}>
                <CustomPageNodeSettings />
              </CustomTabPanel>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button id="item-container-configuration-dialog__close-btn" onClick={onClose} type="button">
            {t("commons.confirm.close")}
          </Button>
          <Button
            id="item-container-configuration-dialog__preview-btn"
            onClick={() => setPreviewVisibility(true)}
            type="button"
          >
            {t("commons.confirm.preview")}
          </Button>
          <Button
            id="item-container-configuration-dialog__save-btn"
            onClick={handleItemContainerSubmit}
            disabled={isItemContainerNonValid() || !validateI18nObj(itemContainer.title)}
          >
            {t("commons.confirm.save")}
          </Button>
        </DialogActions>
      </FullscreenDialog>
      <PreviewDialog
        asDashboard={asDashboard}
        isPreviewVisible={isPreviewVisible}
        setPreviewVisibility={setPreviewVisibility}
      />
    </>
  );
};

export default ItemContainerConfigurationDialog;
