/* config */
const configFolderPath = "config/";
export const getInitConfigUrl = () => configFolderPath + "config.json";
export const getAppConfigUrl = () => configFolderPath + "appConfig.json";
export const getDashboardConfigUrl = () => configFolderPath + "dashboardConfig.json";
export const getMapLayersConfigUrl = () => configFolderPath + "mapLayersConfig.json";
export const getModulesConfigUrl = () => configFolderPath + "modulesConfig.json";
export const getCustomCssUrl = () => configFolderPath + "custom.css";
export const getCustomTranslationUrl = (lang: string) => configFolderPath + `i18n_custom/${lang}.json`;

/* hub */
export const getHubMinimalInfoUrl = () => "hub/minimalInfo";
export const getHubLanguagesUrl = () => "hub/languages";
export const getSetMachineToMachineCredentialsUrl = (hubId: number) =>
  `MachineToMachineInteraction/Credentials/?hubId=${hubId}`;

/* node */
export const getNodeUrl = (nodeId: number) => `nodes/${nodeId}`;
export const getNodeCatalogUrl = (nodeId: number) => `nodes/${nodeId}/catalog`;

/* cache */
export const getClearMemoryCacheUrl = () => "MemoryCache/Clear";
export const getDataflowsCacheListUrl = (nodeId: number) => `DataflowCache/DataflowData/Nodes/${nodeId}`;
export const getDataflowsCacheUpdateUrl = (nodeId: number, cacheId: string) =>
  `DataflowCache/DataflowData/${cacheId}/Nodes/${nodeId}`;
export const getDataflowsCreateCacheUrl = (nodeId: number) => `DataflowCache/DataflowData/Nodes/${nodeId}`;
export const getDataflowsClearCacheUrl = (nodeId: number, cacheId: string) =>
  `DataflowCache/Clear/${cacheId}/Nodes/${nodeId}`;
export const getDataflowsClearAllCacheUrl = (nodeId: number) => `DataflowCache/ClearAll/Nodes/${nodeId}`;
export const getClearNodeCatalogMemoryCacheUrl = (nodeId: number) => `MemoryCache/Clear/CatalogTree/Nodes/${nodeId}`;

export const getDatasetCacheInfoUrl = (nodeId: number, datasetId: string) =>
  `DataflowCache/DataflowData/${datasetId}/Nodes/${nodeId}`;
export const clearDatasetCacheUrl = (nodeId: number, datasetId: string) =>
  `Caches/Nodes/${nodeId}/ClearDataflow/${datasetId}`;
export const getDatasetCacheUpdateUrl = (nodeId: number, datasetId: string) =>
  `DataflowCache/${datasetId}/Nodes/${nodeId}`;
export const getArtefactCacheClearAllUrl = (nodeId: number, preserveDataflows: boolean) =>
  `ArtefactCache/Nodes/${nodeId}/ClearAll?preserveDataflows=${preserveDataflows}`;
export const getArtefactCacheClearUrl = (
  nodeId: number,
  structureType: string,
  structureId: string,
  references?: string
) =>
  `ArtefactCache/Nodes/${nodeId}/Clear/${structureType}/${structureId}${references ? `?references=${references}` : ""}`;

/* templates */
export const getNodeTemplatesUrl = (nodeId: number) => `nodes/${nodeId}/templates`;
export const getCreateTemplateUrl = (nodeId: number) => `nodes/${nodeId}/templates`;
export const getDeleteTemplateUrl = (nodeId: number, id: number) => `nodes/${nodeId}/templates/${id}`;

/* views */
export const getViewsUrl = () => `views`;
export const getCreateViewUrl = (nodeId: number) => `nodes/${nodeId}/views`;
export const getDeleteViewUrl = (nodeId: number, id: number) => `nodes/${nodeId}/views/${id}`;
export const getItemContainerNodeView = (nodeId: number, id: number) => `ItemContainers/nodes/${nodeId}/views/${id}`;

/* dataset */
export const getDatasetUrl = (nodeId: number, datasetId: string, lastNObservations?: number | null) =>
  `nodes/${nodeId}/datasets/${datasetId.replace(/\+/g, ",")}/data` +
  (lastNObservations ? `?lastNObservations=${lastNObservations}` : "");
export const getDatasetStructureUrl = (nodeId: number, datasetId: string, viewId?: string) =>
  `nodes/${nodeId}/datasets/${datasetId}/structure${viewId ? `/${viewId}` : ""}`;
export const getDatasetStructureCodelistUrl = (
  nodeId: number,
  datasetId: string,
  dimensionId: string,
  isFull: boolean
) => `nodes/${nodeId}/datasets/${datasetId}/column/${dimensionId}/${isFull ? "full" : "partial"}/values`;
export const getDatasetStructureDynamicCodelistUrl = (nodeId: number, datasetId: string, dimensionId: string) =>
  `nodes/${nodeId}/datasets/${datasetId}/PartialCodelists/${dimensionId}`;
export const getDatasetStructureCodelistsUrl = (nodeId: number, datasetId: string, isFull: boolean = false) =>
  `nodes/${nodeId}/datasets/${datasetId}/columns/${isFull ? "full" : "partial"}/values`;
export const getDatasetDownloadUrl = (
  nodeId: number,
  datasetId: string,
  format: string,
  zipped?: boolean,
  lastNObservations?: number | null
) =>
  `nodes/${nodeId}/datasets/${datasetId}/${zipped === true ? "downloadZip" : "download"}/${format}${
    lastNObservations ? `?lastNObservations=${lastNObservations}` : ""
  }`;
export const getDatasetCustomDownloadUrl = (
  nodeId: number,
  format: string,
  zipped?: boolean,
  lastNObservations?: number | null
) =>
  `nodes/${nodeId}/export/${format}/${zipped === true ? "true" : "false"}${
    lastNObservations ? `?lastNObservations=${lastNObservations}` : ""
  }`;
export const getDatasetSdmxQueryUrl = (nodeId: number, datasetId: string, lastNObservations?: number | null) =>
  `nodes/${nodeId}/datasets/${datasetId}/url${lastNObservations ? `?lastNObservations=${lastNObservations}` : ""}`;

/* indicators */
export const getDatasetWithMarginalUrl = () => `marginal`;
export const getDatasetWithIndicatorsUrl = (isPreview: boolean = false) => `data?preview=${isPreview}`;
export const getDatasetWithMarginalCustomDownloadUrl = (format: string, zipped?: boolean) =>
  `export/${format}/${zipped === true ? "true" : "false"}`;

/* detail levels */
export const getDatasetDetailLevels = (nodeId: number, datasetId: string) =>
  `nodes/${nodeId}/datasets/${datasetId}/detailLevels`;
export const getDatasetTerritories = (
  nodeId: number,
  datasetId: string,
  territoryDim: string,
  detailLevel: number,
  optimizedData: boolean = false
) =>
  `nodes/${nodeId}/datasets/${datasetId}/detailLevelItems/${territoryDim}/${detailLevel}` +
  (optimizedData ? `?optimizedData=true` : "");
export const getDeleteNodeDatasetDetailsLevels = (nodeId: number) => `nodes/${nodeId}/detailsLevels`;

/* metadata */
export const getDatasetExportMetadataUrl = (nodeId: number, datasetId: string) =>
  `nodes/${nodeId}/datasets/${datasetId}/ExportMetadata`;

/* config */
export const getHubConfigGetUrl = () => "hub/config";
export const getHubConfigPutUrl = () => "hub";
export const getNodesConfigUrl = () => "nodes/config";
export const getNodesOrderPutUrl = () => "nodes/order";
export const getNodeConfigGetUrl = (nodeId: number) => `nodes/${nodeId}/config`;
export const getNodesConfigPostUrl = () => "nodes";
export const getNodesConfigPutUrl = (nodeId: number) => `nodes/${nodeId}`;
export const getNodeDeleteUrl = (nodeId: number, force?: boolean) => `nodes/${nodeId}${force ? "/true" : ""}`;
export const getNodePingUrl = () => "Endpoint/Ping";

export const getMergedNodeConfigCreateUrl = () => "mergedNode";
export const getMergedNodeConfigEditUrl = (nodeId: number) => `mergedNode/${nodeId}`;
export const getMergedNodeConfigGetUrl = (nodeId: number) => `mergedNode/${nodeId}/config`;

export const getNodeExportUrl = (nodeCode: string) => `Export/Nodes/${nodeCode}`;
export const getNodeImportUrl = () => "Import/Nodes/Info";

/* log */
export const getQueryLog = (limit: number) => `tracing/limit/${limit}`;

/* files */
export const getFileUploadUrl = () => "File/Upload";

/* authentication */
export const getTokenUrl = () => "auth/token";
export const getRefreshTokenUrl = () => "auth/refreshToken";
export const getRemoveTokenUrl = (userId: number) => `auth/removeToken/${userId}`;
export const getExternalLoginUrl = (providerId: string) => `auth/provider/${providerId}`;

/* users management */
export const getUsersConfigGetUrl = () => "users";
export const getUserConfigGetUrl = (userId: number) => `users/${userId}`;
export const getUserConfigPostUrl = () => "users/register";
export const getUserConfigPutUrl = (userId: number) => `users/${userId}`;
export const getUserDeleteUrl = (userId: number) => `users/${userId}`;
export const getUserSendResetPasswordMailUrl = () => "Users/RecoveryPassword";
export const getUserResetPasswordUrl = () => "Users/ResetPassword";
export const getUserChangePasswordUrl = () => "Users/ChangePassword";
export const getUserVerifyMailUrl = () => "Users/VerifyMail";
export const getUserSetPermissionsUrl = (userId: number) => `Users/${userId}/NodeCross/Permissions`;

/* geometry */
export const getGeometryUrl = (
  nodeId: number,
  format: string,
  detailLevel?: number | null,
  timeDimValue?: string | null,
  territoryDimCodelist?: string | null,
  generalizationLevel?: number | null
) =>
  `geometry/${nodeId}?format=${format}` +
  (detailLevel !== null && detailLevel !== undefined ? `&geoLevel=${detailLevel}` : "") +
  (timeDimValue !== null && timeDimValue !== undefined ? `&year=${timeDimValue}` : "") +
  (territoryDimCodelist !== null && territoryDimCodelist !== undefined
    ? `&codelistId=${encodeURIComponent(territoryDimCodelist)}`
    : "") +
  (generalizationLevel !== null && generalizationLevel !== undefined
    ? `&generalizationLevel=${encodeURIComponent(generalizationLevel)}`
    : "");

/* permissions */
export const getNodePermissionsConfigGetUrl = (nodeId: number) => `Users/Permissions/Nodes/${nodeId}`;
export const getNodePermissionsConfigPutUrl = (nodeId: number) => `Users/Permissions/Nodes/${nodeId}`;

/* dashboards */
export const getDashboardUrl = (id: number, fetchFullInfo: boolean = false) =>
  `Dashboards/${id}` + (fetchFullInfo ? "?enrichWith=FullWithAllPartialOptimizedInfo" : "");
export const getUserDashboardsUrl = () => "Dashboards";
export const getNodeDashboardsUrl = (nodeId: number) => `Dashboards/Node/${nodeId}`;
export const getHubDashboardsUrl = () => `Dashboards/Hub`;
export const getDashboardCreateUrl = () => `Dashboards/`;
export const getDashboardUpdateUrl = (id: number) => `Dashboards/${id}`;
export const getDashboardDeleteUrl = (id: number) => `Dashboards/${id}`;
export const getDashboardShareManageUrl = () => "Dashboards/Share/Manage";

// TODO: remove
export const getAddHubDashboardUrl = (dashboardId: number) => `Dashboards/${dashboardId}/Hub`;
export const getRemoveHubDashboardUrl = (dashboardId: number) => `Dashboards/${dashboardId}/Hub`;
export const getOrderHubDashboardsUrl = () => "Dashboards/Order/Hub";
export const getAddNodeDashboardUrl = (nodeId: number, dashboardId: number) =>
  `Dashboards/${dashboardId}/Nodes/${nodeId}`;
export const getRemoveNodeDashboardUrl = (nodeId: number, dashboardId: number) =>
  `Dashboards/${dashboardId}/Nodes/${nodeId}`;
export const getOrderNodeDashboardsUrl = (nodeId: number) => `Dashboards/Order/Nodes/${nodeId}`;

/* dashboard filters */
export const getFilterUrl = (id: number) => `Filters/${id}`;
export const getFiltersUrl = (includeValues: boolean = false) => `Filters?includeValues=${includeValues}`;
export const getFilterDeleteUrl = (id: number) => `Filters/${id}`;
export const getFilterInfoUrl = () => "Filters/info";
export const getFilterImportUrl = () => "Filters/import";

/* custom pages */
export const getCustomPageUrl = (id: number, fetchFullInfo: boolean = false) =>
  `CustomPages/${id}` + (fetchFullInfo ? "?enrichWith=FullWithAllPartialOptimizedInfo" : "");
export const getUserCustomPagesUrl = () => "CustomPages";
export const getNodeCustomPagesUrl = (nodeId: number) => `CustomPages/Node/${nodeId}`;
export const getHubCustomPagesUrl = () => `CustomPages/Hub`;
export const getCustomPageCreateUrl = () => `CustomPages/`;
export const getCustomPageUpdateUrl = (id: number) => `CustomPages/${id}`;
export const getCustomPageDeleteUrl = (id: number) => `CustomPages/${id}`;

/* widget */
export const getWidgetUrl = (id: string) => `Widgets/${id}?enrichWith=FullWithAllPartialOptimizedInfo`;
export const getUserWidgetsUrl = (type: string) => `Widgets/type/${type}?showOwner=true`;
export const getWidgetCreateUrl = () => `Widgets/`;
export const getWidgetUpdateUrl = (id: number) => `Widgets/${id}`;
export const getWidgetDeleteUrl = (id: number) => `Widgets/${id}`;
export const getWidgetDatasetUrl = (widgetId: string, itemId: number, lastNObservations?: number | null) =>
  `Widgets/${widgetId}/Items/${itemId}/Data/` + (lastNObservations ? `?lastNObservations=${lastNObservations}` : "");
export const getWidgetDatasetCustomDownloadUrl = (
  widgetId: string,
  itemId: number,
  format: string,
  zipped?: boolean,
  lastNObservations?: number | null
) =>
  `Widgets/${widgetId}/Items/${itemId}/Export/${format}/${zipped === true ? "true" : "false"}` +
  (lastNObservations ? `?lastNObservations=${lastNObservations}` : "");
export const getWidgetDatasetDownloadUrl = (
  widgetId: string,
  itemId: number,
  format?: string,
  zipped?: boolean,
  lastNObservations?: number | null
) =>
  `Widgets/${widgetId}/Items/${itemId}/${zipped === true ? "downloadZip" : "download"}/${format}` +
  (lastNObservations ? `?lastNObservations=${lastNObservations}` : "");

/* optimized data retriever */
export const getOptimizedDataDatasetPageSizeUrl = (nodeId: number) => `nodes/${nodeId}/PageDimension`;
export const getDimensionValuesUrl = (nodeId: number, datasetId: string, dimensionId: string) =>
  `nodes/${nodeId}/datasets/${datasetId}/DimensionValues/${dimensionId}`;
export const getNotEmptyCombinationUrl = (
  nodeId: number,
  datasetId: string,
  onlyCount: boolean = false,
  withAttributes: boolean = false
) =>
  `nodes/${nodeId}/datasets/${datasetId}/NotEmptyCombinations` +
  (onlyCount ? "/count" : "") +
  `?withAttributes=${withAttributes}`;
export const getOptimizedDataOffsetUrl = (nodeId: number, datasetId: string, offset: number) =>
  `nodes/${nodeId}/datasets/${datasetId}/Data/Offset/${offset}`;
export const getOptimizedMapDataUrl = (nodeId: number, datasetId: string) =>
  `nodes/${nodeId}/datasets/${datasetId}/TerritoryData`;
export const getSynthesisAndVariabilityMeasuresUrl = (nodeId: number, datasetId: string) =>
  `nodes/${nodeId}/datasets/${datasetId}/SynthesisAndVariabilityMeasure`;

export const getTerritorialClassificationsUrl = (detailLevel: number) => `parentLayers/${detailLevel}`;
export const getTerritorialClassificationsValuesUrl = (nodeId: number) => `nodes/${nodeId}/parents`;
