import React, {Fragment, useCallback, useEffect, useMemo, useState} from "react";
import ErrorIcon from "@mui/icons-material/Error";
import {Box, useTheme} from "@mui/material";
import _ from "lodash";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {v4 as uuidv4} from "uuid";
import Call from "../../../hocs/call/index";
import usePrevious from "../../../hooks/usePrevious";
import CustomEmpty from "../../custom-empty";
import AttributeDialog from "../AttributeDialog";
import {MAP_LAYERS_ALL} from "../constants";
import GeometriesWarningDialog from "../GeometriesWarningDialog";
import {
  clearMapGeometry,
  fetchMapGeometry,
  hideMapNoGeometryWarning,
  setMapGeometry,
  setMapGeometryChangedStatus
} from "../../../state/maps/mapsActions";
import {getFormattedValue} from "../../../utils/formatters";
import {isNumeric, isValidIntegerInInclusiveRange} from "../../../utils/validator";
import {
  getCleanedSetting,
  getGeneralizationLevelFromZoomLevel,
  getLayerOptions,
  getOverlayImageStyle,
  getOverlayStyle,
  getOverlayTextStyle,
  getTranslationOptions,
  isSettingsChanged
} from "../utils";
import "../style.css";

const mapStateToProps = state => {
  return {
    geoglossaryBaseUrl: state.config.externalServices?.geoglossary,
    defaultLanguage: state.app.language,
    languages: state.app.languages,
    defaultExtent: state.appConfig.mapConfig.defaultExtent,
    geometryBorderColor: state.appConfig.mapConfig.geometryBorderColor,
    zoomLevelStepsMap: state.appConfig.mapConfig.zoomLevelStepsMap,
    baseMap: state.hub?.hub?.extras?.BaseMap || null,
    mapCopyright: state.hub?.hub?.extras?.MapCopyright || null,
    mapLayer: state.hub?.hub?.extras?.MapLayer || null,
    mapLayersConfig: state.maps.mapLayersConfig,
    maps: state.maps.maps
  };
};

const mapDispatchToProps = dispatch => ({
  onGeometryFetch: (
    mapStateUuid,
    nodeId,
    territoryDimValues,
    format,
    detailLevel,
    timeDimValue,
    territoryDimCodelist,
    extent,
    generalizationLevel,
    baseUrl
  ) =>
    dispatch(
      fetchMapGeometry(
        mapStateUuid,
        nodeId,
        territoryDimValues,
        format,
        baseUrl,
        detailLevel,
        timeDimValue,
        territoryDimCodelist,
        extent,
        generalizationLevel
      )
    ),
  onGeometrySet: (mapStateUuid, geometries) => dispatch(setMapGeometry(mapStateUuid, geometries)),
  onGeometryChangedStatusSet: (mapStateUuid, changed) => dispatch(setMapGeometryChangedStatus(mapStateUuid, changed)),
  onGeometryClear: mapStateUuid => dispatch(clearMapGeometry(mapStateUuid)),
  onWarningHide: mapStateUuid => dispatch(hideMapNoGeometryWarning(mapStateUuid))
});

const getInitialLocationObject = (generalizationLevels, enablePagination) => ({
  extent: null,
  generalizationLevel:
    generalizationLevels === undefined || generalizationLevels === null || enablePagination !== true
      ? null
      : (generalizationLevels || []).length > 0
        ? generalizationLevels[generalizationLevels.length - 1]
        : 0
});

const RENDER_TYPE_NONE = "none";
const RENDER_TYPE_UPDATE_LAYER = "updateLayer";
const RENDER_TYPE_UPDATE_LAYER_DATA = "updateLayerData";

const LOCATION_CHANGE_TIMEOUT = 400;

const Map = ({
  geoglossaryBaseUrl,
  defaultLanguage,
  languages,
  defaultExtent,
  geometryBorderColor,
  baseMap,
  mapCopyright,
  mapLayer,
  mapLayersConfig,
  maps,

  onGeometryFetch,
  onGeometrySet,
  onGeometryChangedStatusSet,
  onGeometryClear,
  onWarningHide,

  mapId: externalMapId,
  nodeId,
  dimensionIds,
  territoryDim,
  territories,
  geometriesYear = null,
  layout,
  getFilterTree,
  data,
  getLayoutGeometries,
  getObservationValue,
  labelFormat,
  decimalSeparator,
  roundingStrategy,
  decimalPlaces,
  isFullscreen,
  detailLevel,
  territoryDimCodelist,
  initialBaseLayer,
  defaultSettings,
  settings,
  setSettings,
  onDataClick,
  disableZoomToClickedData = false,
  onDataSelect,
  selectedTerritoryId,
  filteredTerritoryIds,
  onSettingsShow,
  showSelection = false,
  disableBaseLayer = false,
  disableSettings = false,
  showSingleGeometry = false,
  disableWheelZoom = false,
  isPointData = false,
  latAttributeId,
  longAttributeId,
  srid,
  onRenderStart,
  onRenderFinish,
  generalizationLevels,
  zoomLevelStepsMap,
  logGeneralizationError = false
}) => {
  const {t} = useTranslation();

  const theme = useTheme();

  const zoomLevelSteps = useMemo(() => zoomLevelStepsMap?.[detailLevel] || [], [zoomLevelStepsMap, detailLevel]);

  const paginationEnabled = useMemo(() => {
    if ((generalizationLevels || []).length === 0) {
      return false;
    } else {
      if ((zoomLevelSteps || []).length === 0) {
        if (logGeneralizationError) {
          console.error(
            `appConfig's key "zoomLevelStepsMap" is missing or it's value is empty for current detail level.`
          );
        }
        return false;
      } else if ((zoomLevelSteps || []).findIndex(v => !isNumeric(v)) > -1) {
        if (logGeneralizationError) {
          console.error(`appConfig's key "zoomLevelStepsMap" value must contains only numeric values.`);
        }
        return false;
      } else if ((zoomLevelSteps || []).length !== (generalizationLevels || []).length - 1) {
        if (logGeneralizationError) {
          console.error(
            `appConfig's key "zoomLevelStepsMap" value's length is not correct for current detail level:` +
              `is ${(zoomLevelSteps || []).length} instead of ${(generalizationLevels || []).length - 1}.`
          );
        }
        return false;
      } else {
        return true;
      }
    }
  }, [zoomLevelSteps, generalizationLevels, logGeneralizationError]);

  const [mapId, setMapId] = useState(null);

  const [legendTitle] = useState(null);

  const prevData = usePrevious(data);
  const prevLayout = usePrevious(layout);

  const [isFetchEnabled, setIsFetchEnabled] = useState(true);
  const [fetchedGeometries, setFetchedGeometries] = useState(null);
  const [allGeometriesMap, setAllGeometriesMap] = useState({});

  const [geometries, setGeometries] = useState(null);
  const [geometryMap, setGeometryMap] = useState(null);
  const [territoryMap, setTerritoryMap] = useState(null);

  const prevSelectedTerritoryId = usePrevious(selectedTerritoryId);
  const prevFilteredTerritoryIds = usePrevious(filteredTerritoryIds);

  const [mapSettings, setMapSettings] = useState(null);
  const [mapDefaultSettings, setMapDefaultSettings] = useState(null);

  const [renderType, setRenderType] = useState(RENDER_TYPE_NONE);

  const [geometryId, setGeometryId] = useState(null);

  const [dimensionValueTree, setDimensionValueTree] = useState(null);

  const [fullExtent, setFullExtent] = useState(null);

  const [locationObject, setLocationObject] = useState(
    getInitialLocationObject(generalizationLevels, paginationEnabled)
  );

  const [isSpinnerVisible, setSpinnerVisibility] = useState(true);

  const showSpinner = useCallback(() => {
    setSpinnerVisibility(true);
    onRenderStart();
  }, [onRenderStart]);

  const hideSpinner = useCallback(() => {
    setSpinnerVisibility(false);
    onRenderFinish();
  }, [onRenderFinish]);

  const fetchGeometries = useCallback(
    cbParam => {
      const {territories, geometriesYear, locationObject} = cbParam;

      showSpinner();

      if (isPointData) {
        const geometries = territories.map((value, idx) => ({
          id: value,
          uniqueId: idx + 1
        }));
        onGeometrySet(mapId, geometries);
      } else {
        const extent =
          paginationEnabled === true
            ? window.mapVariables[mapId].selectedTerritory
              ? null
              : locationObject?.extent || null
            : null;
        const generalizationLevel =
          paginationEnabled === true
            ? window.mapVariables[mapId].selectedTerritory
              ? null
              : locationObject?.generalizationLevel || 0
            : null;

        window.mapVariables[mapId].locationObjectUsed = {
          extent: extent,
          generalizationLevel: generalizationLevel
        };

        const values = window.mapVariables[mapId].selectedTerritory
          ? [window.mapVariables[mapId].selectedTerritory]
          : territories;

        onGeometryFetch(
          mapId,
          nodeId,
          values,
          "geojson",
          detailLevel,
          geometriesYear,
          territoryDimCodelist,
          extent,
          generalizationLevel,
          geoglossaryBaseUrl ?? undefined
        );
      }
    },
    [
      mapId,
      nodeId,
      detailLevel,
      territoryDimCodelist,
      isPointData,
      onGeometryFetch,
      onGeometrySet,
      paginationEnabled,
      showSpinner,
      geoglossaryBaseUrl
    ]
  );

  // handle mapId and destroy map on component unmount
  useEffect(() => {
    const mapId = externalMapId || uuidv4();
    setMapId(mapId);

    window.mapVariables = window.mapVariables || {};
    window.mapVariables[mapId] = {
      isFirstRender: true,
      areGeometriesUpdated: false,
      areGeometriesChanged: true,
      locationObjectUsed: getInitialLocationObject(null, paginationEnabled),
      locationChangeTimeOutId: null,
      selectedTerritory: null
    };

    return () => {
      window.LMap.destroyMap(mapId);

      onGeometryClear(mapId);
      setIsFetchEnabled(true);

      if (onDataSelect) {
        onDataSelect(null);
      }

      window.mapVariables[mapId] = null;
    };
  }, [externalMapId, onGeometryClear, onDataSelect, paginationEnabled]);

  // handle fullscreen
  useEffect(() => {
    if (mapId) {
      window.LMap.handleViewportChange(mapId);
    }
  }, [mapId, isFullscreen]);

  // handle resize
  useEffect(() => {
    if (mapId) {
      const func = () => window.LMap.handleViewportChange(mapId);
      window.addEventListener("resize", func);
      return () => {
        window.removeEventListener("resize", func);
      };
    }
  }, [mapId]);

  // init map
  useEffect(() => {
    if (mapId && !window.LMap.isInitialized(mapId)) {
      const {baseLayer, layers} = getLayerOptions(
        mapLayer,
        initialBaseLayer,
        mapLayersConfig,
        defaultLanguage,
        languages
      );

      const mapOptions = {
        showZoom: true,
        showScale: true,
        baseLayer: baseLayer,
        layers: layers,
        defaultExtent: defaultExtent,
        showBaseLayerRadio: !disableBaseLayer && mapLayer === MAP_LAYERS_ALL && (layers || []).length > 1,
        showSelection: showSelection,
        disableWheelZoom: disableWheelZoom,
        onSettingsChange: settings => {
          if (setSettings) {
            setSettings(getCleanedSetting(settings));
          }
        },
        onLegendCollapsedChange: isCollapsed => {
          if (setSettings) {
            setSettings({isLegendCollapsed: isCollapsed});
          }
        },
        onBaseLayerChange: baseLayer => {
          if (setSettings) {
            setSettings({baseLayer: baseLayer});
          }
        },
        onSettingsShow: settings => {
          if (onSettingsShow) {
            onSettingsShow(settings);
          }
        },
        legendFontFamily: "Roboto",
        primaryColor: theme.palette.primary.main,
        isBaseLayerRadioPopupClosable: true
      };

      const mapTranslations = getTranslationOptions(t);

      window.LMap.initMap(mapId, mapOptions, mapTranslations);
    }
  }, [
    mapId,
    defaultLanguage,
    languages,
    defaultExtent,
    baseMap,
    mapCopyright,
    mapLayer,
    mapLayersConfig,
    initialBaseLayer,
    disableWheelZoom,
    disableBaseLayer,
    showSelection,
    setSettings,
    onSettingsShow,
    t,
    theme.palette.primary.main
  ]);

  // handle data, layout and location changes
  useEffect(() => {
    if (mapId) {
      const isDataChanged = !_.isEqual(prevData, data);

      const isLayoutChanged = !_.isEqual(prevLayout, layout);

      const usedLocalizationLevel = window.mapVariables[mapId].locationObjectUsed.generalizationLevel;
      const currLocalizationLevel = locationObject.generalizationLevel;

      const usedExtent = window.mapVariables[mapId].locationObjectUsed.extent;
      const currExtent = locationObject.extent;

      // TODO: facendo 2 fetch con geometrie vuote rimane lo spinner
      const isLocationChanged =
        usedLocalizationLevel !== currLocalizationLevel ||
        (usedExtent &&
          currExtent &&
          fullExtent &&
          window.LMap.extent3IntersectsDifferenceExtent1Extent2(currExtent, usedExtent, fullExtent)) ||
        false;

      if (isDataChanged || isLayoutChanged || isLocationChanged) {
        setRenderType(RENDER_TYPE_NONE);

        setIsFetchEnabled(true);

        window.mapVariables[mapId].areGeometriesUpdated = false;

        showSpinner();
      }

      if (isDataChanged || isLayoutChanged) {
        setGeometries(null);
        setGeometryMap(null);

        setLocationObject(prevLocationObject => ({
          extent: null,
          generalizationLevel: window.LMap.panOrZoomChanged(mapId)
            ? getInitialLocationObject(generalizationLevels, paginationEnabled).generalizationLevel
            : prevLocationObject.generalizationLevel
        }));

        if (onDataSelect) {
          onDataSelect(null);
        }

        window.mapVariables[mapId].isFirstRender = true;
      }

      setDimensionValueTree(prevVal => {
        const layoutDims = [territoryDim, ...layout.filters];
        if (getFilterTree && dimensionIds.length !== layoutDims.length && (isDataChanged || !prevVal)) {
          const orderedDims = [...layoutDims, ...dimensionIds.filter(dim => !layoutDims.includes(dim))];
          return getFilterTree(orderedDims, data);
        } else {
          return prevVal;
        }
      });
    }
  }, [
    mapId,
    dimensionIds,
    territoryDim,
    prevData,
    data,
    prevLayout,
    layout,
    isPointData,
    geometriesYear,
    generalizationLevels,
    paginationEnabled,
    locationObject,
    fullExtent,
    onDataSelect,
    getFilterTree,
    showSpinner
  ]);

  // handle new fetched geometries
  useEffect(() => {
    if (mapId && maps?.[mapId]?.changed === true) {
      const geometries = maps[mapId].geometries;

      setFetchedGeometries(geometries);
      onGeometryChangedStatusSet(mapId, false);

      setIsFetchEnabled(false);

      setAllGeometriesMap(prevGeometries => {
        return geometries.reduce((newGeometries, geometry) => {
          newGeometries[geometry.id] = geometry.uniqueId;
          return newGeometries;
        }, prevGeometries);
      });

      window.mapVariables[mapId].areGeometriesUpdated = true;
    }
  }, [mapId, maps, showSingleGeometry, onGeometryChangedStatusSet, t]);

  // calculating filtered geometries
  useEffect(() => {
    if (mapId && window.mapVariables[mapId].areGeometriesUpdated && fetchedGeometries !== null) {
      showSpinner();

      const {layoutGeometries, geometryMap, territoryMap} = getLayoutGeometries(
        fetchedGeometries,
        allGeometriesMap,
        dimensionValueTree
      );

      setGeometries(layoutGeometries);
      if (window.mapVariables[mapId].isFirstRender) {
        setGeometryMap(geometryMap);
        setTerritoryMap(territoryMap);
      }

      window.mapVariables[mapId].areGeometriesChanged = true;
    }
  }, [mapId, fetchedGeometries, allGeometriesMap, dimensionValueTree, getLayoutGeometries, showSpinner]);

  // handle map default settings
  useEffect(() => {
    setMapDefaultSettings(prevDefaultSettings =>
      prevDefaultSettings === null ? defaultSettings || {} : prevDefaultSettings
    );
  }, [defaultSettings]);

  // handle map settings
  useEffect(() => {
    setMapSettings(prevSettings => (isSettingsChanged(settings, prevSettings) ? settings : prevSettings));
  }, [settings]);

  // add or update layer with filtered geometries
  useEffect(() => {
    if (geometries !== null && mapSettings !== null && mapDefaultSettings !== null) {
      showSpinner();

      let newSettings = {};
      if (window.mapVariables[mapId].isFirstRender) {
        newSettings = {...newSettings, ...mapDefaultSettings};
      }
      newSettings = {...newSettings, ...mapSettings};

      setTimeout(() => {
        if (!window.mapVariables[mapId].areGeometriesChanged) {
          window.LMap.updateLayerSettings(mapId, newSettings);
        } else {
          if (window.mapVariables[mapId].isFirstRender) {
            const layerOptions = {
              srid: `EPSG:${srid || 4326}`,
              useGeoJSON: true,
              settings: newSettings,
              onDataRender: () => {
                if (window.mapVariables[mapId]) {
                  if (window.mapVariables[mapId].selectedTerritory) {
                    window.LMap.zoomToData(mapId, territoryMap[window.mapVariables[mapId].selectedTerritory]);
                    window.mapVariables[mapId].selectedTerritory = null;
                  }
                  if (window.mapVariables[mapId].isFirstRender) {
                    const newFullExtent = window.LMap.getLayerExtent(mapId);
                    const newFullExtentProjected = window.LMap.ProjectExtent(mapId, newFullExtent, "EPSG:4326");

                    window.LMap.setFullExtent(mapId, newFullExtent);
                    setFullExtent(newFullExtentProjected);
                  }
                  setRenderType(
                    window.mapVariables[mapId].isFirstRender ? RENDER_TYPE_UPDATE_LAYER : RENDER_TYPE_UPDATE_LAYER_DATA
                  );
                  window.mapVariables[mapId].isFirstRender = false;
                  setTimeout(() => {
                    if (geometries.length > 0) {
                      hideSpinner();
                    }
                  }, 200);
                }
              },
              borderColor: geometryBorderColor,
              valueFormatter: val => getFormattedValue(val, decimalSeparator, decimalPlaces, "", roundingStrategy),
              disableSettings: disableSettings,
              dataInfoDuration: 1000,
              onDataClick: (id, isActive, isSelected) => {
                if (id) {
                  if (onDataClick) {
                    onDataClick(geometryMap[id], isActive, isSelected);
                  }
                } else {
                  if (onDataClick) {
                    onDataClick(null, isActive, isSelected);
                  }
                }
              },
              zoomToClickedData: !disableZoomToClickedData,
              onDataSelect: selected => {
                if (onDataSelect) {
                  const territories = [];
                  (selected || []).forEach(geomId => territories.push(geometryMap[geomId].territoryId));
                  if (territories.length > 0) {
                    onDataSelect(territories);
                  } else {
                    onDataSelect(null);
                  }
                }
              },
              onDataTooltipClick: id => {
                if (geometryMap[id].hasAttributes) {
                  setGeometryId(id);
                }
              },
              onLocationChanged: (extentToProject, zoomLevel) => {
                if (!isPointData && paginationEnabled === true) {
                  if (window.mapVariables[mapId].locationChangeTimeOutId) {
                    clearTimeout(window.mapVariables[mapId].locationChangeTimeOutId);
                  }
                  window.mapVariables[mapId].locationChangeTimeOutId = setTimeout(() => {
                    setLocationObject({
                      extent: window.LMap.ProjectExtent(mapId, extentToProject, "EPSG:4326"),
                      generalizationLevel: getGeneralizationLevelFromZoomLevel(
                        zoomLevel,
                        zoomLevelSteps,
                        generalizationLevels
                      )
                    });
                  }, LOCATION_CHANGE_TIMEOUT);
                }
              }
            };
            window.LMap.updateLayer(mapId, geometries, legendTitle, layerOptions);

            if (setSettings) {
              const settings = window.LMap.getSettings(mapId);
              if (isSettingsChanged(newSettings, settings)) {
                setSettings(getCleanedSetting(settings));
              }
            }
          } else {
            window.LMap.updateLayerData(mapId, geometries, window.mapVariables[mapId].selectedTerritory !== null);
          }

          window.mapVariables[mapId].areGeometriesChanged = false;
        }
      }, 0);
    }
  }, [
    mapId,
    isPointData,
    srid,
    geometries,
    geometryMap,
    territoryMap,
    mapSettings,
    mapDefaultSettings,
    geometryBorderColor,
    decimalSeparator,
    roundingStrategy,
    decimalPlaces,
    disableZoomToClickedData,
    onDataClick,
    onDataSelect,
    disableSettings,
    legendTitle,
    setSettings,
    paginationEnabled,
    generalizationLevels,
    zoomLevelSteps,
    showSpinner,
    hideSpinner
  ]);

  // handle external geometries filter changes
  useEffect(() => {
    if (mapId && renderType !== RENDER_TYPE_NONE && territoryMap) {
      if (renderType === RENDER_TYPE_UPDATE_LAYER || filteredTerritoryIds !== prevFilteredTerritoryIds) {
        let geometryIds;
        if (filteredTerritoryIds === null) {
          geometryIds = null;
        } else {
          geometryIds = (filteredTerritoryIds || []).map(territoryId => territoryMap[territoryId]).filter(el => el);
        }
        window.LMap.setDataFilter(mapId, geometryIds);
      }
    }
  }, [mapId, renderType, territoryMap, filteredTerritoryIds, prevFilteredTerritoryIds]);

  // handle zoom to data
  useEffect(() => {
    if (mapId && renderType !== RENDER_TYPE_NONE && territoryMap) {
      if (renderType === RENDER_TYPE_UPDATE_LAYER || selectedTerritoryId !== prevSelectedTerritoryId) {
        if (selectedTerritoryId === null) {
          window.LMap.zoomToData(mapId, null);
        } else {
          const valorizedData = window.LMap.getDataIds(mapId, geom => (geom?.geometry?.coordinates || []).length > 0);
          const geometryToZoom = territoryMap[selectedTerritoryId];
          if (valorizedData.includes(selectedTerritoryId)) {
            window.LMap.zoomToData(mapId, geometryToZoom);
          } else {
            window.LMap.zoomToData(mapId, null);
            const value = getObservationValue(selectedTerritoryId);
            if (value !== null && value !== undefined) {
              window.mapVariables[mapId].selectedTerritory = selectedTerritoryId;
              setIsFetchEnabled(true);
            } else {
              window.error.show(t("components.map.dialogs.missingGeometry.title"), "warning");
            }
          }
        }
      }
    }
  }, [mapId, renderType, territoryMap, selectedTerritoryId, prevSelectedTerritoryId, getObservationValue, t]);

  if (!mapId) {
    return <span />;
  }

  const territoryDimMissing = !territoryDim || !dimensionIds.includes(territoryDim);
  const detailLevelMissing = isPointData ? false : !isValidIntegerInInclusiveRange(detailLevel);

  if (territoryDimMissing || detailLevelMissing) {
    return <span />;
  }

  const geometriesMissing = geometryMap && _.isEmpty(geometryMap);
  const geometriesFetchingError = maps[mapId]?.error;

  const isMapUnavailable = geometriesMissing || geometriesFetchingError;

  let unavailableMapErrorText;
  if (isMapUnavailable) {
    if (!isSpinnerVisible) {
      showSpinner();
    }
    if (geometriesMissing) {
      unavailableMapErrorText = t("components.map.noDataToDisplay");
    } else if (geometriesFetchingError) {
      unavailableMapErrorText = t("components.map.fetchingGeometriesError");
    } else {
      unavailableMapErrorText = null;
    }
  }

  return (
    <Fragment>
      <Call
        cb={fetchGeometries}
        cbParam={{
          territories: territories,
          geometriesYear: geometriesYear,
          locationObject: locationObject
        }}
        disabled={territoryDimMissing || !isFetchEnabled}
      >
        <Box
          id={`${mapId}__wrapper`}
          sx={{
            position: "relative",
            width: "100%",
            height: "100%"
          }}
          className={` map`}
          aria-hidden={true}
        >
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              height: "100%",
              zIndex: 2,
              visibility: "hidden"
            }}
            className={` map__overlay map__overlay--transparent ` + (isSpinnerVisible ? "map__overlay--visible" : "")}
          >
            <CustomEmpty
              text={isMapUnavailable ? unavailableMapErrorText : t("components.map.spinners.loading") + "..."}
              style={getOverlayStyle(theme, false)}
              textStyle={getOverlayTextStyle(theme, false)}
              imageStyle={getOverlayImageStyle(theme, false)}
              image={isMapUnavailable ? <ErrorIcon /> : null}
            />
          </Box>
          <Box
            id={mapId}
            sx={{
              position: "absolute",
              width: "100%",
              height: "100%",
              zIndex: 0,
              filter: "blur(0)"
            }}
            className={`map__lmap-container`}
          />
        </Box>
      </Call>
      <AttributeDialog
        isOpen={geometryId !== null}
        onClose={() => setGeometryId(null)}
        obsAttributes={geometryMap?.[geometryId]?.obsAttributes}
        dimAttributes={geometryMap?.[geometryId]?.dimAttributes}
        labelFormat={labelFormat}
      />
      <GeometriesWarningDialog
        isOpen={maps[mapId]?.isNoGeometryWarningVisible === true}
        onClose={() => onWarningHide(mapId)}
      />
    </Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Map);
