import {
  CHART_DIM_ORDER_SELECTOR_VALUE_ASC,
  CHART_DIM_ORDER_SELECTOR_VALUE_DEFAULT,
  CHART_DIM_ORDER_SELECTOR_VALUE_DESC,
  CHART_SORTING_VALUE_DEFAULT
} from "../../components/chart/constants";

/** criteria selection mode **/
export const ALL_FULL = "FullAll";
export const ALL_PARTIAL = "PartialAll";
export const ALL_PARTIAL_OPTIMIZED = "PartialAllOptimized";
export const STEP_BY_STEP_FULL = "FullStep";
export const STEP_BY_STEP_PARTIAL = "PartialStep";
export const STEP_BY_STEP_DYNAMIC = "Dynamic";

export const CRITERIA_SELECTION_MODE_STEP_BY_STEP = "stepByStep";
export const CRITERIA_SELECTION_MODE_STEP_BY_STEP_VALUES = [
  STEP_BY_STEP_FULL,
  STEP_BY_STEP_PARTIAL,
  STEP_BY_STEP_DYNAMIC
];

export const CRITERIA_SELECTION_MODE_ALL = "all";
export const CRITERIA_SELECTION_MODE_ALL_VALUES = [ALL_FULL, ALL_PARTIAL, ALL_PARTIAL_OPTIMIZED];

export const CRITERIA_SELECTION_TYPE_FULL = "full";
export const CRITERIA_SELECTION_TYPE_PARTIAL = "partial";
export const CRITERIA_SELECTION_TYPE_DYNAMIC = "dynamic";

/** timings **/
export const GENERATING_HTML_TIME_KEY = "generatingHtml";
export const OBSERVATION_COUNT_KEY = "observationCount";
export const SERVER_TIMINGS_KEY = "serverTimings";

/** types **/
export enum ViewerMode {
  SingleViewer = "SINGLE_VIEWER",
  SingleViewerPlus = "SINGLE_VIEWER_PLUS",
  MultiViewer = "MULTI_VIEWER",
  MultiViewerPlus = "MULTI_VIEWER_PLUS",
  Error = "VIEWER_ERROR"
}

export type Criteria = {
  id: string;
  type: string;
  filterValues?: string[];
  period?: number;
  from?: string;
  to?: string;
  hierarchyId?: string;
};

export type TimePeriod = {
  selectorType: string;
  freq: string;
  minDate: string;
  maxDate: string;
  fromDate: string;
  toDate: string;
  periods: number;
  missingRange: boolean;
};

export type chartSorting = "DEFAULT" | "ASC" | "DESC";

export type ChartSettings = {
  stacked?: boolean;
  legendPosition?: string;
  showAxesLabel?: boolean;
  dataLabelType?: string;
  colors?: any;
  customizeCategoryAxis?: boolean;
  categoryAxisLabel?: {[key: string]: string};
  valueAxisLabel?: {[key: string]: string};
  obsSortingOrderCartesian?: chartSorting;
  obsSortingValueCartesian?: string;
  obsSortingOrderRadial?: chartSorting;
  obsSortingValueRadial?: string;
};

export type MapSettings = {
  baseLayer?: string | null;
  classificationMethod?: string;
  paletteStartColor?: string;
  paletteEndColor?: string;
  paletteCardinality?: number;
  opacity?: number;
  isLegendCollapsed?: boolean;
  customIntervals?: any[] | null;
};

export type DatasetState = {
  viewerMode: ViewerMode | null;

  mode: string | null;
  type: string | null;

  isFetchStructureDisabled: boolean;

  datasetId: string | null;
  nodeCode: string | null;
  categoryPath: string[] | null;
  viewId: string | null;

  datasetCacheInfo: any | null;
};

export const HEADER_CELLS_TYPE = 1;
export const SIDE_CELLS_TYPE = 2;
export const JOIN_CELLS_ACTION = "join";
export const SPLIT_CELLS_ACTION = "split";

export const decodeLayoutAggregateCells = (value: string, cellsType: number) => {
  const regex = new RegExp(/^(join|split),(join|split)$/);
  if (value && regex.test(value.toLowerCase())) {
    const match = regex.exec(value.toLowerCase());
    if (match) {
      return match[cellsType] === SPLIT_CELLS_ACTION;
    }
  }
  return false;
};

export const getChartSortingSettingsFromStructure = (structure: any) => {
  const {layoutChartObsOrderCartesian, layoutChartObsOrderRadial} = structure;

  const validOrders = [CHART_DIM_ORDER_SELECTOR_VALUE_ASC, CHART_DIM_ORDER_SELECTOR_VALUE_DESC];

  const getSortingSettings = (annotation: string = "") => {
    const [order, value] = annotation.split("|");
    return {
      order: order && validOrders.includes(order) ? order : CHART_DIM_ORDER_SELECTOR_VALUE_DEFAULT,
      value: value || CHART_SORTING_VALUE_DEFAULT
    };
  };

  const sortingCartesian = getSortingSettings(layoutChartObsOrderCartesian);
  const sortingRadial = getSortingSettings(layoutChartObsOrderRadial);

  return {
    obsSortingOrderCartesian: sortingCartesian.order,
    obsSortingValueCartesian: sortingCartesian.value,
    obsSortingOrderRadial: sortingRadial.order,
    obsSortingValueRadial: sortingRadial.value
  };
};
