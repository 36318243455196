import React, {Fragment, useCallback, useEffect, useState} from "react";
import ErrorIcon from "@mui/icons-material/Error";
import {Box, useTheme} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import _ from "lodash";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {v4 as uuidv4} from "uuid";
import Call from "../../../hocs/call/index";
import usePrevious from "../../../hooks/usePrevious";
import CustomEmpty from "../../custom-empty";
import CustomSelect from "../../custom-select";
import AttributeDialog from "../AttributeDialog";
import {MAP_LAYERS_ALL} from "../constants";
import GeometriesWarningDialog from "../GeometriesWarningDialog";
import {
  clearMapGeometry,
  fetchMapGeometry,
  hideMapNoGeometryWarning,
  setMapGeometryChangedStatus
} from "../../../state/maps/mapsActions";
import {getFormattedValue} from "../../../utils/formatters";
import {
  getCleanedSetting,
  getDetailLevelsFromGeometries,
  getLayerOptions,
  getLayoutGeometriesFromJsonStat,
  getOverlayImageStyle,
  getOverlayStyle,
  getOverlayTextStyle,
  getTerritoriesWithValue,
  getTranslationOptions,
  isSettingsChanged
} from "../utils";
import "../style.css";

const mapStateToProps = state => {
  return {
    defaultLanguage: state.app.language,
    languages: state.app.languages,
    defaultExtent: state.appConfig.mapConfig.defaultExtent,
    geometryBorderColor: state.appConfig.mapConfig.geometryBorderColor,
    levelsOrder: state.appConfig.mapConfig.levelsOrder,
    baseMap: state.hub?.hub?.extras?.BaseMap || null,
    mapCopyright: state.hub?.hub?.extras?.MapCopyright || null,
    mapLayer: state.hub?.hub?.extras?.MapLayer || null,
    mapLayersConfig: state.maps.mapLayersConfig,
    maps: state.maps.maps
  };
};

const mapDispatchToProps = dispatch => ({
  onGeometryFetch: (mapStateUuid, nodeId, territoryDimValues, format) =>
    dispatch(fetchMapGeometry(mapStateUuid, nodeId, territoryDimValues, format)),
  onGeometryChangedStatusSet: (mapStateUuid, changed) => dispatch(setMapGeometryChangedStatus(mapStateUuid, changed)),
  onGeometryClear: mapStateUuid => dispatch(clearMapGeometry(mapStateUuid)),
  onWarningHide: mapStateUuid => dispatch(hideMapNoGeometryWarning(mapStateUuid))
});

function Map(props) {
  const {
    defaultLanguage,
    languages,
    defaultExtent,
    geometryBorderColor,
    levelsOrder,
    baseMap,
    mapCopyright,
    mapLayer,
    mapLayersConfig,
    maps,

    onGeometryFetch,
    onGeometryChangedStatusSet,
    onGeometryClear,
    onWarningHide,

    mapId: externalMapId,
    nodeId,
    jsonStat,
    layout,
    labelFormat,
    decimalSeparator,
    roundingStrategy,
    decimalPlaces,
    isFullscreen,
    defaultDetailLevel = null,
    onDetailLevelChange,
    disableDetailLevelSelector,
    initialBaseLayer,
    defaultSettings,
    settings,
    setSettings,
    onSettingsShow,
    showSelection = false,
    disableBaseLayer = false,
    disableSettings = false,
    showSingleGeometry = false,
    disableWheelZoom = false,
    localizedTimePeriodFormatMapExternal
  } = props;

  const {t} = useTranslation();
  const theme = useTheme();
  const [mapId, setMapId] = useState(null);

  const [legendTitle] = useState(null);

  const prevJsonStat = usePrevious(jsonStat);

  const [isFetchEnabled, setIsFetchEnabled] = useState(true);
  const [fetchedGeometries, setFetchedGeometries] = useState(null);

  const [internalDetailLevels, setInternalDetailLevels] = useState(null);
  const [internalDetailLevel, setInternalDetailLevel] = useState(defaultDetailLevel);

  const [geometries, setGeometries] = useState(null);
  const [geometryMap, setGeometryMap] = useState(null);

  const [filteredGeometries, setFilteredGeometries] = useState(null);

  const [mapSettings, setMapSettings] = useState(null);
  const [mapDefaultSettings, setMapDefaultSettings] = useState(null);

  const [geometryId, setGeometryId] = useState(null);

  const [isSpinnerVisible, setSpinnerVisibility] = useState(true);

  const showSpinner = useCallback(() => {
    setSpinnerVisibility(true);
  }, []);

  const hideSpinner = useCallback(() => {
    setSpinnerVisibility(false);
  }, []);

  const fetchGeometry = useCallback(
    cbParam => {
      const {nodeId, jsonStat, layout, mapStateUuid} = cbParam;

      showSpinner();

      const territoryDim = layout.territoryDim;
      const filteredTerritoryDimValues = getTerritoriesWithValue(jsonStat, territoryDim);

      onGeometryFetch(mapStateUuid, nodeId, filteredTerritoryDimValues, "wkt");
    },
    [onGeometryFetch, showSpinner]
  );

  // handle mapId and destroy map on component unmount
  useEffect(() => {
    const mapId = externalMapId || uuidv4();
    setMapId(mapId);

    window[`${mapId}_isFirstRender`] = true;
    window[`${mapId}_areGeometriesUpdated`] = false;
    window[`${mapId}_areGeometriesChanged`] = true;

    return () => {
      window.LMap.destroyMap(mapId);

      onGeometryClear(mapId);
      setIsFetchEnabled(true);

      window[`${mapId}_isFirstRender`] = true;
      window[`${mapId}_areGeometriesUpdated`] = false;
      window[`${mapId}_areGeometriesChanged`] = true;
    };
  }, [externalMapId, onGeometryClear]);

  // handle fullscreen
  useEffect(() => {
    if (mapId) {
      window.LMap.handleViewportChange(mapId);
    }
  }, [mapId, isFullscreen]);

  // handle resize
  useEffect(() => {
    if (mapId) {
      const func = () => window.LMap.handleViewportChange(mapId);
      window.addEventListener("resize", func);
      return () => {
        window.removeEventListener("resize", func);
      };
    }
  }, [mapId]);

  // init map
  useEffect(() => {
    if (mapId && !window.LMap.isInitialized(mapId)) {
      const {baseLayer, layers} = getLayerOptions(
        mapLayer,
        initialBaseLayer,
        mapLayersConfig,
        defaultLanguage,
        languages
      );

      const mapOptions = {
        showZoom: true,
        showScale: false,
        baseLayer: baseLayer,
        layers: layers,
        defaultExtent: defaultExtent,
        showBaseLayerRadio: !disableBaseLayer && mapLayer === MAP_LAYERS_ALL && (layers || []).length > 1,
        showSelection: showSelection,
        disableWheelZoom: disableWheelZoom,
        onSettingsChange: settings => {
          if (setSettings) {
            setSettings(getCleanedSetting(settings));
          }
        },
        onLegendCollapsedChange: isCollapsed => {
          if (setSettings) {
            setSettings({isLegendCollapsed: isCollapsed});
          }
        },
        onBaseLayerChange: baseLayer => {
          if (setSettings) {
            setSettings({baseLayer: baseLayer});
          }
        },
        onSettingsShow: settings => {
          if (onSettingsShow) {
            onSettingsShow(settings);
          }
        },
        legendFontFamily: "Roboto",
        primaryColor: theme.palette.text.primary,
        isBaseLayerRadioPopupClosable: true
      };

      const mapTranslations = getTranslationOptions(t);

      window.LMap.initMap(mapId, mapOptions, mapTranslations);
    }
  }, [
    mapId,
    defaultLanguage,
    languages,
    defaultExtent,
    baseMap,
    mapCopyright,
    mapLayer,
    mapLayersConfig,
    initialBaseLayer,
    disableWheelZoom,
    disableBaseLayer,
    showSelection,
    setSettings,
    onSettingsShow,
    t,
    theme.palette.text.primary
  ]);

  // handle jsonStat changes
  useEffect(() => {
    if (mapId) {
      showSpinner();

      if (!_.isEqual(prevJsonStat, jsonStat)) {
        setGeometries(null);
        setGeometryMap(null);

        setFilteredGeometries(null);

        setIsFetchEnabled(true);

        window[`${mapId}_areGeometriesUpdated`] = false;
      }
    }
  }, [mapId, prevJsonStat, jsonStat, showSpinner]);

  // handle new fetched geometries
  useEffect(() => {
    if (mapId && maps?.[mapId]?.changed === true) {
      const geometries = maps[mapId].geometries;

      setFetchedGeometries(geometries);
      onGeometryChangedStatusSet(mapId, false);

      setIsFetchEnabled(false);

      window[`${mapId}_areGeometriesUpdated`] = true;
    }
  }, [mapId, maps, onGeometryChangedStatusSet]);

  // filtering geometries by layout
  useEffect(() => {
    if (window[`${mapId}_areGeometriesUpdated`] && fetchedGeometries !== null) {
      showSpinner();

      const {layoutGeometries, geometryMap} = getLayoutGeometriesFromJsonStat(
        fetchedGeometries,
        jsonStat,
        layout,
        labelFormat,
        t,
        localizedTimePeriodFormatMapExternal
      );

      setGeometries(layoutGeometries);
      setGeometryMap(geometryMap);

      setFilteredGeometries(null);

      const newDetailLevels = getDetailLevelsFromGeometries(layoutGeometries, showSingleGeometry, levelsOrder, t);

      setInternalDetailLevels(newDetailLevels);
      setInternalDetailLevel(prevDetailLevel =>
        prevDetailLevel !== null && newDetailLevels.map(({value}) => value).includes(prevDetailLevel)
          ? prevDetailLevel
          : newDetailLevels[0]?.value >= 0
            ? newDetailLevels[0].value
            : null
      );
    }
  }, [
    mapId,
    fetchedGeometries,
    jsonStat,
    layout,
    labelFormat,
    t,
    showSingleGeometry,
    levelsOrder,
    localizedTimePeriodFormatMapExternal,
    showSpinner
  ]);

  // filtering geometries by detail level (already filtered by layout)
  useEffect(() => {
    if (mapId && (geometries || []).length > 0) {
      showSpinner();

      const newFilteredGeometries = geometries.filter(({level}) => level === internalDetailLevel);
      setFilteredGeometries(newFilteredGeometries);

      window[`${mapId}_areGeometriesChanged`] = true;
    }
  }, [mapId, geometries, internalDetailLevel, showSpinner]);

  // handle map default settings
  useEffect(() => {
    setMapDefaultSettings(prevDefaultSettings =>
      prevDefaultSettings === null ? defaultSettings || {} : prevDefaultSettings
    );
  }, [defaultSettings]);

  // handle map settings
  useEffect(() => {
    setMapSettings(prevSettings => (isSettingsChanged(settings, prevSettings) ? settings : prevSettings));
  }, [settings]);

  // add or update layer with filtered geometries
  useEffect(() => {
    if (filteredGeometries !== null && mapSettings !== null && mapDefaultSettings !== null) {
      showSpinner();

      let newSettings = {};
      if (window[`${mapId}_isFirstRender`]) {
        newSettings = {...newSettings, ...mapDefaultSettings};
      }
      newSettings = {...newSettings, ...mapSettings};

      setTimeout(() => {
        if (!window[`${mapId}_areGeometriesChanged`]) {
          window.LMap.updateLayerSettings(mapId, newSettings);
        } else {
          const layerOptions = {
            srid: "EPSG:4326",
            settings: newSettings,
            onDataRender: () => {
              setTimeout(() => {
                window[`${mapId}_isFirstRender`] = false;
                if (filteredGeometries.length > 0) {
                  hideSpinner();
                }
              }, 200);
            },
            borderColor: geometryBorderColor,
            valueFormatter: val => getFormattedValue(val, decimalSeparator, decimalPlaces, "", roundingStrategy),
            disableSettings: disableSettings,
            dataInfoDuration: 1000,
            zoomToClickedData: true,
            onDataTooltipClick: id => {
              if (geometryMap[id].hasAttributes) {
                setGeometryId(id);
              }
            }
          };
          window.LMap.updateLayer(mapId, filteredGeometries, legendTitle, layerOptions);

          if (setSettings) {
            const settings = window.LMap.getSettings(mapId);
            if (isSettingsChanged(newSettings, settings)) {
              setSettings(getCleanedSetting(settings));
            }
          }

          window[`${mapId}_areGeometriesChanged`] = false;
        }
      }, 0);
    }
  }, [
    mapId,
    filteredGeometries,
    geometryMap,
    mapSettings,
    mapDefaultSettings,
    geometryBorderColor,
    decimalSeparator,
    roundingStrategy,
    decimalPlaces,
    disableSettings,
    legendTitle,
    setSettings,
    showSpinner,
    hideSpinner
  ]);

  if (!mapId) {
    return <span />;
  }

  const territoryDim = layout?.territoryDim;

  const territoryDimMissing = !territoryDim || !jsonStat.dimension[territoryDim];
  const geometriesFetchingError = maps[mapId]?.error;
  const geometriesMissing = filteredGeometries !== null && filteredGeometries.length === 0;
  const detailLevelMissing = internalDetailLevels !== null && internalDetailLevels.length === 0;

  const isMapUnavailable = territoryDimMissing || geometriesFetchingError || geometriesMissing || detailLevelMissing;

  const isDetailLevelSelectorVisible =
    (internalDetailLevels || []).length > 1 && internalDetailLevel !== null && internalDetailLevel >= 0;

  let unavailableMapErrorText;
  if (isMapUnavailable) {
    if (!isSpinnerVisible) {
      showSpinner();
    }
    if (territoryDimMissing) {
      console.debug("missing territory dimension");
      unavailableMapErrorText = t("components.map.noTerritoryDimension");
    } else if (geometriesFetchingError) {
      unavailableMapErrorText = t("components.map.fetchingGeometriesError");
    } else if (geometriesMissing) {
      unavailableMapErrorText = t("components.map.noDataToDisplay");
    } else if (detailLevelMissing) {
      console.debug("missing detail level");
      unavailableMapErrorText = t("components.map.noDetailLevel");
    } else {
      unavailableMapErrorText = null;
    }
  }

  const isOverlaySolid = isFetchEnabled || window[`${mapId}_isFirstRender`];

  return (
    <Fragment>
      <Call
        cb={fetchGeometry}
        cbParam={{
          nodeId: nodeId,
          jsonStat: jsonStat,
          layout: layout,
          mapStateUuid: mapId
        }}
        disabled={territoryDimMissing || !isFetchEnabled}
      >
        <Box
          id={`${mapId}__wrapper`}
          sx={{
            position: "relative",
            width: "100%",
            height: "100%"
          }}
          className={`map`}
          aria-hidden={true}
        >
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              height: "100%",
              zIndex: 2,
              visibility: "hidden"
            }}
            className={
              `map__overlay ` +
              (isSpinnerVisible ? "map__overlay--visible " : " ") +
              (isOverlaySolid ? "map__overlay--solid " : "map__overlay--transparent ")
            }
          >
            <CustomEmpty
              text={
                isMapUnavailable
                  ? unavailableMapErrorText
                  : isFetchEnabled
                    ? t("components.map.spinners.loading") + "..."
                    : t("components.map.spinners.rendering") + "..."
              }
              style={getOverlayStyle(theme, isOverlaySolid)}
              textStyle={getOverlayTextStyle(theme, isOverlaySolid)}
              imageStyle={getOverlayImageStyle(theme, isOverlaySolid)}
              image={isMapUnavailable ? <ErrorIcon /> : isFetchEnabled ? <CircularProgress /> : null}
            />
          </Box>
          <Box
            sx={{
              position: "absolute",
              zIndex: 1,
              top: "8px",
              left: "8px",
              padding: "8px",
              background: "rgba(255, 255, 255, 0.7)"
            }}
            className={` map__detail-level-selector ${
              !isDetailLevelSelectorVisible ? "map__detail-level-selector--hidden" : ""
            }`}
          >
            {(internalDetailLevels || []).length > 0 && (
              <FormControl>
                <CustomSelect
                  label={t("components.map.detailLevelSelector.label")}
                  value={internalDetailLevel}
                  onChange={ev => {
                    const dl = ev.target.value;
                    setInternalDetailLevel(dl);
                    if (onDetailLevelChange) {
                      onDetailLevelChange(dl);
                    }
                  }}
                  disabled={disableDetailLevelSelector || (internalDetailLevels || []).length === 1}
                >
                  {(internalDetailLevels || []).map((val, idx) => (
                    <MenuItem key={idx} value={val.value}>
                      {val.label}
                    </MenuItem>
                  ))}
                </CustomSelect>
              </FormControl>
            )}
          </Box>
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              height: "100%",
              zIndex: 0,
              filter: "blur(0)"
            }}
            id={mapId}
            className={`map__lmap-container ${isDetailLevelSelectorVisible ? "map--has-detail-level-selector" : ""}`}
          />
        </Box>
      </Call>
      <AttributeDialog
        isOpen={geometryId !== null}
        onClose={() => setGeometryId(null)}
        obsAttributes={geometryMap?.[geometryId]?.obsAttributes}
        dimAttributes={geometryMap?.[geometryId]?.dimAttributes}
        labelFormat={labelFormat}
      />
      <GeometriesWarningDialog
        isOpen={maps[mapId]?.isNoGeometryWarningVisible === true}
        onClose={() => onWarningHide(mapId)}
      />
    </Fragment>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Map);
