import React, {Fragment} from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import ViewCompactIcon from "@mui/icons-material/ViewCompact";
import ViewStreamIcon from "@mui/icons-material/ViewStream";
import {Box} from "@mui/material";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import {DragDropContext} from "react-beautiful-dnd";
import {withTranslation} from "react-i18next";
import {ERROR_SNACKBAR_SEVERITY_WARNING} from "../error-snackbar/ErrorSnackbar";
import LayoutSection from "../layout-section";
import Table from "../table/single-viewer";
import "./style.css";

const sectionStyle = {
  height: "240px",
  padding: "8px"
};

const reorder = (list, startIndex, endIndex) => {
  const [removed] = list.splice(startIndex, 1);
  list.splice(endIndex, 0, removed);
};

const move = (source, destination, droppableSource, droppableDestination) => {
  const [removed] = source.splice(droppableSource.index, 1);
  destination.splice(droppableDestination.index, 0, removed);
};

const onDragEnd = (result, dimensions, setDimensions, lockedDimensions, t) => {
  const {source, destination} = result;

  // dropped outside the list
  if (!destination) {
    return;
  }

  const newDimensions = JSON.parse(JSON.stringify(dimensions));

  if (source.droppableId === destination.droppableId) {
    reorder(newDimensions[source.droppableId], source.index, destination.index);
  } else {
    const dimension = newDimensions[source.droppableId][source.index];
    if (lockedDimensions.includes(dimension)) {
      window.error.show(t("components.tableLayout.drag.alert"), ERROR_SNACKBAR_SEVERITY_WARNING);
      return;
    }
    move(newDimensions[source.droppableId], newDimensions[destination.droppableId], source, destination);
  }

  setDimensions(newDimensions);
};

const TableLayout = ({t, layout, dimensionsInfo, lockedDimensions, setLayout, jsonStat, labelFormat, alertText}) => {
  const {filters, sections, rows, cols} = layout;

  let colCount = 1;
  cols.forEach(col => (colCount *= dimensionsInfo[col].size));
  let rowCount = 1;
  rows.forEach(row => (rowCount *= dimensionsInfo[row].size));
  sections.forEach(section => (rowCount *= dimensionsInfo[section].size));

  const layoutLockedDimensions = lockedDimensions || [];

  return (
    <Fragment>
      {alertText && (
        <Alert id="table-layout__alert" severity="warning" sx={{marginBottom: "8px"}}>
          {alertText}
        </Alert>
      )}
      <Box
        id="table-layout"
        sx={{
          margin: "-8px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <Box
          id="table-layout__layout-container"
          sx={[
            {
              width: "60%",
              height: "100%",
              padding: "8px"
            },
            !jsonStat && {width: "100%"}
          ]}
        >
          <DragDropContext onDragEnd={result => onDragEnd(result, layout, setLayout, layoutLockedDimensions, t)}>
            <Grid container spacing={2} style={{height: "100%"}}>
              <Grid item xs={6} sx={sectionStyle}>
                <LayoutSection
                  id="filters"
                  title={t("components.tableLayout.sections.filters")}
                  Icon={<FilterListIcon />}
                  dimensions={filters}
                  dimensionsInfo={dimensionsInfo}
                  lockedDimensions={layoutLockedDimensions}
                />
              </Grid>
              <Grid item xs={6} sx={sectionStyle}>
                <LayoutSection
                  id="sections"
                  title={t("components.tableLayout.sections.sections")}
                  Icon={<ViewCompactIcon />}
                  dimensions={sections}
                  dimensionsInfo={dimensionsInfo}
                  lockedDimensions={layoutLockedDimensions}
                />
              </Grid>
              <Grid item xs={6} sx={sectionStyle}>
                <LayoutSection
                  id="rows"
                  title={t("components.tableLayout.sections.rows")}
                  Icon={<ViewStreamIcon />}
                  dimensions={rows}
                  dimensionsInfo={dimensionsInfo}
                  lockedDimensions={layoutLockedDimensions}
                />
              </Grid>
              <Grid item xs={6} sx={sectionStyle}>
                <LayoutSection
                  id="cols"
                  title={t("components.tableLayout.sections.columns")}
                  Icon={<ViewColumnIcon />}
                  dimensions={cols}
                  dimensionsInfo={dimensionsInfo}
                  lockedDimensions={layoutLockedDimensions}
                />
              </Grid>
            </Grid>
          </DragDropContext>
        </Box>
        {jsonStat && (
          <Box
            id="table-layout__preview-container"
            sx={{
              width: "40%",
              height: "100%",
              padding: "8px"
            }}
          >
            <Table jsonStat={jsonStat} layout={layout} labelFormat={labelFormat} isPreview={true} />
            <Grid container spacing={1} style={{marginTop: 16}}>
              <Grid item xs={12} style={{textAlign: "end"}}>
                {t("components.tableLayout.rowCount", {rowCount})}
              </Grid>
              <Grid item xs={12} style={{textAlign: "end"}}>
                {t("components.tableLayout.colCount", {colCount})}
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
    </Fragment>
  );
};

export default withTranslation()(TableLayout);
