import React, {useEffect, useRef, useState} from "react";
import useOnScreen from "../../hooks/useOnScreen";
import {getDatasetInternalUrl} from "../../links";
import {CATALOG_NAVIGATION_MODE_GRID} from "../settings-select/nodes-settings-form/node-settings-form";
import DatasetCard from "./DatasetCard";

const DatasetList = ({
  datasets,
  isAccessible,
  node,
  catalogNavigationMode,
  query,
  catalog,
  onAccessibleDatasetFetch,
  initialPageSize = 50,
  pageSize = 30
}) => {
  const [pageCount, setPageCount] = useState(0);
  const scrollSensorRef = useRef(null);
  const scrollSensorIsOnScreen = useOnScreen(scrollSensorRef);

  useEffect(() => {
    setPageCount(0);
  }, [datasets]);

  useEffect(() => {
    if (scrollSensorIsOnScreen) {
      setPageCount(prevVal => prevVal + 1);
    }
  }, [scrollSensorIsOnScreen]);

  return (
    <>
      {datasets.slice(0, initialPageSize + pageCount * pageSize).map((dataset, idx) => (
        <DatasetCard
          key={idx}
          to={
            !isAccessible
              ? getDatasetInternalUrl(node.code, dataset.categoryPath, dataset.identifier, undefined)
              : undefined
          }
          onClick={!isAccessible ? undefined : () => onAccessibleDatasetFetch(dataset)}
          nodeId={node.nodeId}
          dataset={dataset}
          xs={12}
          md={catalogNavigationMode === CATALOG_NAVIGATION_MODE_GRID ? 4 : 12}
          isMultiCategorized={
            !!query && JSON.stringify(catalog?.categoryGroups || {}).split(dataset.identifier).length - 1 > 1
          }
        />
      ))}
      <div ref={scrollSensorRef} style={{height: "50px"}}></div>
    </>
  );
};

export default DatasetList;
