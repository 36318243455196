import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import {SimpleTreeView} from "@mui/x-tree-view/SimpleTreeView";
import {TreeItem} from "@mui/x-tree-view/TreeItem";
import {withTranslation} from "react-i18next";
import {goToDatasetsSearch} from "../../links";

const treeItemStyle = {
  backgroundColor: "none !important",
  "&:hover": {
    backgroundColor: "none !important"
  },
  "& .MuiTreeItem-content": {
    background: "none !important",
    padding: "4px 8px"
  },
  "& .MuiTreeItem-label": {
    background: "none !important"
  }
};

function ResultTree(props) {
  const {catalog, query, node, filters = [], categoryGroupsWithCount, uncategorizedDatasetsCount, t} = props;

  const [expanded, setExpanded] = useState([]);

  useEffect(() => {
    setExpanded(
      ((catalog.hasCategorySchemes ? categoryGroupsWithCount : categoryGroupsWithCount?.[0]?.categories) || []).map(
        ({id}) => id
      )
    );
  }, [catalog, categoryGroupsWithCount]);

  const getTreeItems = (tree, prevPath, noCheckbox = false) =>
    tree.map((cat, i) => {
      const selected = !!filters.find(filter => filter.includes(cat.id));

      return (
        <TreeItem
          key={i}
          itemId={[...(prevPath ?? []), cat.id].join("@")}
          label={
            <Grid spacing={1} container alignItems="flex-start" wrap="nowrap">
              {!noCheckbox && (
                <Grid item>
                  <label htmlFor={`result-tree__checkbox__${cat.id}`} style={{display: "none"}}>
                    {cat.label}
                  </label>
                  <Checkbox
                    id={`result-tree__checkbox__${cat.id}`}
                    checked={selected}
                    sx={{
                      padding: "0px",
                      transform: "translateY(-2px)"
                    }}
                    onClick={e => {
                      goToDatasetsSearch(
                        node.code,
                        query,
                        selected ? filters.filter(path => !path.includes(cat.id)) : [...filters, [...prevPath, cat.id]]
                      );
                      e.stopPropagation();
                    }}
                  />
                </Grid>
              )}
              <Grid item style={{width: "100%"}}>
                {cat.label}
                {!noCheckbox && (
                  <Box
                    component="span"
                    sx={{
                      fontWeight: "bold",
                      marginLeft: "4px"
                    }}
                  >
                    {" "}
                    ({cat.count})
                  </Box>
                )}
              </Grid>
            </Grid>
          }
          sx={treeItemStyle}
        >
          {cat.categories && cat.categories.length > 0 && getTreeItems(cat.categories, [...prevPath, cat.id])}
        </TreeItem>
      );
    });

  const uncategorizedSelected = !!filters.find(filter => filter.includes("uncategorized"));

  return (
    <Box
      sx={{
        background: "#F5F5F5",
        paddingTop: "8px",
        paddingBottom: "8px"
      }}
    >
      {categoryGroupsWithCount.length > 0 && (
        <SimpleTreeView
          key={query ? `result-tree-categories-${query}` : "result-tree-categories"}
          expandedItems={expanded}
          onExpandedItemsChange={(_, nodeIds) => setExpanded(nodeIds)}
          sx={{
            color: theme => theme.palette.text.primary
          }}
        >
          {getTreeItems(
            catalog.hasCategorySchemes ? categoryGroupsWithCount : categoryGroupsWithCount[0].categories,
            [],
            catalog.hasCategorySchemes
          )}
        </SimpleTreeView>
      )}
      {uncategorizedDatasetsCount > 0 && (
        <SimpleTreeView
          key={query ? `result-tree-uncategorized-${query}` : "result-tree-uncategorized"}
          sx={{
            color: theme => theme.palette.text.primary
          }}
        >
          <TreeItem
            key={"uncategorized"}
            itemId={"uncategorized"}
            sx={treeItemStyle}
            label={
              <Grid
                spacing={1}
                container
                alignItems="flex-start"
                style={{flexWrap: "nowrap", marginTop: 4, alignItems: "baseline"}}
              >
                <Grid item>
                  <label htmlFor="result-tree__checkbox__uncategorized" style={{display: "none"}}>
                    {t("commons.catalog.uncategorized")}
                  </label>
                  <Checkbox
                    id="result-tree__checkbox__uncategorized"
                    checked={uncategorizedSelected}
                    sx={{
                      padding: "0px",
                      transform: "translateY(-2px)"
                    }}
                    onClick={e => {
                      goToDatasetsSearch(
                        node.code,
                        query,
                        uncategorizedSelected
                          ? filters.filter(path => JSON.stringify(path) !== JSON.stringify(["uncategorized"]))
                          : [...filters, ["uncategorized"]]
                      );
                      e.stopPropagation();
                    }}
                  />
                </Grid>
                <Grid style={{width: "100%"}} item>
                  {t("commons.catalog.uncategorized")}{" "}
                  <Box
                    component="span"
                    sx={{
                      fontWeight: "bold",
                      marginLeft: "4px"
                    }}
                  >
                    ({uncategorizedDatasetsCount})
                  </Box>
                </Grid>
              </Grid>
            }
          />
        </SimpleTreeView>
      )}
    </Box>
  );
}

export default withTranslation()(ResultTree);
