import React, {Fragment} from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import {Box} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import Input from "@mui/material/Input";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {compose} from "redux";
import ButtonSelect from "../button-select";
import DashboardsManager from "../dashboards-manager";
import ModuleMenuItemPlaceholder from "../module-menu-item-placeholder";
import ModulesPlaceholder from "../modules-placeholder";
import SettingsDialog from "../settings-dialog";
import FilterSettingsForm from "../settings-select/filters-settings-form";
import {clearMemoryCache, downloadQueryLog} from "./actions";
import AppSettingsForm from "./app-settings-form";
import MachineToMachineSettingsForm from "./machine-to-machine-settings-form";
import NodesSettingsForm from "./nodes-settings-form";
import UserItemContainersSettingsForm from "./user-itemContainers-settings-form";
import ItemContainerSettingsFormProvider from "./user-itemContainers-settings-form/ItemContainerSettingsFormProvider";
import UsersSettingsForm from "./users-settings-form";
import {
  addHubConfigDashboardsDashboard,
  clearAllHubConfigDashboards,
  clearHubConfigDashboards,
  fetchAllHubConfigDashboards,
  fetchHubConfigDashboards,
  removeHubConfigDashboardsDashboard,
  sendHubConfigDashboardsOrders,
  setHubConfigMachineToMachinePassword,
  submithHubConfigDashboards
} from "../../state/hubConfig/hubConfigActions";
import {
  canDisplayAppSettingsForm,
  canDisplayNodesSettingsForm,
  canDisplayUsersSettingsForm,
  canGetQueryLog,
  canManageAppDashboards,
  canManageFilters,
  canManagePersonalCustomPages,
  canSetMachineToMachinePassword
} from "../../utils/user";

const mapStateToProps = state => ({
  modulesConfig: state.app.modulesConfig,
  user: state.user,
  hub: state.hub,
  dashboards: state.hubConfig.hubDashboards,
  allDashboards: state.hubConfig.allDashboards
});

const mapDispatchToProps = dispatch => ({
  onLogDownload: limit => dispatch(downloadQueryLog(limit)),
  onCacheClear: () => dispatch(clearMemoryCache()),
  submitDashboards: data => dispatch(submithHubConfigDashboards(data)),
  fetchDashboards: () => dispatch(fetchHubConfigDashboards()),
  fetchAllDashboards: () => dispatch(fetchAllHubConfigDashboards()),
  clearDashboards: () => dispatch(clearHubConfigDashboards()),
  clearAllDashboards: () => dispatch(clearAllHubConfigDashboards()),
  addDashboard: dashboardId => dispatch(addHubConfigDashboardsDashboard(dashboardId)),
  removeDashboard: dashboardId => dispatch(removeHubConfigDashboardsDashboard(dashboardId)),
  sendDashboardsOrders: orderedDashboarIds => dispatch(sendHubConfigDashboardsOrders(orderedDashboarIds)),
  onMachineToMachinePwdSet: (hubId, password) => dispatch(setHubConfigMachineToMachinePassword(hubId, password))
});

const optionStyle = {
  width: "100%"
};

class SettingsSelect extends React.Component {
  state = {
    isAppOpen: false,
    isHubDashboardManagerOpen: false,
    isNodesOpen: false,
    isUsersOpen: false,
    isCustomPageOpen: false,
    isDashboardNewOpen: false,
    appSettingsRef: React.createRef(),
    dashboardsManagerRef: React.createRef(),
    nodesSettingsRef: React.createRef(),
    usersSettingsRef: React.createRef(),
    customPagesSettingsRef: React.createRef(),
    dashboardNewSettingsRef: React.createRef(),
    isLogDownloadOpen: false,
    logLimit: 5,
    machineToMachinePwdSettingsRef: React.createRef(),
    isMachineToMachinePwdOpen: false
  };

  onHubDashboardManagerOpen = () =>
    this.setState({
      ...this.state,
      isHubDashboardManagerOpen: true
    });

  onHubDashboardManagerClose = () =>
    this.setState({
      ...this.state,
      isHubDashboardManagerOpen: false
    });

  onAppOpen = () =>
    this.setState({
      ...this.state,
      isAppOpen: true
    });

  onAppClose = showSnackbar =>
    this.setState({
      ...this.state,
      isAppOpen: false,
      isAppSubmitSnackbarVisible: showSnackbar || false
    });

  onNodesOpen = () =>
    this.setState({
      ...this.state,
      isNodesOpen: true
    });

  onNodesClose = () =>
    this.setState({
      ...this.state,
      isNodesOpen: false
    });

  onUsersOpen = () =>
    this.setState({
      ...this.state,
      isUsersOpen: true
    });

  onCustomPageOpen = () =>
    this.setState({
      ...this.state,
      isCustomPageOpen: true
    });

  onCustomPageClose = () =>
    this.setState({
      ...this.state,
      isCustomPageOpen: false
    });

  onFiltersSettingsOpen = () =>
    this.setState({
      ...this.state,
      isFilterSettingsOpen: true
    });

  onFiltersSettingsClosed = () =>
    this.setState({
      ...this.state,
      isFilterSettingsOpen: false
    });

  onUsersClose = () =>
    this.setState({
      ...this.state,
      isUsersOpen: false
    });

  onLogDownloadOpen = () =>
    this.setState({
      ...this.state,
      isLogDownloadOpen: true
    });

  onLogDownloadClose = () =>
    this.setState({
      ...this.state,
      isLogDownloadOpen: false,
      logLimit: 5
    });

  onLogLimitSet = logLimit => {
    if (!isNaN(logLimit)) {
      this.setState({
        ...this.state,
        logLimit: logLimit
      });
    }
  };

  onMachineToMachinePwdOpen = () =>
    this.setState({
      ...this.state,
      isMachineToMachinePwdOpen: true
    });

  onMachineToMachinePwdClose = () =>
    this.setState({
      ...this.state,
      isMachineToMachinePwdOpen: false
    });

  render() {
    const {
      onLogDownload,
      nodes,
      user,
      t,
      modulesConfig,
      hub,
      onMachineToMachinePwdSet,
      dashboards,
      allDashboards,
      submitDashboards,
      fetchDashboards,
      fetchAllDashboards,
      clearDashboards,
      clearAllDashboards,
      addDashboard,
      removeDashboard,
      sendDashboardsOrders
    } = this.props;

    const {
      isAppOpen,
      isHubDashboardManagerOpen,
      isNodesOpen,
      isUsersOpen,
      appSettingsRef,
      dashboardsManagerRef,
      isCustomPageOpen,
      isFilterSettingsOpen,
      nodesSettingsRef,
      usersSettingsRef,
      customPagesSettingsRef,
      isLogDownloadOpen,
      logLimit,
      machineToMachinePwdSettingsRef,
      isMachineToMachinePwdOpen
    } = this.state;

    return (
      <Fragment>
        <ButtonSelect
          value=""
          icon={<SettingsIcon />}
          ariaLabel={t("components.header.actions.settings.ariaLabel")}
          tooltip={t("components.header.actions.settings.title")}
        >
          {[
            canDisplayAppSettingsForm(user) ? (
              <Box id="header_settings_app_btn" sx={optionStyle} key="app" onClick={this.onAppOpen}>
                {t("components.header.settings.app")}
              </Box>
            ) : null,

            modulesConfig.modules.includes("dashboard") && canManageAppDashboards(user) ? (
              <Box
                id="header_settings_hub_dashboard_manager_btn"
                sx={optionStyle}
                key="hubDashboardManager"
                onClick={this.onHubDashboardManagerOpen}
              >
                {t("components.header.settings.hubDashboardManager")}
              </Box>
            ) : null,

            canDisplayUsersSettingsForm(user) ? (
              <Box id="header_settings_users_btn" sx={optionStyle} key="user" onClick={this.onUsersOpen}>
                {t("components.header.settings.users")}
              </Box>
            ) : null,
            canDisplayNodesSettingsForm(user) ? (
              <Box id="header_settings_nodes_btn" sx={optionStyle} key="nodes" onClick={this.onNodesOpen}>
                {t("components.header.settings.nodes")}
              </Box>
            ) : null,
            modulesConfig.modules.includes("custom-page") && canManagePersonalCustomPages(user) ? (
              <Box
                key="customPage"
                id="user-customPages-btn"
                onClick={this.onCustomPageOpen}
                data-value={"customPages"}
                sx={optionStyle}
              >
                {t("components.userSelect.customPages")}
              </Box>
            ) : null,
            modulesConfig.modules.includes("dashboard") && canManageFilters(user) ? (
              <Box
                key="filters"
                id="user-filters-btn"
                onClick={this.onFiltersSettingsOpen}
                data-value={"filters"}
                sx={optionStyle}
              >
                {t("components.header.settings.filters")}
              </Box>
            ) : null,
            ...(modulesConfig.placeholders?.["settings-select-menu"] || []).map((module, idx) => (
              <ModuleMenuItemPlaceholder style={{width: "100%"}} isModulePlaceholder key={idx} module={module} />
            )),
            canSetMachineToMachinePassword(user) ? (
              <Box
                id="header_settings_macchine_to_machine_password_btn"
                sx={optionStyle}
                key="macchineToMachinePassword"
                onClick={this.onMachineToMachinePwdOpen}
              >
                {t("components.header.settings.macchineToMachinePassword")}
              </Box>
            ) : null,
            canGetQueryLog(user) ? (
              <Box
                id="header_settings_queries_log_btn"
                sx={optionStyle}
                key="queriesLog"
                onClick={this.onLogDownloadOpen}
              >
                {t("components.header.settings.queriesLog")}
              </Box>
            ) : null
            /*
            canClearServerCache(user)
              ? (
                <div
                  key="clearServerCache"
                  onClick={onCacheClear}
                  className={classes.option}
                >
                  {_t("components.header.settings.clearServerCache")}
                </div>
              )
              : null
            */
          ]}
        </ButtonSelect>

        <SettingsDialog
          title={t("scenes.appSettings.title")}
          open={isAppOpen}
          onClose={() => {
            if (appSettingsRef.current) {
              appSettingsRef.current.cancel(() => this.onAppClose());
            } else {
              this.onAppClose();
            }
          }}
          onSubmit={() => {
            if (appSettingsRef.current) {
              appSettingsRef.current.submit(() => {
                this.onAppClose();
              });
            } else {
              this.onAppClose();
            }
          }}
          hasSubmit
        >
          <AppSettingsForm ref={appSettingsRef} />
        </SettingsDialog>

        <SettingsDialog
          title={t("scenes.hubDashboardManager.title")}
          open={isHubDashboardManagerOpen}
          onClose={() => {
            if (dashboardsManagerRef.current) {
              dashboardsManagerRef.current.destroy(() => this.onHubDashboardManagerClose());
            } else {
              this.onHubDashboardManagerClose();
            }
          }}
          onSubmit={() => {
            if (dashboardsManagerRef.current) {
              dashboardsManagerRef.current.submit(data => {
                submitDashboards(data);
                this.onHubDashboardManagerClose();
              });
            } else {
              this.onHubDashboardManagerClose();
            }
          }}
          hasSubmit
        >
          <DashboardsManager
            dashboards={dashboards}
            allDashboards={allDashboards}
            fetchDashboards={fetchDashboards}
            fetchAllDashboards={fetchAllDashboards}
            clearDashboards={clearDashboards}
            clearAllDashboards={clearAllDashboards}
            addDashboard={addDashboard}
            removeDashboard={removeDashboard}
            sendDashboardsOrders={sendDashboardsOrders}
            ref={dashboardsManagerRef}
          />
        </SettingsDialog>

        <SettingsDialog
          id={"settings-select__nodes-settings-dialog"}
          title={t("scenes.nodesSettings.title")}
          open={isNodesOpen}
          onClose={() => {
            if (nodesSettingsRef.current) {
              nodesSettingsRef.current.cancel(() => this.onNodesClose());
            } else {
              this.onNodesClose();
            }
          }}
        >
          <NodesSettingsForm ref={nodesSettingsRef} nodes={nodes} onNodesClose={this.onNodesClose} />
        </SettingsDialog>

        <SettingsDialog
          title={t("scenes.customPagesSettings.title")}
          open={isCustomPageOpen}
          onClose={() => {
            if (customPagesSettingsRef.current) {
              customPagesSettingsRef.current.cancel(() => {
                this.onCustomPageClose();
              });
            } else {
              this.onCustomPageClose();
            }
          }}
        >
          <ItemContainerSettingsFormProvider>
            <UserItemContainersSettingsForm asDashboard={false} ref={customPagesSettingsRef} />
          </ItemContainerSettingsFormProvider>
        </SettingsDialog>

        <SettingsDialog
          title={t("scenes.usersSettings.title")}
          open={isUsersOpen}
          onClose={() => {
            if (usersSettingsRef.current) {
              usersSettingsRef.current.cancel(() => this.onUsersClose());
            } else {
              this.onUsersClose();
            }
          }}
        >
          <UsersSettingsForm ref={usersSettingsRef} />
        </SettingsDialog>

        <SettingsDialog
          title={t("components.filtersSettingsForm.title")}
          open={isFilterSettingsOpen}
          onClose={() => this.onFiltersSettingsClosed()}
        >
          <FilterSettingsForm />
        </SettingsDialog>

        <Dialog id="settings-select__log-download__dialog" open={isLogDownloadOpen} onClose={this.onLogDownloadClose}>
          <DialogContent>
            <Grid container spacing={2} style={{width: 400}}>
              <Grid item xs={12}>
                <div>{t("components.header.settings.queriesLogModal.content")}:</div>
              </Grid>
              <Grid item xs={12}>
                <Input
                  id="settings-select__log-download__limit-input"
                  value={logLimit}
                  onChange={ev => this.onLogLimitSet(ev.target.value)}
                  style={{width: "100%"}}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button id="settings-select__log-download__cancel-button" onClick={this.onLogDownloadClose}>
              {t("commons.confirm.cancel")}
            </Button>
            <Button
              id="settings-select__log-download__confirm-button"
              autoFocus
              onClick={() => {
                this.onLogDownloadClose();
                onLogDownload(logLimit);
              }}
              color="primary"
            >
              {t("commons.confirm.download")}
            </Button>
          </DialogActions>
        </Dialog>

        <SettingsDialog
          title={t("scenes.machineToMachineSettings.dialog.password.title")}
          open={isMachineToMachinePwdOpen}
          onClose={() => {
            if (machineToMachinePwdSettingsRef.current) {
              machineToMachinePwdSettingsRef.current.cancel(() => {
                this.onMachineToMachinePwdClose();
              });
            } else {
              this.onMachineToMachinePwdClose();
            }
          }}
          onSubmit={() => {
            if (machineToMachinePwdSettingsRef.current) {
              machineToMachinePwdSettingsRef.current.submit(({password}) => {
                onMachineToMachinePwdSet(hub.hub.hubId, password);
                this.onMachineToMachinePwdClose();
              });
            } else {
              this.onMachineToMachinePwdClose();
            }
          }}
          hasSubmit
          noMinHeight
          noFullScreen
          maxWidth={"sm"}
        >
          <MachineToMachineSettingsForm ref={machineToMachinePwdSettingsRef} hub={hub.hub} />
        </SettingsDialog>

        <ModulesPlaceholder id="settings-select-dialog" />
      </Fragment>
    );
  }
}

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(SettingsSelect);
