import React, {forwardRef, Fragment, useEffect, useImperativeHandle, useMemo, useRef, useState} from "react";
import {Checkbox, FormControlLabel, Grid, Paper, useTheme} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TextField from "@mui/material/TextField";
import _ from "lodash";
import {FormProvider, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {compose} from "redux";
import CustomEmpty from "../../custom-empty";
import FileInput from "../../file-input";
import FileInputText from "../../file-input-text";
import FormLabelWithTooltip from "../../form-label-with-tooltip";
import I18nHtmlEditor from "../../i18n-html-editor";
import I18nInputAdornmentSelect from "../../i18n-input-adornment-select";
import I18nTextField from "../../i18n-text-field";
import {getMapLayerOptions} from "../../map/constants";
import SanitizedHTML from "../../sanitized-html";
import TabPanel from "../../tab-panel";
import CustomColorsForm from "../custom-colors-form/CustomColorsForm";
import {
  HUB_EXTRAS_ACCESS_ONLY_ADVANCED_USER,
  HUB_EXTRAS_ACCESS_ONLY_SUPERADMIN,
  HUB_EXTRAS_ACCESS_OPEN,
  HUB_EXTRAS_REGISTRATION_DISABLED,
  HUB_EXTRAS_REGISTRATION_ENABLED
} from "./constants";
import {
  addHubConfigDashboardsDashboard,
  clearAllHubConfigDashboards,
  clearHubConfig,
  clearHubConfigDashboards,
  closeHubConfig,
  fetchAllHubConfigDashboards,
  fetchHubConfig,
  fetchHubConfigDashboards,
  removeHubConfigDashboardsDashboard,
  sendHubConfig,
  sendHubConfigDashboardsOrders
} from "../../../state/hubConfig/hubConfigActions";
import EXTRAS, {PUBLIC_HUB_EXTRAS} from "../../../utils/extras";
import {localizeI18nObj} from "../../../utils/i18n";
import {isValidIntegerInInclusiveRange} from "../../../utils/validator";

const mapStateToProps = state => ({
  config: state.hubConfig.hub,
  user: state.user,
  dashboards: state.hubConfig.hubDashboards,
  allDashboards: state.hubConfig.allDashboards,
  mapLayersConfig: state.maps.mapLayersConfig,
  appLanguage: state.app.language,
  appLanguages: state.app.languages,
  modulesConfig: state.app.modulesConfig
});

const mapDispatchToProps = dispatch => ({
  clearConfig: () => dispatch(clearHubConfig()),
  fetchConfig: () => dispatch(fetchHubConfig()),
  sendConfig: config => dispatch(sendHubConfig(config)),
  fetchDashboards: () => dispatch(fetchHubConfigDashboards()),
  fetchAllDashboards: () => dispatch(fetchAllHubConfigDashboards()),
  clearDashboards: () => dispatch(clearHubConfigDashboards()),
  clearAllDashboards: () => dispatch(clearAllHubConfigDashboards()),
  addDashboard: dashboardId => dispatch(addHubConfigDashboardsDashboard(dashboardId)),
  removeDashboard: dashboardId => dispatch(removeHubConfigDashboardsDashboard(dashboardId)),
  sendDashboardsOrders: orderedDashboarIds => dispatch(sendHubConfigDashboardsOrders(orderedDashboarIds)),
  onClose: () => dispatch(closeHubConfig())
});

const getDefaultCustomColors = theme => ({
  isEnabled: false,
  palette: {
    text: {
      primary: theme.palette.text.primary
    },
    primary: {
      main: theme.palette.primary.main,
      contrastText: theme.palette.primary.contrastText
    },
    secondary: {
      main: theme.palette.secondary.main,
      contrastText: theme.palette.secondary.contrastText
    }
  }
});

const Form = forwardRef(({config, onSubmit, onCancel, mapLayersConfig, appLanguage, appLanguages}, ref) => {
  const {t} = useTranslation();
  const theme = useTheme();

  const tabsRef = useRef();
  const dashboardsManagerRef = useRef();

  const parsedExtras = useMemo(() => EXTRAS.parse(config?.extra), [config]);

  const [tab, setTab] = useState("general");

  const [footerLang, setFooterLang] = useState(appLanguage);

  const appSettingsForm = useForm({
    defaultValues: {
      ...config,
      mapLayer: parsedExtras.MapLayer,
      treePageSize: parsedExtras.TreePageSize,
      headerLogoSmallURL: parsedExtras.HeaderLogoSmallURL,
      headerLogoAlt: parsedExtras.HeaderLogoAlt,
      headerLogoHref: parsedExtras.HeaderLogoHref,
      headerLogoTitle: parsedExtras.headerLogoTitle,
      disableArtefactsCache: parsedExtras.DisableArtefactsCache || false,
      customColors: parsedExtras.CustomColors
        ? _.merge({}, getDefaultCustomColors(theme), parsedExtras.CustomColors)
        : getDefaultCustomColors(theme),
      footer: parsedExtras.Footer ?? {},
      registration: parsedExtras.Registration ?? HUB_EXTRAS_REGISTRATION_ENABLED,
      access: parsedExtras.Access ?? HUB_EXTRAS_ACCESS_OPEN
    }
  });

  const {
    register,
    formState: {errors},
    handleSubmit,
    watch,
    setValue
  } = appSettingsForm;

  useImperativeHandle(ref, () => ({
    submit(f) {
      handleSubmit(val => {
        const data = {
          ...config,
          ...val,
          mapLayer: undefined,
          treePageSize: undefined,
          headerLogoSmallURL: undefined,
          headerLogoAlt: undefined,
          headerLogoHref: undefined,
          headerLogoTitle: undefined,
          disableArtefactsCache: undefined,
          customColors: undefined,
          footer: undefined,
          registration: undefined,
          access: undefined
        };

        const serializedExtras = EXTRAS.serialize(
          {
            MapLayer: val.mapLayer,
            TreePageSize: val.treePageSize,
            HeaderLogoSmallURL: val.headerLogoSmallURL,
            HeaderLogoAlt: val.headerLogoAlt,
            HeaderLogoHref: val.headerLogoHref,
            headerLogoTitle: val.headerLogoTitle,
            DisableArtefactsCache: val.disableArtefactsCache,
            CustomColors: val.customColors,
            Footer: val.footer,
            Registration: val.registration,
            Access: val.access
          },
          PUBLIC_HUB_EXTRAS
        );

        data.extras = undefined;
        data.extra = serializedExtras; // TODO: switch to using "extras" to match nodes

        onSubmit(data);
        f(data);

        if (dashboardsManagerRef.current) {
          dashboardsManagerRef.current.destroy();
        }
      })();

      const firstFieldWithErrors = Object.keys(errors)[0];
      if (firstFieldWithErrors) {
        switch (firstFieldWithErrors) {
          case "title":
          case "slogan":
          case "supportedLanguages":
          case "defaultLanguage":
          case "maxObservationsAfterCriteria":
          case "maxCells":
          case "treePageSize":
          case "backgroundMediaURL":
          case "logoURL":
          case "headerLogoURL": {
            setTab("general");
            break;
          }
          case "description": {
            setTab("infos");
            break;
          }
          case "footer": {
            setTab("footer");
            break;
          }
          case "disclaimer": {
            setTab("users");
            break;
          }
          case "mapLayer": {
            setTab("map");
            break;
          }
          case "cache": {
            setTab("cache");
            break;
          }
          default: {
          }
        }
      }
    },
    cancel(f) {
      onCancel();
      f();
      if (dashboardsManagerRef.current) {
        dashboardsManagerRef.current.destroy();
      }
    }
  }));

  /* custom register */
  useEffect(() => {
    register("title");
    register("slogan");
    register("supportedLanguages", {
      validate: val => val.length > 0 || t("commons.validation.required")
    });
    register("defaultLanguage", {
      required: t("commons.validation.required")
    });
    register("maxObservationsAfterCriteria", {
      required: t("commons.validation.required"),
      min: {
        value: 1,
        message: t("commons.validation.positiveInteger")
      }
    });
    register("maxCells", {
      required: t("commons.validation.required"),
      min: {
        value: 1,
        message: t("commons.validation.positiveInteger")
      }
    });
    register("treePageSize", {
      validate: val => !val || isValidIntegerInInclusiveRange(val, 1) || t("commons.validation.positiveInteger")
    });
    register("backgroundMediaURL");
    register("logoURL");
    register("headerLogoURL");
    register("headerLogoSmallURL");
    register("headerLogoAlt");
    register("headerLogoHref");
    register("headerLogoTitle");
    register("description");
    register("footer");
    register("disclaimer");
    register("mapLayer", {
      required: t("commons.validation.required")
    });
    register("disableArtefactsCache");
    register("customColors");
    register("registration");
    register("access");
  }, [register, t]);

  const defaultLanguage = watch("defaultLanguage");
  const supportedLanguages = watch("supportedLanguages");

  const fieldStyle = {
    marginTop: theme.spacing(3)
  };

  return (
    <Fragment>
      <Box
        id="settings-select__app-settings-form"
        sx={{
          height: "100%"
        }}
      >
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          value={tab}
          onChange={(_, tab) => {
            setTab(tab);
          }}
        >
          <Tab
            id="settings-select__app-settings-form__general-tab"
            value="general"
            label={t("scenes.appSettings.tabs.general")}
          />
          <Tab
            id="settings-select__app-settings-form__infos-tab"
            value="infos"
            label={t("scenes.appSettings.tabs.information")}
          />
          <Tab
            id="settings-select__app-settings-form__footer-tab"
            value="footer"
            label={t("scenes.appSettings.tabs.footer")}
          />
          <Tab
            id="settings-select__app-settings-form__users-tab"
            value="users"
            label={t("scenes.appSettings.tabs.users")}
          />
          <Tab id="settings-select__app-settings-form__map-tab" value="map" label={t("scenes.appSettings.tabs.map")} />
          <Tab
            id="settings-select__app-settings-form__colors-tab"
            value="colors"
            label={t("scenes.appSettings.tabs.colors")}
          />
          <Tab
            id="settings-select__app-settings-form__cache-tab"
            value="cache"
            label={t("scenes.appSettings.tabs.cache")}
          />
        </Tabs>
        <Box
          ref={tabsRef}
          sx={{
            overflowY: "auto",
            overflowX: "hidden",
            height: "calc(100% - 56px)",
            marginTop: "8px",
            padding: "0 4px"
          }}
        >
          <TabPanel id="settings-select__app-settings-form__general-tab" value="general" selected={tab}>
            <FormControl id="settings-select__app-settings-form__general-tab__title" fullWidth sx={fieldStyle}>
              <I18nTextField
                name="title"
                label={t("scenes.appSettings.fields.title.label")}
                error={!!errors.title}
                helperText={errors.title?.message}
                variant="outlined"
                value={watch("title") || {}}
                onChange={value => setValue("title", value)}
              />
            </FormControl>
            <FormControl id="settings-select__app-settings-form__general-tab__slogan" fullWidth sx={fieldStyle}>
              <I18nTextField
                name="slogan"
                label={t("scenes.appSettings.fields.slogan.label")}
                variant="outlined"
                value={watch("slogan") || {}}
                onChange={value => setValue("slogan", value)}
              />
            </FormControl>
            <Autocomplete
              multiple
              variant="outlined"
              freeSolo
              options={[]}
              value={supportedLanguages || []}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    {...getTagProps({index})}
                    key={index}
                    variant="outlined"
                    label={option}
                    className="app-settings-form__general-tab__supported-languages__chip"
                  />
                ))
              }
              required
              onChange={(e, val) => {
                setValue("supportedLanguages", val);
                if (defaultLanguage && !val.includes(defaultLanguage)) {
                  setValue("defaultLanguage", "");
                }
              }}
              renderInput={params => (
                <FormControl
                  id="settings-select__app-settings-form__general-tab__supported-languages"
                  fullWidth
                  sx={fieldStyle}
                >
                  <TextField
                    {...params}
                    label={t("scenes.appSettings.fields.supportedLanguages.label")}
                    variant="outlined"
                    placeholder={t("scenes.appSettings.fields.supportedLanguages.placeholder")}
                    error={!!errors.supportedLanguages}
                    helperText={
                      errors.supportedLanguages?.message || (
                        <Fragment>
                          {t("scenes.appSettings.fields.supportedLanguages.helper.text")}
                          <a
                            href="https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{marginLeft: 4}}
                          >
                            {t("scenes.appSettings.fields.supportedLanguages.helper.linkText")}
                          </a>
                        </Fragment>
                      )
                    }
                  />
                </FormControl>
              )}
            />
            <FormControl
              id="settings-select__app-settings-form__general-tab__default-language"
              fullWidth
              sx={fieldStyle}
            >
              <TextField
                name="defaultLanguage"
                select
                required
                label={t("scenes.appSettings.fields.defaultLanguage.label")}
                onChange={e => setValue("defaultLanguage", e.target.value)}
                value={watch("defaultLanguage") || ""}
                error={!!errors.defaultLanguage}
                helperText={errors.defaultLanguage?.message || t("scenes.appSettings.fields.defaultLanguage.helper")}
                variant="outlined"
                SelectProps={{SelectDisplayProps: {"aria-haspopup": true}}}
              >
                {supportedLanguages.map((val, index) => (
                  <MenuItem key={index} value={val}>
                    {val}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
            <FormControl
              id="settings-select__app-settings-form__general-tab__max-observations-after-criteria"
              fullWidth
              sx={fieldStyle}
            >
              <TextField
                name="maxObservationsAfterCriteria"
                label={t("scenes.appSettings.fields.maxObservationsAfterCriteria.label")}
                required
                type="number"
                error={!!errors.maxObservationsAfterCriteria}
                helperText={errors.maxObservationsAfterCriteria?.message}
                variant="outlined"
                value={watch("maxObservationsAfterCriteria") || ""}
                onChange={({target}) => setValue("maxObservationsAfterCriteria", target.value)}
              />
            </FormControl>
            <FormControl id="settings-select__app-settings-form__general-tab__max-cells" fullWidth sx={fieldStyle}>
              <TextField
                name="maxCells"
                label={t("scenes.appSettings.fields.maxCells.label")}
                required
                error={!!errors.maxCells}
                helperText={errors.maxCells?.message}
                variant="outlined"
                type="number"
                value={watch("maxCells") || ""}
                onChange={({target}) => setValue("maxCells", target.value)}
              />
            </FormControl>
            <FormControl id="settings-select__app-settings-form__general-tab__tree-page-size" fullWidth sx={fieldStyle}>
              <TextField
                name="treePageSize"
                label={t("scenes.appSettings.fields.treePageSize.label")}
                error={!!errors.treePageSize}
                helperText={errors.treePageSize?.message}
                variant="outlined"
                type="number"
                value={watch("treePageSize") || ""}
                onChange={({target}) => setValue("treePageSize", target.value)}
              />
            </FormControl>
            <FormControl
              id="settings-select__app-settings-form__general-tab__background-media-url"
              fullWidth
              sx={fieldStyle}
            >
              <FileInput
                label={t("scenes.appSettings.fields.backgroundMediaURL.label")}
                value={watch("backgroundMediaURL")}
                onChange={value => setValue("backgroundMediaURL", value)}
                enableDownload
              />
            </FormControl>
            <FormControl id="settings-select__app-settings-form__general-tab__logo-url" fullWidth sx={fieldStyle}>
              <FileInput
                label={t("scenes.appSettings.fields.logoURL.label")}
                value={watch("logoURL")}
                onChange={value => setValue("logoURL", value)}
                enableDownload
              />
            </FormControl>
            <FormControl
              id="settings-select__app-settings-form__general-tab__header-logo-url"
              fullWidth
              sx={fieldStyle}
            >
              <FileInput
                label={t("scenes.appSettings.fields.headerLogoURL.label")}
                value={watch("headerLogoURL")}
                onChange={value => setValue("headerLogoURL", value)}
                enableDownload
              />
            </FormControl>
            <FormControl
              id="settings-select__app-settings-form__general-tab__header-logo-small-url"
              fullWidth
              sx={fieldStyle}
            >
              <FileInput
                label={t("scenes.appSettings.fields.headerLogoSmallURL.label")}
                value={watch("headerLogoSmallURL")}
                onChange={value => setValue("headerLogoSmallURL", value)}
                enableDownload
              />
            </FormControl>
            <FormControl
              id="settings-select__app-settings-form__general-tab__header-logo-alt"
              fullWidth
              sx={fieldStyle}
            >
              <I18nTextField
                name="headerLogoAlt"
                label={
                  <FormLabelWithTooltip tooltip={t("scenes.appSettings.fields.headerLogoAlt.tooltip")}>
                    {t("scenes.appSettings.fields.headerLogoAlt.label")}
                  </FormLabelWithTooltip>
                }
                error={!!errors.headerLogoAlt}
                helperText={errors.headerLogoAlt?.message}
                variant="outlined"
                value={watch("headerLogoAlt") || {}}
                onChange={value => setValue("headerLogoAlt", value)}
              />
            </FormControl>
            <FormControl
              id="settings-select__app-settings-form__general-tab__header-logo-href"
              fullWidth
              sx={fieldStyle}
            >
              <TextField
                name="headerLogoHref"
                label={t("scenes.appSettings.fields.headerLogoHref.label")}
                error={!!errors.headerLogoHref}
                helperText={errors.headerLogoHref?.message}
                variant="outlined"
                value={watch("headerLogoHref") || ""}
                onChange={({target}) => setValue("headerLogoHref", target.value)}
              />
            </FormControl>
            <FormControl
              id="settings-select__app-settings-form__general-tab__header-logo-title"
              fullWidth
              sx={fieldStyle}
            >
              <I18nTextField
                name="headerLogoTitle"
                label={t("scenes.appSettings.fields.headerLogoTitle.label")}
                error={!!errors.headerLogoTitle}
                helperText={errors.headerLogoTitle?.message}
                variant="outlined"
                value={watch("headerLogoTitle") || ""}
                onChange={value => setValue("headerLogoTitle", value)}
              />
            </FormControl>
          </TabPanel>
          <TabPanel id="settings-select__app-settings-form__infos-tab" value="infos" selected={tab}>
            <I18nHtmlEditor value={watch("description")} onChange={val => setValue("description", val)} />
          </TabPanel>
          <TabPanel id="settings-select__app-settings-form__footer-tab" value="footer" selected={tab}>
            <Grid container display={"flex"} justifyContent={"flex-end"}>
              <Grid item sx={{mb: "4px"}}>
                <I18nInputAdornmentSelect value={footerLang} onChange={lang => setFooterLang(lang)} />
              </Grid>
            </Grid>
            <Paper variant="outlined" sx={{padding: "16px"}}>
              <Grid container rowSpacing={3} direction={"column"}>
                <Grid item>
                  <FormControl id="settings-select__app-settings-form__footer-tab__footer" fullWidth>
                    <FileInputText
                      key={footerLang}
                      label={t("scenes.appSettings.fields.footer.label")}
                      value={watch("footer")[footerLang]}
                      onChange={value => setValue(`footer.${footerLang}`, value)}
                      accept=".html"
                    />
                  </FormControl>
                </Grid>
                <Grid item>{t("scenes.appSettings.fields.footer.preview.label")}:</Grid>
                <Grid id="settings-select__app-settings-form__footer-tab__footer-preview" item>
                  <Box>
                    {(watch("footer")[footerLang] ?? "").length > 0 ? (
                      <SanitizedHTML html={watch("footer")[footerLang]} allowTarget />
                    ) : (
                      <CustomEmpty sx={{height: "64px"}} text={t("scenes.appSettings.fields.footer.preview.empty")} />
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </TabPanel>
          <TabPanel id="settings-select__app-settings-form__users-tab" value="users" selected={tab}>
            <Fragment>
              <FormControl id="settings-select__app-settings-form__map-tab__map-layer" fullWidth sx={fieldStyle}>
                <TextField
                  name="registration"
                  select
                  required
                  label={t("scenes.appSettings.fields.registration.label")}
                  onChange={e => setValue("registration", e.target.value)}
                  value={watch("registration") || ""}
                  error={!!errors.registration}
                  variant="outlined"
                  SelectProps={{SelectDisplayProps: {"aria-haspopup": true}}}
                >
                  <MenuItem value={HUB_EXTRAS_REGISTRATION_ENABLED}>
                    {t("scenes.appSettings.fields.registration.values.enabled")}
                  </MenuItem>
                  <MenuItem value={HUB_EXTRAS_REGISTRATION_DISABLED}>
                    {t("scenes.appSettings.fields.registration.values.disabled")}
                  </MenuItem>
                </TextField>
              </FormControl>
              <FormControl id="settings-select__app-settings-form__map-tab__map-layer" fullWidth sx={fieldStyle}>
                <TextField
                  name="access"
                  select
                  required
                  label={t("scenes.appSettings.fields.access.label")}
                  onChange={e => setValue("access", e.target.value)}
                  value={watch("access") || ""}
                  error={!!errors.access}
                  variant="outlined"
                  SelectProps={{SelectDisplayProps: {"aria-haspopup": true}}}
                >
                  <MenuItem value={HUB_EXTRAS_ACCESS_OPEN}>
                    {t("scenes.appSettings.fields.access.values.open")}
                  </MenuItem>
                  <MenuItem value={HUB_EXTRAS_ACCESS_ONLY_ADVANCED_USER}>
                    {t("scenes.appSettings.fields.access.values.onlyAdvancedUsers")}
                  </MenuItem>
                  <MenuItem value={HUB_EXTRAS_ACCESS_ONLY_SUPERADMIN}>
                    {t("scenes.appSettings.fields.access.values.onlySuperadmin")}
                  </MenuItem>
                </TextField>
              </FormControl>
              <Box sx={fieldStyle}>{t("scenes.appSettings.fields.disclaimer.label")}:</Box>
              <I18nHtmlEditor value={watch("disclaimer")} onChange={val => setValue("disclaimer", val)} />
            </Fragment>
          </TabPanel>
          <TabPanel id="settings-select__app-settings-form__map-tab" value="map" selected={tab}>
            <FormControl id="settings-select__app-settings-form__map-tab__map-layer" fullWidth sx={fieldStyle}>
              <TextField
                name="mapLayer"
                select
                required
                label={t("scenes.nodeSettings.fields.mapLayer.label")}
                onChange={e => setValue("mapLayer", e.target.value)}
                value={watch("mapLayer") || ""}
                error={!!errors.mapLayer}
                variant="outlined"
                SelectProps={{SelectDisplayProps: {"aria-haspopup": true}}}
              >
                {getMapLayerOptions(t).map(({value, label}, idx) => (
                  <MenuItem key={idx} value={value}>
                    {label}
                  </MenuItem>
                ))}
                {(mapLayersConfig || []).map(({id, name}, idx) => (
                  <MenuItem key={idx} value={id}>
                    {name ? localizeI18nObj(name, appLanguage, appLanguages) : id}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </TabPanel>
          <TabPanel id="settings-select__app-settings-form__colors-tab" value="colors" selected={tab}>
            <FormProvider {...appSettingsForm}>
              <CustomColorsForm />
            </FormProvider>
          </TabPanel>
          <TabPanel id="settings-select__app-settings-form__cache-tab" value="cache" selected={tab}>
            <FormControl fullWidth sx={fieldStyle}>
              <FormControlLabel
                label={t("scenes.nodeSettings.fields.disableArtefactsCache.label")}
                control={
                  <Checkbox
                    id="settings-select__app-settings-form__cache-tab__disable-artefacts-cache"
                    name="disableArtefactsCache"
                    required
                    checked={watch("disableArtefactsCache") || false}
                    onChange={(e, value) => setValue("disableArtefactsCache", value)}
                  />
                }
              />
            </FormControl>
          </TabPanel>
        </Box>
      </Box>
    </Fragment>
  );
});

const AppSettingsForm = (
  {
    config,
    fetchConfig,
    sendConfig,
    clearConfig,
    user,
    dashboards,
    allDashboards,
    mapLayersConfig,
    appLanguage,
    appLanguages,
    modulesConfig,
    fetchDashboards,
    fetchAllDashboards,
    clearDashboards,
    clearAllDashboards,
    addDashboard,
    removeDashboard,
    sendDashboardsOrders,
    onClose
  },
  ref
) => {
  const [needConfig, setNeedConfig] = useState(true);

  useEffect(() => {
    if (needConfig) {
      setNeedConfig(false);
      fetchConfig();
    }
  }, [config, needConfig, setNeedConfig, fetchConfig]);

  return (
    config && (
      <Form
        config={config}
        ref={ref}
        onSubmit={sendConfig}
        onCancel={() => {
          clearConfig();
          onClose();
        }}
        user={user}
        dashboards={dashboards}
        allDashboards={allDashboards}
        mapLayersConfig={mapLayersConfig}
        appLanguage={appLanguage}
        appLanguages={appLanguages}
        modulesConfig={modulesConfig}
        fetchDashboards={fetchDashboards}
        fetchAllDashboards={fetchAllDashboards}
        clearDashboards={clearDashboards}
        clearAllDashboards={clearAllDashboards}
        addDashboard={addDashboard}
        removeDashboard={removeDashboard}
        sendDashboardsOrders={sendDashboardsOrders}
      />
    )
  );
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true}),
  forwardRef
)(AppSettingsForm);
