import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState} from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ClearIcon from "@mui/icons-material/Clear";
import CompressIcon from "@mui/icons-material/Compress";
import ExpandIcon from "@mui/icons-material/Expand";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FilterNoneIcon from "@mui/icons-material/FilterNone";
import FolderIcon from "@mui/icons-material/Folder";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import SearchIcon from "@mui/icons-material/Search";
import {Box, Checkbox, IconButton, InputAdornment, TextField, Tooltip} from "@mui/material";
import _ from "lodash";
import {useTranslation} from "react-i18next";
import {Virtuoso} from "react-virtuoso";
import "./style.css";

/**
 * properties for selectMode
 */
export const DEFAULT_SELECT_MODE = "default";
export const SINGLE_SELECT_MODE = "single";
export const HIERARCHICAL_SELECT_MODE = "hierarchical";

const DEFAULT_PATH_SEPARATOR = "+";

const MaterialTree = (
  {
    treeData,
    idKey,
    childrenKey,
    labelKey,
    renderNode = null,
    selectableKey = null,
    selectMode = DEFAULT_SELECT_MODE,
    defaultSelectedNodeIds = null,
    disableSelection = null,
    removeDisabledCheckbox = false,
    onNodeSelect = null,
    defaultExpandedNodeIds = null,
    onNodeClick = null,
    height = null,
    hideButtonBar = false,
    pathSeparator = DEFAULT_PATH_SEPARATOR,
    debounceSearchMs = null,
    minSearchString = null,
    showFolderIcon = false
  },
  ref
) => {
  useImperativeHandle(ref, () => ({
    expandAll: () => onExpandAll(),
    collapseAll: () => onCollapseAll(),
    selectAll: () => handleSelectAll(),
    deselectAll: () => handleDeselectAll(),
    expandSelected: () => onExpandSelected(),
    selectedIds: selectedNodeIds || []
  }));

  const inputRef = useRef();
  const treeItemRef = useRef();

  const [data, setData] = useState([]);
  const [flatData, setFlatData] = useState([]);
  const [cachedExpandedNode, setCachedExpandedNode] = useState({});
  const [allSelectedNodes, setAllSelectedNodes] = useState({});
  const [allSelectedNodesList, setAllSelectedNodesList] = useState([]);
  const [selectedNodes, setSelectedNodes] = useState({});
  const [expandedNodes, setExpandedNodes] = useState({});
  const [selectedNodeIds, setSelectedNodeIds] = useState();
  const [parentChildrenOnlyClicked, setParentChildrenOnlyClicked] = useState({});
  const [treeDepthLevel, setTreeDepthLevel] = useState({});
  const [levelsTree, setLevelsTree] = useState();

  const elementRef = useRef(null);

  const {t} = useTranslation();

  const updateTreelvl = (checkedNodeIds, updateAll) => {
    if (flatData && levelsTree) {
      const tdl = {...treeDepthLevel};

      Object.keys(treeDepthLevel).forEach(k => {
        tdl[k].countSelected = 0;
      });

      flatData.forEach(f => {
        if (!updateAll && (checkedNodeIds || []).includes(f[idKey])) {
          tdl[f.level].countSelected++;
        } else if (updateAll) {
          tdl[f.level].countSelected++;
        }
      });

      const l = Object.values(tdl);
      const lt = [];
      levelsTree.forEach((_, i) => lt.push(l[i]));
      setTreeDepthLevel(tdl);
      setLevelsTree(lt);
    }
  };

  const initListTree = fd => {
    setExpandedNodes({});

    const t = fd.reduce((newArray, fd) => {
      if (fd.level === 0) {
        newArray.push({...fd});
      }
      return newArray;
    }, []);

    return t;
  };

  const calculateTreeDepthLevel = useCallback(
    fd => {
      const levels = {};
      fd.forEach(d => {
        if (levels[d.level] === undefined) {
          levels[d.level] = {
            level: d.level,
            parentLevel: d.level > 0 ? d.level - 1 : null,
            countSelected: selectedNodes[d[idKey]] ? 1 : 0,
            total: d[selectableKey] ? 1 : 0,
            expanded: false,
            checked: false,
            hasChildren: true
          };
        } else {
          if (d[selectableKey]) levels[d.level].total++;

          if (selectedNodes[d[idKey]]) levels[d.level].countSelected++;
        }
      });

      return levels;
    },
    [selectedNodes, idKey, selectableKey]
  );

  const generateTreePath = useCallback((node, childId) => `${node.path}${pathSeparator}${childId}`, [pathSeparator]);

  const prepareFlatData = useCallback(
    (tree, list, node) => {
      tree.forEach(item => {
        const path = node ? generateTreePath(node, item[idKey]) : `${item[idKey]}`;
        const n = {
          ...item,
          [idKey]: `${item[idKey]}`,
          parentId: node ? node[idKey] : undefined,
          children: null,
          hasChildren: item[childrenKey] && item[childrenKey].length > 0,
          path: path,
          level: node ? path.split(pathSeparator).length - 1 : 0
        };
        delete n.children;
        delete n[childrenKey];
        list.push(n);

        if (item[childrenKey]) {
          return prepareFlatData(item[childrenKey], list, n);
        }
      });
      return list;
    },
    [idKey, pathSeparator, childrenKey, generateTreePath]
  );

  const nodeListExpanded = useCallback(
    (flatD, idsToShow, expandedNodes) => {
      const exp = {...expandedNodes};

      const list = [];
      flatD.forEach((f, i, arr) => {
        if (f.level === 0) {
          list.push({...f});
          f.hasChildren && idsToShow.includes(f[idKey]) ? (exp[f[idKey]] = true) : delete exp[f[idKey]];
        }
        if (f.hasChildren && idsToShow.includes(f[idKey])) {
          const children = arr.filter(a => a.parentId === f[idKey]);
          const index = list.findIndex(l => l[idKey] === f[idKey]);
          if (index !== -1) {
            exp[list[index][idKey]] = true;
            list.splice(+index + 1, 0, ...children);
          }
        }
      });
      setExpandedNodes(exp);
      return list;
    },
    [idKey]
  );

  const defaultExpanded = useCallback(
    flatD => {
      const idsToShow = [];
      const exp = {};
      defaultExpandedNodeIds.forEach(en => {
        exp[en] = true;
        const n = flatD.find(fd => fd[idKey] === en);
        if (n) {
          const pathSplitted = n.path.split(pathSeparator);
          pathSplitted.forEach(ps => {
            if (!idsToShow.includes(ps)) {
              idsToShow.push(ps);
            }
          });
        }
      });

      const list = nodeListExpanded(flatD, idsToShow, exp);
      return list;
    },
    [defaultExpandedNodeIds, pathSeparator, idKey, nodeListExpanded]
  );

  useEffect(() => {
    const fd = prepareFlatData(treeData, [], undefined);
    setFlatData(fd);

    //predispongo un oggetto con tutti i nodi selezionati da settare in caso di select all
    const asn = {};
    const asnl = [];
    fd.forEach(f => {
      if (f[selectableKey]) {
        asn[f[idKey]] = true;
        asnl.push(f[idKey]);
      }
    });

    setAllSelectedNodes(asn);
    setAllSelectedNodesList(asnl);

    if (defaultExpandedNodeIds) {
      const d = defaultExpanded(fd);
      setData(d);
    } else {
      const d = initListTree(fd);
      setData(d);
    }
  }, [
    treeData,
    defaultExpandedNodeIds,
    selectMode,
    idKey,
    selectableKey,
    defaultExpanded,
    prepareFlatData,
    setAllSelectedNodes
  ]);

  useEffect(() => {
    if (!levelsTree && flatData && flatData.length > 0) {
      if (selectMode !== SINGLE_SELECT_MODE) {
        const levels = calculateTreeDepthLevel(flatData);
        const l = Object.values(levels);
        levels[l.length - 1].hasChildren = false;
        l[l.length - 1].hasChildren = false;
        setTreeDepthLevel(levels);
        const lt = [l[0]];
        setLevelsTree(lt);
      }
    }
  }, [flatData, levelsTree, selectMode, calculateTreeDepthLevel]);

  useEffect(() => {
    if (!selectedNodeIds && defaultSelectedNodeIds) {
      const sn = {};
      defaultSelectedNodeIds.forEach(dsn => {
        sn[dsn] = true;
      });
      setSelectedNodes(sn);
      setSelectedNodeIds(defaultSelectedNodeIds);
    }
  }, [defaultSelectedNodeIds, selectedNodeIds, idKey, setSelectedNodes, setSelectedNodeIds]);

  const expandChecked = flatD => {
    const idsToShow = [];
    const exp = {...expandedNodes};
    flatD.forEach(fd => {
      delete exp[fd[idKey]];
      if (selectedNodes[fd[idKey]]) {
        const pathSplitted = fd.path.split(pathSeparator);
        pathSplitted.forEach(ps => {
          if (!idsToShow.includes(ps)) {
            idsToShow.push(ps);
          }
        });
      }
    });
    setExpandedNodes(exp);
    const list = nodeListExpanded(flatD, idsToShow, exp);
    return list;
  };

  const onExpandSelected = () => {
    const d = expandChecked(flatData);
    setData(d);
  };

  const onSearch = _.debounce(searchString => {
    const exp = {...expandedNodes};
    const length = minSearchString ?? 1;
    if (searchString && searchString.trim().length > length) {
      const s = new RegExp(`${searchString.toLowerCase()}`);

      const idList = flatData.reduce((newArray, fd) => {
        if (s.test(fd[labelKey].toLowerCase())) {
          newArray.push(...fd.path.split(pathSeparator));
        }
        return newArray;
      }, []);

      const fdFiltered = flatData.reduce((newArray, f) => {
        for (let i = 0; i < idList.length; i++) {
          if (idList[i] === f[idKey]) {
            newArray.push({...f});
            f.hasChildren ? (exp[f[idKey]] = true) : delete exp[f[idKey]];
            break;
          }
        }
        return newArray;
      }, []);
      setExpandedNodes(exp);
      setData(fdFiltered);
    } else {
      resetSearch(true);
    }
  }, debounceSearchMs ?? 400);

  const resetSearch = keepInputRef => {
    if (!keepInputRef) inputRef.current.value = "";
    const d = initListTree(flatData);
    setData(d);
    setCachedExpandedNode({});
  };

  const onExpandAll = () => {
    const exp = {...expandedNodes};
    const l = [];
    flatData.forEach(fd => {
      l.push({...fd});
      fd.hasChildren ? (exp[fd[idKey]] = true) : delete exp[fd[idKey]];
    });
    setExpandedNodes(exp);
    setData(l);
  };

  const onCollapseAll = () => {
    const d = initListTree(flatData);
    setData(d);
    setCachedExpandedNode({});
  };

  const handleSelectAll = () => {
    setSelectedNodes(allSelectedNodes);

    setSelectedNodeIds(allSelectedNodesList);
    updateTreelvl(allSelectedNodesList, true);

    if (onNodeSelect) onNodeSelect(allSelectedNodesList);
  };

  const handleDeselectAll = () => {
    setSelectedNodeIds([]);
    updateTreelvl([]);
    setSelectedNodes({});

    if (onNodeSelect) onNodeSelect([]);
  };

  const handleCheckChildrenOnly = (index, node) => {
    const sn = {...selectedNodes};
    flatData.forEach(f => {
      if (f.parentId === node[idKey] && !parentChildrenOnlyClicked[node[idKey]]) {
        if (f[selectableKey]) sn[f[idKey]] = true;
      } else if (f.parentId === node[idKey] && parentChildrenOnlyClicked[node[idKey]]) {
        delete sn[f[idKey]];
      }
    });

    setSelectedNodes(sn);
    setSelectedNodeIds(Object.keys(sn));
    updateTreelvl(Object.keys(sn));

    if (onNodeSelect) onNodeSelect(Object.keys(sn), node, index);

    if (!parentChildrenOnlyClicked[node[idKey]]) {
      setParentChildrenOnlyClicked({...parentChildrenOnlyClicked, [node[idKey]]: true});
    } else {
      delete parentChildrenOnlyClicked[node[idKey]];
      setParentChildrenOnlyClicked({...parentChildrenOnlyClicked});
    }
  };

  const checkNoStrictMode = (checked, node) => {
    // aggiorno i nodi visibili
    const parendIds = node.path.split(pathSeparator);

    const sn = {...selectedNodes};
    flatData.forEach(f => {
      if (!checked) {
        if (parendIds.includes(f[idKey]) || f.path.startsWith(`${node.path}${pathSeparator}`)) {
          delete sn[f[idKey]];
        }
      } else {
        if (f[idKey] === node[idKey] || f.path.startsWith(`${node.path}${pathSeparator}`)) {
          sn[f[idKey]] = true;
        }
      }
    });

    setSelectedNodes(sn);

    return Object.keys(sn);
  };

  const checkPathStartWithList = (levelPathList, pathToCheck) => {
    for (const path of levelPathList) {
      if (pathToCheck.startsWith(`${path}${pathSeparator}`)) {
        return true;
      }
    }
    return false;
  };

  const handleTreeLevelCheck = (level, checked, selectMode) => {
    const levelPathList = flatData.filter(f => f.level === level).map(f => f.path);

    const sn = {...selectedNodes};
    flatData.forEach(f => {
      if (f[selectableKey]) {
        if (
          f.level === level ||
          (selectMode === HIERARCHICAL_SELECT_MODE && checkPathStartWithList(levelPathList, f.path))
        ) {
          checked ? (sn[f[idKey]] = true) : delete sn[f[idKey]];
        }
      }
    });

    setSelectedNodes(sn);
    setSelectedNodeIds(Object.keys(sn));
    updateTreelvl(Object.keys(sn));

    if (onNodeSelect) onNodeSelect(Object.keys(sn), null, null);
  };

  const onTreeLevelCheck = (level, checked) => {
    handleTreeLevelCheck(level, checked, selectMode);
  };

  const handleCheckHierarchicalMode = (e, index, node) => {
    const checkedIds = checkNoStrictMode(e.target.checked, node);
    setSelectedNodeIds(checkedIds);
    updateTreelvl(checkedIds);

    if (onNodeSelect) onNodeSelect(checkedIds, node, index);
  };

  const onHandleCheck = (e, index, node) => {
    if (selectMode !== HIERARCHICAL_SELECT_MODE) {
      const sn = selectMode !== SINGLE_SELECT_MODE ? {...selectedNodes} : {};
      flatData.forEach(f => {
        if (f[idKey] === node[idKey]) {
          e.target.checked
            ? (sn[f[idKey]] = true)
            : selectMode !== SINGLE_SELECT_MODE
              ? delete sn[f[idKey]]
              : (sn[f[idKey]] = true);
        }
      });

      setSelectedNodes(sn);
      setSelectedNodeIds(Object.keys(sn));
      updateTreelvl(Object.keys(sn));

      if (onNodeSelect) onNodeSelect(Object.keys(sn), node, index);
    } else {
      handleCheckHierarchicalMode(e, index, node);
    }
  };

  const onTreeLevelItemExpand = (index, level) => {
    if (!levelsTree[index].expanded) {
      let tdl = {...treeDepthLevel};
      tdl[index].expanded = true;
      const l = Object.values(tdl);

      let children = [];
      children = l.filter(l => l.parentLevel === level.level);

      let d = levelsTree.map(lt => ({...lt}));
      d[index].expanded = true;
      d.splice(+index + 1, 0, ...children);
      setLevelsTree(d);
      setTreeDepthLevel(tdl);
    } else {
      let tdl = {...treeDepthLevel};

      Object.keys(tdl).forEach(key => {
        tdl[key].expanded = key < index;
      });

      const d = levelsTree.filter(l => l.level <= level.level);
      d[index].expanded = false;
      setLevelsTree(d);
      setTreeDepthLevel(tdl);
    }
  };

  const onExpand = (index, node) => {
    const exp = {...expandedNodes};

    if (!exp[data[index][idKey]]) {
      let children = [];
      if (cachedExpandedNode[node[idKey]]) {
        children = cachedExpandedNode[node[idKey]];
      } else {
        children = flatData.filter(fd => fd.parentId === node[idKey]);
      }

      let d = data.map(da => ({...da}));
      exp[d[index][idKey]] = true;

      d.splice(+index + 1, 0, ...children);
      setData(d);
      delete cachedExpandedNode[node[idKey]];
      setCachedExpandedNode(cachedExpandedNode);
    } else {
      delete exp[data[index][idKey]];
      const en = data.filter(d => d.path.startsWith(`${node.path}${pathSeparator}`));
      const ex = {[node[idKey]]: en, ...cachedExpandedNode};
      setCachedExpandedNode(ex);
      let d = data.filter(d => !d.path.startsWith(`${node.path}${pathSeparator}`));
      setData(d);
    }
    setExpandedNodes(exp);
  };

  const getCheckboxVisibility = useCallback(
    node => {
      if (removeDisabledCheckbox) {
        const isDisabled =
          disableSelection || (selectMode !== HIERARCHICAL_SELECT_MODE && selectableKey && !node[selectableKey]);
        return !isDisabled;
      } else {
        return true;
      }
    },
    [removeDisabledCheckbox, disableSelection, selectMode, selectableKey]
  );

  return (
    <Box id="optimized-tree" sx={{height: "100%", color: theme => theme.palette.text.primary}}>
      <Box className="v-tree-header-container" ref={elementRef}>
        <Box className="v-tree-toolbar-container">
          {!hideButtonBar && (
            <Box className="v-tree-icon-container">
              {selectMode !== SINGLE_SELECT_MODE && (
                <Tooltip title={t("components.optimizedTree.selectAll.tooltip")}>
                  <span>
                    <IconButton
                      className="material-tree__select-all"
                      disabled={disableSelection}
                      aria-label={t("components.optimizedTree.selectAll.ariaLabel")}
                      onClick={handleSelectAll}
                    >
                      <LibraryAddCheckIcon fontSize="small" />
                    </IconButton>
                  </span>
                </Tooltip>
              )}
              {selectMode !== SINGLE_SELECT_MODE && (
                <Tooltip title={t("components.optimizedTree.deselectAll.tooltip")}>
                  <span>
                    <IconButton
                      className="material-tree__deselect-all"
                      disabled={disableSelection}
                      aria-label={t("components.optimizedTree.deselectAll.ariaLabel")}
                      onClick={handleDeselectAll}
                    >
                      <FilterNoneIcon fontSize="small" />
                    </IconButton>
                  </span>
                </Tooltip>
              )}
              <Tooltip title={t("components.optimizedTree.expandAll.tooltip")}>
                <span>
                  <IconButton
                    className="material-tree__expand-all"
                    onClick={onExpandAll}
                    aria-label={t("components.optimizedTree.expandAll.ariaLabel")}
                  >
                    <ExpandIcon fontSize="small" />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title={t("components.optimizedTree.collapseAll.tooltip")}>
                <span>
                  <IconButton
                    className="material-tree__collapse-all"
                    aria-label={t("components.optimizedTree.collapseAll.ariaLabel")}
                    onClick={onCollapseAll}
                  >
                    <CompressIcon fontSize="small" />
                  </IconButton>
                </span>
              </Tooltip>
            </Box>
          )}
          <Box className="v-tree-search-container" sx={{width: hideButtonBar ? "100%" : "50%"}}>
            <TextField
              id="standard-basic"
              fullWidth
              inputRef={inputRef}
              onChange={e => onSearch(e.target.value)}
              placeholder={t("components.optimizedTree.search.placeholder")}
              variant="standard"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: inputRef?.current?.value ? (
                  <InputAdornment position="end">
                    <IconButton size="small" onClick={() => resetSearch(false)}>
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ) : undefined
              }}
            />
          </Box>
        </Box>
        {levelsTree && levelsTree.length > 0 && (
          <Box className="v-tree-level-selector-container">
            {levelsTree.map((d, index) => (
              <Box
                id={`material-tree__${d[idKey]}`}
                key={`${d.level}-${index}`}
                ref={treeItemRef}
                className="v-tree-item-container"
                sx={{marginLeft: `${d.level * 15 * 2}px`}}
              >
                {d.hasChildren ? (
                  <Box
                    id={`material-tree__${d[idKey]}__expand-icon`}
                    className="v-tree-item-container-icon"
                    onClick={() => onTreeLevelItemExpand(index, d)}
                  >
                    {d.expanded ? <ExpandMoreIcon fontSize="small" /> : <ChevronRightIcon fontSize="small" />}
                  </Box>
                ) : (
                  <Box className="v-tree-item-container-icon-placeholder" />
                )}
                <Tooltip
                  title={
                    d.countSelected === d.total && d.total !== 0
                      ? t("components.optimizedTree.levelSelector.uncheckTooltip", {level: d.level})
                      : t("components.optimizedTree.levelSelector.checkTooltip", {level: d.level})
                  }
                >
                  <Checkbox
                    id={`material-tree__${d[idKey]}__checkbox`}
                    className="v-tree-item-container-checkbox"
                    size="small"
                    sx={{marginRight: "4px"}}
                    checked={d.countSelected === d.total && d.total !== 0}
                    disabled={disableSelection || d.total === 0}
                    onChange={(e, checked) => onTreeLevelCheck(d.level, checked)}
                  />
                </Tooltip>
                <span className="v-tree-item-container-label">
                  {t("components.optimizedTree.levelSelector.level", {level: d.level})}
                </span>
              </Box>
            ))}
          </Box>
        )}
      </Box>
      <Box className="v-tree-tree-container">
        <Virtuoso
          data={data}
          totalCount={flatData.length}
          itemContent={(index, d) => {
            return (
              <Box
                id={`material-tree__${d[idKey]}`}
                className="v-tree-item-container"
                sx={{
                  marginLeft: `${d.level * 15 * 2}px`,
                  cursor: `${onNodeClick ? "pointer" : "default"}`
                }}
              >
                {d.hasChildren ? (
                  <Box
                    id={`material-tree__${d[idKey]}__expand-icon`}
                    className="v-tree-item-container-icon"
                    onClick={() => onExpand(index, d)}
                  >
                    {expandedNodes[d[idKey]] || false ? (
                      <>
                        <ExpandMoreIcon fontSize="small" />
                        {showFolderIcon && <FolderOpenIcon sx={{marginTop: "-2px"}} />}
                      </>
                    ) : (
                      <>
                        <ChevronRightIcon fontSize="small" />
                        {showFolderIcon && <FolderIcon sx={{marginTop: "-2px"}} />}
                      </>
                    )}
                  </Box>
                ) : (
                  <>
                    <Box className="v-tree-item-container-icon-placeholder" />
                    {showFolderIcon && <FolderOpenIcon sx={{marginTop: "-2px"}} />}
                  </>
                )}
                {getCheckboxVisibility(d) && (
                  <Tooltip
                    title={
                      selectedNodes[d[idKey]]
                        ? t("components.optimizedTree.checkBox.uncheck.tooltip", {
                            label: renderNode ? renderNode(d) : d[labelKey]
                          })
                        : t("components.optimizedTree.checkBox.check.tooltip", {
                            label: renderNode ? renderNode(d) : d[labelKey]
                          })
                    }
                  >
                    <Checkbox
                      id={`material-tree__${d[idKey]}__checkbox`}
                      className="v-tree-item-container-checkbox"
                      size="small"
                      sx={{marginRight: "2px"}}
                      checked={selectedNodes[d[idKey]] || false}
                      onChange={e => onHandleCheck(e, index, d)}
                      checkedIcon={selectMode === SINGLE_SELECT_MODE ? <RadioButtonCheckedIcon /> : undefined}
                      icon={selectMode === SINGLE_SELECT_MODE ? <RadioButtonUncheckedIcon /> : undefined}
                      disabled={
                        disableSelection ||
                        (selectMode !== HIERARCHICAL_SELECT_MODE && selectableKey && !d[selectableKey])
                      }
                    />
                  </Tooltip>
                )}
                <span
                  className="v-tree-item-container-label"
                  style={{cursor: "pointer"}}
                  onClick={() => (onNodeClick ? onNodeClick(d, index) : onExpand(index, d))}
                >
                  {renderNode ? renderNode(d) : d[labelKey]}
                </span>
                {!disableSelection && selectMode === DEFAULT_SELECT_MODE && d.hasChildren && (
                  <Tooltip title={t("components.optimizedTree.actions.selectChildren.title")}>
                    <IconButton
                      className="v-tree-item-container-select-all-children"
                      style={{marginLeft: "4px"}}
                      aria-label={t("components.optimizedTree.actions.selectChildren.ariaLabel")}
                      size="small"
                      onClick={() => handleCheckChildrenOnly(index, d)}
                    >
                      <PlaylistAddCheckIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            );
          }}
          computeItemKey={(index, d) => d.path + (expandedNodes[d[idKey]] || false ? "_expanded" : "")}
          style={{height: height ?? "100%"}}
        />
      </Box>
    </Box>
  );
};

export default forwardRef(MaterialTree);
