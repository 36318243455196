import React, {Fragment} from "react";
import {connect} from "react-redux";
import DeleteConflictModal from "../components/delete-conflict-modal";
import ErrorSnackbar from "../components/error-snackbar/ErrorSnackbar";
import NotImplementedSnackbar from "../components/not-implemented-snackbar/NotImplementedSnackbar";
import {HUB_EXTRAS_REGISTRATION_DISABLED} from "../components/settings-select/app-settings-form/constants";
import UserLoginDialog from "../components/user-login-dialog";
import UserSetPasswordDialog from "../components/user-set-password-dialog";
import Call from "../hocs/call";
import {fetchHub, fetchHubItemContainers} from "../state/hub/hubActions";
import {fetchUser, hideUserLoginForm, showUserRegisterModal} from "../state/user/userActions";

const mapStateToProps = state => ({
  hub: state.hub,
  user: state.user,
  modulesConfig: state.app.modulesConfig
});

const mapDispatchToProps = dispatch => ({
  fetchHub: () => dispatch(fetchHub()),
  fetchHubDashboards: () => dispatch(fetchHubItemContainers("dashboard")),
  fetchHubCustomPages: () => dispatch(fetchHubItemContainers("customPages")),
  onLoginHide: () => dispatch(hideUserLoginForm()),
  onLogin: (email, password) => dispatch(fetchUser(email, password)),
  onRegisterModalShow: () => dispatch(showUserRegisterModal())
});

const HubDomain = ({
  hub,
  user,
  modulesConfig,
  fetchHub,
  fetchHubDashboards,
  fetchHubCustomPages,
  onLoginHide,
  onLogin,
  onRegisterModalShow,
  children
}) => (
  <Call cb={fetchHub} disabled={hub !== null}>
    <Call
      cb={fetchHubDashboards}
      disabled={!modulesConfig.modules.includes("dashboard") || !hub || !!hub?.hub?.itemContainers?.dashboard}
    >
      <Call
        cb={fetchHubCustomPages}
        disabled={!modulesConfig.modules.includes("custom-page") || !hub || !!hub?.hub?.itemContainers?.customPages}
      >
        {hub && (
          <Fragment>
            <NotImplementedSnackbar />
            <ErrorSnackbar />
            <DeleteConflictModal />
            <UserSetPasswordDialog />
            <UserLoginDialog
              open={user.isLoginDialogOpen}
              onSubmit={({email, password}) => onLogin(email, password)}
              onRegister={onRegisterModalShow}
              onHide={onLoginHide}
              alert={user.message}
              alertSeverity={user.messageSeverity}
              loginOrHome={user.loginOrHome}
              disableRegistration={hub.hub?.extras?.Registration === HUB_EXTRAS_REGISTRATION_DISABLED}
            />
            {children}
          </Fragment>
        )}
      </Call>
    </Call>
  </Call>
);

export default connect(mapStateToProps, mapDispatchToProps)(HubDomain);
