import {Grid} from "@mui/material";
import {HUB_EXTRAS_ACCESS_OPEN} from "../settings-select/app-settings-form/constants";
import UserSelect from "../user-select";

const UserSettingsButton = ({hub, nodes, user, disabled}) =>
  hub?.extras.Access === HUB_EXTRAS_ACCESS_OPEN || user.isAuthenticated ? (
    <Grid item className="user-settings-btn">
      <UserSelect nodes={nodes} disabled={disabled} />
    </Grid>
  ) : null;

export default UserSettingsButton;
