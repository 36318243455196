import React, {Component, forwardRef, Fragment} from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";

class ButtonSelect extends Component {
  state = {
    anchorEl: null
  };

  setAnchorEl = el => {
    this.setState({
      anchorEl: el
    });
  };

  handleMenu = event => {
    this.setAnchorEl(event.currentTarget);
  };

  handleClose = () => {
    this.setAnchorEl(null);
  };

  render() {
    const {
      children,
      icon,
      value,
      color,
      tooltip,
      disabled,
      selectedIdx,
      onChange,
      ariaLabel,
      buttonStyle,
      removeEndIcon
    } = this.props;

    const {anchorEl} = this.state;
    const open = Boolean(anchorEl);

    const Item = forwardRef(({c, id, isSelected, isUnselectable, isModulePlaceholder}, ref) => {
      return isModulePlaceholder ? (
        <MenuItem onClick={this.handleClose} style={{padding: 0}}>
          {c}
        </MenuItem>
      ) : (
        <MenuItem
          ref={ref}
          id={id}
          onClick={() => {
            if (c.props?.onClick) {
              c.props.onClick();
            }
            if (onChange) {
              onChange(c.props["data-value"]);
            }
            this.handleClose();
          }}
          disabled={isUnselectable}
          style={{background: isSelected ? "#fff9e5" : undefined}}
          tabIndex={0}
        >
          {{...c, props: {...c.props, id: undefined}}}
        </MenuItem>
      );
    });

    const items = children.length > 0 ? [...children] : [children];

    return (
      <Fragment>
        <Tooltip title={tooltip || ""}>
          <div>
            {value ? (
              <Button
                className="button-select__menu-button"
                color={color || "inherit"}
                onClick={this.handleMenu}
                startIcon={icon}
                endIcon={!removeEndIcon ? <ArrowDropDownIcon /> : undefined}
                disabled={disabled}
                aria-label={ariaLabel}
                style={buttonStyle}
              >
                {value}
              </Button>
            ) : (
              <IconButton
                color={color || "inherit"}
                onClick={this.handleMenu}
                disabled={disabled}
                aria-label={ariaLabel}
                style={buttonStyle}
              >
                {icon}
              </IconButton>
            )}
          </div>
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          open={open}
          onClose={this.handleClose}
        >
          {items
            .filter(c => c)
            .map((c, i) => (
              <Item
                key={i}
                c={c}
                id={c.props?.id}
                isSelected={i === selectedIdx}
                isUnselectable={
                  !(
                    (c.props?.onClick !== null && c.props?.onClick !== undefined) ||
                    (c.props?.["data-value"] !== null && c.props?.["data-value"] !== undefined) ||
                    c?.props?.isModulePlaceholder
                  )
                }
                isModulePlaceholder={c?.props?.isModulePlaceholder}
              />
            ))}
        </Menu>
      </Fragment>
    );
  }
}

export default ButtonSelect;
