import React, {Fragment, useCallback, useEffect, useState} from "react";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/Info";
import StorageIcon from "@mui/icons-material/Storage";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {getDatasetInternalUrlFromDatasetId} from "../../links";
import CatalogInfoButton from "../catalog-info-button";
import CustomLink from "../custom-link";
import DatasetAttachments from "../dataset-attachments";
import DatasetMetadataButton, {VIEW_MODE_BUTTON, VIEW_MODE_ICON_BUTTON} from "../dataset-metadata-button";
import SanitizedHTML from "../sanitized-html";
import SdmxDownloadButton from "./SdmxDownloadButton";
import {hubNodesSelector} from "../../state/hub/hubSelectors";

const $ = window.jQuery;

const actionStyle = {
  display: "flex",
  alignItems: "center",
  padding: "4px",
  "& > button": {
    padding: "8px"
  }
};

const browseDataBtnStyle = {
  whiteSpace: "nowrap",
  padding: "8px",
  backgroundColor: "#00295a",
  color: "white",
  "&:hover": {
    backgroundColor: "#000030"
  }
};

const descriptionStyle = {
  width: "100%",
  padding: "16px !important"
};

// based on https://stackoverflow.com/a/7616484
const getIdFromDatasetIdentifier = identifier => {
  if (!identifier) {
    return undefined;
  }

  let hash = 0,
    i,
    chr;
  if (identifier.length === 0) return hash;
  for (i = 0; i < identifier.length; i++) {
    chr = identifier.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // convert to 32bit integer
  }
  return "id__" + hash;
};

const handleStyle = (dataset, isExpanded) => {
  const textHeight = $(`#${getIdFromDatasetIdentifier(dataset.identifier)}__description__text`).outerHeight() || 0;
  const textWrapperHeight =
    $(`#${getIdFromDatasetIdentifier(dataset.identifier)}__description__text-wrapper`).outerHeight() || 0;
  $(`#${getIdFromDatasetIdentifier(dataset.identifier)}__description__icon`).css({
    display: textHeight > textWrapperHeight || isExpanded ? "flex" : "none"
  });
};

const DatasetCard = ({
  nodeId,
  dataset,
  to,
  onClick,
  xs,
  md,
  isMultiCategorized,
  categoryItem,
  enableBrowseData = false,
  enableSDMXDownload = false,
  enableMetadataAsButton = false
}) => {
  const {t} = useTranslation();

  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    const f = () => handleStyle(dataset, isExpanded);
    f();
    window.addEventListener("resize", f);
    return () => window.removeEventListener("resize", f);
  }, [dataset, isExpanded]);

  const onExpand = useCallback(() => {
    setIsExpanded(true);
  }, []);

  const onCollapse = useCallback(() => {
    setIsExpanded(false);
  }, []);

  const nodes = useSelector(hubNodesSelector);

  const downloadFormats = JSON.parse(
    categoryItem?.nodeExtras?.find(({key}) => key === "DownloadFormats")?.value || "[]"
  );
  const sdmxAvailableFormats = downloadFormats.filter(v => v === "structurespecificdata" || v === "compactdata");

  const isBrowseDataVisible =
    categoryItem?.browseDataLink === "Internal" ||
    (categoryItem?.browseDataLink === "External" && dataset?.externalUrlForBrowse);

  const isDownloadSdmxVisible = categoryItem && categoryItem.enableSDMXDownload && sdmxAvailableFormats.length > 0;

  const isMetadataVisible = dataset.referenceMetadata && (!categoryItem || categoryItem.enableMetadata);

  return (
    <Grid
      className={md === 4 ? "dataset-card__grid-mode" : "dataset-card__list-mode"}
      item
      xs={xs}
      md={md}
      id={getIdFromDatasetIdentifier(dataset.identifier)}
    >
      <Card>
        <Box
          className={"dataset-card"}
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            flexDirection: "row"
          }}
        >
          <Box
            className={"dataset-card__link"}
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              flexDirection: "row"
            }}
          >
            <CustomLink
              to={to}
              onClick={onClick}
              text={
                <Fragment>
                  {dataset.title}
                  {isMultiCategorized && (
                    <Tooltip title={t("components.datasetCard.isMultiCategorized.title")}>
                      <InfoIcon fontSize="small" style={{margin: "0 0 -4px 8px"}} />
                    </Tooltip>
                  )}
                </Fragment>
              }
              icon={
                dataset.datasetType === "dashboard" ? (
                  <DashboardIcon fontSize="small" />
                ) : (
                  <StorageIcon fontSize="small" />
                )
              }
              textStyle={{
                fontSize: 18,
                minHeight: 48,
                padding: "16px 16px 0 16px"
              }}
              subText={dataset.source}
              subTextStyle={{
                fontSize: 14,
                fontWeight: "normal",
                color: "rgba(0, 0, 0, 0.54)",
                padding: "0 16px 16px 16px"
              }}
              disabled={dataset.datasetType === "onlyFile"}
            />
            {dataset.attachedDataFiles && dataset.attachedDataFiles.length > 0 && (
              <Box sx={actionStyle}>
                <DatasetAttachments title={dataset.title} attachedDataFiles={dataset.attachedDataFiles} />
              </Box>
            )}
            {dataset.note && (
              <Box sx={actionStyle}>
                <CatalogInfoButton note={dataset.note} />
              </Box>
            )}
          </Box>
          <Box
            className={"dataset-card__actions"}
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              "& > div:last-child": {
                marginRight: "8px"
              }
            }}
          >
            {(enableBrowseData || isBrowseDataVisible) && (
              <Box style={{visibility: isBrowseDataVisible ? "visible" : "hidden"}} sx={actionStyle}>
                {categoryItem?.browseDataLink === "Internal" ? (
                  <Link
                    to={getDatasetInternalUrlFromDatasetId(
                      nodes.find(n => categoryItem.nodeId === n.nodeId)?.code,
                      dataset.identifier
                    )}
                  >
                    <Button
                      size="small"
                      sx={browseDataBtnStyle}
                      className="dataset-card__browse-data-btn"
                      color="primary"
                      variant="contained"
                    >
                      {t("components.datasetCard.buttons.browseData")}
                    </Button>
                  </Link>
                ) : categoryItem?.browseDataLink === "External" && dataset?.externalUrlForBrowse ? (
                  <Button
                    size="small"
                    sx={browseDataBtnStyle}
                    className="dataset-card__browse-data-btn"
                    variant="contained"
                    onClick={() => window.open(dataset.externalUrlForBrowse)}
                  >
                    {t("components.datasetCard.buttons.browseData")}
                  </Button>
                ) : (
                  <Button
                    size="small"
                    sx={browseDataBtnStyle}
                    className="dataset-card__browse-data-btn"
                    variant="contained"
                  >
                    {t("components.datasetCard.buttons.browseData")}
                  </Button>
                )}
              </Box>
            )}
            {(enableSDMXDownload || isDownloadSdmxVisible) && (
              <Box style={{visibility: isDownloadSdmxVisible ? "visible" : "hidden"}} sx={actionStyle}>
                <SdmxDownloadButton
                  nodeId={categoryItem.nodeId}
                  datasetTitle={dataset.title}
                  datasetId={dataset.identifier}
                  availableFormats={sdmxAvailableFormats}
                />
              </Box>
            )}
            {(enableMetadataAsButton || isMetadataVisible) && (
              <Box style={{visibility: isMetadataVisible ? "visible" : "hidden"}} sx={actionStyle}>
                <DatasetMetadataButton
                  viewMode={enableMetadataAsButton ? VIEW_MODE_BUTTON : VIEW_MODE_ICON_BUTTON}
                  label={t("components.datasetCard.buttons.metadata")}
                  metadataUrl={dataset.referenceMetadata}
                  datasetId={dataset.identifier}
                  nodeId={nodeId}
                  sx={
                    enableMetadataAsButton
                      ? {
                          backgroundColor: "#f8a81e",
                          color: "white",
                          "&:hover": {
                            backgroundColor: "#c07900"
                          }
                        }
                      : undefined
                  }
                />
              </Box>
            )}
          </Box>
        </Box>
        {dataset.description && (
          <Box sx={descriptionStyle}>
            <Box
              sx={[
                {fontSize: "16px"},
                isExpanded
                  ? {minHeight: "48px"}
                  : {
                      maxHeight: "48px",
                      overflow: "hidden"
                    }
              ]}
              id={`${getIdFromDatasetIdentifier(dataset.identifier)}__description__text-wrapper`}
            >
              {dataset.description && (
                <SanitizedHTML
                  id={`${getIdFromDatasetIdentifier(dataset.identifier)}__description__text`}
                  html={dataset.description}
                  sx={{
                    "& > p": {
                      margin: "0px"
                    }
                  }}
                />
              )}
            </Box>
            <Box
              id={`${getIdFromDatasetIdentifier(dataset.identifier)}__description__icon`}
              sx={{
                display: "none",
                justifyContent: "flex-end"
              }}
            >
              <Tooltip title={isExpanded ? t("commons.confirm.collapse") : t("commons.confirm.expand")}>
                <IconButton onClick={isExpanded ? onCollapse : onExpand}>
                  {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        )}
      </Card>
    </Grid>
  );
};

export default DatasetCard;
