import React, {useState} from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import _ from "lodash";
import {useTranslation} from "react-i18next";
import {isNumeric} from "../../../utils/validator";

export const OBS_FILTER_OPERATOR_AND = "And";
export const OBS_FILTER_OPERATOR_OR = "Or";
export const OBS_FILTER_OPERATOR_EQUAL = "Equal";
export const OBS_FILTER_OPERATOR_NOT_EQUAL = "NotEqual";
export const OBS_FILTER_OPERATOR_GREATER_OR_EQUAL = "GreaterThanOrEqual";
export const OBS_FILTER_OPERATOR_GREATER = "GreaterThan";
export const OBS_FILTER_OPERATOR_LESS_OR_EQUAL = "LessThanOrEqual";
export const OBS_FILTER_OPERATOR_LESS = "LessThan";

const emptyFilter = {
  operator: OBS_FILTER_OPERATOR_AND,
  firstNumberFilter: {
    id: "OBS_VALUE",
    operator: OBS_FILTER_OPERATOR_EQUAL,
    value: ""
  },
  secondNumberFilter: {
    id: "OBS_VALUE",
    operator: OBS_FILTER_OPERATOR_EQUAL,
    value: ""
  }
};

const ObservationFilterForm = ({filter, onSubmit}) => {
  const {t} = useTranslation();

  const [filterTmp, setFilterTmp] = useState(_.merge({}, emptyFilter, filter));

  const handleApply = () => {
    if (isNumeric(filterTmp.firstNumberFilter.value) && isNumeric(filterTmp.secondNumberFilter.value)) {
      onSubmit(filterTmp);
    } else if (isNumeric(filterTmp.firstNumberFilter.value)) {
      onSubmit({
        ...filterTmp,
        secondNumberFilter: undefined
      });
    } else if (isNumeric(filterTmp.secondNumberFilter.value)) {
      onSubmit({
        ...filterTmp,
        firstNumberFilter: undefined
      });
    } else {
      onSubmit(null);
    }
  };

  const handleRemove = () => {
    onSubmit(null);
  };

  const {operator, firstNumberFilter, secondNumberFilter} = filterTmp;

  return (
    <Grid id="table__observation-filter-form" container spacing={1}>
      <Grid item xs={12}>
        {t("components.table.observationFilter.info")}
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Select
              value={firstNumberFilter.operator}
              variant="outlined"
              fullWidth
              onChange={({target}) =>
                setFilterTmp({
                  ...filterTmp,
                  firstNumberFilter: {
                    ...filterTmp.firstNumberFilter,
                    operator: target.value
                  }
                })
              }
              SelectDisplayProps={{"aria-haspopup": true}}
            >
              <MenuItem value={OBS_FILTER_OPERATOR_EQUAL}>
                {t("components.table.observationFilter.operator.equal")}
              </MenuItem>
              <MenuItem value={OBS_FILTER_OPERATOR_NOT_EQUAL}>
                {t("components.table.observationFilter.operator.notEqual")}
              </MenuItem>
              <MenuItem value={OBS_FILTER_OPERATOR_GREATER_OR_EQUAL}>
                {t("components.table.observationFilter.operator.greaterOrEqual")}
              </MenuItem>
              <MenuItem value={OBS_FILTER_OPERATOR_GREATER}>
                {t("components.table.observationFilter.operator.greater")}
              </MenuItem>
              <MenuItem value={OBS_FILTER_OPERATOR_LESS_OR_EQUAL}>
                {t("components.table.observationFilter.operator.lessOrEqual")}
              </MenuItem>
              <MenuItem value={OBS_FILTER_OPERATOR_LESS}>
                {t("components.table.observationFilter.operator.less")}
              </MenuItem>
            </Select>
          </Grid>
          <Grid item xs={6}>
            <TextField
              value={firstNumberFilter.value}
              type="number"
              variant="outlined"
              fullWidth
              onChange={({target}) =>
                setFilterTmp({
                  ...filterTmp,
                  firstNumberFilter: {
                    ...filterTmp.firstNumberFilter,
                    value: target.value
                  }
                })
              }
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1} justifyContent="center">
          <Grid item xs={4}>
            <Select
              value={operator}
              variant="outlined"
              fullWidth
              onChange={({target}) =>
                setFilterTmp({
                  ...filterTmp,
                  operator: target.value
                })
              }
              SelectDisplayProps={{"aria-haspopup": true}}
            >
              <MenuItem value={OBS_FILTER_OPERATOR_AND}>
                {t("components.table.observationFilter.operator.and")}
              </MenuItem>
              <MenuItem value={OBS_FILTER_OPERATOR_OR}>{t("components.table.observationFilter.operator.or")}</MenuItem>
            </Select>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Select
              value={secondNumberFilter.operator}
              variant="outlined"
              fullWidth
              onChange={({target}) =>
                setFilterTmp({
                  ...filterTmp,
                  secondNumberFilter: {
                    ...filterTmp.secondNumberFilter,
                    operator: target.value
                  }
                })
              }
              SelectDisplayProps={{"aria-haspopup": true}}
            >
              <MenuItem value={OBS_FILTER_OPERATOR_EQUAL}>
                {t("components.table.observationFilter.operator.equal")}
              </MenuItem>
              <MenuItem value={OBS_FILTER_OPERATOR_NOT_EQUAL}>
                {t("components.table.observationFilter.operator.notEqual")}
              </MenuItem>
              <MenuItem value={OBS_FILTER_OPERATOR_GREATER_OR_EQUAL}>
                {t("components.table.observationFilter.operator.greaterOrEqual")}
              </MenuItem>
              <MenuItem value={OBS_FILTER_OPERATOR_GREATER}>
                {t("components.table.observationFilter.operator.greater")}
              </MenuItem>
              <MenuItem value={OBS_FILTER_OPERATOR_LESS_OR_EQUAL}>
                {t("components.table.observationFilter.operator.lessOrEqual")}
              </MenuItem>
              <MenuItem value={OBS_FILTER_OPERATOR_LESS}>
                {t("components.table.observationFilter.operator.less")}
              </MenuItem>
            </Select>
          </Grid>
          <Grid item xs={6}>
            <TextField
              value={secondNumberFilter.value}
              type="number"
              variant="outlined"
              fullWidth
              onChange={({target}) =>
                setFilterTmp({
                  ...filterTmp,
                  secondNumberFilter: {
                    ...filterTmp.secondNumberFilter,
                    value: target.value
                  }
                })
              }
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="flex-end" spacing={1}>
          <Grid item>
            <Button onClick={handleRemove} color="primary">
              {t("components.table.observationFilter.remove")}
            </Button>
            <Button onClick={handleApply} color="primary">
              {t("components.table.observationFilter.apply")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ObservationFilterForm;
