import React, {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import {useTranslation} from "react-i18next";
import {
  DIM_FILTER_OPERATOR_ENDS_WITH,
  DIM_FILTER_OPERATOR_EQUAL,
  DIM_FILTER_OPERATOR_INCLUDES,
  DIM_FILTER_OPERATOR_NOT_EQUAL,
  DIM_FILTER_OPERATOR_NOT_INCLUDES,
  DIM_FILTER_OPERATOR_STARTS_WITH,
  FILTER_TYPE_DIM
} from "./utils";

const emptyFilter = {
  type: FILTER_TYPE_DIM,
  operator: DIM_FILTER_OPERATOR_EQUAL,
  filterValue: ""
};

const DimensionFilterForm = ({initialFilter, onApply, onRemove}) => {
  const {t} = useTranslation();

  const [filter, setFilter] = useState(emptyFilter);

  useEffect(() => {
    if (initialFilter) {
      setFilter(initialFilter);
    }
  }, [initialFilter]);

  const handleApply = () => {
    if ((filter.filterValue || "").length > 0) {
      onApply(filter);
    } else {
      onRemove();
    }
  };

  const {operator, filterValue} = filter;

  return (
    filter && (
      <Grid id="table__dimension-filter-form" container spacing={1}>
        <Grid item xs={12}>
          {t("components.table.dimensionFilter.info")}
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Select
                value={operator}
                variant="outlined"
                fullWidth
                onChange={({target}) => setFilter({...filter, operator: target.value})}
                SelectDisplayProps={{"aria-haspopup": true}}
              >
                <MenuItem value={DIM_FILTER_OPERATOR_EQUAL}>
                  {t("components.table.dimensionFilter.operator.equal")}
                </MenuItem>
                <MenuItem value={DIM_FILTER_OPERATOR_NOT_EQUAL}>
                  {t("components.table.dimensionFilter.operator.notEqual")}
                </MenuItem>
                <MenuItem value={DIM_FILTER_OPERATOR_STARTS_WITH}>
                  {t("components.table.dimensionFilter.operator.startsWith")}
                </MenuItem>
                <MenuItem value={DIM_FILTER_OPERATOR_ENDS_WITH}>
                  {t("components.table.dimensionFilter.operator.endsWith")}
                </MenuItem>
                <MenuItem value={DIM_FILTER_OPERATOR_INCLUDES}>
                  {t("components.table.dimensionFilter.operator.contains")}
                </MenuItem>
                <MenuItem value={DIM_FILTER_OPERATOR_NOT_INCLUDES}>
                  {t("components.table.dimensionFilter.operator.notContains")}
                </MenuItem>
              </Select>
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={filterValue}
                variant="outlined"
                fullWidth
                onChange={({target}) => setFilter({...filter, filterValue: target.value})}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="flex-end" spacing={1}>
            <Grid item>
              <Button onClick={onRemove} color="primary">
                {t("components.table.dimensionFilter.remove")}
              </Button>
              <Button onClick={handleApply} color="primary">
                {t("components.table.dimensionFilter.apply")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  );
};

export default DimensionFilterForm;
