import React, {useState} from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {withTranslation} from "react-i18next";
import {getVariationValueLabels, VARIATION_VALUE_CYCLICAL_KEY, VARIATION_VALUE_TREND_KEY} from "../../utils/dataset";

const menuItemStyle = {
  height: "42px",
  fontSize: "14px",
  backgroundColor: "#ffffff !important"
};

const VARIATION_VALUE_NONE_KEY = "NONE";

const VariationSelector = ({t, showTrend, showCyclical, onVariationSet}) => {
  const [open, setOpen] = useState(false);

  const values = [];
  if (showTrend) {
    values.push(VARIATION_VALUE_TREND_KEY);
  }
  if (showCyclical) {
    values.push(VARIATION_VALUE_CYCLICAL_KEY);
  }
  if (values.length === 0) {
    values.push(VARIATION_VALUE_NONE_KEY);
  }

  return (
    <Grid
      container
      sx={{
        margin: "0 8px",
        alignItems: "center"
      }}
    >
      <Grid
        item
        sx={{
          color: "rgba(0, 0, 0, 0.54)",
          fontSize: "13px",
          height: "40px",
          lineHeight: "40px",
          marginRight: "8px"
        }}
        id="variation-selector-label"
      >
        {t("components.variationSelector.variation.title")}:
      </Grid>
      <Grid item>
        <FormControl
          id="variation-selector__select"
          sx={{
            margin: "4px 0"
          }}
        >
          <Select
            sx={{
              "& .MuiSelect-select": {
                fontSize: "14px"
              }
            }}
            multiple
            value={values}
            renderValue={selected => {
              const newSelected = selected.filter(val => val !== VARIATION_VALUE_NONE_KEY);
              if (newSelected.length === 0) {
                return t("components.variationSelector.variation.none");
              } else if (newSelected.length === 1) {
                return getVariationValueLabels(t)[newSelected[0]];
              } else if (newSelected.length === 2) {
                return t("components.variationSelector.variation.both");
              } else {
                return "";
              }
            }}
            onChange={ev => {
              const values = ev.target.value;
              onVariationSet({
                showTrend: values.includes(VARIATION_VALUE_TREND_KEY),
                showCyclical: values.includes(VARIATION_VALUE_CYCLICAL_KEY)
              });
              setOpen(false);
            }}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            MenuProps={{
              open: open,
              variant: "menu"
            }}
            SelectDisplayProps={{
              "aria-haspopup": true,
              "aria-labelledby": "variation-selector-label"
            }}
          >
            <MenuItem sx={menuItemStyle} value={VARIATION_VALUE_TREND_KEY}>
              <Checkbox checked={values.includes(VARIATION_VALUE_TREND_KEY)} />
              {getVariationValueLabels(t)[VARIATION_VALUE_TREND_KEY]}
            </MenuItem>
            <MenuItem sx={menuItemStyle} value={VARIATION_VALUE_CYCLICAL_KEY}>
              <Checkbox checked={values.includes(VARIATION_VALUE_CYCLICAL_KEY)} />
              {getVariationValueLabels(t)[VARIATION_VALUE_CYCLICAL_KEY]}
            </MenuItem>
            <MenuItem sx={menuItemStyle} value={VARIATION_VALUE_NONE_KEY} style={{display: "none"}}>
              <Checkbox checked={values.includes(VARIATION_VALUE_NONE_KEY)} />
              {t("components.variationSelector.variation.none")}
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default withTranslation()(VariationSelector);
