import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  IconButton,
  Input,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {TableVirtuoso} from "react-virtuoso";
import CustomEmpty from "../custom-empty";
import "./style.css";

let searchTimeoutID = null;
const SEARCH_TIMEOUT = 100;

const ActionsCell = React.memo(({actions, row, index, editMode}) => {
  return (
    <TableCell style={{color: "inherit", width: "1px", whiteSpace: "nowrap"}}>
      {actions?.map((a, ai) =>
        a === undefined || a === null ? null : typeof a === "function" ? (
          <Tooltip key={`key-tooltip-${ai}`} title={a(row, index)?.tooltip}>
            <span>
              <IconButton
                className={a(row, index)?.className}
                color="inherit"
                disabled={editMode || a(row, index)?.disabled}
                onClick={e => {
                  e.stopPropagation();
                  a(row, index)?.onClick(e, row, index);
                }}
                size="small"
              >
                {a(row, index)?.icon}
              </IconButton>
            </span>
          </Tooltip>
        ) : (
          <Tooltip key={`key-tooltip-${ai}`} title={a?.tooltip}>
            <span>
              <IconButton
                className={a?.className}
                color="inherit"
                disabled={editMode || a?.disabled}
                onClick={e => {
                  e.stopPropagation();
                  a?.onClick(e, row, index);
                }}
                size="small"
              >
                {a?.icon}
              </IconButton>
            </span>
          </Tooltip>
        )
      )}
    </TableCell>
  );
});

const ItemContent = ({
  t,
  currentRow,
  actions,
  index,
  row,
  columns,
  editMode,
  editableIndex,
  onConfirm,
  onChange,
  setEditMode,
  setEditableIndex,
  setCurrentRow
}) => (
  <>
    {columns.map((column, c) => (
      <TableCell
        key={`key-${c}`}
        className={column?.getClassName ? column.getClassName(row) : undefined}
        style={{
          color: "inherit",
          width: `${column.width ? column.width + "px" : 100 / columns.filter(col => !col.width).length + "%"}`,
          minWidth: column.width ? column.width : undefined,
          maxWidth: column.width ? column.width : undefined
        }}
      >
        {index === editableIndex && !column.editDisabled ? (
          <Input
            key={`key-${c}-${column.field}`}
            value={currentRow[column.field]}
            name={column.field}
            onChange={e => onChange(e)}
          />
        ) : column.render ? (
          <div style={{wordBreak: "break-all", textWrap: "wrap"}}>{column.render(row)}</div>
        ) : (
          <div style={{wordBreak: "break-all", textWrap: "wrap"}}>{row[column.field]}</div>
        )}
      </TableCell>
    ))}
    {index !== editableIndex ? (
      <ActionsCell actions={actions} row={row} index={index} editMode={editMode} />
    ) : (
      <TableCell style={{color: "inherit", width: "1%", whiteSpace: "nowrap"}}>
        <Tooltip title={t("commons.confirm.save")}>
          <IconButton
            className="material-table__edit-actions__confirm"
            color="primary"
            onClick={e => {
              e.stopPropagation();
              onConfirm(row);
            }}
            size="small"
          >
            <CheckIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("commons.confirm.cancel")}>
          <IconButton
            className="material-table__edit-actions__cancel"
            color="primary"
            onClick={e => {
              e.stopPropagation();
              setEditMode(false);
              setEditableIndex(undefined);
              setCurrentRow(undefined);
            }}
            size="small"
          >
            <ClearIcon />
          </IconButton>
        </Tooltip>
      </TableCell>
    )}

    {/* *************** WIP ottimizzazione render cella azioni ********************* */}
    {/* index !== editableIndex && <TableCell style={{ width: '1px', whiteSpace: 'nowrap' }} ><div style={{display:'flex'}}>
        {actions?.map((a, ai) => {
            if (typeof a === "function") {
                return <Tooltip key={`key-tooltip-${ai}`} title={a(row, index)?.tooltip} ><button 
                className='button-action'  
                key={`key-${ai}`} disabled={editMode || a(row, index)?.disabled} onClick={(e) => { e.stopPropagation(); a(e, row, index)?.onClick(e, row, index); }} >
                    {a(row, index)?.icon}</button></Tooltip>
            } else {
                return <Tooltip key={`key-tooltip-${ai}`} title={a?.tooltip}><span><IconButton color="primary" key={`key-${ai}`} disabled={editMode || a?.disabled} onClick={(e) => { e.stopPropagation(); a?.onClick(e, row, index); }} size="small">
                    {a?.icon}</IconButton></span></Tooltip>
            }
        })}
    </div></TableCell>*/}
  </>
);

const MaterialTable = ({
  idKey = undefined,
  data = [],
  columns = [],
  actions = [],
  rightActions = [],
  onRowClick = null,
  onRowEdit = null,
  hideHeader = false
}) => {
  const {t} = useTranslation();

  const toolbarRef = useRef(null);
  const searchRef = useRef(null);

  const [dataRows, setDataRows] = useState();

  const [editMode, setEditMode] = useState(false);
  const [editableIndex, setEditableIndex] = useState();
  const [currentRow, setCurrentRow] = useState();
  const [toolbarHeight, setToolbarHeight] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setToolbarHeight(toolbarRef?.current?.offsetHeight);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const editAction = {
      icon: <EditIcon />,
      tooltip: t("commons.confirm.edit"),
      onClick: (_, row, index) => {
        setCurrentRow({...row});
        setEditableIndex(index);
        setEditMode(true);
      },
      className: "material-table__edit-actions__edit"
    };

    if (onRowEdit) {
      actions?.unshift(editAction);
    }
  }, [onRowEdit, actions, t]);

  const getFilteredData = useCallback(
    data => {
      const searchedText = searchRef?.current?.value || "";
      if (searchedText.length > 0) {
        const filteredData = data.filter(row => {
          const foundList = [];
          for (const [key, value] of Object.entries(row)) {
            const col = columns.find(column => column.field === key);

            if (col) {
              if (col.render) {
                const renderedValue = col.render(row);
                if (renderedValue) {
                  foundList.push(renderedValue.toLowerCase().includes(searchedText.toLowerCase()));
                }
              } else {
                if (typeof value === "string") {
                  foundList.push(value.toLowerCase().includes(searchedText.toLowerCase()));
                }
              }

              let i = 0;
              while (i < foundList.length) {
                if (foundList[i] === true) {
                  return true;
                } else {
                  i++;
                }
              }
            }
          }
          return false;
        });
        return filteredData;
      } else {
        return data;
      }
    },
    [columns]
  );

  useEffect(() => {
    const filteredData = getFilteredData(data);
    setDataRows(filteredData);
  }, [data, getFilteredData]);

  const onSearchTextChange = () => {
    if (searchTimeoutID) {
      clearTimeout(searchTimeoutID);
    }
    searchTimeoutID = setTimeout(() => {
      const filteredData = getFilteredData(data);
      setDataRows(filteredData);
    }, SEARCH_TIMEOUT);
  };

  const onRowChange = useCallback(e => {
    const value = e.target.value;
    const name = e.target.name;
    setCurrentRow(currentRow => ({...currentRow, [name]: value}));
  }, []);

  const onConfirm = useCallback(
    row => {
      onRowEdit(currentRow, row, editableIndex);
      setCurrentRow(undefined);
      setEditMode(false);
      setEditableIndex(undefined);
    },
    [currentRow, editableIndex, onRowEdit]
  );

  const TableComponents = useMemo(() => {
    return {
      Scroller: TableContainer,
      Table: Table,
      TableHead: TableHead,
      TableRow: React.forwardRef((props, ref) => {
        return (
          <TableRow
            id={`material_table__rows__${idKey ? props.item[idKey] : props.item.id ? props.item.id : props["data-index"]}`}
            innerref={ref}
            {...props}
            key={props["data-index"]}
            className={
              "v-table-row" + (editMode ? " v-table-row--disabled" : "") + (onRowClick ? " v-table-row--clickable" : "")
            }
            onClick={e => {
              if (onRowClick) {
                onRowClick(e, props.item, props["data-index"]);
              }
            }}
          />
        );
      }),
      TableBody: TableBody
    };
  }, [onRowClick, editMode, idKey]);

  return (
    <div style={{height: "100%"}}>
      <div style={{display: "flex", alignItems: "center"}} ref={toolbarRef}>
        <div style={{flexGrow: 1}}>
          <TextField
            inputRef={searchRef}
            id={`material_table__search__${idKey}`}
            fullWidth
            placeholder={t("components.materialTable.search.placeholder")}
            onChange={onSearchTextChange}
            disabled={editMode}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon size="small" color="primary" />
                </InputAdornment>
              ),
              endAdornment:
                (searchRef?.current?.value || "").length > 0 ? (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      color="primary"
                      disabled={editMode}
                      onClick={() => {
                        searchRef.current.value = "";
                        onSearchTextChange();
                      }}
                    >
                      <ClearIcon color="primary" size="small" />
                    </IconButton>
                  </InputAdornment>
                ) : null
            }}
          />
        </div>
        {rightActions && rightActions.length > 0 && (
          <div style={{marginLeft: "0.5rem", marginTop: "0.5rem", display: "flex"}}>
            {rightActions.map((ra, k) => {
              if (typeof ra === "function") {
                return (
                  <Button
                    key={`key-${k}`}
                    id={ra()?.id}
                    style={{marginLeft: "0.2rem"}}
                    startIcon={ra()?.startIcon}
                    color="primary"
                    size="small"
                    onClick={() => ra()?.onClick()}
                    disabled={editMode || ra()?.disabled}
                  >
                    {ra()?.label}
                  </Button>
                );
              } else {
                return (
                  <Button
                    key={`key-${k}`}
                    id={ra?.id}
                    style={{marginLeft: "0.2rem"}}
                    startIcon={ra?.startIcon}
                    color="primary"
                    size="small"
                    onClick={() => ra?.onClick()}
                    disabled={editMode || ra?.disabled}
                  >
                    {ra?.label}
                  </Button>
                );
              }
            })}
          </div>
        )}
      </div>
      <div
        id={`material_table__${idKey}`}
        style={{height: `calc(${dataRows && dataRows.length > 0 ? 100 : 30}% - ${toolbarHeight}px)`, width: "100%"}}
      >
        <TableVirtuoso
          key={"v-table-virtuoso"}
          data={dataRows}
          components={TableComponents}
          fixedHeaderContent={() => {
            return hideHeader ? undefined : (
              <TableRow>
                {columns.map((column, j) => (
                  <TableCell
                    key={`key-${j}`}
                    style={{
                      background: "white",
                      color: "inherit",
                      width: `${
                        column.width ? column.width + "px" : 100 / columns.filter(col => !col.width).length + "%"
                      }`,
                      minWidth: column.width ? column.width : undefined,
                      maxWidth: column.width ? column.width : undefined
                    }}
                  >
                    {column.title}
                  </TableCell>
                ))}
                {actions ? <TableCell style={{background: "white"}}></TableCell> : null}
              </TableRow>
            );
          }}
          itemContent={(index, row) => (
            <ItemContent
              t={t}
              currentRow={currentRow}
              actions={actions}
              index={index}
              row={row}
              columns={columns}
              editMode={editMode}
              editableIndex={editableIndex}
              onConfirm={onConfirm}
              onChange={onRowChange}
              setEditMode={setEditMode}
              setEditableIndex={setEditableIndex}
              setCurrentRow={setCurrentRow}
            />
          )}
        />
        {dataRows?.length < 1 && <CustomEmpty text={t("components.materialTable.empty")} />}
      </div>
    </div>
  );
};

export default MaterialTable;
