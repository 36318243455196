import React, {Fragment, useCallback, useEffect, useMemo, useState} from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import HomeIcon from "@mui/icons-material/Home";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import {useTranslation, withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {compose} from "redux";
import {goToHome, goToNode} from "../../links";
import {NodeVisibility} from "../../model/IHubMinimalNode.d.ts";
import ComponentVariantLoader from "../component-variant-loader";
import A11yButton from "./A11yButton";
import AdminSettingButton from "./AdminSettingButton";
import DashboardButton from "./DashboardButton";
import HubLogo from "./HubLogo";
import HubName from "./HubName";
import InfoButton from "./InfoButton";
import LanguageButton from "./LanguageButton";
import NavigationDrawerButton from "./NavigationDrawerButton";
import NodeSelector from "./NodeSelector";
import SearchButton from "./SearchButton";
import TerritoryToolsButton from "./TerritoryToolsButton";
import UserSettingsButton from "./UserSettingsButton";
import {WINDOW_SCREEN_WIDTH_MD, WINDOW_SCREEN_WIDTH_SM} from "../../utils/constants";
import {localizeI18nObj} from "../../utils/i18n";
import {areElementsOverlap} from "../../utils/other";

const $ = window.jQuery;

const firstRowContainerStyle = {
  position: "absolute",
  padding: "0 8px"
};

const firstRowLeftContainerStyle = {
  left: "0px"
};

const firstRowCenterContainerStyle = {
  cursor: "pointer"
};

const firstRowRightContainerStyle = {
  right: "0px"
};

const hiddenContainerStyle = {
  visibility: "hidden"
};

const mapStateToProps = state => ({
  baseURL: state.config.baseURL,
  enableHeaderAutoResponsiveness: state.appConfig.enableHeaderAutoResponsiveness,
  isA11y: state.app.isA11y,
  defaultLanguage: state.app.language,
  languages: state.app.languages,
  modulesConfig: state.app.modulesConfig,
  themeConfig: state.app.themeConfig,
  hub: state.hub.hub,
  nodes: state.hub.nodes,
  node: state.node,
  catalog: state.catalog,
  user: state.user,
  filteredCatalog: state.detailLevel.filteredCatalog
});

const Header = ({
  baseURL,
  enableHeaderAutoResponsiveness,
  isA11y,
  defaultLanguage,
  languages,
  modulesConfig,
  themeConfig,
  hub,
  nodes,
  node,
  title = node?.name,
  noNode = !node?.nodeId,
  catalog,
  user,
  filteredCatalog,

  emptyHeader,
  isDefault,
  selectedCategoryPath,
  selectedDataset,
  getCustomA11yPath,
  getAdditionalA11yUrlParams
}) => {
  const {t} = useTranslation();

  const [isToolbarDrawerOpen, setisToolbarDrawerOpen] = useState(false);
  const [isFirstRowCollapsed, setisFirstRowCollapsed] = useState(false);
  const [isCenterContainerCollapsed, setisCenterContainerCollapsed] = useState(false);

  const headerLogoSmallURL = useMemo(() => hub.extras.HeaderLogoSmallURL || "", [hub.extras]);
  const headerLogoAlt = useMemo(
    () => localizeI18nObj(hub.extras.HeaderLogoAlt, defaultLanguage, languages) || "",
    [hub.extras, defaultLanguage, languages]
  );
  const headerLogoHref = useMemo(() => hub.extras.HeaderLogoHref || "", [hub.extras]);
  const headerLogoTitle = useMemo(
    () => localizeI18nObj(hub.extras.headerLogoTitle, defaultLanguage, languages) || "",
    [hub.extras, defaultLanguage, languages]
  );

  const handleDefaultHeaderStyle = useCallback(() => {
    const newIsFirstRowCollapsed = enableHeaderAutoResponsiveness
      ? areElementsOverlap("node-header__first-row__center-container", "node-header__first-row__left-container") ||
        areElementsOverlap("node-header__first-row__center-container", "node-header__first-row__right-container")
      : window.innerWidth < WINDOW_SCREEN_WIDTH_MD;

    const newIsCenterContainerCollapsed = enableHeaderAutoResponsiveness
      ? areElementsOverlap(
          "node-header__first-row__center-container",
          "node-header__first-row__left-container--collapsed"
        ) ||
        areElementsOverlap(
          "node-header__first-row__center-container",
          "node-header__first-row__right-container--collapsed"
        )
      : window.innerWidth < WINDOW_SCREEN_WIDTH_SM;

    if (!enableHeaderAutoResponsiveness) {
      const centerContainerWidth = $("#node-header__first-row__center-container").outerWidth(true) || 0;
      const navigationDrawerBtnWidth =
        $("#node-header__first-row__left-container .navigation-drawer-btn").outerWidth(true) || 0;
      const homeBtnWidth = $("#node-header__first-row__left-container .home-btn").outerWidth(true) || 0;
      const territoryToolsBtnWidth =
        $("#node-header__first-row__left-container .territory-tools-btn").outerWidth(true) || 0;

      $("#node-header__first-row__left-container .node-selector").css({
        maxWidth: `calc(50vw - ${
          centerContainerWidth / 2 + navigationDrawerBtnWidth + homeBtnWidth + territoryToolsBtnWidth + 16
        }px)`
      });
    }

    setisFirstRowCollapsed(newIsFirstRowCollapsed);
    setisCenterContainerCollapsed(newIsCenterContainerCollapsed);
  }, [enableHeaderAutoResponsiveness]);

  const handleHeaderHeight = useCallback(() => {
    const nodeHeaderHeight = document.getElementById("node-header")
      ? document.getElementById("node-header").offsetHeight
      : 0;
    if (document.getElementById("main")) {
      document.getElementById("main").setAttribute("style", `padding-top: ${nodeHeaderHeight}px`);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleDefaultHeaderStyle);
    window.addEventListener("resize", handleHeaderHeight);
    return () => {
      window.removeEventListener("resize", handleDefaultHeaderStyle);
      window.removeEventListener("resize", handleHeaderHeight);
    };
  }, [handleDefaultHeaderStyle, handleHeaderHeight]);

  useEffect(() => {
    handleDefaultHeaderStyle();
    handleHeaderHeight();
  });

  const onToolbarDrawerOpen = useCallback(() => {
    setisToolbarDrawerOpen(true);
  }, []);

  const onToolbarDrawerClose = useCallback(() => {
    setisToolbarDrawerOpen(false);
  }, []);

  const isDefaultOrUniqueNode =
    !noNode && isDefault && node
      ? nodes.filter(n => n.code.toLowerCase() !== node.code.toLowerCase()).length === 0
      : false;

  const isSecondRowVisible = isCenterContainerCollapsed;
  const isThirdRowVisible = isFirstRowCollapsed && !noNode && !isDefaultOrUniqueNode;

  return (
    <AppBar
      id="node-header"
      position="static"
      color="primary"
      role="navigation"
      aria-label={t("components.header.appBar.ariaLabel")}
      sx={{
        position: "fixed",
        width: "100%",
        zIndex: theme => theme.zIndex.appBar
      }}
    >
      {themeConfig.componentsVariant?.header ? (
        <ComponentVariantLoader
          componentId="header"
          fallback={<Box />}
          baseURL={baseURL}
          isA11y={isA11y}
          defaultLanguage={defaultLanguage}
          languages={languages}
          modulesConfig={modulesConfig}
          hub={hub}
          nodes={nodes}
          node={node}
          title={title}
          noNode={noNode}
          catalog={catalog}
          user={user}
          filteredCatalog={filteredCatalog}
          isDefault={isDefault}
          selectedCategoryPath={selectedCategoryPath}
          selectedDataset={selectedDataset}
          getCustomA11yPath={getCustomA11yPath}
          getAdditionalA11yUrlParams={getAdditionalA11yUrlParams}
          onRender={handleHeaderHeight}
        />
      ) : emptyHeader ? (
        <Grid container columnSpacing={1} alignItems="center" wrap="nowrap" justifyContent="center">
          <HubLogo
            hub={hub}
            headerLogoTitle={headerLogoTitle}
            headerLogoHref={headerLogoHref}
            baseURL={baseURL}
            headerLogoAlt={headerLogoAlt}
            handleStyle={handleDefaultHeaderStyle}
            headerLogoSmallURL={headerLogoSmallURL}
          />
          <HubName hub={hub} />
        </Grid>
      ) : (
        <Fragment>
          <Box
            id="node-header__first-row"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minHeight: "64px"
            }}
          >
            {(enableHeaderAutoResponsiveness || !isFirstRowCollapsed) && (
              <Box
                id="node-header__first-row__left-container"
                sx={[firstRowContainerStyle, firstRowLeftContainerStyle, isFirstRowCollapsed && hiddenContainerStyle]}
              >
                <Grid container spacing={1} alignItems="center" wrap="nowrap">
                  <Fragment>
                    {node?.visible !== NodeVisibility.No ? (
                      !noNode ? (
                        <Fragment>
                          {catalog && !catalog.isEmpty && (
                            <NavigationDrawerButton
                              selectedCategoryPath={selectedCategoryPath}
                              selectedDataset={selectedDataset}
                              title={title}
                              nodes={nodes}
                              noNode={noNode}
                              catalog={catalog}
                              node={node}
                              isA11y={isA11y}
                              filteredCatalog={filteredCatalog}
                              defaultLanguage={defaultLanguage}
                              languages={languages}
                              modulesConfig={modulesConfig}
                            />
                          )}
                          <Grid item className="node-home-btn">
                            <Tooltip title={t("components.header.actions.nodeHome.title")}>
                              <IconButton
                                color="inherit"
                                onClick={() => goToNode((node?.code || "").toLowerCase())}
                                aria-label={t("components.header.actions.nodeHome.ariaLabel")}
                              >
                                <HomeIcon />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                          <NodeSelector isDefaultUniqueNode={isDefaultOrUniqueNode} node={node} nodes={nodes} />
                        </Fragment>
                      ) : (
                        <span />
                      )
                    ) : (
                      <Grid item className="home-btn">
                        <Tooltip title={t("components.header.actions.home.title")}>
                          <IconButton
                            color="inherit"
                            onClick={() => goToHome()}
                            aria-label={t("components.header.actions.home.ariaLabel")}
                          >
                            <HomeIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    )}
                    <TerritoryToolsButton />
                  </Fragment>
                </Grid>
              </Box>
            )}
            {(enableHeaderAutoResponsiveness || isFirstRowCollapsed) && (
              <Box
                id="node-header__first-row__left-container--collapsed"
                sx={[firstRowContainerStyle, firstRowLeftContainerStyle, !isFirstRowCollapsed && hiddenContainerStyle]}
              >
                {!noNode && node?.visible !== NodeVisibility.No && catalog && !catalog.isEmpty && (
                  <Grid container spacing={1} alignItems="center" wrap="nowrap">
                    <NavigationDrawerButton
                      selectedCategoryPath={selectedCategoryPath}
                      selectedDataset={selectedDataset}
                      title={title}
                      nodes={nodes}
                      noNode={noNode}
                      catalog={catalog}
                      node={node}
                      isA11y={isA11y}
                      filteredCatalog={filteredCatalog}
                      defaultLanguage={defaultLanguage}
                      languages={languages}
                      modulesConfig={modulesConfig}
                    />
                  </Grid>
                )}
              </Box>
            )}
            {(enableHeaderAutoResponsiveness || !isCenterContainerCollapsed) && (
              <Box
                id="node-header__first-row__center-container"
                sx={[
                  firstRowContainerStyle,
                  firstRowCenterContainerStyle,
                  isCenterContainerCollapsed && hiddenContainerStyle
                ]}
              >
                <Grid container spacing={1} alignItems="center" wrap="nowrap">
                  <HubLogo
                    hub={hub}
                    headerLogoTitle={headerLogoTitle}
                    headerLogoHref={headerLogoHref}
                    baseURL={baseURL}
                    headerLogoAlt={headerLogoAlt}
                    handleStyle={handleDefaultHeaderStyle}
                    headerLogoSmallURL={headerLogoSmallURL}
                  />
                  <HubName hub={hub} />
                </Grid>
              </Box>
            )}
            {(enableHeaderAutoResponsiveness || isCenterContainerCollapsed) && (
              <Box
                id="node-header__first-row__center-container--collapsed"
                sx={[
                  firstRowContainerStyle,
                  firstRowCenterContainerStyle,
                  !isCenterContainerCollapsed && hiddenContainerStyle
                ]}
              >
                <Grid container spacing={1} alignItems="center" wrap="nowrap">
                  <HubLogo
                    hub={hub}
                    headerLogoTitle={headerLogoTitle}
                    headerLogoHref={headerLogoHref}
                    baseURL={baseURL}
                    headerLogoAlt={headerLogoAlt}
                    handleStyle={handleDefaultHeaderStyle}
                    headerLogoSmallURL={headerLogoSmallURL}
                    isCollapsed
                  />
                </Grid>
              </Box>
            )}
            {(enableHeaderAutoResponsiveness || !isFirstRowCollapsed) && (
              <Box
                id="node-header__first-row__right-container"
                sx={[firstRowContainerStyle, firstRowRightContainerStyle, isFirstRowCollapsed && hiddenContainerStyle]}
              >
                <Grid container spacing={1} alignItems="center" wrap="nowrap">
                  <SearchButton noNode={noNode} node={node} catalog={catalog} />
                  <LanguageButton id="header_language_btn" />
                  <A11yButton
                    id="header_a11y_btn"
                    getAdditionalA11yUrlParams={getAdditionalA11yUrlParams}
                    getCustomA11yPath={getCustomA11yPath}
                  />
                  <DashboardButton modulesConfig={modulesConfig} isA11y={isA11y} noNode={noNode} node={node} />
                  <AdminSettingButton id="header_admin_setting_button_btn" user={user} nodes={nodes} />
                  <UserSettingsButton
                    id="header_user_setting_button_btn"
                    hub={hub}
                    nodes={nodes}
                    user={user}
                    disabled={isFirstRowCollapsed}
                  />
                  <InfoButton id="header_info_button_btn" hub={hub} />
                </Grid>
              </Box>
            )}
            {(enableHeaderAutoResponsiveness || isFirstRowCollapsed) && (
              <Box
                id="node-header__first-row__right-container--collapsed"
                sx={[firstRowContainerStyle, firstRowRightContainerStyle, !isFirstRowCollapsed && hiddenContainerStyle]}
              >
                <Grid container spacing={1} alignItems="center" wrap="nowrap">
                  <Grid item className="toolbar-drawer-btn">
                    <Tooltip title={t("components.header.actions.openToolbarDrawer.title")}>
                      <IconButton
                        color="inherit"
                        onClick={onToolbarDrawerOpen}
                        aria-label={t("components.header.actions.openToolbarDrawer.ariaLabel")}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Box>
          {isSecondRowVisible && (
            <Box
              id="node-header__second-row"
              sx={{
                display: "flex",
                justifyContent: "center",
                "& $hubName": {
                  height: "unset",
                  padding: "0px",
                  fontSize: "24px"
                }
              }}
              style={{paddingBottom: isThirdRowVisible ? 0 : 8}}
            >
              <HubName hub={hub} />
            </Box>
          )}
          {isThirdRowVisible && (
            <Box id="node-header__third-row" sx={{paddingLeft: "12px"}}>
              <NodeSelector isDefaultUniqueNode={isDefaultOrUniqueNode} node={node} nodes={nodes} />
            </Box>
          )}
          <Drawer
            anchor="right"
            open={isToolbarDrawerOpen}
            PaperProps={{
              sx: {
                overflow: "hidden",
                padding: "8px"
              }
            }}
            onClose={onToolbarDrawerClose}
          >
            <nav id="toolbar-drawer" aria-label={t("components.header.toolbarDrawer.ariaLabel")}>
              <Grid container direction="column" spacing={1} alignItems="center">
                <Grid item>
                  <Tooltip title={t("components.header.actions.closeToolbarDrawer.title")}>
                    <IconButton
                      color="inherit"
                      onClick={onToolbarDrawerClose}
                      aria-label={t("components.header.actions.closeToolbarDrawer.ariaLabel")}
                    >
                      <ChevronRightIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid
                  item
                  sx={{
                    width: "100%",
                    margin: "4px 0"
                  }}
                >
                  <Divider />
                </Grid>
                <SearchButton noNode={noNode} node={node} catalog={catalog} />
                <LanguageButton />
                <A11yButton
                  getAdditionalA11yUrlParams={getAdditionalA11yUrlParams}
                  getCustomA11yPath={getCustomA11yPath}
                />
                <DashboardButton modulesConfig={modulesConfig} isA11y={isA11y} node={node} noNode={noNode} />
                <AdminSettingButton user={user} nodes={nodes} />
                <UserSettingsButton hub={hub} nodes={nodes} user={user} disabled={!isFirstRowCollapsed} />
                <InfoButton hub={hub} />
                <TerritoryToolsButton />
              </Grid>
            </nav>
          </Drawer>
        </Fragment>
      )}
    </AppBar>
  );
};

export default compose(withTranslation(), connect(mapStateToProps))(Header);
