import React, {createContext, PropsWithChildren, useContext, useEffect, useMemo, useState} from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from "@mui/material";
import {FilterDto} from "../../model/item-containers-models/filterDto";
import {FilterLevelDto} from "../../model/item-containers-models/filterLevelDto";
import {ItemContainerDto} from "../../model/item-containers-models/itemContainerDto";
import {ValueLevelDto} from "../../model/item-containers-models/valueLevelDto";
import CustomDialogTitle from "../custom-dialog-title";
import InfiniteScrollTable from "../infinite-scroll-table";
import useLanguages from "../../state/hooks/useLanguages";
import {getTextWidth} from "../../utils/style";

const FilterLevelSelection = ({
  filterLevel,
  selectValues,
  value,
  onChange
}: {
  filterLevel: FilterLevelDto;
  selectValues: ValueLevelDto[];
  value: string;
  onChange: (s: string) => void;
}) => {
  const {t, localizeI18nObj, defaultLanguage} = useLanguages();
  const [selectionVisible, setSelectionVisible] = useState(false);

  const infiniteScrollTableProps = {
    data: selectValues,
    getRowKey: ({id}) => id,
    showHeader: false,
    columns: [
      {
        title: "",
        dataIndex: "id",
        render: (_, {id, description}) => `[${id}] ${description}`,
        renderText: (_, {id, description}) => `[${id}] ${description}`
      }
    ],
    onRowClick: rowData => {
      onChange(rowData.id);
      setSelectionVisible(false);
    },
    getRowStyle: rowData => ({
      background: rowData.id === value ? "#fff9e5" : undefined
    }),
    height: 400
  };

  const getTextWidthEl = (() => {
    const spanElement = document.createElement("span");
    spanElement.style.cssText = "visibility: hidden; position: absolute; font-size: 16px;";
    document.body.appendChild(spanElement);
    return spanElement;
  })();

  const minWidth = getTextWidth(localizeI18nObj(filterLevel.label), getTextWidthEl) + 32;

  return (
    <>
      <Box
        sx={{
          display: "inline-block",
          verticalAlign: "bottom",
          marginRight: "16px",
          padding: "4px 0"
        }}
      >
        <FormControl
          id={`item-container__filters__select${filterLevel.level}`}
          style={{display: "inline-block", verticalAlign: "bottom"}}
        >
          <InputLabel>{localizeI18nObj(filterLevel.label)}</InputLabel>
          <Select
            open={false}
            onOpen={() => setSelectionVisible(true)}
            SelectDisplayProps={{"aria-haspopup": true}}
            value={value || ""}
            style={{minWidth: minWidth}}
          >
            {getLevelValues(filterLevel, defaultLanguage).map(({id, description}) => (
              <MenuItem key={id} value={id}>
                {description}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Dialog
        id="item-container__filters__dialog"
        open={selectionVisible}
        onClose={() => setSelectionVisible(false)}
        fullWidth
        maxWidth="md"
      >
        <CustomDialogTitle onClose={() => setSelectionVisible(false)}>
          {t("components.itemContainerFilters.modals.title", {
            level: localizeI18nObj(filterLevel.label)
          })}
        </CustomDialogTitle>
        <DialogContent>
          <InfiniteScrollTable {...infiniteScrollTableProps} />
        </DialogContent>
        <DialogActions>
          <Button
            id="item-container__filters__dialog__close-btn"
            onClick={() => setSelectionVisible(false)}
            color="primary"
          >
            {t("commons.confirm.close")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const FilterContext = createContext<{
  filterValue: string | null;
  setFilterValue: (v: string[] | null) => void;
  selectedFilterValues: string[];
}>({
  filterValue: null,
  setFilterValue: () => {},
  selectedFilterValues: []
});

const getFilterUniqueId = (filterData: {filter: FilterDto; selectedLevels: number[]}) =>
  filterData.filter.id + [...filterData.selectedLevels].sort((a: number, b: number) => a - b).join("");

function lastOrNull<T>(arr: T[] | null) {
  return arr?.length > 0 ? arr[arr.length - 1] : null;
}

const EMPTY_FILTERS_ID = Symbol();
export const FilterProvider = ({
  children,
  filterData
}: PropsWithChildren<{filterData: {filter: FilterDto; selectedLevels: number[]}}>) => {
  const [filterValueInUse, setFilterValueInUse] = useState<string | null>(null);
  const [filterValuesMap, setValuesMap] = useState<Record<string | symbol, string[]>>({[EMPTY_FILTERS_ID]: []});
  const filterId = filterData ? getFilterUniqueId(filterData) : EMPTY_FILTERS_ID;
  const filterValues = useMemo(() => filterValuesMap[filterId] || [], [filterValuesMap, filterId]);
  useEffect(() => {
    setFilterValueInUse(lastOrNull(filterValues));
  }, [filterValues]);
  const setFilterValue = (values: string[] | null) => {
    setValuesMap(prevState => ({...prevState, [filterId]: values}));
    setFilterValueInUse(lastOrNull(values));
  };

  return (
    <FilterContext.Provider
      value={{
        filterValue: filterValueInUse,
        setFilterValue,
        selectedFilterValues: filterValues
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export const useItemContainerFilters = () => {
  const {filterValue} = useContext(FilterContext);
  return filterValue;
};

const getLevelValues = (filterLevel: FilterLevelDto, defaultLanguage?: string) =>
  filterLevel.values[defaultLanguage] || Object.values(filterLevel.values)[0] || [];

const ItemContainerFilter = ({itemContainer}: {itemContainer: ItemContainerDto}) => {
  const selectedFilterLevels =
    itemContainer.filter.levels.filter(v => itemContainer.filterLevelsSelected.includes(v.level)) || null;
  const {setFilterValue, selectedFilterValues: initiallySelectedFilterValues} = useContext(FilterContext);
  const [selectedFilterValues, setSelectedFilterValues] = useState<string[]>(initiallySelectedFilterValues);
  useEffect(() => {
    setSelectedFilterValues(initiallySelectedFilterValues);
  }, [initiallySelectedFilterValues, setSelectedFilterValues]);
  const onFilterApply = () => setFilterValue(selectedFilterValues);
  const onFilterReset = () => {
    setSelectedFilterValues([]);
    setFilterValue(null);
  };
  const filter = itemContainer.filter;
  const {t, defaultLanguage} = useLanguages();

  const getparentId = (i: number): string => {
    if (i === 0) {
      return null;
    } else {
      return selectedFilterValues[i - 1];
    }
  };

  const parentValuesIncludeValue = (parentLevels: ValueLevelDto[]) => (v: ValueLevelDto) =>
    parentLevels.map(p => p?.id).includes(v.parentId);

  const getParents = (lastLevel: number, parentId: string, filterLevel: FilterLevelDto): ValueLevelDto[] => {
    let possibleParents: ValueLevelDto[] = getLevelValues(filter.levels[lastLevel - 1], defaultLanguage).filter(
      v => v.id === parentId
    );
    let nextPossibleParents: ValueLevelDto[] = [];
    while (lastLevel !== filterLevel.level - 1) {
      nextPossibleParents = getLevelValues(filter.levels[lastLevel], defaultLanguage).filter(
        parentValuesIncludeValue(possibleParents)
      );
      possibleParents = nextPossibleParents;
      lastLevel++;
    }
    return possibleParents;
  };

  const getSelectValues = (i: number): ValueLevelDto[] => {
    const parentId = getparentId(i);
    const filterLevel = selectedFilterLevels[i];
    const levelValues = getLevelValues(filterLevel, defaultLanguage);
    if (i === 0) {
      return levelValues;
    }
    if (selectedFilterLevels[i].level - 1 === selectedFilterLevels[i - 1].level) {
      return levelValues.filter(f => f.parentId === parentId);
    }
    let lastLevel: number = selectedFilterLevels[i - 1].level;
    let possibleParents = getParents(lastLevel, parentId, filterLevel);
    return levelValues.filter(f => possibleParents.map(p => p?.id).includes(f.parentId));
  };

  return (
    <>
      {Array.from({length: Math.min(selectedFilterLevels?.length, selectedFilterValues?.length + 1)}).map((_, i) => (
        <FilterLevelSelection
          key={selectedFilterValues[i] ?? i}
          filterLevel={selectedFilterLevels[i]}
          selectValues={getSelectValues(i)}
          value={selectedFilterValues[i]}
          onChange={v => {
            setSelectedFilterValues(prev => {
              if (selectedFilterValues[i]) {
                return prev.slice(0, i).concat(v);
              } else {
                return prev.concat(v);
              }
            });
          }}
        />
      ))}

      {Array.from({length: Math.min(selectedFilterLevels?.length, selectedFilterValues?.length + 1)}).length > 0 && (
        <Button id="item-containers__filter-apply" onClick={onFilterApply} disabled={!selectedFilterValues.length}>
          {t("commons.confirm.apply")}
        </Button>
      )}

      {Array.from({length: Math.min(selectedFilterLevels?.length, selectedFilterValues?.length + 1)}).length > 0 && (
        <Button id="item-containers__filter-reset" onClick={onFilterReset}>
          {t("commons.confirm.reset")}
        </Button>
      )}
    </>
  );
};

export default ItemContainerFilter;
