import React, {forwardRef, Fragment, useEffect, useImperativeHandle, useMemo, useState} from "react";
import {Visibility} from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {compose} from "redux";
import {goToDataset} from "../../../links";
import CustomDialogTitle from "../../custom-dialog-title";
import MaterialTable from "../../material-table";
import TabPanel from "../../tab-panel";
import {
  clearOtherConfigViews,
  deleteOtherConfigView,
  fetchOtherConfigViews
} from "../../../state/otherConfig/otherConfigActions";
import {getI18nObjCustomFilterAndSearch, localizeI18nObj} from "../../../utils/i18n";

const mapStateToProps = state => ({
  defaultLanguage: state.app.language,
  languages: state.app.languages,
  views: state.otherConfig.views,
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  fetchViews: () => dispatch(fetchOtherConfigViews()),
  clearViews: () => dispatch(clearOtherConfigViews()),
  deleteView: (nodeId, id) => dispatch(deleteOtherConfigView(nodeId, id))
});

const ViewsTable = ({defaultLanguage, languages, nodes, data, onViewsClose, setDeleteViewId, setDeleteViewNodeId}) => {
  const {t} = useTranslation();
  return (
    <MaterialTable
      idKey={"viewsTable"}
      columns={[
        {
          field: "nodeId",
          title: t("scenes.viewsSettings.table.columns.viewNodeId"),
          render: ({nodeId}) => nodes.find(node => node.nodeId === nodeId).code,
          customFilterAndSearch: (str, {nodeId}) =>
            nodes
              .find(node => node.nodeId === nodeId)
              .code.toLowerCase()
              .includes(str.toLowerCase()),
          getClassName: () => "user-views-settings-form__view-table-node-id"
        },
        {
          field: "datasetId",
          title: t("scenes.viewsSettings.table.columns.viewDatasetId"),
          getClassName: () => "user-views-settings-form__view-table-dataset-id"
        },
        {
          field: "title",
          title: t("scenes.viewsSettings.table.columns.viewTitle"),
          render: ({title}) => localizeI18nObj(title, defaultLanguage, languages),
          customFilterAndSearch: getI18nObjCustomFilterAndSearch(defaultLanguage, languages),
          getClassName: () => "user-views-settings-form__view-table-title"
        }
      ]}
      data={data}
      actions={[
        {
          className: "user-views-settings-form__view-btn",
          icon: <Visibility />,
          tooltip: t("scenes.viewsSettings.table.actions.viewView"),
          onClick: (_, {nodeId, datasetId, viewTemplateId}) => {
            if (!window.location.href.toLowerCase().includes(`view=${viewTemplateId}`)) {
              goToDataset(nodes.find(node => node.nodeId === nodeId).code, [], datasetId, viewTemplateId);
            }
            onViewsClose();
          }
        },
        {
          className: "user-views-settings-form__delete-btn",
          icon: <DeleteIcon />,
          tooltip: t("scenes.viewsSettings.table.actions.deleteView"),
          onClick: (_, {nodeId, viewTemplateId}) => {
            setDeleteViewId(viewTemplateId);
            setDeleteViewNodeId(nodeId);
          }
        }
      ]}
    />
  );
};

const UserViewsSettingsForm = (
  {defaultLanguage, languages, views, user, fetchViews, clearViews, deleteView, nodes, onViewsClose},
  ref
) => {
  const [needViews, setNeedViews] = useState(true);
  const {t} = useTranslation();

  useEffect(() => {
    if (needViews) {
      setNeedViews(false);
      fetchViews();
    }
  }, [views, needViews, setNeedViews, fetchViews]);

  useImperativeHandle(ref, () => ({
    cancel(f) {
      clearViews();
      f();
    }
  }));

  const [deleteViewId, setDeleteViewId] = useState(null);
  const [deleteViewNodeId, setDeleteViewNodeId] = useState(null);

  const [tabId, setTabId] = useState("private");

  const privateViews = useMemo(() => {
    return (views || [])
      .filter(view => view.userId === user.userId)
      .filter(({nodeId}) => nodes.find(node => node.nodeId === nodeId));
  }, [views, user, nodes]);

  const sharedViews = useMemo(() => {
    return (views || [])
      .filter(view => view.userId !== user.userId)
      .filter(({nodeId}) => nodes.find(node => node.nodeId === nodeId));
  }, [views, user, nodes]);

  return (
    nodes &&
    views && (
      <Fragment>
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          value={tabId}
          onChange={(_, id) => {
            setTabId(id);
          }}
          sx={{
            heigth: "49px"
          }}
        >
          <Tab
            id="user-views-settings-form__private-tab"
            value="private"
            label={t("scenes.viewsSettings.tabs.private.label")}
          />
          <Tab
            id="user-views-settings-form__shared-tab"
            value="shared"
            label={t("scenes.viewsSettings.tabs.shared.label")}
          />
        </Tabs>
        <TabPanel
          id="user-views-settings-form__tab-panel-private"
          value="private"
          selected={tabId}
          sx={{height: "calc(100% - 49px)", paddingTop: "8px"}}
        >
          <ViewsTable
            defaultLanguage={defaultLanguage}
            languages={languages}
            nodes={nodes}
            data={privateViews}
            onViewsClose={onViewsClose}
            setDeleteViewId={setDeleteViewId}
            setDeleteViewNodeId={setDeleteViewNodeId}
          />
        </TabPanel>
        <TabPanel
          id="user-views-settings-form__tab-panel-shared"
          value="shared"
          selected={tabId}
          sx={{height: "calc(100% - 49px)", paddingTop: "8px"}}
        >
          <ViewsTable
            defaultLanguage={defaultLanguage}
            languages={languages}
            nodes={nodes}
            data={sharedViews}
            onViewsClose={onViewsClose}
            setDeleteViewId={setDeleteViewId}
            setDeleteViewNodeId={setDeleteViewNodeId}
          />
        </TabPanel>

        <Dialog
          id="views-settings-form__modals__delete-view"
          maxWidth="xs"
          open={deleteViewId !== null}
          onClose={() => {
            setDeleteViewId(null);
            setDeleteViewNodeId(null);
          }}
        >
          <CustomDialogTitle
            onClose={() => {
              setDeleteViewId(null);
              setDeleteViewNodeId(null);
            }}
          >
            {t("scenes.viewsSettings.modals.deleteView.title")}
          </CustomDialogTitle>
          <DialogContent>{t("scenes.viewsSettings.modals.deleteView.content")}</DialogContent>
          <DialogActions>
            <Button
              id="views-settings-form__modals__delete-view__cancel-btn"
              autoFocus
              onClick={() => {
                setDeleteViewId(null);
                setDeleteViewNodeId(null);
              }}
            >
              {t("commons.confirm.cancel")}
            </Button>
            <Button
              id="views-settings-form__modals__delete-view__confirm-btn"
              onClick={() => {
                deleteView(deleteViewNodeId, deleteViewId);
                setDeleteViewId(null);
                setDeleteViewNodeId(null);
              }}
            >
              {t("commons.confirm.confirm")}
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    )
  );
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true}),
  forwardRef
)(UserViewsSettingsForm);
