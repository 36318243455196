import React, {Fragment, useState} from "react";
import {Box} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import {SketchPicker} from "react-color";
import {withTranslation} from "react-i18next";
import {getMapClassificationMethodOptions, MAP_CLASSIFICATION_METHOD_CUSTOM_INTERVALS} from "../map/constants";
import {isValidIntegerInInclusiveRange} from "../../utils/validator";

const fieldStyle = {marginBottom: "12px"};
const labelStyle = {
  height: "100%",
  display: "flex",
  alignItems: "center"
};
const colorContainerStyle = {
  border: "2px solid darkgray",
  borderRadius: "5px"
};

const colorPickStyle = {
  width: "100%",
  height: "56px",
  display: "flex",
  alignItems: "center",
  cursor: "pointer"
};

const palettePreviewStyle = {
  width: "100%",
  height: "56px"
};

const previewLabelStyle = {
  textAlign: "center"
};

const getGradientStr = colors => {
  let gradientStr = "linear-gradient(90deg, ";

  const percStep = 100 / colors.length;
  for (let i = 0; i < colors.length; i++) {
    if (i === 0) {
      gradientStr += `${colors[i]} 0%, `;
    }
    if (i > 0) {
      const perc = percStep * i;
      gradientStr += `${colors[i - 1]} ${perc}%, ${colors[i]} ${perc}%, `;
    }
    if (i === colors.length - 1) {
      gradientStr += `${colors[i]} 100%`;
    }
  }

  gradientStr += ")";

  return gradientStr;
};

const getCustomIntervals = (values, colors) => {
  return colors.map((color, idx) => ({
    min: values[idx],
    max: values[idx + 1],
    color: color
  }));
};

const getCustomIntervalValuesValidity = values =>
  values.find(
    (el, idx) =>
      idx > 0 &&
      idx < values.length - 1 &&
      (el === null || !isValidIntegerInInclusiveRange(el, values[idx - 1], values[idx + 1]))
  ) === undefined;

function MapSettingsSettings(props) {
  const {t, mapId, settings, onSettingsSet, setSettingsValidity} = props;

  const {classificationMethod, paletteStartColor, paletteEndColor, paletteCardinality, customIntervals} = settings;

  const [tmpColor, setTmpColor] = useState(null);
  const [isStartColor, setIsStartColor] = useState(true);

  const handleChooseColorOpen = isStartColor => {
    setIsStartColor(isStartColor);
    setTmpColor(isStartColor ? paletteStartColor : paletteEndColor);
  };

  const handleChooseColorClose = () => {
    setTmpColor(null);
    setIsStartColor(null);
  };

  const handleChooseColorSubmit = () => {
    setTmpColor(null);
    setIsStartColor(null);

    const newPaletteStartColor = isStartColor ? tmpColor : paletteStartColor;
    const newPaletteEndColor = !isStartColor ? tmpColor : paletteEndColor;

    let newCustomInterval = null;
    if (customIntervals !== null && customIntervals !== undefined) {
      const previewColors = window.LMap.getColorPalette(newPaletteStartColor, newPaletteEndColor, paletteCardinality);
      newCustomInterval = customIntervals.map((el, idx) => ({...el, color: previewColors[idx]}));
    }

    onSettingsSet({
      ...settings,
      paletteStartColor: newPaletteStartColor,
      paletteEndColor: newPaletteEndColor,
      customIntervals: newCustomInterval
    });
  };

  const previewColors = window.LMap.getColorPalette(paletteStartColor, paletteEndColor, paletteCardinality);
  const previewGradientStr = getGradientStr(previewColors);

  if (!window.LMap.getPaletteConstraints(mapId)) {
    return <span />;
  }

  const {paletteCardinalityMin, paletteCardinalityMax, seriesMin, seriesMax} = window.LMap.getPaletteConstraints(mapId);

  const customIntervalValues = new Array(previewColors.length + 1).fill(null);
  customIntervalValues[0] = seriesMin;
  customIntervalValues[previewColors.length] = seriesMax;

  if ((customIntervals || []).length === previewColors.length) {
    customIntervals.forEach(({min}, idx) => (customIntervalValues[idx] = min));
  }

  let cardinalityValues = [];
  if (paletteCardinalityMin && paletteCardinalityMax) {
    cardinalityValues = Array.from(
      {length: paletteCardinalityMax - paletteCardinalityMin + 1},
      (e, i) => i + paletteCardinalityMin
    );
  }

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%"
      }}
    >
      <Grid container spacing={2}>
        <Grid id="map-settings-form__classification-method-label" item xs={3} sx={fieldStyle}>
          <Box sx={labelStyle}>{t("components.mapSettings.view.classificationMethod.label") + ":"}</Box>
        </Grid>
        <Grid id="map-settings-form__classification-method" item xs={9} sx={fieldStyle}>
          <TextField
            select
            fullWidth
            value={classificationMethod}
            variant="outlined"
            onChange={({target}) => {
              onSettingsSet({
                ...settings,
                classificationMethod: target.value,
                customIntervals: null
              });
              setSettingsValidity(target.value !== MAP_CLASSIFICATION_METHOD_CUSTOM_INTERVALS);
            }}
            SelectProps={{SelectDisplayProps: {"aria-haspopup": true}}}
          >
            {getMapClassificationMethodOptions(t).map(({value, label}) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid id="map-settings-form__palette-cardinality-label" item xs={3} sx={fieldStyle}>
          <Box sx={labelStyle}>{t("components.mapSettings.view.paletteCardinality.label") + ":"}</Box>
        </Grid>
        <Grid id="map-settings-form__palette-cardinality" item xs={9} sx={fieldStyle}>
          <TextField
            select
            fullWidth
            value={paletteCardinality !== null ? paletteCardinality : ""}
            variant="outlined"
            onChange={({target}) => {
              onSettingsSet({
                ...settings,
                paletteCardinality: target.value,
                customIntervals: null
              });
            }}
            SelectProps={{SelectDisplayProps: {"aria-haspopup": true}}}
          >
            {cardinalityValues.map(val => (
              <MenuItem key={val} value={val}>
                {val}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid id="map-settings-form__palette-start-color-label" item xs={3} sx={fieldStyle}>
          <Box sx={labelStyle}>{t("components.mapSettings.view.paletteStartColor.label") + ":"}</Box>
        </Grid>
        <Grid id="map-settings-form__palette-start-color" item xs={2} sx={fieldStyle}>
          <Box
            sx={[colorContainerStyle, colorPickStyle]}
            style={{backgroundColor: paletteStartColor}}
            onClick={() => handleChooseColorOpen(true)}
          />
        </Grid>
        <Grid item xs={1} sx={fieldStyle} />
        <Grid id="map-settings-form__palette-end-color-label" item xs={3} sx={fieldStyle}>
          <Box sx={labelStyle}>{t("components.mapSettings.view.paletteEndColor.label") + ":"}</Box>
        </Grid>
        <Grid item xs={1} sx={fieldStyle} />
        <Grid id="map-settings-form__palette-end-color" item xs={2} sx={fieldStyle}>
          <Box
            sx={[colorContainerStyle, colorPickStyle]}
            style={{backgroundColor: paletteEndColor}}
            onClick={() => handleChooseColorOpen(false)}
          />
        </Grid>
        <Grid id="map-settings-form__palette-preview-label" item xs={3} sx={fieldStyle}>
          <Box sx={labelStyle}>{t("components.mapSettings.view.palettePreview.label") + ":"}</Box>
        </Grid>
        {classificationMethod !== MAP_CLASSIFICATION_METHOD_CUSTOM_INTERVALS ? (
          <Grid item xs={9} sx={fieldStyle}>
            <Box sx={[palettePreviewStyle, colorContainerStyle]} style={{background: previewGradientStr}} />
          </Grid>
        ) : (
          <Fragment>
            <Grid id="map-settings-form__palette-preview-start-label" item xs={3} sx={[fieldStyle, previewLabelStyle]}>
              {t("components.mapSettings.view.palettePreview.start.label") + ":"}
            </Grid>
            <Grid item xs={2} />
            <Grid id="map-settings-form__palette-preview-end-label" item xs={3} sx={[fieldStyle, previewLabelStyle]}>
              {t("components.mapSettings.view.palettePreview.end.label") + ":"}
            </Grid>
            <Grid item xs={1} />
            {previewColors.map((color, idx) => (
              <Fragment key={idx}>
                <Grid item xs={2}>
                  <Box sx={[palettePreviewStyle, colorContainerStyle]} style={{background: color}} />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    type="number"
                    value={customIntervalValues[idx] !== null ? customIntervalValues[idx] : ""}
                    disabled
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    type="number"
                    value={customIntervalValues[idx + 1] !== null ? customIntervalValues[idx + 1] : ""}
                    onChange={({target}) => {
                      const val = (target.value || "").length > 0 && !isNaN(target.value) ? Number(target.value) : null;
                      const newCustomIntervalValues = [...customIntervalValues];
                      newCustomIntervalValues[idx + 1] = val;
                      onSettingsSet({
                        ...settings,
                        customIntervals: getCustomIntervals(newCustomIntervalValues, previewColors)
                      });
                      setSettingsValidity(getCustomIntervalValuesValidity(newCustomIntervalValues));
                    }}
                    disabled={idx === previewColors.length - 1}
                    error={
                      customIntervalValues[idx + 1] === null ||
                      customIntervalValues[idx + 1] <= customIntervalValues[idx] ||
                      customIntervalValues[idx + 1] >= customIntervalValues[idx + 2]
                    }
                  />
                </Grid>
              </Fragment>
            ))}
          </Fragment>
        )}
      </Grid>

      <Dialog open={tmpColor !== null} onClose={handleChooseColorClose} maxWidth="sm">
        <DialogContent sx={{padding: "0px"}}>
          <SketchPicker color={tmpColor || ""} onChange={({hex}) => setTmpColor(hex)} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleChooseColorClose}>{t("commons.confirm.close")}</Button>
          <Button onClick={handleChooseColorSubmit}>{t("commons.confirm.confirm")}</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default withTranslation()(MapSettingsSettings);
