import React, {Fragment, useCallback, useEffect, useMemo, useState} from "react";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import BarChartIcon from "@mui/icons-material/BarChart";
import CheckIcon from "@mui/icons-material/Check";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import {Box} from "@mui/material";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Stepper from "@mui/material/Stepper";
import Tooltip from "@mui/material/Tooltip";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import AdditionalDatasetCreateDialog from "../../additional-datasets-dialogs/create";
import Criteria from "../../criteria";
import CustomDialogTitle from "../../custom-dialog-title";
import CalculateIcon from "../../custom-icons/CalculateIcon";
import FileDownloadIcon from "../../custom-icons/FileDownloadIcon";
import FilterAltIcon from "../../custom-icons/FIlterAltIcon";
import FlagIcon from "../../custom-icons/FlagIcon";
import ExportButtonCombinationsWrapper from "../../export-button/ExportButtonCombinationsWrapper";
import FullscreenDialog from "../../fullscreen-dialog";
import IndicatorCreateCombinationsWrapper from "../../indicator-dialogs/create/IndicatorCreateCombinationsWrapper";
import {
  LABEL_FORMAT_SELECTOR_LABEL_FORMAT_BOTH,
  LABEL_FORMAT_SELECTOR_LABEL_FORMAT_ID
} from "../../label-format-selector/constants";
import {TEMPORAL_DIM_ORDER_SELECTOR_VALUE_DESC} from "../../temporal-dim-order-selector/constants";
import DetailLevelSelector from "../../territory-selectors/DetailLevelSelector";
import TerritoriesSelector from "../../territory-selectors/TerritorySelector";
import {ViewerMode} from "../../../state/dataset/constants";
import {
  changeDatasetMVPAdditionalDataset,
  enableDatasetMVPDatasetFetch,
  fetchDatasetMVPAdditionalDatasetCatalog,
  fetchDatasetMVPAdditionalDatasetStructure,
  fetchDatasetMVPIndicatorPreview,
  fetchDatasetMVPStructureCodelist,
  fetchDatasetMVPStructureCodelistFull,
  fetchDatasetMVPTerritoryLastYear,
  fetchDatasetMVPTerritoryTerritories,
  hideDatasetMVPCriteria,
  hideDatasetMVPCriteriaAlert,
  hideDatasetMVPCriteriaObsCountWarning,
  hideDatasetMVPStructureCodelistFull,
  hideDatasetMVPTerritory,
  publishDatasetMVPIndicator,
  resetDatasetMVPIndicatorPreview,
  setDatasetMVPAdditionalDatasetCreateVisibility,
  setDatasetMVPIndicatorArithmeticMeanVisibility,
  setDatasetMVPIndicatorCoefficientOfVariationVisibility,
  setDatasetMVPIndicatorCreateVisibility,
  setDatasetMVPIndicatorStandardDeviationVisibility,
  setDatasetMVPStructureCriteria,
  setDatasetMVPViewerChartVisibility,
  setDatasetMVPViewerMapVisibility,
  setDatasetMVPViewerTableVisibility,
  showDatasetMVPCriteria,
  showDatasetMVPTerritory,
  submitDatasetMVPAdditionalDataset,
  submitDatasetMVPDownload,
  submitDatasetMVPDownloadMetadata,
  submitDatasetMVPTerritory
} from "../../../state/dataset/multi-viewer-plus/actions";
import {getFormattedValue} from "../../../utils/formatters";
import {getNode, getNodes} from "../../../utils/tree";

const actionDisabledStyle = {
  color: "rgba(0, 0, 0, 0.54) !important",
  "& svg": {
    color: "rgba(0, 0, 0, 0.54)"
  }
};

const mapStateToProps = ({config, appConfig, app, hub, node, dataset}) => ({
  indicatorsBaseUrl: config.externalServices?.indicator,
  optimizedDataRetrieverBaseUrl: config.externalServices?.optimizedDataRetriever,
  shapefilesUrl: appConfig.shapefilesUrl,
  maxTableColCount: appConfig.tableConfig.maxColCount,
  maxMapPolygonCount: appConfig.mapConfig.maxPolygonCount,
  hiddenDimensionValueLabels: appConfig.hiddenDimensionValueLabels,
  defaultLanguage: app.language,
  languages: app.languages,
  exportConfig: hub.hub.exportConfig,
  maxObservations: hub.hub.maxObservationsAfterCriteria || Number.MAX_SAFE_INTEGER,
  node: node,
  mode: dataset.commons.mode,
  type: dataset.commons.type,
  colCount: dataset.multiViewerPlus.colCount,
  colCountLimit: dataset.multiViewerPlus.colCountLimit,
  colCountLimitExceeded: dataset.multiViewerPlus.colCountLimitExceeded,
  isEmptyData: dataset.multiViewerPlus.isEmptyData,
  isTooBigData: dataset.multiViewerPlus.isTooBigData,
  isCriteriaVisible: dataset.multiViewerPlus.isCriteriaVisible,
  isCriteriaAlertVisible: dataset.multiViewerPlus.isCriteriaAlertVisible,
  isObsCountWarningVisible: dataset.multiViewerPlus.isObsCountWarningVisible,
  dimensions: dataset.multiViewerPlus.dimensions,
  dimensionLabels: dataset.multiViewerPlus.dimensionLabels,
  dimensionMap: dataset.multiViewerPlus.dimensionMap,
  cols: dataset.multiViewerPlus.cols,
  rows: dataset.multiViewerPlus.rows,
  tableChartDataset: dataset.multiViewerPlus.tableChartDataset,
  mapDataset: dataset.multiViewerPlus.mapDataset,
  territoryDim: dataset.multiViewerPlus.territoryDim,
  timeDim: dataset.multiViewerPlus.timeDim,
  freqDim: dataset.multiViewerPlus.freqDim,
  isTableVisible: dataset.multiViewerPlus.isTableVisible,
  isMapVisible: dataset.multiViewerPlus.isMapVisible,
  isChartVisible: dataset.multiViewerPlus.isChartVisible,
  tableLayout: dataset.multiViewerPlus.tableLayout,
  mapLayout: dataset.multiViewerPlus.mapLayout,
  chartLayout: dataset.multiViewerPlus.chartLayout,
  labelFormat: dataset.multiViewerPlus.labelFormat,
  areCriteriaApplied: dataset.multiViewerPlus.areCriteriaApplied,
  criteria: dataset.multiViewerPlus.criteria,
  territoryDimCriteria: dataset.multiViewerPlus.criteria?.[dataset.multiViewerPlus.territoryDim] || null,
  decimalSeparator: dataset.multiViewerPlus.decimalSeparator,
  roundingStrategy: dataset.multiViewerPlus.roundingStrategy,
  decimalPlaces: dataset.multiViewerPlus.decimalPlaces,
  tableEmptyChar: dataset.multiViewerPlus.tableEmptyChar,
  chartSettings: dataset.multiViewerPlus.chartSettings,
  mapSettings: dataset.multiViewerPlus.mapSettings,
  codelists: dataset.multiViewerPlus.codelists,
  codelistsLength: dataset.multiViewerPlus.codelistsLength,
  codelistFetchError: dataset.multiViewerPlus.codelistFetchError,
  isTerritoryVisible: dataset.multiViewerPlus.isTerritoryVisible,
  detailLevelTree: dataset.multiViewerPlus.detailLevelTree,
  detailLevel: dataset.multiViewerPlus.detailLevel,
  territoryTree: dataset.multiViewerPlus.territoryTree,
  territories: dataset.multiViewerPlus.territories,
  allTerritoriesSelected: dataset.multiViewerPlus.allTerritoriesSelected,
  territorialClassificationsConfig: dataset.multiViewerPlus.territorialClassificationsConfig,
  territorialClassificationsValues: dataset.multiViewerPlus.territorialClassificationsValues,
  isIndicatorCreateVisible: dataset.multiViewerPlus.isIndicatorCreateVisible,
  indicators: dataset.multiViewerPlus.indicators,
  showArithmeticMean: dataset.multiViewerPlus.showArithmeticMean,
  showStandardDeviation: dataset.multiViewerPlus.showStandardDeviation,
  showCoefficientOfVariation: dataset.multiViewerPlus.showCoefficientOfVariation,
  isAdditionalDatasetCreateVisible: dataset.multiViewerPlus.isAdditionalDatasetCreateVisible,
  additionalDatasetCatalog: dataset.multiViewerPlus.additionalDatasetCatalog,
  missingFilterValues: dataset.multiViewerPlus.missingFilterValues,
  territoryDimCodelist: dataset.multiViewerPlus.territoryDimCodelist,
  lastTerritoryYear: dataset.multiViewerPlus.lastTerritoryYear,
  additionalDataset: dataset.multiViewerPlus.additionalDataset,
  additionalDatasets: dataset.multiViewerPlus.additionalDatasets,
  indicatorPreview: dataset.multiViewerPlus.indicatorPreview
});

const mapDispatchToProps = (dispatch, props) => ({
  onFetchDatasetEnable: maxObservations => dispatch(enableDatasetMVPDatasetFetch(maxObservations)),
  onCriteriaShow: () => dispatch(showDatasetMVPCriteria()),
  onCriteriaHide: () => dispatch(hideDatasetMVPCriteria()),
  onSetCriteria: criteria => dispatch(setDatasetMVPStructureCriteria(criteria)),
  onCriteriaAlertHide: () => dispatch(hideDatasetMVPCriteriaAlert()),
  fetchCodelist: (
    nodeId,
    nodeCode,
    datasetId,
    mode,
    type,
    dimensionId,
    criteria,
    territoryDim,
    detailLevelNameIdentifier,
    allTerritoriesSelected,
    freq,
    defaultLastNPeriods,
    preserveFiltersWithDynamic,
    baseUrl
  ) =>
    dispatch(
      fetchDatasetMVPStructureCodelist(
        nodeId,
        nodeCode,
        datasetId,
        mode,
        type,
        dimensionId,
        criteria,
        territoryDim,
        detailLevelNameIdentifier,
        allTerritoriesSelected,
        freq,
        defaultLastNPeriods,
        preserveFiltersWithDynamic,
        baseUrl
      )
    ),
  onCriteriaObsCountWarningHide: () => dispatch(hideDatasetMVPCriteriaObsCountWarning()),
  fetchCodelistFull: (nodeId, datasetId, dimensionId, missingFilterValueIds) =>
    dispatch(fetchDatasetMVPStructureCodelistFull(nodeId, datasetId, dimensionId, missingFilterValueIds)),
  onCodelistFullHide: () => dispatch(hideDatasetMVPStructureCodelistFull()),
  onTerritoryShow: () => dispatch(showDatasetMVPTerritory()),
  onTerritoryHide: () => dispatch(hideDatasetMVPTerritory()),
  onTerritorySubmit: (detailLevel, territories, allTerritoriesSelected, criteria) =>
    dispatch(submitDatasetMVPTerritory(detailLevel, territories, allTerritoriesSelected, criteria)),
  onTableVisibilitySet: isVisible => dispatch(setDatasetMVPViewerTableVisibility(isVisible)),
  onMapVisibilitySet: isVisible => dispatch(setDatasetMVPViewerMapVisibility(isVisible)),
  onChartVisibilitySet: isVisible => dispatch(setDatasetMVPViewerChartVisibility(isVisible)),
  onDownloadSubmit: (
    nodeId,
    nodeCode,
    datasetId,
    datasetTitle,
    criteria,
    timeDim,
    territoryDim,
    layout,
    indicators,
    additionalDatasets,
    format,
    extension,
    zipped,
    params,
    defaultLanguage,
    languages,
    t,
    indicatorsBaseUrl
  ) =>
    dispatch(
      submitDatasetMVPDownload(
        nodeId,
        nodeCode,
        datasetId,
        datasetTitle,
        criteria,
        timeDim,
        territoryDim,
        layout,
        indicators,
        additionalDatasets,
        format,
        extension,
        zipped,
        params,
        defaultLanguage,
        languages,
        t,
        indicatorsBaseUrl
      )
    ),
  onIndicatorCreateVisibilitySet: isVisible => dispatch(setDatasetMVPIndicatorCreateVisibility(isVisible)),
  onArithmeticMeanVisibilitySet: isVisible => dispatch(setDatasetMVPIndicatorArithmeticMeanVisibility(isVisible)),
  onStandardDeviationVisibilitySet: isVisible => dispatch(setDatasetMVPIndicatorStandardDeviationVisibility(isVisible)),
  onCoefficientOfVariationVisibilitySet: isVisible =>
    dispatch(setDatasetMVPIndicatorCoefficientOfVariationVisibility(isVisible)),
  onAdditionalDatasetCreateVisibilitySet: isVisible =>
    dispatch(setDatasetMVPAdditionalDatasetCreateVisibility(isVisible)),
  fetchADCatalog: ({selectedNodeId, mainDatasetId, baseUrl}) =>
    dispatch(fetchDatasetMVPAdditionalDatasetCatalog(selectedNodeId, mainDatasetId, baseUrl)),
  fetchADStructure: ({nodeId, datasetId}) => dispatch(fetchDatasetMVPAdditionalDatasetStructure(nodeId, datasetId)),
  onADChange: additionalDataset => dispatch(changeDatasetMVPAdditionalDataset(additionalDataset)),
  onADSubmit: () => dispatch(submitDatasetMVPAdditionalDataset()),
  onDownloadReferenceMetadataSubmit: (nodeId, datasetId, datasetTitle) =>
    dispatch(submitDatasetMVPDownloadMetadata(nodeId, datasetId, datasetTitle)),
  fetchLastYear: ({nodeId, datasetId, timeDim, criteria}) =>
    dispatch(fetchDatasetMVPTerritoryLastYear(nodeId, datasetId, timeDim, criteria)),
  fetchTerritories: (nodeId, datasetId, territoryDim, detailLevel, baseUrl) =>
    dispatch(fetchDatasetMVPTerritoryTerritories(nodeId, datasetId, territoryDim, detailLevel, baseUrl)),
  onIndicatorPreviewFetch: (
    nodeId,
    nodeCode,
    datasetId,
    criteria,
    timeDim,
    territoryDim,
    indicators,
    additionalDatasets,
    defaultLanguage,
    languages,
    indicatorsBaseUrl
  ) =>
    dispatch(
      fetchDatasetMVPIndicatorPreview(
        nodeId,
        nodeCode,
        datasetId,
        criteria,
        timeDim,
        territoryDim,
        indicators,
        additionalDatasets,
        defaultLanguage,
        languages,
        indicatorsBaseUrl
      )
    ),
  onIndicatorPreviewReset: () => dispatch(resetDatasetMVPIndicatorPreview()),
  onIndicatorPublish: indicator => dispatch(publishDatasetMVPIndicator(indicator))
});

function MultiViewerSidebar(props) {
  const {
    nodeId,
    nodeCode,
    nodeExtras,
    datasetId,
    datasetTitle,
    chartId,
    mapId,
    datasetMap,
    indicatorsBaseUrl,
    optimizedDataRetrieverBaseUrl,
    shapefilesUrl,
    maxTableColCount,
    maxMapPolygonCount,
    hiddenDimensionValueLabels,
    defaultLanguage,
    languages,
    exportConfig,
    maxObservations,
    node,
    mode,
    type,
    colCount,
    colCountLimit,
    colCountLimitExceeded,
    isEmptyData,
    isTooBigData,
    isCriteriaVisible,
    isCriteriaAlertVisible,
    isObsCountWarningVisible,
    dimensions,
    dimensionLabels,
    dimensionMap,
    cols,
    rows,
    tableChartDataset,
    mapDataset,
    territoryDim,
    timeDim,
    freqDim,
    isTableVisible,
    isMapVisible,
    isChartVisible,
    tableLayout,
    mapLayout,
    chartLayout,
    labelFormat,
    areCriteriaApplied,
    criteria,
    territoryDimCriteria,
    decimalSeparator,
    roundingStrategy,
    decimalPlaces,
    tableEmptyChar,
    chartSettings,
    mapSettings,
    codelists,
    codelistsLength,
    codelistFetchError,
    isTerritoryVisible,
    detailLevelTree,
    detailLevel,
    territoryTree,
    territories,
    allTerritoriesSelected,
    territorialClassificationsConfig,
    territorialClassificationsValues,
    isIndicatorCreateVisible,
    indicators,
    showArithmeticMean,
    showStandardDeviation,
    showCoefficientOfVariation,
    isAdditionalDatasetCreateVisible,
    additionalDatasetCatalog,
    missingFilterValues,
    territoryDimCodelist,
    lastTerritoryYear,
    additionalDataset,
    additionalDatasets,
    indicatorPreview,

    onFetchDatasetEnable,
    onCriteriaShow,
    onCriteriaHide,
    onSetCriteria,
    onCriteriaAlertHide,
    fetchCodelist,
    onCriteriaObsCountWarningHide,
    fetchCodelistFull,
    onCodelistFullHide,
    onTerritoryShow,
    onTerritoryHide,
    onTerritorySubmit,
    onTableVisibilitySet,
    onMapVisibilitySet,
    onChartVisibilitySet,
    onDownloadSubmit,
    onIndicatorCreateVisibilitySet,
    onArithmeticMeanVisibilitySet,
    onStandardDeviationVisibilitySet,
    onCoefficientOfVariationVisibilitySet,
    onAdditionalDatasetCreateVisibilitySet,
    fetchADCatalog,
    fetchADStructure,
    onADChange,
    onADSubmit,
    onDownloadReferenceMetadataSubmit,
    fetchLastYear,
    fetchTerritories,
    onIndicatorPreviewFetch,
    onIndicatorPreviewReset,
    onIndicatorPublish
  } = props;

  const {t} = useTranslation();

  const [datasetIdx, setDatasetIdx] = useState(0);

  const [isCriteriaValid, setCriteriaValidity] = useState(true);

  const [detailLevelTmp, setDetailLevelTmp] = useState(null);
  const [territoriesTmp, setTerritoriesTmp] = useState(null);

  const [step, setStep] = useState(0);

  const [isTerritoryWarningVisible, setTerritoryWarningVisibility] = useState(false);

  const [indicatorsAnchorEl, setIndicatorsAnchorEl] = useState(null);
  const [additionalDatasetsAnchorEl, setAdditionalDatasetsAnchorEl] = useState(null);

  const detailLevelNameIdentifier = useMemo(
    () => getNode(detailLevelTree, "layers", ({id}) => id === detailLevel)?.nameIdentifier ?? "",
    [detailLevelTree, detailLevel]
  );

  const generalizationLevel = useMemo(() => {
    const generalizationLevels =
      getNode(detailLevelTree, "layers", ({id}) => id === detailLevelTmp)?.generalizationLevels ?? [];
    return (generalizationLevels || []).length > 0 ? generalizationLevels[generalizationLevels.length - 1] : null;
  }, [detailLevelTree, detailLevelTmp]);

  const getDimensionsCombinationCount = useCallback(
    dimensions =>
      dimensions && dimensionMap ? dimensions.reduce((acc, dim) => acc * Object.keys(dimensionMap[dim]).length, 1) : 0,
    [dimensionMap]
  );

  const handleFetchCodelist = useCallback(
    (
      nodeId,
      nodeCode,
      datasetId,
      mode,
      type,
      dimensionId,
      fullCriteria,
      freq,
      defaultLastNPeriods,
      preserveFiltersWithDynamic,
      isOptimized = true
    ) => {
      fetchCodelist(
        nodeId,
        nodeCode,
        datasetId,
        mode,
        type,
        dimensionId,
        fullCriteria,
        territoryDim,
        detailLevelNameIdentifier,
        allTerritoriesSelected,
        freq,
        defaultLastNPeriods,
        preserveFiltersWithDynamic,
        isOptimized ? optimizedDataRetrieverBaseUrl : undefined
      );
    },
    [territoryDim, detailLevelNameIdentifier, allTerritoriesSelected, optimizedDataRetrieverBaseUrl, fetchCodelist]
  );

  const downloadFormats = nodeExtras?.DownloadFormats;

  const defaultLastNPeriods = nodeExtras?.DefaultLastNPeriods;

  const handleCriteriaOpen = () => {
    setDatasetIdx(0);
    onCriteriaShow();
  };

  const handleCriteriaClose = () => {
    onCriteriaHide();
    setCriteriaValidity(true);
  };

  const handleCriteriaSubmit = maxObservations => {
    onFetchDatasetEnable(maxObservations);
  };

  const handleTerritoryOpen = () => {
    setStep(0);

    setDetailLevelTmp(detailLevel);
    setTerritoriesTmp(territories);

    onTerritoryShow();
  };

  useEffect(() => {
    setDetailLevelTmp(prevVal => (prevVal === null && detailLevel !== null ? detailLevel : prevVal));
  }, [detailLevel]);

  useEffect(() => {
    setTerritoriesTmp(prevVal =>
      (prevVal || []).length === 0 && (territoryTree || []).length > 0
        ? getNodes(territoryTree, "children", () => true).map(({id}) => id)
        : prevVal
    );
  }, [territoryTree]);

  const handleTerritoryClose = () => {
    onTerritoryHide();
  };

  const handleTerritoriesFetch = useCallback(
    ({nodeId, datasetId, territoryDim, detailLevel: detailLevelTmp, baseUrl}) => {
      fetchTerritories(nodeId, datasetId, territoryDim, detailLevelTmp, baseUrl);
    },
    [fetchTerritories]
  );

  const handleTerritorySubmit = () => {
    const territoryFilterValues = getNodes(
      territoryTree,
      "children",
      ({id, children}) => territoriesTmp.includes(id) && (children || []).length === 0
    );

    if (territoryFilterValues.length < maxMapPolygonCount) {
      let newCriteria = {
        ...criteria,
        [territoryDim]: {
          id: territoryDim,
          filterValues: territoryFilterValues.map(({id}) => id)
        }
      };
      const allTerritories = getNodes(territoryTree, "children");
      onTerritorySubmit(detailLevelTmp, territoriesTmp, territoriesTmp.length === allTerritories.length, newCriteria);
    } else {
      setTerritoryWarningVisibility(true);
    }
  };

  const visibleViewerCount = [isTableVisible, isChartVisible, isMapVisible].filter(el => el).length;
  const isTableDisabled = !areCriteriaApplied || !tableLayout;
  const isChartDisabled = !areCriteriaApplied || !chartLayout;
  const isMapDisabled = !areCriteriaApplied || !mapLayout;

  const missingDataset =
    !areCriteriaApplied || cols === null || rows === null || (tableChartDataset === null && mapDataset === null);

  return (
    <Card
      sx={theme => ({
        width: "80px",
        height: "100%",
        overflow: "auto",
        padding: "8px 0",
        marginRight: "16px",
        "& .MuiBottomNavigation-root": {
          padding: "unset !important"
        },
        "& .MuiBottomNavigation-root button": {
          color: theme.palette.primary.main
        },
        "& .MuiBottomNavigationAction-label": {
          fontSize: "13px !important"
        }
      })}
      role="menu"
    >
      <Box
        sx={theme => ({
          marginBottom: "8px",
          "& button:hover": {
            background: "rgba(0, 0, 0, 0.1)",
            color: `${theme.palette.primary.main} !important`,
            "& svg": {
              color: theme.palette.primary.main
            }
          }
        })}
      >
        {territoryDim && (
          <BottomNavigation showLabels onChange={handleTerritoryOpen}>
            <BottomNavigationAction
              label={t("scenes.dataViewer.multiViewer.sidebar.territory")}
              icon={<FlagIcon />}
              role="menuitem"
            />
          </BottomNavigation>
        )}
        <BottomNavigation showLabels onChange={handleCriteriaOpen}>
          <BottomNavigationAction
            label={t("scenes.dataViewer.multiViewer.sidebar.criteria")}
            icon={<FilterAltIcon />}
            sx={(territories || []).length === 0 ? actionDisabledStyle : null}
            disabled={(territories || []).length === 0}
            role="menuitem"
          />
        </BottomNavigation>
      </Box>

      <Box
        sx={theme => [
          {
            marginBottom: "8px",
            "& button:hover": {
              background: "rgba(0, 0, 0, 0.1)",
              color: `${theme.palette.primary.main} !important`,
              "& svg": {
                color: theme.palette.primary.main
              }
            }
          },
          {
            "& button": {
              background: "rgba(0, 0, 0, 0.07)"
            }
          }
        ]}
      >
        <BottomNavigation
          showLabels
          value={isTableVisible ? 0 : null}
          onChange={() => onTableVisibilitySet(!isTableVisible)}
        >
          <BottomNavigationAction
            label={t("scenes.dataViewer.multiViewer.sidebar.table")}
            icon={<TableChartOutlinedIcon />}
            sx={!isTableVisible ? actionDisabledStyle : null}
            disabled={isTableDisabled || (isTableVisible && visibleViewerCount <= 1)}
            role="menuitem"
          />
        </BottomNavigation>
        <BottomNavigation
          showLabels
          value={isChartVisible ? 0 : null}
          onChange={() => onChartVisibilitySet(!isChartVisible)}
        >
          <BottomNavigationAction
            label={t("scenes.dataViewer.multiViewer.sidebar.chart")}
            icon={<BarChartIcon />}
            sx={!isChartVisible ? actionDisabledStyle : null}
            disabled={isChartDisabled || (isChartVisible && visibleViewerCount <= 1)}
            role="menuitem"
          />
        </BottomNavigation>
        <BottomNavigation showLabels value={isMapVisible ? 0 : null} onChange={() => onMapVisibilitySet(!isMapVisible)}>
          <BottomNavigationAction
            label={t("scenes.dataViewer.multiViewer.sidebar.map")}
            icon={<MapOutlinedIcon />}
            sx={!isMapVisible ? actionDisabledStyle : null}
            disabled={isMapDisabled || (isMapVisible && visibleViewerCount <= 1)}
            role="menuitem"
          />
        </BottomNavigation>
      </Box>

      <Box
        sx={theme => ({
          marginBottom: "8px",
          "& button:hover": {
            background: "rgba(0, 0, 0, 0.1)",
            color: `${theme.palette.primary.main} !important`,
            "& svg": {
              color: theme.palette.primary.main
            }
          }
        })}
      >
        <BottomNavigation showLabels onChange={({currentTarget}) => setAdditionalDatasetsAnchorEl(currentTarget)}>
          <BottomNavigationAction
            label={t("scenes.dataViewer.multiViewer.sidebar.addData")}
            icon={<AddCircleOutlineOutlinedIcon />}
            sx={missingDataset ? actionDisabledStyle : null}
            disabled={missingDataset}
            role="menuitem"
          />
        </BottomNavigation>
        <BottomNavigation showLabels onChange={({currentTarget}) => setIndicatorsAnchorEl(currentTarget)}>
          <BottomNavigationAction
            label={t("scenes.dataViewer.multiViewer.sidebar.calculateIndicator")}
            icon={<CalculateIcon />}
            sx={missingDataset && indicators.length === 0 ? actionDisabledStyle : null}
            disabled={missingDataset && indicators.length === 0}
            role="menuitem"
          />
        </BottomNavigation>
      </Box>

      <Box
        sx={theme => ({
          marginBottom: "8px",
          "& button:hover": {
            background: "rgba(0, 0, 0, 0.1)",
            color: `${theme.palette.primary.main} !important`,
            "& svg": {
              color: theme.palette.primary.main
            }
          }
        })}
      >
        <ExportButtonCombinationsWrapper
          dimensionLabels={dimensionLabels}
          dimensionMap={dimensionMap}
          formats={downloadFormats}
          viewerIdx={0}
          showAsBottomNavigation={true}
          icon={<FileDownloadIcon />}
          isTableVisible={isTableVisible}
          isMapVisible={isMapVisible}
          isChartVisible={isChartVisible}
          tableLayout={tableLayout}
          mapId={mapId}
          mapContainerId="data-viewer__viewers__map__viewer"
          mapLayout={mapLayout}
          mapSettings={mapSettings}
          chartId={chartId}
          chartContainerId="data-viewer__viewers__chart__viewer"
          chartLayout={chartLayout}
          chartSettings={chartSettings}
          labelFormat={labelFormat}
          datasetTitle={datasetTitle}
          shapefilesUrl={shapefilesUrl}
          role="menuitem"
          //className={missingDataset ? actionDisabledStyle : ""} // TODO: fix (with sx prop) className propagation in nested commponents
          disabled={missingDataset}
          getDimensionsCombinationCount={getDimensionsCombinationCount}
          submitDownload={(format, extension, additionalParams) => {
            const exportParams = {
              labelFormat: labelFormat,
              customLabelFormat: {
                [timeDim]: LABEL_FORMAT_SELECTOR_LABEL_FORMAT_ID,
                [territoryDim]: LABEL_FORMAT_SELECTOR_LABEL_FORMAT_BOTH
              },
              decimalSeparator: decimalSeparator,
              roundingStrategy: roundingStrategy,
              decimalNumber: decimalPlaces,
              emptyCellPlaceHolder: tableEmptyChar,
              hiddenDimensionValueLabels: hiddenDimensionValueLabels,
              temporalDimOrder: TEMPORAL_DIM_ORDER_SELECTOR_VALUE_DESC,
              exportConfig: exportConfig,
              suffixToRemove: {
                [territoryDim]:
                  getNode(detailLevelTree, "layers", ({id}) => id === detailLevel)?.detailLevelSuffix || ""
              },
              customDimensionLabels: {
                [territoryDim]: getNode(detailLevelTree, "layers", ({id}) => id === detailLevel)?.label || null
              },
              territorialClassificationsConfig: territorialClassificationsConfig,
              territorialClassificationsValues: territorialClassificationsValues,
              ...additionalParams
            };

            onDownloadSubmit(
              nodeId,
              nodeCode,
              datasetId,
              datasetTitle,
              criteria,
              timeDim,
              territoryDim,
              tableLayout,
              indicators,
              additionalDatasets,
              format,
              extension,
              false,
              exportParams,
              defaultLanguage,
              languages,
              t,
              indicatorsBaseUrl
            );
          }}
          submitMetadataDownload={
            datasetMap[datasetId]?.referenceMetadata
              ? () => onDownloadReferenceMetadataSubmit(nodeId, datasetId, datasetTitle)
              : null
          }
        />
      </Box>

      <FullscreenDialog open={isCriteriaVisible === true} onClose={handleCriteriaClose} id={"criteria-dialog"}>
        <CustomDialogTitle onClose={handleCriteriaClose}>
          {t("scenes.dataViewer.multiViewer.dialogs.criteria.title")}
        </CustomDialogTitle>
        <DialogContent sx={{height: "100%"}}>
          <div
            style={{
              height: "100%"
            }}
          >
            <Criteria
              key={`${nodeCode}+${datasetId}`}
              viewerMode={ViewerMode.MultiViewer}
              nodeId={nodeId}
              nodeCode={nodeCode}
              datasetId={datasetId}
              dimensions={dimensions}
              timeDim={timeDim}
              freqDim={freqDim}
              territoryDim={territoryDim}
              hideTerritoryDim
              mode={mode}
              type={type}
              criteria={criteria}
              territoryDimCriteria={datasetIdx > 0 && criteria?.[territoryDim] ? criteria[territoryDim] : null}
              onSetCriteria={onSetCriteria}
              codelists={codelists}
              codelistsLength={codelistsLength}
              fetchCodelist={handleFetchCodelist}
              codelistFetchError={codelistFetchError}
              isCriteriaValid={isCriteriaValid}
              setCriteriaValidity={setCriteriaValidity}
              isObsCountWarningVisible={isObsCountWarningVisible}
              onCriteriaObsCountWarningHide={onCriteriaObsCountWarningHide}
              onSubmit={handleCriteriaSubmit}
              defaultLastNPeriods={defaultLastNPeriods}
              preserveFiltersWithDynamic
              missingFilterValues={missingFilterValues}
              fetchCodelistFull={fetchCodelistFull}
              onCodelistFullHide={onCodelistFullHide}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCriteriaClose}>{t("commons.confirm.cancel")}</Button>
          <Button
            autoFocus
            color="primary"
            onClick={() => handleCriteriaSubmit(maxObservations)}
            disabled={!isCriteriaValid}
            id="data_viewer_multi_viewer_plus_sidebar_criteria_confirm_btn"
          >
            {t("commons.confirm.apply")}
          </Button>
        </DialogActions>
      </FullscreenDialog>

      <Dialog open={isCriteriaAlertVisible} fullWidth maxWidth="sm" onClose={onCriteriaAlertHide}>
        {(() => {
          if (isEmptyData) {
            return (
              <CustomDialogTitle onClose={onCriteriaAlertHide}>
                {t("scenes.dataViewer.sidebar.warning.emptyData.title")}
              </CustomDialogTitle>
            );
          } else if (isTooBigData) {
            return (
              <Fragment>
                <CustomDialogTitle
                  onClose={() => {
                    onCriteriaAlertHide();
                    onCriteriaShow();
                  }}
                >
                  {t("scenes.dataViewer.sidebar.warning.tooMuchCols.title")}
                </CustomDialogTitle>
                <DialogContent>
                  {colCountLimitExceeded && colCountLimit !== null
                    ? t("scenes.dataViewer.sidebar.warning.tooMuchCols.limitExceeded", {
                        val: getFormattedValue(colCountLimit, node?.decimalSeparator, node?.decimalNumber),
                        max: getFormattedValue(maxTableColCount, node?.decimalSeparator, node?.decimalNumber)
                      })
                    : t("scenes.dataViewer.sidebar.warning.tooMuchCols.content", {
                        val: getFormattedValue(colCount, node?.decimalSeparator, node?.decimalNumber),
                        max: getFormattedValue(maxTableColCount, node?.decimalSeparator, node?.decimalNumber)
                      })}
                </DialogContent>
              </Fragment>
            );
          } else {
            return (
              <CustomDialogTitle onClose={onCriteriaAlertHide}>
                {t("scenes.dataViewer.sidebar.warning.genericError.title")}
              </CustomDialogTitle>
            );
          }
        })()}
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              onCriteriaAlertHide();
              if (!isIndicatorCreateVisible && !isAdditionalDatasetCreateVisible) {
                onCriteriaShow();
              }
            }}
          >
            {colCount > maxTableColCount ? t("commons.confirm.confirm") : t("commons.confirm.close")}
          </Button>
        </DialogActions>
      </Dialog>

      <FullscreenDialog open={isTerritoryVisible === true} onClose={handleTerritoryClose} maxWidth={1280}>
        <CustomDialogTitle onClose={handleTerritoryClose}>{datasetTitle}</CustomDialogTitle>
        <DialogContent sx={{height: "100%"}}>
          <Grid container justifyContent="center">
            <Grid item xs={6}>
              <Stepper activeStep={step} nonLinear alternativeLabel>
                <Step key={0}>
                  <StepButton onClick={() => setStep(0)}>
                    {t("components.territory.steps.detailLevel.title")}
                  </StepButton>
                </Step>
                <Step key={1}>
                  <StepButton onClick={() => setStep(1)} disabled={detailLevelTmp === null}>
                    {t("components.territory.steps.territory.title")}
                  </StepButton>
                </Step>
              </Stepper>
            </Grid>
          </Grid>
          <Box
            key={step}
            sx={{
              height: "calc(100% - 108px)",
              overflow: "hidden"
            }}
          >
            {step === 0 && (
              <DetailLevelSelector
                detailLevelTree={detailLevelTree}
                detailLevel={detailLevelTmp}
                setDetailLevel={newDetailLevel => {
                  setDetailLevelTmp(newDetailLevel);
                  setTerritoriesTmp(null);
                }}
              />
            )}
            {step === 1 && (
              <TerritoriesSelector
                nodeId={nodeId}
                datasetId={datasetId}
                territoryDimCodelist={territoryDimCodelist}
                territoryDim={territoryDim}
                timeDim={timeDim}
                criteria={criteria}
                detailLevelTree={detailLevelTree}
                detailLevel={detailLevelTmp}
                territoryTree={territoryTree}
                territories={territoriesTmp}
                setTerritories={setTerritoriesTmp}
                fetchTerritories={handleTerritoriesFetch}
                lastTerritoryYear={lastTerritoryYear}
                fetchLastYear={fetchLastYear}
                generalizationLevel={generalizationLevel}
                isFetchEnabled={isTerritoryVisible}
              />
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          {step === 0 && (
            <Fragment>
              <Button onClick={handleTerritoryClose}>{t("commons.confirm.cancel")}</Button>
              <Button autoFocus color="primary" onClick={() => setStep(1)} disabled={detailLevelTmp === null}>
                {t("commons.confirm.next")}
              </Button>
            </Fragment>
          )}
          {step === 1 && (
            <Grid container justifyContent="space-between">
              <Grid item>
                <Button color="primary" onClick={() => setStep(0)}>
                  {t("commons.confirm.back")}
                </Button>
              </Grid>
              <Grid item>
                <Button onClick={handleTerritoryClose}>{t("commons.confirm.cancel")}</Button>
                <Button
                  autoFocus
                  color="primary"
                  onClick={handleTerritorySubmit}
                  disabled={(territoriesTmp || []).length === 0}
                >
                  {t("commons.confirm.apply")}
                </Button>
              </Grid>
            </Grid>
          )}
        </DialogActions>
      </FullscreenDialog>

      <Dialog
        open={isTerritoryWarningVisible === true}
        maxWidth="sm"
        fullWidth
        onClose={() => setTerritoryWarningVisibility(false)}
      >
        <CustomDialogTitle onClose={() => setTerritoryWarningVisibility(false)}>
          {t("scenes.dataViewer.multiViewer.dialogs.toMuchPolygon.title")}
        </CustomDialogTitle>
        <DialogContent>{t("scenes.dataViewer.multiViewer.dialogs.toMuchPolygon.content")}</DialogContent>
        <DialogActions>
          <Button onClick={() => setTerritoryWarningVisibility(false)}>{t("commons.confirm.close")}</Button>
        </DialogActions>
      </Dialog>

      <Menu
        anchorEl={indicatorsAnchorEl}
        keepMounted
        open={indicatorsAnchorEl !== null}
        onClose={() => setIndicatorsAnchorEl(null)}
      >
        <MenuItem
          disabled
          sx={{
            width: "100%",
            color: "gray",
            fontStyle: "italic",
            fontSize: "14px"
          }}
        >
          {t("scenes.dataViewer.multiViewer.indicators.title")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            onIndicatorCreateVisibilitySet(true);
            setIndicatorsAnchorEl(null);
          }}
          disabled={missingDataset || (indicators || []).length >= 5}
        >
          {t("scenes.dataViewer.multiViewer.indicators.values.create")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setIndicatorsAnchorEl(null);
          }}
          disabled={(indicators || []).length === 0}
        >
          {t("scenes.dataViewer.multiViewer.indicators.values.list")}
        </MenuItem>
        <MenuItem
          disabled
          sx={{
            width: "100%",
            color: "gray",
            fontStyle: "italic",
            fontSize: "14px"
          }}
        >
          {t("scenes.dataViewer.multiViewer.measuresOfSynthesisAndVariability.title")}
        </MenuItem>
        <Tooltip placement="top" title={t("commons.measuresOfSynthesisAndVariability.values.arithmeticMean.tooltip")}>
          <MenuItem onClick={() => onArithmeticMeanVisibilitySet(!showArithmeticMean)} disabled={missingDataset}>
            {t("commons.measuresOfSynthesisAndVariability.values.arithmeticMean.label")}
            {showArithmeticMean && <CheckIcon style={{marginLeft: 8}} />}
          </MenuItem>
        </Tooltip>
        <Tooltip
          placement="top"
          title={t("commons.measuresOfSynthesisAndVariability.values.standardDeviation.tooltip")}
        >
          <MenuItem onClick={() => onStandardDeviationVisibilitySet(!showStandardDeviation)} disabled={missingDataset}>
            {t("commons.measuresOfSynthesisAndVariability.values.standardDeviation.label")}
            {showStandardDeviation && <CheckIcon style={{marginLeft: 8}} />}
          </MenuItem>
        </Tooltip>
        <Tooltip
          placement="top"
          title={t("commons.measuresOfSynthesisAndVariability.values.coefficientOfVariation.tooltip")}
        >
          <MenuItem
            onClick={() => onCoefficientOfVariationVisibilitySet(!showCoefficientOfVariation)}
            disabled={missingDataset}
          >
            {t("commons.measuresOfSynthesisAndVariability.values.coefficientOfVariation.label")}
            {showCoefficientOfVariation && <CheckIcon style={{marginLeft: 8}} />}
          </MenuItem>
        </Tooltip>
      </Menu>

      <Menu
        anchorEl={additionalDatasetsAnchorEl}
        keepMounted
        open={additionalDatasetsAnchorEl !== null}
        onClose={() => setAdditionalDatasetsAnchorEl(null)}
      >
        <MenuItem
          onClick={() => {
            onAdditionalDatasetCreateVisibilitySet(true);
            setAdditionalDatasetsAnchorEl(null);
          }}
          disabled={missingDataset}
        >
          {t("scenes.dataViewer.multiViewer.additionalDataset.create.title")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAdditionalDatasetsAnchorEl(null);
          }}
          disabled
        >
          {t("scenes.dataViewer.multiViewer.additionalDataset.list.title")}
        </MenuItem>
      </Menu>

      {isIndicatorCreateVisible && (
        <IndicatorCreateCombinationsWrapper
          isOpen={isIndicatorCreateVisible}
          onClose={() => onIndicatorCreateVisibilitySet(false)}
          datasetId={datasetId}
          territoryDim={territoryDim}
          timeDim={timeDim}
          criteria={criteria}
          tableLayout={tableLayout}
          indicators={indicators}
          combinations={cols}
          dimensionLabels={dimensionLabels}
          dimensionMap={dimensionMap}
          indicatorPreview={indicatorPreview}
          additionalDatasets={additionalDatasets}
          onPreviewFetch={onIndicatorPreviewFetch}
          onPreviewReset={onIndicatorPreviewReset}
          onPublish={onIndicatorPublish}
        />
      )}

      {isAdditionalDatasetCreateVisible && (
        <AdditionalDatasetCreateDialog
          isOpen={isAdditionalDatasetCreateVisible}
          onClose={() => onAdditionalDatasetCreateVisibilitySet(false)}
          defaultLastNPeriods={defaultLastNPeriods}
          catalog={additionalDatasetCatalog}
          fetchCatalog={fetchADCatalog}
          additionalDataset={additionalDataset}
          fetchStructure={fetchADStructure}
          onDatasetChange={onADChange}
          onDatasetSubmit={onADSubmit}
          isObsCountWarningVisible={isObsCountWarningVisible}
          onObsCountWarningHide={onCriteriaObsCountWarningHide}
          territoryDimCriteria={territoryDimCriteria}
          fetchCodelist={(...params) => handleFetchCodelist(...params, additionalDataset.optimizedData)}
          codelistFetchError={codelistFetchError}
          fetchCodelistFull={fetchCodelistFull}
          onCodelistFullHide={onCodelistFullHide}
          missingFilterValues={missingFilterValues}
        />
      )}
    </Card>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(MultiViewerSidebar);
