import React, {forwardRef, Fragment, useEffect, useImperativeHandle, useRef, useState} from "react";
import AddIcon from "@mui/icons-material/Add";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  styled,
  Switch,
  TextField,
  Tooltip,
  Typography,
  useTheme
} from "@mui/material";
import Box from "@mui/material/Box";
import _ from "lodash";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {compose} from "redux";
import I18nTextField from "../i18n-text-field";
import {localizeI18nObj} from "../../utils/i18n";

const Item = styled(Paper)(({theme}) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.primary
}));

const titleStyle = {
  fontSize: "20px",
  fontWeight: "500px",
  letterSpacing: "0px",
  paddingBottom: "0px"
};

const mapStateToProps = state => ({
  languages: state.app.languages,
  defaultLanguage: state.app.language
});

const DashboardsManager = (
  {
    defaultLanguage,
    languages,
    dashboards,
    allDashboards,
    fetchDashboards,
    fetchAllDashboards,
    clearDashboards,
    clearAllDashboards
  },
  ref
) => {
  const [needFetch, setNeedFetch] = useState(true);
  const {t} = useTranslation();
  const theme = useTheme();

  const inputRef = useRef();

  const fieldStyle = {
    marginTop: theme.spacing(1),
    "& .MuiOutlinedInput-input": {
      padding: "18.5px 14px"
    }
  };

  const [categoryEnabled, setCategoryEnabled] = useState(false);
  const [categoryTitle, setCategoryTitle] = useState();
  const [categoryFakeId, setCategoryFakeId] = useState(0);

  const [selectedDashboards, setSelectedDashboards] = useState([]);
  const [allDashboardsList, setAllDashboardsList] = useState([]);
  const [allDashboardsListFiltered, setAllDashboardsListFiltered] = useState([]);

  const [categories, setCategories] = useState([]);
  const [openCategoryDialog, setOpenCategoryDialog] = useState(false);
  const [openCategoryManagementDialog, setOpenCategoryManagementDialog] = useState(false);
  const [currentCategory, setCurrentCategory] = useState();
  const [firstCategory, setFirstCategory] = useState(false);

  const [defaultDashboard, setDefaultDashboard] = useState(0);
  const [defaultTreeView, setDefaultTreeView] = useState(true);

  useImperativeHandle(ref, () => ({
    destroy(f) {
      clearDashboards();
      if (f) {
        f();
      }
    },
    submit(f) {
      const data = onSubmit();
      if (f) {
        f(data);
      }
      clearDashboards();
      clearAllDashboards();
    }
  }));

  useEffect(() => {
    if (needFetch) {
      setNeedFetch(false);
      fetchDashboards();
      fetchAllDashboards();
    }

    let slDashboards = [];

    if (dashboards && dashboards.defaultDashboardId) {
      setDefaultDashboard(dashboards.defaultDashboardId);
    }
    if (dashboards && dashboards.expandCategoryTree) {
      setDefaultTreeView(dashboards.expandCategoryTree);
    }

    if (dashboards && dashboards.categorizedDashboards && dashboards.categorizedDashboards.length > 0) {
      const catWithIds = dashboards.categorizedDashboards.map((c, index) => {
        return {categoryId: index + 1, ...c};
      });
      setCategories(catWithIds);
      setCategoryEnabled(true);

      let dl = [];
      catWithIds.forEach(c => {
        dl = [...dl, ...c.dashboards];
      });
      slDashboards = [...dl];
      setSelectedDashboards(slDashboards);
    }

    if (dashboards && dashboards.uncategorizedDashboards && dashboards.uncategorizedDashboards.length > 0) {
      slDashboards = dashboards.uncategorizedDashboards;
      setSelectedDashboards(slDashboards);
      setCategoryEnabled(false);
    }

    if (allDashboards && slDashboards) {
      const allDashFiltered = allDashboards.filter(ad => !slDashboards.map(({id}) => id).includes(ad.id));
      setAllDashboardsListFiltered(allDashFiltered);
      setAllDashboardsList(allDashFiltered);
    }
  }, [needFetch, allDashboards, dashboards, setNeedFetch, fetchDashboards, fetchAllDashboards]);

  const onSubmit = () => {
    const obj = {
      defaultDashboardId: defaultDashboard === 0 ? null : defaultDashboard,
      expandCategoryTree: defaultTreeView,
      uncategorizedDashboards: [],
      categorizedDashboards: []
    };
    if (categoryEnabled) {
      const catMapped = categories.map(c => {
        return {
          title: c.title,
          dashboards: c.dashboards.map(d => d.id)
        };
      });
      obj.categorizedDashboards = catMapped;
    } else {
      obj.uncategorizedDashboards = selectedDashboards.map(d => d.id);
    }

    return obj;
  };

  const handleCategoryEnabled = checked => {
    setCategoryEnabled(checked);
    if (checked && selectedDashboards.length > 0) {
      setFirstCategory(true);
      setOpenCategoryManagementDialog(true);
    } else {
      setCategories([]);
      setCategoryFakeId(0);
    }
  };

  const getNextCategoryFakeId = () => {
    const id = categoryFakeId - 1;
    setCategoryFakeId(id);
    return id;
  };

  const submitCategory = () => {
    if (currentCategory) {
      const cats = categories.map(c => {
        if (c.categoryId === currentCategory.categoryId) {
          c.title = categoryTitle;
          return c;
        } else {
          return c;
        }
      });
      setCategories([...cats]);
      setCurrentCategory(undefined);
    } else {
      const cat = {
        categoryId: getNextCategoryFakeId(),
        title: categoryTitle,
        dashboards: firstCategory ? [...selectedDashboards] : []
      };
      setCategories([...categories, cat]);
      setFirstCategory(false);
    }
    setCategoryTitle(undefined);
    setOpenCategoryManagementDialog(false);
  };

  const closeCategoryManagementDialog = () => {
    setCurrentCategory(undefined);
    setCategoryTitle(undefined);
    setOpenCategoryManagementDialog(false);
    if (firstCategory) {
      setFirstCategory(false);
      setCategoryEnabled(false);
    }
  };

  const editCategory = cat => {
    setCurrentCategory(cat);
    setCategoryTitle(cat.title);
    setOpenCategoryManagementDialog(true);
  };

  const deleteCategory = (index, category) => {
    if (category.dashboards && category.dashboards.length > 0) return;

    categories.splice(index, 1);
    setCategories([...categories]);
  };

  const moveCategory = (sourceIndex, destinationIndex) => {
    if (destinationIndex < 0) return;
    if (destinationIndex > categories.length - 1) return;
    const c = categories.splice(sourceIndex, 1);
    categories.splice(destinationIndex, 0, ...c);
    setCategories([...categories]);
  };

  const onSearch = _.debounce(searchString => {
    const newAllDashboardsListFiltered = [...allDashboardsListFiltered];

    const minSearchStrLength = 0;
    const trimmedSearchString = (searchString || "").trim();

    if (trimmedSearchString.length >= minSearchStrLength) {
      setAllDashboardsList(
        newAllDashboardsListFiltered.filter(dashboard =>
          (localizeI18nObj(dashboard.title, defaultLanguage, languages) || "")
            .toLowerCase()
            .includes(trimmedSearchString.toLowerCase())
        )
      );
    } else {
      setAllDashboardsList([...newAllDashboardsListFiltered]);
    }
  }, 250);

  const resetSearch = () => {
    inputRef.current.value = "";
    setAllDashboardsList([...allDashboardsListFiltered]);
  };

  const onDragEnd = result => {
    const {source, destination} = result;

    if (!destination) return;

    if (source.droppableId === "selectedDashboards" && destination.droppableId === "selectedDashboards") {
      const d = selectedDashboards.splice(source.index, 1);
      selectedDashboards.splice(destination.index, 0, ...d);
      setSelectedDashboards([...selectedDashboards]);
    }

    if (source.droppableId === "dashboards" && destination.droppableId === "selectedDashboards") {
      const d = allDashboardsList.splice(source.index, 1);
      selectedDashboards.splice(destination.index, 0, ...d);
      setSelectedDashboards([...selectedDashboards]);
      setAllDashboardsList([...allDashboardsList]);
      allDashboardsListFiltered.splice(source.index, 1);
      setAllDashboardsListFiltered([...allDashboardsListFiltered]);
    }

    if (source.droppableId === "selectedDashboards" && destination.droppableId === "dashboards") {
      const d = selectedDashboards.splice(source.index, 1);
      allDashboardsList.splice(destination.index, 0, ...d);
      setSelectedDashboards([...selectedDashboards]);
      setAllDashboardsList([...allDashboardsList]);
      allDashboardsListFiltered.splice(destination.index, 0, ...d);
      setAllDashboardsListFiltered([...allDashboardsListFiltered]);

      if (defaultDashboard === d[0].id) {
        setDefaultDashboard(0);
      }
    }

    if (source.droppableId === "dashboards" && destination.droppableId.startsWith("category")) {
      const d = allDashboardsList.splice(source.index, 1);
      const categoryId = destination.droppableId.split("_")[1];
      for (let index = 0; index < categories.length; index++) {
        const c = categories[index];
        if (c.categoryId === +categoryId) {
          categories[index].dashboards.splice(destination.index, 0, ...d);
          setSelectedDashboards([...selectedDashboards, ...d]);
          setCategories([...categories]);
          break;
        }
      }
    }

    if (source.droppableId.startsWith("category") && destination.droppableId === "dashboards") {
      const categoryId = source.droppableId.split("_")[1];
      for (let index = 0; index < categories.length; index++) {
        const c = categories[index];
        if (c.categoryId === +categoryId) {
          const d = categories[index].dashboards.splice(source.index, 1);
          allDashboardsList.splice(destination.index, 0, ...d);
          const slFiltered = selectedDashboards.filter(sl => sl.id !== d[0].id);

          if (defaultDashboard === d[0].id) {
            setDefaultDashboard(0);
          }

          setAllDashboardsList([...allDashboardsList]);
          allDashboardsListFiltered.splice(destination.index, 0, ...d);
          setAllDashboardsListFiltered([...allDashboardsListFiltered]);
          setSelectedDashboards([...slFiltered]);
          setCategories([...categories]);
          break;
        }
      }
    }

    if (source.droppableId.startsWith("category") && destination.droppableId.startsWith("category")) {
      const sourceCategoryId = source.droppableId.split("_")[1];
      for (let index = 0; index < categories.length; index++) {
        const c = categories[index];
        if (c.categoryId === +sourceCategoryId) {
          const d = categories[index].dashboards.splice(source.index, 1);

          const destinationCategoryId = destination.droppableId.split("_")[1];
          for (let index = 0; index < categories.length; index++) {
            const c = categories[index];
            if (c.categoryId === +destinationCategoryId) {
              categories[index].dashboards.splice(destination.index, 0, ...d);
              break;
            }
          }
          break;
        }
      }
      setCategories([...categories]);
    }
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    marginBottom: 4,
    marginTop: 4,
    ...draggableStyle
  });

  const handleDefaultDashboard = event => {
    setDefaultDashboard(event.target.value);
  };

  const handleDefaultTreeView = event => {
    setDefaultTreeView(event.target.value);
  };

  return (
    allDashboardsList !== null && (
      <Fragment>
        <Box className="dashboards-manager__table" style={{height: "100%"}}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Grid container spacing={2}>
              <Grid item sm={6} flexGrow={2}>
                <Box sx={{padding: "8px"}}>
                  <Box sx={{width: "100%", marginBottom: "30px", marginTop: "0px"}}>
                    <Typography sx={[titleStyle, {marginBottom: "0px", marginTop: "0px"}]} variant="h1">
                      {t("components.DashboardsCategoriesManager.listDashboard.title")}
                    </Typography>
                  </Box>
                  <Box sx={{display: "flex", alignItems: "center", marginBottom: "10px", width: "100%"}}>
                    <TextField
                      id="standard-basic"
                      fullWidth
                      inputRef={inputRef}
                      onChange={e => onSearch(e.target.value)}
                      className="mt-0"
                      margin="normal"
                      placeholder={t("components.DashboardsCategoriesManager.listDashboard.searchPlaceholder")}
                      variant="standard"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton size="small" onClick={() => resetSearch()}>
                              <ClearIcon />
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  </Box>
                </Box>
                <Droppable droppableId="dashboards">
                  {(provided, snapshot) => (
                    <Box
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      sx={{
                        padding: "8px",
                        backgroundColor: "#f0f0f0ab",
                        height: "calc(100vh - 330px)",
                        borderRadius: "8px",
                        overflowY: "auto",
                        overflowX: "hidden"
                      }}
                    >
                      <Stack id="dashboards-manager__table" spacing={2}>
                        {allDashboardsList.map((d, index) => (
                          <Draggable key={d.id} draggableId={`dashboards_${d.id}`} index={index}>
                            {(provided, snapshot) => (
                              <Item
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                              >
                                {localizeI18nObj(d.title, defaultLanguage, languages)}
                              </Item>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </Stack>
                    </Box>
                  )}
                </Droppable>
              </Grid>
              <Grid item sm={6}>
                <Box sx={{padding: "8px"}}>
                  <Grid container spacing={1} alignItems="center" marginBottom={1}>
                    <Grid item sm={categoryEnabled ? 6 : 12} width={"100%"}>
                      <FormControl variant="outlined" sx={fieldStyle} fullWidth>
                        <InputLabel id="dashboards_categories_manager_form_default_dashboard_label">
                          {t("components.DashboardsCategoriesManager.form.defaultDashboard.label")}
                        </InputLabel>
                        <Select
                          labelId="dashboards_categories_manager_form_default_dashboard_label"
                          id="dashboards_categories_manager_form_default_dashboard_select"
                          value={defaultDashboard}
                          label="Dashboard predefinita"
                          onChange={handleDefaultDashboard}
                        >
                          <MenuItem value={0}>
                            {t("components.DashboardsCategoriesManager.form.defaultDashboard.noValue")}
                          </MenuItem>
                          {selectedDashboards.map((sd, idx) => (
                            <MenuItem key={idx} value={sd.id}>
                              {localizeI18nObj(sd.title, defaultLanguage, languages)}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    {categoryEnabled && (
                      <Grid item sm={6} width={"100%"}>
                        <FormControl sx={fieldStyle} variant="outlined" fullWidth>
                          <InputLabel id="dashboards_categories_manager_form_default_tree_view_label">
                            {t("components.DashboardsCategoriesManager.form.defaultTreeView.label")}
                          </InputLabel>
                          <Select
                            labelId="dashboards_categories_manager_form_default_tree_view_label"
                            id="dashboards_categories_manager_form_default_tree_view_select"
                            value={defaultTreeView}
                            label={t("components.DashboardsCategoriesManager.form.defaultTreeView.label")}
                            onChange={handleDefaultTreeView}
                          >
                            <MenuItem value={true}>
                              {t("components.DashboardsCategoriesManager.form.defaultTreeView.open")}
                            </MenuItem>
                            <MenuItem value={false}>
                              {t("components.DashboardsCategoriesManager.form.defaultTreeView.close")}
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    )}
                  </Grid>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item flexGrow={1}>
                      <Typography sx={[titleStyle, {marginBottom: "8px", marginTop: "0px"}]} variant="h1">
                        {categoryEnabled
                          ? t("components.DashboardsCategoriesManager.selectedDashboards.categories")
                          : t("components.DashboardsCategoriesManager.selectedDashboards.selectedDashboards")}
                      </Typography>
                    </Grid>
                    {categoryEnabled && (
                      <Grid item>
                        <Button
                          id="dashboards_categories_manager_form_add_category_btn"
                          sx={{
                            ...theme.typography.button
                          }}
                          size="small"
                          onClick={() => {
                            setOpenCategoryManagementDialog(true);
                          }}
                          startIcon={<AddIcon />}
                        >
                          {t("components.DashboardsCategoriesManager.form.addCategory.label")}
                        </Button>
                      </Grid>
                    )}
                    <Grid item>
                      <FormControlLabel
                        control={
                          <Switch
                            id="dashboards_categories_manager_form_add_category_switch"
                            sx={{
                              "& .MuiButtonBase-root": {
                                position: "absolute",
                                color: categoryEnabled ? undefined : "#fff"
                              }
                            }}
                            size="small"
                            checked={categoryEnabled}
                            onChange={(event, checked) => {
                              categories.length > 1 ? setOpenCategoryDialog(true) : handleCategoryEnabled(checked);
                            }}
                          />
                        }
                        label={t("components.DashboardsCategoriesManager.form.categoryEnabled.label")}
                      />
                    </Grid>
                  </Grid>
                </Box>
                {categoryEnabled ? (
                  <Box
                    className="dashboards-manager__categories-scrollable-container"
                    sx={{
                      padding: "8px",
                      backgroundColor: "#f0f0f0ab",
                      borderRadius: "8px",
                      height: "calc(100vh - 330px)",
                      overflowY: "auto"
                    }}
                  >
                    <Grid
                      className="dashboards-manager__categories"
                      container
                      rowSpacing={1}
                      columnSpacing={{xs: 1, sm: 2, md: 3}}
                    >
                      {categories.map((g, index) => (
                        <Droppable key={g.categoryId} droppableId={`category_${g.categoryId}`}>
                          {(provided, snapshot) => (
                            <Grid
                              className="dashboards-manager__category"
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              item
                              xs={12}
                            >
                              <Card>
                                <CardHeader
                                  className="dashboards-manager__category-header"
                                  sx={[
                                    titleStyle,
                                    {
                                      "& .MuiCardHeader-content": {
                                        display: "block",
                                        overflow: "hidden"
                                      }
                                    },
                                    {borderBottom: "0.1rem solid lightgrey"}
                                  ]}
                                  titleTypographyProps={{
                                    noWrap: true,
                                    textOverflow: "ellipsis",
                                    variant: "h6"
                                  }}
                                  title={
                                    <Tooltip title={localizeI18nObj(g.title, defaultLanguage, languages)}>
                                      <span>{localizeI18nObj(g.title, defaultLanguage, languages)}</span>
                                    </Tooltip>
                                  }
                                  action={
                                    <>
                                      <Tooltip
                                        title={
                                          g.dashboards && g.dashboards.length > 0
                                            ? t(
                                                "components.DashboardsCategoriesManager.categoryList.cannotDeleteCategory.label"
                                              )
                                            : t(
                                                "components.DashboardsCategoriesManager.categoryList.deleteCategory.label"
                                              )
                                        }
                                      >
                                        <span>
                                          <IconButton
                                            id="dashboards_categories_manager_actions_delete_btn"
                                            onClick={() => deleteCategory(index, g)}
                                            disabled={g.dashboards && g.dashboards.length > 0}
                                            aria-label="delete"
                                          >
                                            <DeleteIcon fontSize="small" />
                                          </IconButton>
                                        </span>
                                      </Tooltip>
                                      <Tooltip
                                        title={t(
                                          "components.DashboardsCategoriesManager.categoryList.editCategory.label"
                                        )}
                                      >
                                        <span>
                                          <IconButton
                                            id="dashboards_categories_manager_actions_edit_btn"
                                            onClick={() => editCategory(g)}
                                            aria-label="edit"
                                          >
                                            <EditIcon fontSize="small" />
                                          </IconButton>
                                        </span>
                                      </Tooltip>
                                      <Tooltip
                                        title={t("components.DashboardsCategoriesManager.categoryList.moveUp.label")}
                                      >
                                        <span>
                                          <IconButton
                                            id="dashboards_categories_manager_actions_move_up_btn"
                                            onClick={() => moveCategory(index, index - 1)}
                                            disabled={index === 0}
                                            aria-label="up"
                                          >
                                            <ArrowUpwardIcon fontSize="small" />
                                          </IconButton>
                                        </span>
                                      </Tooltip>
                                      <Tooltip
                                        title={t("components.DashboardsCategoriesManager.categoryList.moveDown.label")}
                                      >
                                        <span>
                                          <IconButton
                                            id="dashboards_categories_manager_actions_move_down_btn"
                                            onClick={() => moveCategory(index, index + 1)}
                                            disabled={index === categories.length - 1}
                                            aria-label="down"
                                          >
                                            <ArrowDownwardIcon fontSize="small" />
                                          </IconButton>
                                        </span>
                                      </Tooltip>
                                    </>
                                  }
                                />
                                <CardContent>
                                  <Stack className="dashboards-manager__category-dashboards" spacing={2}>
                                    {g.dashboards.map((d, index) => (
                                      <Draggable
                                        key={`cat_${g.categoryId}_d_${d.id}`}
                                        draggableId={`category_${g.categoryId}_dash_${d.id}`}
                                        index={index}
                                      >
                                        {(provided, snapshot) => (
                                          <Item
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                          >
                                            {localizeI18nObj(d.title, defaultLanguage, languages)}
                                          </Item>
                                        )}
                                      </Draggable>
                                    ))}
                                    {provided.placeholder}
                                  </Stack>
                                </CardContent>
                              </Card>
                            </Grid>
                          )}
                        </Droppable>
                      ))}
                    </Grid>
                  </Box>
                ) : (
                  <Droppable droppableId="selectedDashboards">
                    {(provided, snapshot) => (
                      <Box
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        sx={{
                          padding: "8px",
                          backgroundColor: "#f0f0f0ab",
                          height: "calc(100vh - 320px)",
                          borderRadius: "8px",
                          overflowY: "auto",
                          overflowX: "hidden"
                        }}
                      >
                        <Stack spacing={2}>
                          {selectedDashboards.map((d, index) => (
                            <Draggable key={d.id} draggableId={`dashboards_${d.id}`} index={index}>
                              {(provided, snapshot) => (
                                <Item
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                >
                                  {localizeI18nObj(d.title, defaultLanguage, languages)}
                                </Item>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </Stack>
                      </Box>
                    )}
                  </Droppable>
                )}
              </Grid>
            </Grid>
          </DragDropContext>

          <Dialog
            open={openCategoryDialog}
            onClose={() => {
              setOpenCategoryDialog(false);
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Attenzione"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {t("components.DashboardsCategoriesManager.dialog.lostDataAlert")}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                id="dashboards-manager__dialog-close-btn"
                sx={{
                  ...theme.typography.button
                }}
                onClick={() => {
                  setOpenCategoryDialog(false);
                }}
              >
                Chiudi
              </Button>
              <Button
                id="dashboards-manager__dialog-continue-btn"
                sx={{
                  ...theme.typography.button
                }}
                onClick={() => {
                  setOpenCategoryDialog(false);
                  handleCategoryEnabled(false);
                }}
                autoFocus
              >
                Continua
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            id="dashboards-manager__new-category-dialog"
            open={openCategoryManagementDialog}
            onClose={closeCategoryManagementDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {currentCategory
                ? t("components.DashboardsCategoriesManager.dialog.editCategory")
                : t("components.DashboardsCategoriesManager.dialog.createCategory")}
            </DialogTitle>
            <DialogContent>
              {firstCategory && (
                <DialogContentText id="alert-dialog-description">
                  {t("components.DashboardsCategoriesManager.dialog.newCategory")}
                </DialogContentText>
              )}
              <FormControl fullWidth sx={fieldStyle}>
                <I18nTextField
                  name="categoryTitle"
                  label={t("components.DashboardsCategoriesManager.dialog.title")}
                  variant="outlined"
                  value={categoryTitle || {}}
                  onChange={value => setCategoryTitle(value)}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                id="dashboards-manager__new-category-dialog-close-btn"
                sx={{
                  ...theme.typography.button
                }}
                onClick={closeCategoryManagementDialog}
              >
                {t("components.DashboardsCategoriesManager.dialog.newCategoryClose")}
              </Button>
              <Button
                id="dashboards-manager__new-category-dialog-save-btn"
                sx={{
                  ...theme.typography.button
                }}
                onClick={submitCategory}
                autoFocus
              >
                {t("components.DashboardsCategoriesManager.dialog.newCategorySave")}
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Fragment>
    )
  );
};

export default compose(connect(mapStateToProps, null, null, {forwardRef: true}), forwardRef)(DashboardsManager);
