import React, {forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState} from "react";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import PersonIcon from "@mui/icons-material/Person";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {compose} from "redux";
import CustomDialogTitle from "../../custom-dialog-title";
import MaterialTable from "../../material-table";
import SettingsDialog from "../../settings-dialog";
import UserSettingsForm from "./user-settings-form";
import {UserRoles} from "../../../state/user/userReducer";
import {
  clearUsersConfigUsers,
  deleteUsersConfigUser,
  fetchUsersConfigUserPermissions,
  fetchUsersConfigUsers,
  hideUserConfigForm,
  showUserConfigForm,
  submitUsersConfigUserPermissions
} from "../../../state/usersConfig/usersConfigActions";
import {localizeI18nObj} from "../../../utils/i18n";
import "./style.css";

const mapStateToProps = state => ({
  config: state.usersConfig.users,
  userErrors: state.usersConfig.userErrors,
  userId: state.usersConfig.userId,
  appConfig: state.appConfig,
  languages: state.app.languages,
  language: state.app.language,
  userPermissions: state.usersConfig.permissions,
  modulesHubPermissions: state.app.modulesConfig.hubPermissions
});

const mapDispatchToProps = dispatch => ({
  fetchConfig: () => dispatch(fetchUsersConfigUsers()),
  deleteUser: userId => dispatch(deleteUsersConfigUser(userId)),
  clearConfig: () => dispatch(clearUsersConfigUsers()),
  onUserFormShow: userId => dispatch(showUserConfigForm(userId)),
  onUserFormHide: () => dispatch(hideUserConfigForm()),
  fetchUserPermissions: userId => dispatch(fetchUsersConfigUserPermissions(userId)),
  submitUserPermissions: (userId, permissions) => dispatch(submitUsersConfigUserPermissions(userId, permissions))
});

const UsersSettingsForm = (
  {
    config,
    fetchConfig,
    deleteUser,
    clearConfig,
    users,
    userErrors,
    userId,
    onUserFormShow,
    onUserFormHide,
    appConfig,
    languages,
    language,
    userPermissions,
    modulesHubPermissions,
    fetchUserPermissions,
    submitUserPermissions
  },
  ref
) => {
  const {t} = useTranslation();

  const [needConfig, setNeedConfig] = useState(true);

  useEffect(() => {
    if (needConfig) {
      setNeedConfig(false);
      fetchConfig();
    }
  }, [config, needConfig, setNeedConfig, fetchConfig]);

  useImperativeHandle(ref, () => ({
    cancel(f) {
      clearConfig();
      f();
    }
  }));

  const availablePermissions = useMemo(
    () => [
      {
        id: "ManageView",
        label: t("scenes.usersSettings.permissions.ManageView")
      },
      ...modulesHubPermissions
    ],
    [modulesHubPermissions, t]
  );

  const [userIdForPermissions, setUserIdForPermissions] = useState(null);
  const [userNewPermissions, setUserNewPermissions] = useState([]);
  useEffect(() => {
    setUserNewPermissions(userPermissions ?? []);
  }, [userPermissions]);

  const handleUserPermissionShow = userId => {
    setUserIdForPermissions(userId);
    fetchUserPermissions(userId);
  };

  const handleUserPermissionHide = () => {
    setUserIdForPermissions(null);
    setUserNewPermissions([]);
  };

  const handleUserPermissionSubmit = () => {
    setUserIdForPermissions(null);
    setUserNewPermissions([]);
    submitUserPermissions(userIdForPermissions, userNewPermissions);
  };

  const userFormRef = useRef();

  const [userDeleteFormUserId, setUserDeleteFormUserId] = useState(null);

  return (
    config && (
      <div className="users-settings-form__table" style={{height: "100%"}}>
        <MaterialTable
          idKey="userId"
          rightActions={[
            {
              id: "user-settings-form__create-user",
              label: t("scenes.usersSettings.createUser"),
              startIcon: <AddIcon />,
              onClick: () => onUserFormShow(-1)
            }
          ]}
          columns={[
            {
              field: "firstName",
              title: t("scenes.usersSettings.table.columns.userFirstName"),
              getClassName: () => "users-table__columns__user-first-name"
            },
            {
              field: "lastName",
              title: t("scenes.usersSettings.table.columns.userLastName"),
              getClassName: () => "users-table__columns__user-last-name"
            },
            {
              field: "type",
              title: t("scenes.usersSettings.table.columns.type"),
              render: ({type}) =>
                localizeI18nObj(appConfig.user.typeOptions?.find(({id}) => type === id)?.label, language, languages),
              getClassName: () => "users-table__columns__user-type"
            },
            {field: "organization", title: t("scenes.usersSettings.table.columns.userOrganization")},
            {
              field: "providerLabel",
              title: t("scenes.usersSettings.table.columns.userProvider"),
              getClassName: () => "users-table__columns__user-provider"
            },
            {
              field: "email",
              title: t("scenes.usersSettings.table.columns.userEmail"),
              getClassName: () => "users-table__columns__user-email"
            },
            {
              field: "isActive",
              title: t("scenes.usersSettings.table.columns.isActive.title"),
              render: ({isActive}) =>
                isActive
                  ? t("scenes.usersSettings.table.columns.isActive.values.true")
                  : t("scenes.usersSettings.table.columns.isActive.values.false"),
              getClassName: ({isActive}) =>
                `users-table__columns__isUserActive ${isActive ? "users-table__columns__isUserActive--true" : "users-table__columns__isUserActive--false"}`
            }
          ]}
          data={config}
          actions={[
            {
              icon: <EditIcon />,
              tooltip: t("scenes.usersSettings.table.actions.editUser"),
              onClick: (_, {userId}) => {
                onUserFormShow(userId);
              }
            },
            user => ({
              icon: <PersonIcon />,
              tooltip: t("scenes.usersSettings.table.actions.editPermissions"),
              onClick: (_, {userId}) => {
                handleUserPermissionShow(userId);
              },
              disabled: !user || (user.roles ?? []).includes(UserRoles.Administrator)
            }),
            {
              id: "user-settings-form__delete-user",
              icon: <DeleteIcon />,
              tooltip: t("scenes.usersSettings.table.actions.deleteUser"),
              onClick: (_, {userId}) => {
                setUserDeleteFormUserId(userId);
              }
            }
          ]}
        />
        <SettingsDialog
          title={
            userId === -1 ? t("scenes.usersSettings.modals.createUser") : t("scenes.usersSettings.modals.editUser")
          }
          open={userId !== null || userErrors !== null}
          onClose={() => {
            if (userFormRef.current) {
              userFormRef.current.cancel(() => {
                onUserFormHide();
              });
            } else {
              onUserFormHide();
            }
          }}
          onSubmit={() => {
            if (userFormRef.current) {
              userFormRef.current.submit(() => {});
            }
          }}
          hasSubmit
        >
          <UserSettingsForm ref={userFormRef} userId={userId} />
        </SettingsDialog>

        <Dialog
          id="user-settings-form__delete-user-dialog"
          maxWidth="xs"
          open={userDeleteFormUserId !== null}
          onClose={() => setUserDeleteFormUserId(null)}
        >
          <CustomDialogTitle onClose={() => setUserDeleteFormUserId(null)}>
            {t("scenes.usersSettings.modals.deleteUser.title")}
          </CustomDialogTitle>
          <DialogContent>{t("scenes.usersSettings.modals.deleteUser.content")}</DialogContent>
          <DialogActions>
            <Button
              id="user-settings-form__delete-user-dialog__cancel-button"
              autoFocus
              onClick={() => setUserDeleteFormUserId(null)}
            >
              {t("commons.confirm.cancel")}
            </Button>
            <Button
              id="user-settings-form__delete-user-dialog__confirm-button"
              onClick={() => {
                deleteUser(userDeleteFormUserId);
                setUserDeleteFormUserId(null);
              }}
            >
              {t("commons.confirm.confirm")}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          id="user-settings-form__user-permissions-dialog"
          maxWidth="sm"
          fullWidth
          open={userIdForPermissions !== null}
          onClose={handleUserPermissionHide}
        >
          <CustomDialogTitle onClose={handleUserPermissionHide}>
            {t("scenes.usersSettings.modals.userPermissions.title")}
          </CustomDialogTitle>
          <DialogContent>
            <Grid container spacing={1}>
              {(availablePermissions ?? []).map(({id, label}, idx) => (
                <Grid key={idx} item xs={12}>
                  <FormControlLabel
                    label={label}
                    disabled={id === "ManageView"}
                    control={
                      <Checkbox
                        id="user-settings-form__user-permissions-dialog__checkbox"
                        checked={userNewPermissions.includes(id)}
                        onChange={(ev, checked) => {
                          if (checked) {
                            setUserNewPermissions([...userPermissions, id]);
                          } else {
                            setUserNewPermissions(userPermissions.filter(permissionId => permissionId !== id));
                          }
                        }}
                      />
                    }
                  />
                </Grid>
              ))}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button id="user-settings-form__user-permissions-dialog__cancel-button" onClick={handleUserPermissionHide}>
              {t("commons.confirm.cancel")}
            </Button>
            <Button
              id="user-settings-form__user-permissions-dialog__confirm-button"
              onClick={handleUserPermissionSubmit}
            >
              {t("commons.confirm.confirm")}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  );
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true}),
  forwardRef
)(UsersSettingsForm);
