import React, {Fragment, useState} from "react";
import {Box} from "@mui/material";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Stepper from "@mui/material/Stepper";
import TextField from "@mui/material/TextField";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import Call from "../../../hocs/call";
import {NodeDataAccessibility, NodeVisibility} from "../../../model/IHubMinimalNode.d.ts";
import CategoriesTree from "../../categories-tree";
import Criteria from "../../criteria";
import CustomDialogTitle from "../../custom-dialog-title";
import CustomEmpty from "../../custom-empty";
import FullscreenDialog from "../../fullscreen-dialog";
import {ViewerMode} from "../../../state/dataset/constants";

const stepsStyle = {
  height: "108px"
};

const mapStateToProps = ({app, config, hub, node, dataset}) => ({
  isMultiViewerTheme: app.themeConfig.isMultiViewer,
  geoglossaryBaseUrl: config.externalServices?.geoglossary,
  nodes: hub.nodes,
  currentNode: node,
  mainDatasetId: dataset.commons.datasetId,
  mode: dataset.commons.mode,
  type: dataset.commons.type
});

const AdditionalDatasetCreateDialog = ({
  geoglossaryBaseUrl,
  isMultiViewerTheme,
  nodes,
  currentNode,
  mainDatasetId,
  mode,
  type,

  isOpen,
  onClose,
  defaultLastNPeriods,
  catalog,
  fetchCatalog,
  additionalDataset,
  fetchStructure,
  onDatasetChange,
  onDatasetSubmit,
  isObsCountWarningVisible,
  onObsCountWarningHide,
  territoryDimCriteria,
  fetchCodelist,
  codelistFetchError,
  fetchCodelistFull,
  onCodelistFullHide,
  missingFilterValues
}) => {
  const {t} = useTranslation();

  const [step, setStep] = useState(0);

  const [selectedNodeId, setSelectedNodeId] = useState(currentNode.nodeId);

  const [isCriteriaValid, setCriteriaValidity] = useState(true);

  const goToStepData = () => {
    setStep(0);
  };

  const goToStepFilters = () => {
    setStep(1);
  };

  const fetchCatalogBaseUrl = isMultiViewerTheme && geoglossaryBaseUrl ? geoglossaryBaseUrl : undefined;

  return (
    <Call
      cb={fetchCatalog}
      cbParam={{
        selectedNodeId: selectedNodeId,
        mainDatasetId: mainDatasetId,
        baseUrl: fetchCatalogBaseUrl
      }}
    >
      <FullscreenDialog open={isOpen} onClose={onClose}>
        <CustomDialogTitle onClose={onClose}>
          {t("components.additionalDatasetDialog.create.dialogs.wizard.title", {step: step + 1})}
        </CustomDialogTitle>
        <DialogContent>
          <Grid container justifyContent="center" sx={stepsStyle}>
            <Grid item xs={9}>
              <Stepper activeStep={step} nonLinear alternativeLabel sx={stepsStyle}>
                <Step>
                  <StepButton onClick={goToStepData}>
                    {t("components.additionalDatasetDialog.create.dialogs.wizard.steps.data.title")}
                  </StepButton>
                </Step>
                <Step>
                  <StepButton onClick={goToStepFilters} disabled={!additionalDataset?.datasetId}>
                    {t("components.additionalDatasetDialog.create.dialogs.wizard.steps.filter.title")}
                  </StepButton>
                </Step>
              </Stepper>
            </Grid>
          </Grid>
          <Box
            key={step}
            sx={{
              height: "calc(100% - 108px)",
              overflow: "hidden"
            }}
          >
            {step === 0 && (
              <Fragment>
                <FormControl
                  fullWidth
                  sx={{
                    marginBottom: "8px"
                  }}
                >
                  <TextField
                    select
                    value={selectedNodeId}
                    onChange={ev => setSelectedNodeId(ev.target.value)}
                    variant="outlined"
                    SelectProps={{SelectDisplayProps: {"aria-haspopup": true}}}
                  >
                    {nodes
                      .filter(
                        ({visible, dataAccess}) =>
                          visible === NodeVisibility.Yes || dataAccess === NodeDataAccessibility.Profiled
                      )
                      .map(({nodeId, name}, idx) => (
                        <MenuItem key={idx} value={nodeId}>
                          {name}
                        </MenuItem>
                      ))}
                  </TextField>
                </FormControl>
                <Box
                  sx={{
                    height: "calc(100% - 64px)",
                    overflow: "auto"
                  }}
                >
                  {!catalog ? (
                    <span />
                  ) : catalog.isEmpty ? (
                    <CustomEmpty text={t("components.additionalDatasetDialog.create.dialogs.emptyCatalog.content")} />
                  ) : (
                    <CategoriesTree
                      catalog={catalog}
                      showDatasetList
                      selectedDataset={additionalDataset?.datasetId}
                      onDatasetClick={datasetId => {
                        const node = nodes.find(({nodeId}) => nodeId === selectedNodeId);
                        onDatasetChange({
                          nodeId: node.nodeId,
                          nodeCode: node.code,
                          datasetId: datasetId,
                          datasetTitle: catalog?.datasetMap?.[datasetId]
                            ? catalog.datasetMap[datasetId].title
                            : catalog.uncategorizedDatasets.find(({identifier}) => identifier === datasetId).title
                        });
                      }}
                    />
                  )}
                </Box>
              </Fragment>
            )}
            {step === 1 && (
              <Call
                cb={fetchStructure}
                cbParam={{
                  nodeId: selectedNodeId,
                  datasetId: additionalDataset.datasetId
                }}
              >
                {additionalDataset.dimensions ? (
                  <Criteria
                    viewerMode={ViewerMode.MultiViewer}
                    nodeId={additionalDataset.nodeId}
                    nodeCode={additionalDataset.nodeCode}
                    datasetId={additionalDataset.datasetId}
                    dimensions={additionalDataset.dimensions}
                    timeDim={additionalDataset.timeDim}
                    freqDim={additionalDataset.freqDim}
                    territoryDim={additionalDataset.territoryDim}
                    hideTerritoryDim
                    mode={mode}
                    type={type}
                    criteria={additionalDataset.criteria}
                    territoryDimCriteria={territoryDimCriteria}
                    onSetCriteria={criteria =>
                      onDatasetChange({
                        criteria: criteria
                      })
                    }
                    codelists={additionalDataset.codelists}
                    codelistsLength={additionalDataset.codelistsLength}
                    fetchCodelist={fetchCodelist}
                    codelistFetchError={codelistFetchError}
                    isCriteriaValid={isCriteriaValid}
                    setCriteriaValidity={setCriteriaValidity}
                    isObsCountWarningVisible={isObsCountWarningVisible}
                    onCriteriaObsCountWarningHide={onObsCountWarningHide}
                    onSubmit={onDatasetSubmit}
                    defaultLastNPeriods={defaultLastNPeriods}
                    preserveFiltersWithDynamic
                    missingFilterValues={missingFilterValues}
                    fetchCodelistFull={fetchCodelistFull}
                    onCodelistFullHide={onCodelistFullHide}
                  />
                ) : (
                  <span />
                )}
              </Call>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          {step === 0 && (
            <Fragment>
              <Button onClick={onClose}>{t("commons.confirm.cancel")}</Button>
              <Button autoFocus color="primary" onClick={goToStepFilters} disabled={!additionalDataset?.datasetId}>
                {t("commons.confirm.next")}
              </Button>
            </Fragment>
          )}
          {step === 1 && (
            <Grid container justifyContent="space-between">
              <Grid item>
                <Button color="primary" onClick={goToStepData}>
                  {t("commons.confirm.back")}
                </Button>
              </Grid>
              <Grid item>
                <Button onClick={onClose}>{t("commons.confirm.cancel")}</Button>
                <Button
                  id="additional_datasets_dialogs_create_criteria_confirm_btn "
                  autoFocus
                  color="primary"
                  onClick={onDatasetSubmit}
                  disabled={!isCriteriaValid}
                >
                  {t("commons.confirm.apply")}
                </Button>
              </Grid>
            </Grid>
          )}
        </DialogActions>
      </FullscreenDialog>
    </Call>
  );
};

export default connect(mapStateToProps)(AdditionalDatasetCreateDialog);
