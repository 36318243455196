import {RefObject, useEffect, useRef, useState} from "react";

export default function useOnScreen(ref: RefObject<HTMLElement>) {
  const observerRef = useRef<IntersectionObserver | null>(null);
  const [isOnScreen, setIsOnScreen] = useState<boolean>(false);

  useEffect(() => {
    observerRef.current = new IntersectionObserver(([entry]) => setIsOnScreen(entry.isIntersecting), {
      root: null,
      rootMargin: "0px",
      threshold: 0.1
    });
  }, []);

  useEffect(() => {
    if (observerRef.current) {
      observerRef.current.observe(ref.current);
    }
    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [ref]);

  return isOnScreen;
}
