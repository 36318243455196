import React, {Fragment, useCallback, useEffect, useMemo, useState} from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FolderIcon from "@mui/icons-material/Folder";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import HeightIcon from "@mui/icons-material/Height";
import StorageIcon from "@mui/icons-material/Storage";
import VerticalAlignCenterIcon from "@mui/icons-material/VerticalAlignCenter";
import {Box, Button, Grid, IconButton, Tooltip} from "@mui/material";
import _ from "lodash";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {Virtuoso} from "react-virtuoso";
import {compose} from "redux";
import {getDatasetsInternalUrl} from "../../links";
import CatalogInfoButton from "../catalog-info-button";
import CustomLink from "../custom-link";
import "./style.css";

const PATH_SEPARATOR = "+";

const CategoriesTree = ({
  t,
  themeConfig,
  node,
  catalog,
  initialPath,
  showDatasetList = false,
  showExpandControls = false,
  showCategoriesFirst = false,
  selectedCategories,
  selectedDataset,
  onClose,
  onDatasetClick
}) => {
  const [allData, setAllData] = useState(null); // whole catalog presented as a flat list
  const [visibleData, setVisibleData] = useState(null); // current visible data (portion of allData)

  const [expandedNodes, setExpandedNodes] = useState({});
  const [cachedExpandedNodes, setCachedExpandedNodes] = useState({});

  // function that formats a category and its children, both categories and datasets
  const getFormattedCategory = useCallback(
    category => {
      let children = [];
      let formattedCategories = [];
      let formattedDatasets = [];

      if (category.childrenCategories && category.childrenCategories.length > 0) {
        formattedCategories = (category?.childrenCategories || []).map(childCategory => ({
          ...getFormattedCategory(childCategory),
          parentId: category.id
        }));
      }

      if ((category?.datasetIdentifiers || []).length > 0) {
        if (themeConfig.showDatasetListInCategoriesTree || showDatasetList) {
          formattedDatasets = children.concat(
            category.datasetIdentifiers.map(id => ({
              ...catalog.datasetMap[id],
              type: "dataset",
              id: Object.keys(catalog.datasetMap).find(key => key === id),
              parentId: category.id,
              label: catalog.datasetMap[id].title,
              source: catalog.datasetMap[id].source,
              description: catalog.datasetMap[id].description,
              attachments: catalog.datasetMap[id].attachedDataFiles,
              isDatasetOnlyFile: catalog.datasetMap[id].datasetType === "onlyFile",
              isDatasetWithDashboard: catalog.datasetMap[id].datasetType === "dashboard"
            }))
          );
        } else {
          formattedDatasets = children.concat([
            {
              id: "",
              type: "dataset",
              label: t("components.categoriesTree.goToData", {datasetCount: category.datasetIdentifiers.length})
            }
          ]);
        }
      }

      children = showCategoriesFirst
        ? children.concat(formattedCategories, formattedDatasets)
        : children.concat(formattedDatasets, formattedCategories);

      return {
        ...category,
        children: children,
        childrenCategories: null,
        datasetIdentifiers: null
      };
    },
    [t, catalog.datasetMap, showCategoriesFirst, showDatasetList, themeConfig.showDatasetListInCategoriesTree]
  );

  // function that creates the category tree from the catalog, also adding the root level datasets to the root of the tree
  const getFormattedCatalogTree = useCallback(
    catalog => {
      const rootCatalogCategories =
        catalog.categoryGroups.length > 0
          ? catalog.hasCategorySchemes
            ? catalog.categoryGroups.map(({id, label, categories}) => ({id, label, childrenCategories: categories}))
            : catalog.categoryGroups[0].categories
          : [];

      const formattedCatalog = rootCatalogCategories.map(rootCategory => getFormattedCategory(rootCategory));

      (catalog?.rootDatasets || []).forEach(dataset =>
        formattedCatalog.push({
          ...dataset,
          type: "dataset",
          id: dataset.identifier,
          parentId: dataset.categoryPath[1],
          label: dataset.title,
          source: dataset.source,
          description: dataset.description,
          attachments: dataset.attachedDataFiles,
          isDatasetOnlyFile: dataset.datasetType === "onlyFile",
          isDatasetWithDashboard: dataset.datasetType === "dashboard"
        })
      );

      return formattedCatalog;
    },
    [getFormattedCategory]
  );

  // function that formats uncategorized datasets
  const getFormattedUncategorizedDatasets = useCallback(
    datasets => {
      return themeConfig.showDatasetListInCategoriesTree || showDatasetList
        ? (datasets || []).map(dataset => ({
            ...dataset,
            type: "dataset",
            id: dataset.identifier,
            label: dataset.title,
            parentId: "uncategorized"
          }))
        : [
            {
              id: "",
              type: "dataset",
              label: t("components.categoriesTree.goToData", {
                datasetCount: datasets.length
              })
            }
          ];
    },
    [t, showDatasetList, themeConfig]
  );

  // formatted tree containing special "uncategorized" category for uncategorized datasets
  const catalogTree = useMemo(() => {
    const catalogTree = getFormattedCatalogTree(catalog);
    if ((catalog?.uncategorizedDatasets || []).length > 0) {
      catalogTree.push({
        id: "uncategorized",
        label: t("commons.catalog.uncategorized"),
        children: getFormattedUncategorizedDatasets(catalog.uncategorizedDatasets)
      });
    }
    return catalogTree;
  }, [t, catalog, getFormattedCatalogTree, getFormattedUncategorizedDatasets]);

  // function that creates a flat list with data from the whole catalog
  const getAllData = useCallback(
    tree => {
      let data = [];

      const traverseTree = (nodes, parent = null) => {
        nodes.forEach(item => {
          const path = parent
            ? `${parent.path}${PATH_SEPARATOR}${item.id}`
            : [...(initialPath || []), item.id].join(PATH_SEPARATOR);

          const node = {
            ...item,
            id: `${item.id}`,
            parentId: parent ? parent.id : undefined,
            children: null,
            hasChildren: item.children && item.children.length > 0,
            path: path,
            level: parent ? path.split(PATH_SEPARATOR).length - 1 - (initialPath || []).length : 0
          };

          data.push(node);

          if ((item?.children || []).length > 0) {
            traverseTree(item.children, node);
          }
        });
      };

      traverseTree(tree);

      return data;
    },
    [initialPath]
  );

  // funtion that return the list of expanded nodes
  const getVisibleData = useCallback((allData, idsToShow, expandedNodes) => {
    const newExpandedNodes = _.cloneDeep(expandedNodes);
    const newVisibleData = [];

    allData.forEach((data, _, arr) => {
      if (data.level === 0) {
        newVisibleData.push({...data});
        if (data.hasChildren && idsToShow.includes(data.id)) {
          newExpandedNodes[data.id] = true;
        } else {
          delete newExpandedNodes[data.id];
        }
      }
      if (data.hasChildren && idsToShow.includes(data.id)) {
        const children = arr.filter(a => a.parentId === data.id);
        const index = newVisibleData.findIndex(element => element.id === data.id);
        if (index !== -1) {
          newExpandedNodes[newVisibleData[index].id] = true;
          newVisibleData.splice(+index + 1, 0, ...children);
        }
      }
    });

    setExpandedNodes(newExpandedNodes);
    return newVisibleData;
  }, []);

  const getInitialVisibleData = useCallback(allData => {
    const firstLevelData = allData.filter(data => data.level === 0);
    return firstLevelData;
  }, []);

  useEffect(() => {
    if (catalogTree) {
      const newAllData = getAllData(catalogTree);
      setAllData(newAllData);
    }
  }, [catalogTree, getAllData]);

  useEffect(() => {
    if (allData) {
      if (selectedCategories) {
        const idsToShow = [];
        const expandedNodes = {};
        selectedCategories.forEach(categoryId => {
          expandedNodes[categoryId] = true;
          const category = allData.find(data => data.id === categoryId);
          if (category) {
            const pathSplitted = category.path.split(PATH_SEPARATOR);
            pathSplitted.forEach(ps => {
              if (!idsToShow.includes(ps)) {
                idsToShow.push(ps);
              }
            });
          }
        });

        const newVisibleData = getVisibleData(allData, idsToShow, expandedNodes);
        setVisibleData(newVisibleData);
      } else {
        setExpandedNodes({});
        setCachedExpandedNodes({});
        const newVisibleData = getInitialVisibleData(allData);
        setVisibleData(newVisibleData);
      }
    }
  }, [allData, selectedCategories, getVisibleData, getInitialVisibleData]);

  const onExpandOrCollapse = useCallback(
    (index, node) => {
      const newExpandedNodes = _.cloneDeep(expandedNodes);

      if (!newExpandedNodes[visibleData[index].id]) {
        // expand case

        const children = cachedExpandedNodes[node.id]
          ? cachedExpandedNodes[node.id]
          : allData.filter(data => data.parentId === node.id);

        delete cachedExpandedNodes[node.id];
        setCachedExpandedNodes(cachedExpandedNodes);

        const newVisibleData = _.cloneDeep(visibleData);
        newVisibleData.splice(+index + 1, 0, ...children);
        setVisibleData(newVisibleData);

        newExpandedNodes[newVisibleData[index].id] = true;
      } else {
        // collapse case

        delete newExpandedNodes[visibleData[index].id];
        const descendants = visibleData.filter(data => data.path.startsWith(`${node.path}${PATH_SEPARATOR}`));
        const newCachedExpanded = {[node.id]: descendants, ...cachedExpandedNodes};
        setCachedExpandedNodes(newCachedExpanded);

        const newVisibleData = visibleData.filter(data => !data.path.startsWith(`${node.path}${PATH_SEPARATOR}`));
        setVisibleData(newVisibleData);
      }

      setExpandedNodes(newExpandedNodes);
    },
    [allData, visibleData, expandedNodes, cachedExpandedNodes]
  );

  const onExpandAll = useCallback(() => {
    const newExpandedNodes = _.cloneDeep(expandedNodes);
    const newVisibleData = [];
    allData.forEach(data => {
      newVisibleData.push(_.cloneDeep(data));
      if (data.hasChildren) {
        newExpandedNodes[data.id] = true;
      } else {
        delete newExpandedNodes[data.id];
      }
    });
    setExpandedNodes(newExpandedNodes);
    setVisibleData(newVisibleData);
  }, [allData, expandedNodes]);

  const onCollapseAll = useCallback(() => {
    setExpandedNodes({});
    setCachedExpandedNodes({});
    const newVisibleData = getInitialVisibleData(allData);
    setVisibleData(newVisibleData);
  }, [allData, getInitialVisibleData]);

  return (
    <Box className="categories-tree" sx={{color: theme => theme.palette.text.primary}}>
      {showExpandControls && catalog.categoryGroups.length > 0 && (
        <Grid container spacing={1} sx={{marginBottom: "2px"}} justifyContent="flex-end">
          <Grid item>
            <Tooltip title={t("components.categoriesTree.expandAll.tooltip")}>
              <IconButton
                aria-label={t("components.categoriesTree.expandAll.ariaLabel")}
                sx={{padding: "8px"}}
                onClick={() => onExpandAll()}
              >
                <HeightIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title={t("components.categoriesTree.collapseAll.tooltip")}>
              <IconButton
                aria-label={t("components.categoriesTree.collapseAll.ariaLabel")}
                sx={{padding: "8px"}}
                onClick={() => onCollapseAll()}
              >
                <VerticalAlignCenterIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      )}
      <Box className="v-tree-tree-container">
        <Virtuoso
          data={visibleData}
          style={{height: "600px"}}
          itemContent={(index, d) => {
            return (
              <Box
                className={`v-tree-item-container ${[selectedDataset, ...(selectedCategories || [])].includes(d.id) ? "v-tree-item-container--selected" : ""}`}
                sx={{
                  marginLeft: `${d.level * 15}px`,
                  cursor: `pointer`
                }}
                onClick={() => onExpandOrCollapse(index, d)}
              >
                {d.type !== "dataset" && d.hasChildren ? (
                  <Box className="v-tree-item-container-icon">
                    {expandedNodes[d.id] || false ? (
                      <ExpandMoreIcon fontSize="small" />
                    ) : (
                      <ChevronRightIcon fontSize="small" />
                    )}
                  </Box>
                ) : (
                  <Box className="v-tree-item-container-icon-placeholder" />
                )}
                {d.type === "dataset" ? (
                  <Fragment>
                    {onDatasetClick ? (
                      <Button
                        onClick={() => (!d.isDatasetOnlyFile ? onDatasetClick(d.id) : null)}
                        disabled={d.isDatasetOnlyFile}
                        startIcon={
                          d.isDatasetWithDashboard ? (
                            <DashboardIcon fontSize="small" className="categories-tree__tree-item__dataset__icon" />
                          ) : (
                            <StorageIcon fontSize="small" className="categories-tree__tree-item__dataset__icon" />
                          )
                        }
                        className="categories-tree__tree-item__dataset__button"
                      >
                        {d.label}
                      </Button>
                    ) : (
                      <CustomLink
                        to={getDatasetsInternalUrl(node.code.toLowerCase(), d.path.split(PATH_SEPARATOR))}
                        text={<i className="categories-tree__tree-item__dataset__label">{d.label}</i>}
                        icon={
                          d.isDatasetWithDashboard ? (
                            <DashboardIcon fontSize="small" className="categories-tree__tree-item__dataset__icon" />
                          ) : (
                            <StorageIcon fontSize="small" className="categories-tree__tree-item__dataset__icon" />
                          )
                        }
                        textStyle={{
                          width: "calc(100% - 24px)",
                          paddingLeft: "1px"
                        }}
                        onClick={onClose}
                        disabled={d.isDatasetOnlyFile}
                      />
                    )}
                    {(d.source || d.description || (d.attachments && d.attachments.length > 0)) && (
                      <Box
                        sx={{marginLeft: "4px", "& button": {padding: "4px"}}}
                        className={"categories-tree__tree-item__dataset__action"}
                      >
                        <CatalogInfoButton
                          title={d.label}
                          source={d.source}
                          description={d.description}
                          attachments={d.attachments}
                        />
                      </Box>
                    )}
                  </Fragment>
                ) : (
                  <Fragment>
                    {expandedNodes[d.id] || false ? (
                      <FolderOpenIcon sx={{marginRight: "8px"}} className="categories-tree__tree-item__node__icon" />
                    ) : (
                      <FolderIcon sx={{marginRight: "8px"}} className="categories-tree__tree-item__node__icon" />
                    )}
                    <span className="v-tree-item-container-label" style={{cursor: `pointer`}}>
                      {d.label}
                    </span>
                  </Fragment>
                )}
              </Box>
            );
          }}
        />
      </Box>
    </Box>
  );
};

export default compose(
  withTranslation(),
  connect(state => ({
    themeConfig: state.app.themeConfig,
    nodeCatalog: state.catalog
  }))
)(CategoriesTree);
