import {ItemContainerType, ItemContainerTypes} from "../../model/item-containers-models/itemContainerType";
import {ItemViewTemplateDto} from "../../model/item-containers-models/itemViewTemplateDto";
import {
  getCustomPageUrl,
  getDashboardUrl,
  getDatasetCustomDownloadUrl,
  getDatasetDownloadUrl,
  getDatasetUrl,
  getWidgetDatasetCustomDownloadUrl,
  getWidgetDatasetDownloadUrl,
  getWidgetDatasetUrl
} from "../../serverApi/urls";
import {external} from "../../middlewares/external-service-redirect-middleware/middleware";
import {initRequest, RequestMethod} from "../../middlewares/request/requestActions";
import {getCriteriaArrayFromObject} from "../../utils/criteria";
import {
  DOWNLOAD_FORMAT_CSV,
  DOWNLOAD_FORMAT_EXCEL,
  downloadFormats,
  getCustomExportRequestBody
} from "../../utils/download";

export const ITEMCONTAINERS_ITEMCONTAINER_FETCH = "itemContainers/itemContainer/id";
export const ITEMCONTAINERS_DATASET_FETCH = "itemContainers/dataset/fetch";
export const ITEMCONTAINERS_DATASET_DOWNLOAD_SUBMIT = "itemContainer/dataset/download/submit";

export const fetchItemContainerById = (
  itemContainerId: number,
  type: "dashboard" | "customPages",
  fetchFullInfo = false
) => {
  return external(
    initRequest(
      ITEMCONTAINERS_ITEMCONTAINER_FETCH,
      type === "dashboard"
        ? getDashboardUrl(itemContainerId, fetchFullInfo)
        : getCustomPageUrl(itemContainerId, fetchFullInfo),
      RequestMethod.GET,
      undefined,
      t => ({
        onStart:
          type === "dashboard"
            ? t("scenes.itemContainers.actions.fetchingDashboard")
            : t("scenes.itemContainers.actions.fetchingCustomPages")
      }),
      undefined,
      undefined,
      () => true
    ),
    type
  );
};

export const fetchItemContainerViewDataset = (
  item: ItemViewTemplateDto,
  widgetId: string,
  itemContainerType: ItemContainerType,
  coreDataRetrieverUrl?: string
) => {
  return initRequest(
    ITEMCONTAINERS_DATASET_FETCH + item.nodeId + item.viewTemplate.datasetId,
    (itemContainerType === ItemContainerTypes.Widget || itemContainerType === ItemContainerTypes.WidgetDashboard) &&
      !coreDataRetrieverUrl
      ? getWidgetDatasetUrl(widgetId, item.itemId, item.viewTemplate?.lastNObservations)
      : getDatasetUrl(item.nodeId, item.viewTemplate.datasetId, item.viewTemplate?.lastNObservations),
    RequestMethod.POST,
    getCriteriaArrayFromObject(item.viewTemplate.criteria),
    t => ({
      onStart: t("scenes.itemContainers.actions.fetchingDataset")
    }),
    null,
    coreDataRetrieverUrl || "",
    () => true,
    true
  );
};

export const submitItemContainerDatasetDownload = (
  nodeId: number,
  datasetId: string,
  datasetTitle: string,
  criteria: any,
  layout: any,
  format: string,
  extension: string,
  zipped: boolean,
  params: any,
  defaultLanguage: string,
  languages: string[],
  t: any,
  itemId: number,
  widgetId: string,
  itemContainerType: ItemContainerType,
  lastNObservations?: number,
  coreDataRetrieverUrl?: string
) => {
  let url, body;
  if (format === DOWNLOAD_FORMAT_EXCEL || format === DOWNLOAD_FORMAT_CSV) {
    url =
      (itemContainerType === ItemContainerTypes.Widget || itemContainerType === ItemContainerTypes.WidgetDashboard) &&
      !coreDataRetrieverUrl
        ? getWidgetDatasetCustomDownloadUrl(widgetId, itemId, format, zipped, lastNObservations)
        : getDatasetCustomDownloadUrl(nodeId, format, zipped, lastNObservations);
    body = getCustomExportRequestBody(
      nodeId,
      datasetId,
      datasetTitle,
      criteria,
      layout,
      params,
      defaultLanguage,
      languages,
      t
    );
  } else {
    // @ts-ignore
    const formatParam = downloadFormats()[format]?.param || format;
    url =
      (itemContainerType === ItemContainerTypes.Widget || itemContainerType === ItemContainerTypes.WidgetDashboard) &&
      !coreDataRetrieverUrl
        ? getWidgetDatasetDownloadUrl(widgetId, itemId, formatParam, zipped, lastNObservations)
        : getDatasetDownloadUrl(nodeId, datasetId, formatParam, zipped, lastNObservations);
    body = getCriteriaArrayFromObject(criteria);
  }

  const type = format === DOWNLOAD_FORMAT_EXCEL ? "application/vnd.ms-excel" : "text/plain;charset=utf-8";

  return initRequest(
    ITEMCONTAINERS_DATASET_DOWNLOAD_SUBMIT,
    url,
    RequestMethod.POST,
    body,
    t => ({
      onStart: t("scenes.dataset.actions.downloadingDataset")
    }),
    {
      fileSave: {
        name: `${datasetTitle} (${datasetId})`,
        extension: extension,
        type: type
      }
    },
    coreDataRetrieverUrl || undefined,
    (statusCode: number) => statusCode === 406,
    undefined,
    undefined,
    true
  );
};
