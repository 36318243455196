import React, {Fragment} from "react";
import {connect, useSelector} from "react-redux";
import {HashRouter, Route, Routes} from "react-router-dom";
import Error from "../components/error";
import ExternalLogin from "../components/external-login";
import Home from "../components/home";
import ModulePage from "../components/module-page";
import Node from "../components/node";
import WithCustomTheme from "../components/with-custom-theme";
import A11yDomain from "../domains/A11yDomain";
import AppDomain from "../domains/AppDomain";
import ConfirmEmailDomain from "../domains/ConfirmEmailDomain";
import HubDomain from "../domains/HubDomain";
import ItemContainersDomain from "../domains/ItemContainersDomain";
import MultiLanguageDomain from "../domains/MultiLanguageDomain";
import NodeDomain from "../domains/NodeDomain";
import ResetPasswordDomain from "../domains/ResetPasswordDomain";
import Call from "../hocs/call";
import NodeRouter from "./NodeRouter";
import {hubCustomHomePageIdSelector} from "../state/hub/hubSelectors";
import {nodeCustomHomePageIdSelector} from "../state/node/nodeSelectors";
import {showUserLoginForm} from "../state/user/userActions";

const mapStateToProps = state => ({
  hub: state.hub,
  node: state.node,
  modulesConfig: state.app.modulesConfig,
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  onLoginFormShow: () => dispatch(showUserLoginForm())
});

const HubRouter = ({hub, node, modulesConfig, user, onLoginFormShow}) => {
  const defaultNode = (hub?.nodes || []).find(node => node.default === true) || null;
  const isDefault = (node && defaultNode && node.nodeId === defaultNode.nodeId) || false;
  const nodeCode = node ? node.code : null;

  const hubHomePageId = useSelector(hubCustomHomePageIdSelector);
  const defaultNodeHomePageId = useSelector(nodeCustomHomePageIdSelector(defaultNode?.nodeId ?? -1));

  return (
    <HashRouter>
      <AppDomain>
        <WithCustomTheme nodeCode={nodeCode}>
          <A11yDomain>
            <Routes>
              <Route
                path="/"
                element={
                  <HubDomain>
                    {defaultNode !== null ? (
                      <NodeDomain nodeCode={defaultNode.code}>
                        {defaultNodeHomePageId ? (
                          <ItemContainersDomain
                            itemContainerId={defaultNodeHomePageId}
                            hideBreadcrumbs
                            nodeCode={nodeCode}
                            isDefault={isDefault}
                          />
                        ) : (
                          <Node nodeCode={defaultNode.code} isDefault />
                        )}
                      </NodeDomain>
                    ) : hubHomePageId ? (
                      <ItemContainersDomain
                        itemContainerId={hubHomePageId}
                        hideBreadcrumbs
                        nodeCode={nodeCode}
                        isDefault={isDefault}
                      />
                    ) : (
                      <Home />
                    )}
                  </HubDomain>
                }
              />
              <Route path="/auth/shibboleth" element={<ExternalLogin />} />
              <Route
                path="/error"
                element={
                  <HubDomain>
                    <Error />
                  </HubDomain>
                }
              />
              <Route
                path="/:lang"
                element={
                  <MultiLanguageDomain>
                    <HubDomain>
                      {defaultNode !== null ? (
                        <NodeDomain nodeCode={defaultNode.code}>
                          {defaultNodeHomePageId ? (
                            <ItemContainersDomain
                              itemContainerId={defaultNodeHomePageId}
                              hideBreadcrumbs
                              nodeCode={nodeCode}
                              isDefault={isDefault}
                            />
                          ) : (
                            <Node nodeCode={defaultNode.code} isDefault />
                          )}
                        </NodeDomain>
                      ) : hubHomePageId ? (
                        <ItemContainersDomain
                          itemContainerId={hubHomePageId}
                          hideBreadcrumbs
                          nodeCode={nodeCode}
                          isDefault={isDefault}
                        />
                      ) : (
                        <Home />
                      )}
                    </HubDomain>
                  </MultiLanguageDomain>
                }
              />
              {modulesConfig.modules.includes("dashboard") && (
                <Route
                  path="/:lang/dashboards"
                  element={
                    <MultiLanguageDomain>
                      <HubDomain>
                        <ItemContainersDomain />
                      </HubDomain>
                    </MultiLanguageDomain>
                  }
                />
              )}
              {modulesConfig.modules.includes("dashboard") && (
                <Route
                  path="/:lang/dashboards/:id"
                  element={
                    <MultiLanguageDomain>
                      <HubDomain>
                        <ItemContainersDomain />
                      </HubDomain>
                    </MultiLanguageDomain>
                  }
                />
              )}
              {modulesConfig.modules.includes("custom-page") && (
                <Route
                  path="/:lang/pages/:id"
                  element={
                    <MultiLanguageDomain>
                      <HubDomain>
                        <ItemContainersDomain />
                      </HubDomain>
                    </MultiLanguageDomain>
                  }
                />
              )}
              <Route
                path="/:lang/resetPassword"
                element={
                  <MultiLanguageDomain>
                    <ResetPasswordDomain>
                      {defaultNode !== null ? (
                        <HubDomain>
                          <NodeDomain nodeCode={defaultNode.code}>
                            <Node nodeCode={defaultNode.code} isDefault />
                          </NodeDomain>
                        </HubDomain>
                      ) : (
                        <HubDomain>
                          <Home />
                        </HubDomain>
                      )}
                    </ResetPasswordDomain>
                  </MultiLanguageDomain>
                }
              />
              <Route
                path="/:lang/confirmEmail"
                element={
                  <MultiLanguageDomain>
                    <ConfirmEmailDomain>
                      <Fragment>
                        {defaultNode !== null ? (
                          <HubDomain>
                            <NodeDomain nodeCode={defaultNode.code}>
                              <Node nodeCode={defaultNode.code} isDefault />
                            </NodeDomain>
                          </HubDomain>
                        ) : (
                          <HubDomain>
                            <Home />
                          </HubDomain>
                        )}
                      </Fragment>
                    </ConfirmEmailDomain>
                  </MultiLanguageDomain>
                }
              />
              <Route
                path="/:lang/auth"
                element={
                  <MultiLanguageDomain>
                    <Call cb={onLoginFormShow} disabled={user.isAuthenticated}>
                      <Fragment>
                        {defaultNode !== null ? (
                          <HubDomain>
                            <NodeDomain nodeCode={defaultNode.code}>
                              <Node nodeCode={defaultNode.code} isDefault />
                            </NodeDomain>
                          </HubDomain>
                        ) : (
                          <HubDomain>
                            <Home />
                          </HubDomain>
                        )}
                      </Fragment>
                    </Call>
                  </MultiLanguageDomain>
                }
              />
              {(modulesConfig.hubRoutes || []).map(module => {
                return (
                  <Route
                    key={module.id}
                    path={`/:lang/${module.route}/*`}
                    element={
                      <MultiLanguageDomain>
                        <HubDomain>
                          <ModulePage
                            moduleId={module.id}
                            moduleComponent={module.component}
                            moduleFallback={module.fallback}
                            hideAppBar={module.hideAppBar}
                            hideFooter={module.hideFooter}
                            containerMaxWidth={module.containerMaxWidth}
                          />
                        </HubDomain>
                      </MultiLanguageDomain>
                    }
                  />
                );
              })}
              <Route
                path="/:lang/:nodeCode/*"
                element={
                  <MultiLanguageDomain>
                    <HubDomain>
                      <NodeDomain>
                        <NodeRouter isDefault={isDefault} />
                      </NodeDomain>
                    </HubDomain>
                  </MultiLanguageDomain>
                }
              />
            </Routes>
          </A11yDomain>
        </WithCustomTheme>
      </AppDomain>
    </HashRouter>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(HubRouter);
