import moment from "moment";
import {
  getCreateTemplateUrl,
  getCreateViewUrl,
  getDatasetDetailLevels,
  getDatasetDownloadUrl,
  getDatasetExportMetadataUrl,
  getDatasetSdmxQueryUrl,
  getDatasetStructureCodelistUrl,
  getDatasetStructureDynamicCodelistUrl,
  getDatasetStructureUrl,
  getDatasetTerritories,
  getDatasetWithIndicatorsUrl,
  getDatasetWithMarginalCustomDownloadUrl,
  getDimensionValuesUrl,
  getNodeCatalogUrl,
  getNotEmptyCombinationUrl,
  getOptimizedDataDatasetPageSizeUrl,
  getOptimizedDataOffsetUrl,
  getOptimizedMapDataUrl,
  getSynthesisAndVariabilityMeasuresUrl,
  getTerritorialClassificationsUrl,
  getTerritorialClassificationsValuesUrl
} from "../../../serverApi/urls";
import {Criteria} from "../constants";
import {getIndicatorsDefinition, Indicator} from "../multi-viewer/constants";
import {getOptimizedDataPayload} from "./constants";
import {initRequest, RequestMethod} from "../../../middlewares/request/requestActions";
import {getDetailLevelFromTree} from "../../../utils/catalog";
import {CRITERIA_FILTER_TYPE_RANGE, getCriteriaArrayFromObject} from "../../../utils/criteria";
import {
  DOWNLOAD_FORMAT_CSV,
  DOWNLOAD_FORMAT_EXCEL,
  downloadFormats,
  getCustomExportRequestBodyWithMarginal
} from "../../../utils/download";
import {getFilteredTreeWithPaths} from "../../../utils/tree";

export const DATASET_MVP_STATE_CLEAR = "dataset/mvp/state/clear";

export const DATASET_MVP_STATE_BACKUP = "dataset/mvp/state/backup";

export const DATASET_MVP_STRUCTURE_FETCH_INIT = "dataset/mvp/structure/fetch/init";
export const DATASET_MVP_STRUCTURE_FETCH_SUCCESS = "dataset/mvp/structure/fetch/success";
export const DATASET_MVP_STRUCTURE_FETCH_ERROR = "dataset/mvp/structure/fetch/error";

export const DATASET_MVP_DETAIL_LEVELS_SET = "dataset/mvp/detail/levels/set";
export const DATASET_MVP_DETAIL_LEVELS_FETCH = "dataset/mvp/detail/levels/fetch";

export const DATASET_MVP_DATASET_PAGE_SIZE_FETCH = "dataset/mvp/dataset/pageSize/fetch";

export const DATASET_MVP_TABLE_COL_COUNT_FETCH = "dataset/mvp/table/colCount/fetch";
export const DATASET_MVP_TABLE_COLS_FETCH = "dataset/mvp/table/cols/fetch";
export const DATASET_MVP_TABLE_ROWS_FETCH = "dataset/mvp/table/rows/fetch";

export const DATASET_MVP_DATASET_TABLE_FILTERS_SET = "dataset/mvp/dataset/tableFilters/set";
export const DATASET_MVP_DATASET_MAP_FILTERS_SET = "dataset/mvp/dataset/mapFilters/set";

export const DATASET_MVP_DATASET_FETCH_ENABLE = "dataset/mvp/dataset/fetch/enable";
export const DATASET_MVP_TABLE_CHART_DATASET_FETCH = "dataset/mvp/tableChart/dataset/fetch";
export const DATASET_MVP_MAP_TERRITORIES_FETCH = "dataset/mvp/map/territories/fetch";
export const DATASET_MVP_MAP_DATASET_FETCH = "dataset/mvp/map/dataset/fetch";

export const DATASET_MVP_DATASET_SYNTHESIS_AND_VARIABILITY_MEASURES_FETCH =
  "dataset/mvp/dataset/synthesisAndVariabilityMeasures/fetch";

export const DATASET_MVP_TERRITORIAL_CLASSIFICATIONS_FETCH = "dataset/mvp/territorialClassifications/fetch";
export const DATASET_MVP_TERRITORIAL_CLASSIFICATIONS_CONFIG_SET = "dataset/mvp/territorialClassifications/config/set";
export const DATASET_MVP_TERRITORIAL_CLASSIFICATIONS_VALUES_FETCH =
  "dataset/mvp/territorialClassifications/values/fetch";

export const DATASET_MVP_CRITERIA_SHOW = "dataset/mvp/criteria/show";
export const DATASET_MVP_CRITERIA_HIDE = "dataset/mvp/criteria/hide";
export const DATASET_MVP_CRITERIA_ALERT_HIDE = "dataset/mvp/criteria/alert/hide";
export const DATASET_MVP_CRITERIA_OBS_COUNT_WARNING_HIDE = "dataset/mvp/criteria/obs/count/warning/hide";
export const DATASET_MVP_STRUCTURE_CODELIST_FETCH = "dataset/mvp/structure/codelist/fetch";
export const DATASET_MVP_STRUCTURE_CRITERIA_SET = "dataset/mvp/structure/criteria/set";

export const DATASET_MVP_TERRITORY_SHOW = "dataset/mvp/territory/show";
export const DATASET_MVP_TERRITORY_HIDE = "dataset/mvp/territory/hide";
export const DATASET_MVP_TERRITORY_TERRITORIES_FETCH = "dataset/mvp/territory/territories/fetch";
export const DATASET_MVP_TERRITORY_SUBMIT = "dataset/mvp/territory/submit";
export const DATASET_MVP_TERRITORY_LAST_YEAR_FETCH = "dataset/mvp/territory/last/year/fetch";

export const DATASET_MVP_VIEWER_TABLE_VISIBILITY_SET = "dataset/mvp/viewer/table/visibility/set";
export const DATASET_MVP_VIEWER_MAP_VISIBILITY_SET = "dataset/mvp/viewer/map/visibility/set";
export const DATASET_MVP_VIEWER_CHART_VISIBILITY_SET = "dataset/mvp/viewer/chart/visibility/set";
export const DATASET_MVP_VIEWER_CHART_TYPE_SET = "dataset/mvp/viewer/chart/type/set";

export const DATASET_MVP_MAP_LAYOUT_SUBMIT = "dataset/mvp/map/layout/submit";
export const DATASET_MVP_CHART_LAYOUT_SUBMIT = "dataset/mvp/chart/layout/submit";

export const DATASET_MVP_CHART_SETTINGS_SET = "dataset/mvp/chart/settings/set";

export const DATASET_MVP_MAP_SETTINGS_SET = "dataset/mvp/map/settings/set";

export const DATASET_MVP_DOWNLOAD_SUBMIT = "dataset/mvp/download/submit";
export const DATASET_MVP_DOWNLOAD_WARNING_HIDE = "dataset/mvp/download/warning/hide";

export const DATASET_MVP_VIEW_TEMPLATE_SHOW = "dataset/mvp/view/template/show";
export const DATASET_MVP_VIEW_TEMPLATE_HIDE = "dataset/mvp/view/template/hide";
export const DATASET_MVP_VIEW_TEMPLATE_SUBMIT = "dataset/mvp/view/template/submit";
export const DATASET_MVP_VIEW_ERROR_HIDE = "dataset/mvp/view/error/hide";

export const DATASET_MVP_UNAVAILABLE_VIEW_WARNING_HIDE = "dataset/mvp/unavailable/view/warning/hide";

export const DATASET_MVP_HTML_GENERATING_TIME_SET = "dataset/mvp/html/generating/time/set";

export const DATASET_MVP_SDMX_QUERY_SHOW = "dataset/mvp/sdmx/query/show";
export const DATASET_MVP_SDMX_QUERY_HIDE = "dataset/mvp/sdmx/query/hide";
export const DATASET_MVP_SDMX_QUERY_FETCH = "dataset/mvp/sdmx/query/fetch";

export const DATASET_MVP_INDICATOR_CREATE_VISIBILITY_SET = "dataset/mvp/indicator/create/visibility/set";
export const DATASET_MVP_INDICATOR_PREVIEW_FETCH = "dataset/mvp/indicator/preview/fetch";
export const DATASET_MVP_INDICATOR_PREVIEW_RESET = "dataset/mvp/indicator/preview/reset";
export const DATASET_MVP_INDICATOR_PUBLISH = "dataset/mvp/indicator/publish";
export const DATASET_MVP_INDICATOR_ARITHMETIC_MEAN_VISIBILITY_SET =
  "dataset/mvp/indicator/arithmeticMean/visibility/set";
export const DATASET_MVP_INDICATOR_STANDARD_DEVIATION_VISIBILITY_SET =
  "dataset/mvp/indicator/standardDeviation/visibility/set";
export const DATASET_MVP_INDICATOR_COEFFICIENT_OF_VARIATION_VISIBILITY_SET =
  "dataset/mvp/coefficientOfVariation/visibility/set";

export const DATASET_MVP_ADDITIONAL_DATASET_CREATE_VISIBILITY_SET =
  "dataset/mvp/additionalDataset/create/visibility/set";
export const DATASET_MVP_ADDITIONAL_DATASET_CATALOG_FETCH = "dataset/mvp/additionalDataset/catalog/fetch";
export const DATASET_MVP_ADDITIONAL_DATASET_STRUCTURE_FETCH = "dataset/mvp/additionalDataset/structure/fetch";
export const DATASET_MVP_ADDITIONAL_DATASET_CHANGE = "dataset/mvp/additionalDataset/change";
export const DATASET_MVP_ADDITIONAL_DATASET_SUBMIT = "dataset/mvp/additionalDataset/submit";

export const DATASET_MVP_DOWNLOAD_METADATA_SUBMIT = "dataset/mvp/download/metadata/submit";

export const DATASET_MVP_STRUCTURE_CODELIST_FULL_FETCH = "dataset/mvp/structure/codelist/full/fetch";
export const DATASET_MVP_STRUCTURE_CODELIST_FULL_HIDE = "dataset/mvp/structure/codelist/full/hide";

export const clearDatasetMVPState = () => ({
  type: DATASET_MVP_STATE_CLEAR
});

export const backupDatasetMVPState = (datasetUuid: string) => ({
  type: DATASET_MVP_STATE_BACKUP,
  datasetUuid
});

export const fetchDatasetMVPStructureInit = () => ({
  type: DATASET_MVP_STRUCTURE_FETCH_INIT
});

export const fetchDatasetMVPStructureSuccess = (datasetUuid: string, structure: any, viewId: string | null) => ({
  type: DATASET_MVP_STRUCTURE_FETCH_SUCCESS,
  payload: {
    datasetUuid,
    structure,
    viewId
  }
});

export const fetchDatasetMVPStructureError = () => ({
  type: DATASET_MVP_STRUCTURE_FETCH_ERROR
});

export const handleDatasetMVPDetailLevels = (
  nodeId: any,
  datasetId: any,
  catalogDetailLevelTree: any,
  datasetDetailLevels: any,
  datasetDetailLevel: any,
  nodeDetailLevel: any,
  baseUrl: string
) => {
  if (catalogDetailLevelTree && datasetDetailLevels) {
    const detailLevelTree = getFilteredTreeWithPaths(catalogDetailLevelTree, "layers", (node: any) =>
      (datasetDetailLevels || []).includes(node.id)
    );
    const detailLevel = getDetailLevelFromTree(detailLevelTree, [datasetDetailLevel, nodeDetailLevel]);

    return {
      type: DATASET_MVP_DETAIL_LEVELS_SET,
      payload: {
        detailLevelTree: detailLevelTree,
        detailLevel: detailLevel
      }
    };
  } else {
    return initRequest(
      DATASET_MVP_DETAIL_LEVELS_FETCH,
      getDatasetDetailLevels(nodeId, datasetId),
      RequestMethod.POST,
      undefined,
      t => ({
        onStart: t("scenes.dataset.actions.fetchingDetailLevels")
      }),
      {
        datasetDetailLevel,
        nodeDetailLevel
      },
      baseUrl
    );
  }
};

export const fetchDatasetMVPDatasetPageSize = (nodeId: number, baseUrl: string) =>
  initRequest(
    DATASET_MVP_DATASET_PAGE_SIZE_FETCH,
    getOptimizedDataDatasetPageSizeUrl(nodeId),
    RequestMethod.GET,
    undefined,
    undefined,
    undefined,
    baseUrl
  );

export const fetchDatasetMVPTableColCount = (
  nodeId: number,
  datasetId: string,
  criteria: {[key: string]: Criteria},
  territoryDim: string,
  detailLevelNameIdentifier: string,
  allTerritoriesSelected: boolean,
  dimensions: string[],
  maxColCount: number,
  baseUrl: string
) =>
  initRequest(
    DATASET_MVP_TABLE_COL_COUNT_FETCH,
    getNotEmptyCombinationUrl(nodeId, datasetId, true, false),
    RequestMethod.POST,
    getOptimizedDataPayload(criteria, territoryDim, detailLevelNameIdentifier, allTerritoriesSelected, dimensions),
    t => ({
      onStart: t("scenes.dataset.actions.fetchingDatasetColCount")
    }),
    {
      maxColCount: maxColCount
    },
    baseUrl
  );

export const fetchDatasetMVPTableCols = (
  nodeId: number,
  datasetId: string,
  criteria: {[key: string]: Criteria},
  territoryDim: string,
  detailLevelNameIdentifier: string,
  allTerritoriesSelected: boolean,
  dimensions: string[],
  baseUrl: string
) =>
  initRequest(
    DATASET_MVP_TABLE_COLS_FETCH,
    getNotEmptyCombinationUrl(nodeId, datasetId, false, true),
    RequestMethod.POST,
    getOptimizedDataPayload(criteria, territoryDim, detailLevelNameIdentifier, allTerritoriesSelected, dimensions),
    undefined,
    undefined,
    baseUrl,
    undefined,
    true
  );

export const fetchDatasetMVPTableRows = (
  nodeId: number,
  datasetId: string,
  criteria: {[key: string]: Criteria},
  territoryDim: string,
  detailLevelNameIdentifier: string,
  allTerritoriesSelected: boolean,
  tableFilters: any,
  mapFilters: string[] | null,
  dimensions: string[],
  baseUrl: string
) =>
  initRequest(
    DATASET_MVP_TABLE_ROWS_FETCH,
    getNotEmptyCombinationUrl(nodeId, datasetId, false, false),
    RequestMethod.POST,
    getOptimizedDataPayload(
      criteria,
      territoryDim,
      detailLevelNameIdentifier,
      allTerritoriesSelected,
      dimensions,
      tableFilters,
      mapFilters
    ),
    undefined,
    undefined,
    baseUrl,
    undefined,
    true
  );

export const setDatasetMVPDatasetTableFilters = (filters: any) => ({
  type: DATASET_MVP_DATASET_TABLE_FILTERS_SET,
  payload: {
    filters: filters
  }
});

export const setDatasetMVPDatasetMapFilters = (filters: string[] | null) => ({
  type: DATASET_MVP_DATASET_MAP_FILTERS_SET,
  payload: {
    filters: filters
  }
});

export const enableDatasetMVPDatasetFetch = (maxObservations?: number) => ({
  type: DATASET_MVP_DATASET_FETCH_ENABLE,
  payload: {
    maxObservations
  }
});

export const fetchDatasetMVPTableChartDataset = (
  nodeId: number,
  datasetId: string,
  datasetTitle: string,
  columns: string[],
  criteria: {[key: string]: Criteria},
  territoryDim: string,
  detailLevelNameIdentifier: string,
  allTerritoriesSelected: boolean,
  tableFilters: any,
  mapFilters: string[] | null,
  offset: number,
  baseUrl: string
) => {
  return initRequest(
    DATASET_MVP_TABLE_CHART_DATASET_FETCH,
    getOptimizedDataOffsetUrl(nodeId, datasetId, offset),
    RequestMethod.POST,
    getOptimizedDataPayload(
      criteria,
      territoryDim,
      detailLevelNameIdentifier,
      allTerritoriesSelected,
      columns,
      tableFilters,
      mapFilters
    ),
    t => ({
      onStart: t("scenes.dataset.actions.fetchingDataset", {title: datasetTitle})
    }),
    {
      offset: offset
    },
    baseUrl,
    (statusCode: number) => statusCode === 413 || statusCode === 414,
    true
  );
};

export const fetchDatasetMVPMapTerritories = (
  nodeId: number,
  datasetId: string,
  criteria: {[key: string]: Criteria},
  territoryDim: string,
  detailLevelNameIdentifier: string,
  allTerritoriesSelected: boolean,
  dimensions: string[],
  baseUrl: string
) =>
  initRequest(
    DATASET_MVP_MAP_TERRITORIES_FETCH,
    getNotEmptyCombinationUrl(nodeId, datasetId, false, false),
    RequestMethod.POST,
    getOptimizedDataPayload(criteria, territoryDim, detailLevelNameIdentifier, allTerritoriesSelected, dimensions),
    undefined,
    undefined,
    baseUrl,
    undefined,
    true
  );

export const fetchDatasetMVPMapDataset = (
  nodeId: number,
  datasetId: string,
  datasetTitle: string,
  columns: string[],
  criteria: {[key: string]: Criteria},
  territoryDim: string,
  detailLevelNameIdentifier: string,
  allTerritoriesSelected: boolean,
  baseUrl: string
) =>
  initRequest(
    DATASET_MVP_MAP_DATASET_FETCH,
    getOptimizedMapDataUrl(nodeId, datasetId),
    RequestMethod.POST,
    getOptimizedDataPayload(criteria, territoryDim, detailLevelNameIdentifier, allTerritoriesSelected, columns),
    t => ({
      onStart: t("scenes.dataset.actions.fetchingDataset", {title: datasetTitle})
    }),
    undefined,
    baseUrl,
    (statusCode: number) => statusCode === 413 || statusCode === 414,
    true
  );

export const fetchDatasetMVPDatasetSynthesisAndVariabilityMeasures = (
  nodeId: number,
  datasetId: string,
  criteria: {[key: string]: Criteria},
  territoryDim: string,
  detailLevelNameIdentifier: string,
  allTerritoriesSelected: boolean,
  tableFilters: any,
  mapFilters: string[] | null,
  dimensions: string[],
  baseUrl: string
) =>
  initRequest(
    DATASET_MVP_DATASET_SYNTHESIS_AND_VARIABILITY_MEASURES_FETCH,
    getSynthesisAndVariabilityMeasuresUrl(nodeId, datasetId),
    RequestMethod.POST,
    getOptimizedDataPayload(
      criteria,
      territoryDim,
      detailLevelNameIdentifier,
      allTerritoriesSelected,
      dimensions,
      tableFilters,
      mapFilters
    ),
    t => ({
      onStart: t("scenes.dataset.actions.fetchingDatasetSynthesisAndVariabilityMeasures")
    }),
    undefined,
    baseUrl
  );

export const fetchDatasetMVPTerritorialClassifications = (detailLevel: number, baseUrl: string) =>
  initRequest(
    DATASET_MVP_TERRITORIAL_CLASSIFICATIONS_FETCH,
    getTerritorialClassificationsUrl(detailLevel),
    RequestMethod.GET,
    undefined,
    t => ({
      onStart: t("scenes.dataset.actions.fetchingTerritorialClassifications")
    }),
    undefined,
    baseUrl
  );

export const setDatasetMVPTerritorialClassificationsConfig = (territorialClassificationsConfig: any) => ({
  type: DATASET_MVP_TERRITORIAL_CLASSIFICATIONS_CONFIG_SET,
  payload: {
    territorialClassificationsConfig
  }
});

export const fetchDatasetMVPTerritorialClassificationsValues = (
  nodeId: number,
  criteria: {[key: string]: Criteria},
  territoryDim: string,
  classifications: number[],
  year: string | null,
  baseUrl: string
) => {
  return initRequest(
    DATASET_MVP_TERRITORIAL_CLASSIFICATIONS_VALUES_FETCH,
    getTerritorialClassificationsValuesUrl(nodeId),
    RequestMethod.POST,
    {
      items: criteria?.[territoryDim]?.filterValues || [],
      relationships: classifications,
      year: year
    },
    t => ({
      onStart: t("scenes.dataset.actions.fetchingTerritorialClassificationsValues")
    }),
    {classifications: classifications},
    baseUrl
  );
};

export const showDatasetMVPCriteria = () => ({
  type: DATASET_MVP_CRITERIA_SHOW
});

export const hideDatasetMVPCriteria = () => ({
  type: DATASET_MVP_CRITERIA_HIDE
});

export const hideDatasetMVPCriteriaAlert = () => ({
  type: DATASET_MVP_CRITERIA_ALERT_HIDE
});

export const hideDatasetMVPCriteriaObsCountWarning = () => ({
  type: DATASET_MVP_CRITERIA_OBS_COUNT_WARNING_HIDE
});

export const fetchDatasetMVPStructureCodelist = (
  nodeId: number,
  nodeCode: string,
  datasetId: string,
  mode: string,
  type: string,
  dimensionId: string,
  criteria: {[key: string]: Criteria},
  territoryDim: string,
  detailLevelNameIdentifier: string,
  allTerritoriesSelected: boolean,
  freq: string,
  defaultLastNPeriods: number,
  preserveFiltersWithDynamic: boolean,
  baseUrl: string
) => {
  return initRequest(
    DATASET_MVP_STRUCTURE_CODELIST_FETCH,
    baseUrl
      ? getDimensionValuesUrl(nodeId, datasetId, dimensionId)
      : getDatasetStructureDynamicCodelistUrl(nodeId, datasetId, dimensionId),
    RequestMethod.POST,
    baseUrl
      ? getOptimizedDataPayload(criteria, territoryDim, detailLevelNameIdentifier, allTerritoriesSelected)
      : getCriteriaArrayFromObject(criteria),
    t => ({
      onStart: t("scenes.dataset.actions.fetchingCodelist")
    }),
    {
      nodeCode: nodeCode,
      datasetId: datasetId,
      defaultLastNPeriods: defaultLastNPeriods,
      freq: freq,
      mode: mode,
      type: type
    },
    baseUrl,
    (statusCode: number) => statusCode === 414
  );
};

export const setDatasetMVPStructureCriteria = (criteria: {[key: string]: Criteria}) => ({
  type: DATASET_MVP_STRUCTURE_CRITERIA_SET,
  criteria
});

export const showDatasetMVPTerritory = () => ({
  type: DATASET_MVP_TERRITORY_SHOW
});

export const hideDatasetMVPTerritory = () => ({
  type: DATASET_MVP_TERRITORY_HIDE
});

export const fetchDatasetMVPTerritoryTerritories = (
  nodeId: number,
  datasetId: string,
  territoryDim: string,
  detailLevel: number,
  baseUrl: string
) =>
  initRequest(
    DATASET_MVP_TERRITORY_TERRITORIES_FETCH,
    getDatasetTerritories(nodeId, datasetId, territoryDim, detailLevel, true),
    RequestMethod.POST,
    undefined,
    t => ({
      onStart: t("scenes.dataset.actions.fetchingTerritories")
    }),
    undefined,
    baseUrl
  );

export const submitDatasetMVPTerritory = (
  detailLevel: number,
  territories: string[],
  allTerritoriesSelected: boolean,
  criteria: {[key: string]: Criteria}
) => ({
  type: DATASET_MVP_TERRITORY_SUBMIT,
  payload: {
    detailLevel: detailLevel,
    territories: territories,
    allTerritoriesSelected: allTerritoriesSelected,
    criteria: criteria
  }
});

export const fetchDatasetMVPTerritoryLastYear = (
  nodeId: number,
  datasetId: string,
  timeDim: string,
  criteria: {[key: string]: Criteria}
) => {
  if (criteria?.[timeDim]?.type === CRITERIA_FILTER_TYPE_RANGE && criteria?.[timeDim]?.to) {
    return {
      type: DATASET_MVP_TERRITORY_LAST_YEAR_FETCH,
      payload: {
        lastTerritoryYear: moment(criteria[timeDim].to).format("YYYY")
      }
    };
  } else {
    const newCriteria = {
      ...criteria,
      [timeDim]: undefined
    };

    return initRequest(
      DATASET_MVP_TERRITORY_LAST_YEAR_FETCH,
      getDatasetStructureDynamicCodelistUrl(nodeId, datasetId, timeDim),
      RequestMethod.POST,
      getCriteriaArrayFromObject(newCriteria),
      t => ({
        onStart: t("scenes.dataset.actions.fetchingTimePeriod")
      })
    );
  }
};

export const setDatasetMVPViewerTableVisibility = (isVisible: boolean) => ({
  type: DATASET_MVP_VIEWER_TABLE_VISIBILITY_SET,
  isVisible
});

export const setDatasetMVPViewerMapVisibility = (isVisible: boolean) => ({
  type: DATASET_MVP_VIEWER_MAP_VISIBILITY_SET,
  isVisible
});

export const setDatasetMVPViewerChartVisibility = (isVisible: boolean) => ({
  type: DATASET_MVP_VIEWER_CHART_VISIBILITY_SET,
  isVisible
});

export const setDatasetMVPViewerChartType = (chartType: string) => ({
  type: DATASET_MVP_VIEWER_CHART_TYPE_SET,
  chartType
});

export const submitDatasetMVPMapLayout = (layout: any) => ({
  type: DATASET_MVP_MAP_LAYOUT_SUBMIT,
  layout
});

export const submitDatasetMVPChartLayout = (layout: any) => ({
  type: DATASET_MVP_CHART_LAYOUT_SUBMIT,
  layout
});

export const setDatasetMVPChartSettings = (chartSettings: any) => ({
  type: DATASET_MVP_CHART_SETTINGS_SET,
  chartSettings
});

export const setDatasetMVPMapSettings = (mapSettings: any) => ({
  type: DATASET_MVP_MAP_SETTINGS_SET,
  mapSettings
});

export const submitDatasetMVPDownload = (
  nodeId: number,
  nodeCode: string,
  datasetId: string,
  datasetTitle: string,
  criteria: {[key: string]: Criteria},
  timeDim: string,
  territoryDim: string,
  layout: any,
  indicators: Indicator[] | null,
  additionalDatasets: any[],
  format: string,
  extension: string,
  zipped: boolean,
  params: any,
  defaultLanguage: string,
  languages: string[],
  t: any,
  indicatorsBaseUrl?: string
) => {
  const datasets = [
    {
      nodeId: nodeId,
      nodeCode: nodeCode,
      datasetId: datasetId,
      datasetTitle: datasetTitle,
      criteria: criteria,
      timeDim: timeDim,
      territoryDim: territoryDim,
      layout: layout
    },
    ...additionalDatasets
  ];

  let url,
    body,
    baseUrl = undefined;

  if (format === DOWNLOAD_FORMAT_EXCEL || format === DOWNLOAD_FORMAT_CSV) {
    url = getDatasetWithMarginalCustomDownloadUrl(format, zipped);
    body = getCustomExportRequestBodyWithMarginal(
      datasets,
      indicators,
      timeDim,
      layout,
      params,
      defaultLanguage,
      languages,
      t
    );
    baseUrl = indicatorsBaseUrl;
  } else {
    // @ts-ignore
    const formatParam = downloadFormats()[format]?.param || format;
    url = getDatasetDownloadUrl(nodeId, datasetId, formatParam, zipped);
    body = getCriteriaArrayFromObject(criteria);
  }

  const type = format === DOWNLOAD_FORMAT_EXCEL ? "application/vnd.ms-excel" : "text/plain;charset=utf-8";

  return initRequest(
    DATASET_MVP_DOWNLOAD_SUBMIT,
    url,
    RequestMethod.POST,
    body,
    t => ({
      onStart: t("scenes.dataset.actions.downloadingDataset")
    }),
    {
      fileSave: {
        name: datasets.map(({datasetId, datasetTitle}) => `${datasetTitle} (${datasetId})`).join(`, `),
        extension: extension,
        type: type
      }
    },
    baseUrl,
    (statusCode: number) => statusCode === 406,
    undefined,
    undefined,
    true
  );
};

export const hideDatasetMVPDownloadWarning = () => ({
  type: DATASET_MVP_DOWNLOAD_WARNING_HIDE
});

export const showDatasetMVPViewTemplate = (isView: boolean) => ({
  type: DATASET_MVP_VIEW_TEMPLATE_SHOW,
  isView
});

export const hideDatasetMVPViewTemplate = (isView: boolean) => ({
  type: DATASET_MVP_VIEW_TEMPLATE_HIDE,
  isView
});

export const submitDatasetMVPViewTemplate = (nodeId: number, viewTemplate: any, isView: boolean) =>
  initRequest(
    DATASET_MVP_VIEW_TEMPLATE_SUBMIT,
    isView ? getCreateViewUrl(nodeId) : getCreateTemplateUrl(nodeId),
    RequestMethod.POST,
    viewTemplate,
    t => ({
      onStart: isView ? t("scenes.dataset.actions.savingView") : t("scenes.dataset.actions.savingTemplate")
    }),
    {
      isView: isView
    },
    undefined,
    (statusCode: number) => statusCode === 409
  );

export const hideDatasetMVPViewError = () => ({
  type: DATASET_MVP_VIEW_ERROR_HIDE
});

export const hideDatasetMVPUnavailableViewWarning = () => ({
  type: DATASET_MVP_UNAVAILABLE_VIEW_WARNING_HIDE
});

export const setDatasetMVPHtmlGeneratingTime = (time: number) => ({
  type: DATASET_MVP_HTML_GENERATING_TIME_SET,
  time
});

export const showDatasetMVPSdmxQuery = () => ({
  type: DATASET_MVP_SDMX_QUERY_SHOW
});

export const hideDatasetMVPSdmxQuery = () => ({
  type: DATASET_MVP_SDMX_QUERY_HIDE
});

export const fetchDatasetMVPSdmxQuery = (
  nodeId: number,
  datasetId: string,
  criteria: {[key: string]: Criteria},
  datasetTitle: string
) =>
  initRequest(
    DATASET_MVP_SDMX_QUERY_FETCH,
    getDatasetSdmxQueryUrl(nodeId, datasetId),
    RequestMethod.POST,
    getCriteriaArrayFromObject(criteria),
    t => ({
      onStart: t("scenes.dataset.actions.fetchingDatasetSdmxQuery", {title: datasetTitle})
    })
  );

export const setDatasetMVPIndicatorCreateVisibility = (isVisible: boolean) => ({
  type: DATASET_MVP_INDICATOR_CREATE_VISIBILITY_SET,
  isVisible
});

export const setDatasetMVPAdditionalDatasetCreateVisibility = (isVisible: boolean) => ({
  type: DATASET_MVP_ADDITIONAL_DATASET_CREATE_VISIBILITY_SET,
  isVisible
});

export const fetchDatasetMVPAdditionalDatasetCatalog = (
  selectedNodeId: number,
  mainDatasetId: string,
  baseUrl: string
) =>
  initRequest(
    DATASET_MVP_ADDITIONAL_DATASET_CATALOG_FETCH,
    getNodeCatalogUrl(selectedNodeId),
    undefined,
    undefined,
    t => ({
      onStart: t("domains.catalog.messages.fetch.start")
    }),
    {
      selectedNodeId: selectedNodeId,
      mainDatasetId: mainDatasetId
    },
    baseUrl
  );

export const fetchDatasetMVPAdditionalDatasetStructure = (nodeId: number, datasetId: string) =>
  initRequest(
    DATASET_MVP_ADDITIONAL_DATASET_STRUCTURE_FETCH,
    getDatasetStructureUrl(nodeId, datasetId),
    RequestMethod.GET,
    undefined,
    t => ({
      onStart: t("scenes.dataset.actions.fetchingAdditionalDatasetStructure")
    })
  );

export const changeDatasetMVPAdditionalDataset = (additionalDataset: any) => ({
  type: DATASET_MVP_ADDITIONAL_DATASET_CHANGE,
  additionalDataset
});

export const submitDatasetMVPAdditionalDataset = () => ({
  type: DATASET_MVP_ADDITIONAL_DATASET_SUBMIT
});

export const fetchDatasetMVPIndicatorPreview = (
  nodeId: number,
  nodeCode: string,
  datasetId: string,
  criteria: {[key: string]: Criteria},
  timeDim: string,
  territoryDim: string,
  indicators: Indicator[],
  additionalDatasets: any[],
  defaultLanguage: string,
  languages: string[],
  indicatorsBaseUrl?: string
) => {
  const datasets = [
    {
      nodeId: nodeId,
      nodeCode: nodeCode,
      datasetId: datasetId,
      criteria: criteria,
      timeDim: timeDim,
      territoryDim: territoryDim
    },
    ...additionalDatasets
  ];

  return initRequest(
    DATASET_MVP_INDICATOR_PREVIEW_FETCH,
    getDatasetWithIndicatorsUrl(true),
    RequestMethod.POST,
    getIndicatorsDefinition(indicators, datasets, timeDim, defaultLanguage, languages),
    t => ({
      onStart: t("scenes.dataset.actions.fetchingIndicatorPreview")
    }),
    undefined,
    indicatorsBaseUrl
  );
};

export const resetDatasetMVPIndicatorPreview = () => ({
  type: DATASET_MVP_INDICATOR_PREVIEW_RESET
});

export const publishDatasetMVPIndicator = (indicator: Indicator) => ({
  type: DATASET_MVP_INDICATOR_PUBLISH,
  indicator
});

export const setDatasetMVPIndicatorArithmeticMeanVisibility = (visible: boolean) => ({
  type: DATASET_MVP_INDICATOR_ARITHMETIC_MEAN_VISIBILITY_SET,
  visible
});

export const setDatasetMVPIndicatorStandardDeviationVisibility = (visible: boolean) => ({
  type: DATASET_MVP_INDICATOR_STANDARD_DEVIATION_VISIBILITY_SET,
  visible
});

export const setDatasetMVPIndicatorCoefficientOfVariationVisibility = (visible: boolean) => ({
  type: DATASET_MVP_INDICATOR_COEFFICIENT_OF_VARIATION_VISIBILITY_SET,
  visible
});

export const submitDatasetMVPDownloadMetadata = (nodeId: number, datasetId: string, datasetTitle: string) => {
  return initRequest(
    DATASET_MVP_DOWNLOAD_METADATA_SUBMIT,
    getDatasetExportMetadataUrl(nodeId, datasetId),
    RequestMethod.POST,
    undefined,
    t => ({
      onStart: t("scenes.dataset.actions.downloadingDatasetMetadata")
    }),
    {
      fileSave: {
        name: `${datasetTitle} (${datasetId})`,
        extension: "html",
        type: "text/html;charset=utf-8"
      }
    }
  );
};

export const fetchDatasetMVPStructureCodelistFull = (
  nodeId: number,
  datasetId: string,
  dimensionId: string,
  missingFilterValueIds: string[]
) =>
  initRequest(
    DATASET_MVP_STRUCTURE_CODELIST_FULL_FETCH,
    getDatasetStructureCodelistUrl(nodeId, datasetId, dimensionId, true),
    undefined,
    undefined,
    t => ({
      onStart: t("scenes.dataset.actions.fetchingCodelist")
    }),
    {
      missingFilterValueIds: missingFilterValueIds
    }
  );

export const hideDatasetMVPStructureCodelistFull = () => ({
  type: DATASET_MVP_STRUCTURE_CODELIST_FULL_HIDE
});
