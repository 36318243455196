import React, {Fragment, useState} from "react";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {FilterDto} from "../../../model/item-containers-models/filterDto";
import {getFilterDeleteUrl} from "../../../serverApi/urls";
import ConfirmationDialog from "../../confirmation-dialog";
import MaterialTable from "../../material-table";
import FilterConfigurationDialog from "./FilterConfigurationDialog";
import useApi from "../../../state/api/useApi";
import {languageSelector, languagesSelector} from "../../../state/app/appSelectors";
import {deleteFilter, fetchFilters} from "../../../state/dashboardFilters/dashboardFilterActions";
import {getI18nObjCustomFilterAndSearch, localizeI18nObj} from "../../../utils/i18n";

const FilterSettingsForm = () => {
  const defaultLanguage = useSelector(languageSelector);
  const languages = useSelector(languagesSelector);
  const {t} = useTranslation();
  const [filterToEdit, setFilterToEdit] = useState<number | null>(null);
  const [filterToDelete, setFilterToDelete] = useState<number | null>(null);

  const fetchFiltersApi = useApi<FilterDto[]>(fetchFilters(false), {
    invokeImmediately: true,
    clearOnUnmount: true
  });
  const deleteFilterApi = useApi(deleteFilter(null), {
    onSuccess: _ => fetchFiltersApi.call(),
    clearOnUnmount: true
  });

  return (
    <Fragment>
      <MaterialTable
        rightActions={[
          {
            id: "filter-settings-actions__add-filter",
            label: t("components.filtersSettingsForm.filtersManager.add"),
            startIcon: <AddIcon />,
            onClick: () => setFilterToEdit(-1)
          }
        ]}
        columns={[
          {
            field: "title",
            title: t("components.filtersSettingsForm.filtersManager.columnTitle"),
            render: ({name}) => localizeI18nObj(name, defaultLanguage, languages),
            customFilterAndSearch: getI18nObjCustomFilterAndSearch(defaultLanguage, languages)
          }
        ]}
        data={fetchFiltersApi.data || []}
        actions={[
          {
            className: "filter-settings-form__actions__edit",
            icon: <EditIcon />,
            tooltip: t("components.filtersSettingsForm.filtersManager.edit"),
            onClick: (_, {id}) => {
              setFilterToEdit(id);
            }
          },
          {
            className: "filter-settings-form__actions__delete",
            icon: <DeleteIcon />,
            tooltip: t("components.filtersSettingsForm.filtersManager.delete"),
            onClick: (_, {id}) => {
              setFilterToDelete(id);
            }
          }
        ]}
      />

      <ConfirmationDialog
        open={filterToDelete !== null}
        onClose={() => setFilterToDelete(null)}
        onConfirm={() => {
          deleteFilterApi.request.url = getFilterDeleteUrl(filterToDelete);
          deleteFilterApi.call({...deleteFilterApi.request});
          setFilterToDelete(null);
        }}
        title={t("components.filtersSettingsForm.filtersManager.deleteDialog.title")}
        content={t("components.filtersSettingsForm.filtersManager.deleteDialog.content")}
      />
      {!!filterToEdit && (
        <FilterConfigurationDialog
          filterToEdit={filterToEdit}
          onClose={() => setFilterToEdit(null)}
          open={!!filterToEdit}
          onFilterCreated={() => fetchFiltersApi.call()}
        />
      )}
    </Fragment>
  );
};

export default FilterSettingsForm;
