import React, {Fragment, useEffect, useMemo} from "react";
import {Box, createTheme, CssBaseline} from "@mui/material";
import {ThemeProvider} from "@mui/material/styles";
import {StyledEngineProvider} from "@mui/styled-engine";
import _ from "lodash";
import Helmet from "react-helmet";
import {connect} from "react-redux";
import ErrorBoundary from "../error-boundary";
import Spinner from "../spinner/Spinner";
import {DEFAULT_ALL1_THEME, DEFAULT_THEME} from "../../utils/defaultThemes";

const mapStateToProps = state => ({
  colorSchemes: state.appConfig.colorSchemes,
  a11yColorSchemes: state.appConfig.a11yColorSchemes,
  isA11y: state.app.isA11y,
  user: state.user,
  hub: state.hub?.hub,
  nodes: state.hub?.nodes
});

const WithCustomTheme = ({colorSchemes, a11yColorSchemes, isA11y, user, nodeCode, children, hub, nodes}) => {
  const theme = useMemo(() => {
    let newTheme = _.cloneDeep(DEFAULT_THEME);

    if (colorSchemes?.hub) {
      newTheme = _.merge(newTheme, colorSchemes.hub);
    }
    if (hub) {
      if (hub?.extras?.CustomColors?.isEnabled) {
        newTheme = _.merge(newTheme, hub.extras.CustomColors);
      }
    }
    if (nodeCode && colorSchemes?.nodes[nodeCode]) {
      newTheme = _.merge(newTheme, colorSchemes.nodes?.[nodeCode]);
    }
    if (nodeCode) {
      const currentNode = nodes?.find(n => n.code.toLowerCase() === nodeCode.toLowerCase());
      const nodeCustomColorsExtra = JSON.parse(
        (currentNode?.extras || []).find(e => e.key === "CustomColors")?.value || "{}"
      );
      if (nodeCustomColorsExtra?.isEnabled) {
        newTheme = _.merge(newTheme, nodeCustomColorsExtra);
      }
    }
    if (isA11y) {
      newTheme = _.merge(newTheme, DEFAULT_ALL1_THEME);
      if (a11yColorSchemes?.hub) {
        newTheme = _.merge(newTheme, a11yColorSchemes.hub);
      }
      if (nodeCode && a11yColorSchemes?.nodes?.[nodeCode]) {
        newTheme = _.merge(newTheme, a11yColorSchemes.nodes[nodeCode]);
      }
    }

    return createTheme(newTheme);
  }, [colorSchemes, a11yColorSchemes, nodeCode, isA11y, hub, nodes]);

  useEffect(() => {
    if (theme) {
      const doc = document.documentElement;
      doc.style.setProperty("--text-primary", theme.palette.text.primary);
      doc.style.setProperty("--primary-main", theme.palette.primary.main);
      doc.style.setProperty("--primary-light", theme.palette.primary.light);
      doc.style.setProperty("--primary-dark", theme.palette.primary.dark);
      doc.style.setProperty("--primary-contrast-text", theme.palette.primary.contrastText);
      doc.style.setProperty("--secondary-main", theme.palette.secondary.main);
      doc.style.setProperty("--secondary-light", theme.palette.secondary.light);
      doc.style.setProperty("--secondary-dark", theme.palette.secondary.dark);
      doc.style.setProperty("--secondary-contrast-text", theme.palette.secondary.contrastText);
    }
  }, [theme]);

  return theme ? (
    <Fragment>
      <Helmet>
        {/* eslint-disable-next-line react/style-prop-object */}
        <body
          style={"background-color: " + theme.palette.primary.main}
          authenticated={user.isAuthenticated ? "true" : "false"}
        />
      </Helmet>
      <CssBaseline />
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <ErrorBoundary>
            <Spinner>
              <Box
                id="fullscreen-container"
                sx={{
                  display: "none",
                  position: "fixed",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  background: "#f5f5f5",
                  zIndex: 1299,
                  borderRadius: "unset",
                  boxShadow: "unset",
                  "& > *": {
                    width: "100% !important",
                    height: "100% !important",
                    padding: "16px !important",
                    margin: "0 !important"
                  }
                }}
                style={{display: "none"}}
              />
              <Box
                id="app-container"
                sx={{
                  width: "100%",
                  height: "100%"
                }}
              >
                {children}
              </Box>
            </Spinner>
          </ErrorBoundary>
        </ThemeProvider>
      </StyledEngineProvider>
    </Fragment>
  ) : null;
};

export default connect(mapStateToProps)(WithCustomTheme);
