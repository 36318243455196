import React, {forwardRef, Fragment, useEffect, useImperativeHandle, useState} from "react";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {Box, Checkbox, useTheme} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {compose} from "redux";
import CustomDialogTitle from "../../../custom-dialog-title";
import SanitizedHTML from "../../../sanitized-html";
import UserErrors from "../../../user-errors";
import {USER_TYPE_UNSPECIFIED} from "../../actions";
import {
  clearUsersConfigUser,
  fetchUsersConfigUser,
  sendUsersConfigUserCreate,
  sendUsersConfigUserEdit
} from "../../../../state/usersConfig/usersConfigActions";
import {getTrimmedStr} from "../../../../utils/formatters";
import {localizeI18nObj} from "../../../../utils/i18n";

const Form = forwardRef(({config, onSubmit, onCancel, isAnonymous, hub, types, languages, language}, ref) => {
  const {
    register,
    formState: {errors},
    handleSubmit,
    watch,
    setValue
  } = useForm({
    defaultValues: {
      isActive: true,
      type: USER_TYPE_UNSPECIFIED,
      organization: "",
      disclaimerAccepted: isAnonymous ? false : undefined,
      ...config
    }
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [isDisclaimerModalOpen, setIsDisclaimerModalOpen] = useState(false);

  const {t} = useTranslation();

  const theme = useTheme();

  const fieldStyle = {
    marginTop: theme.spacing(1),
    "& .MuiOutlinedInput-input": {
      padding: "18.5px 14px"
    }
  };

  useImperativeHandle(ref, () => ({
    submit(f) {
      handleSubmit(val => {
        const data = {
          ...config,
          ...val,
          type: val.type !== "null" && val.type !== USER_TYPE_UNSPECIFIED ? val.type : null,
          disclaimerAccepted: undefined,
          emailConfirm: undefined,
          passwordConfirm: undefined
        };

        onSubmit(data);
        f(data);
      })();
    },
    cancel(f) {
      onCancel();
      f();
    }
  }));

  useEffect(() => {
    register("type");

    if (isAnonymous) {
      register("disclaimerAccepted", {
        validate: val => val || t("scenes.usersSettings.userSettings.validation.mustAcceptDisclaimer")
      });
    } else {
      register("isActive");
    }
  }, [isAnonymous, register, t]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <FormControl fullWidth sx={fieldStyle}>
          <TextField
            id="user-settings-form__form__email"
            {...register("email", {
              required: t("commons.validation.required")
            })}
            disabled={!!config}
            name="email"
            label={t("scenes.usersSettings.userSettings.fields.email")}
            error={!!errors.email}
            helperText={errors.email?.message}
            variant="outlined"
            required
          />
        </FormControl>
      </Grid>
      {!config && (
        <Grid item xs={6}>
          <FormControl fullWidth sx={fieldStyle}>
            <TextField
              id="user-settings-form__form__email-confirm"
              {...register("emailConfirm", {
                required: t("commons.validation.required"),
                validate: val =>
                  getTrimmedStr(val) === getTrimmedStr(watch("email")) ||
                  t("scenes.usersSettings.userSettings.validation.emailsMustBeEqual")
              })}
              disabled={!!config}
              name="emailConfirm"
              label={t("scenes.usersSettings.userSettings.fields.emailConfirm")}
              error={!!errors.emailConfirm}
              helperText={errors.emailConfirm?.message}
              variant="outlined"
              required
            />
          </FormControl>
        </Grid>
      )}
      {!isAnonymous && (
        <Grid item xs={12}>
          <FormControl fullWidth>
            <FormControlLabel
              label={t("scenes.usersSettings.userSettings.fields.isActive.label")}
              control={
                <Checkbox
                  id="user-settings-form__form__is-active"
                  name="isActive"
                  required
                  checked={watch("isActive")}
                  onChange={(e, value) => setValue("isActive", value)}
                />
              }
            />
          </FormControl>
        </Grid>
      )}
      {types && types.length > 0 && (
        <Grid item xs={12}>
          <FormControl fullWidth sx={fieldStyle}>
            <TextField
              id="user-settings-form__form__type"
              name="type"
              select
              label={t("scenes.usersSettings.userSettings.fields.type.label")}
              variant="outlined"
              onChange={e => setValue("type", e.target.value)}
              value={watch("type") ?? ""}
              helperText={errors.type?.message}
              error={!!errors.type}
              disabled={!!config?.provider}
              SelectProps={{SelectDisplayProps: {"aria-haspopup": true}}}
            >
              <MenuItem value={USER_TYPE_UNSPECIFIED} key={USER_TYPE_UNSPECIFIED}>
                {t("scenes.usersSettings.userSettings.fields.type.values.unspecified")}
              </MenuItem>
              {types.map(({id, label}) => (
                <MenuItem value={id} key={id}>
                  {localizeI18nObj(label, language, languages)}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Grid>
      )}
      <Grid item xs={6}>
        <FormControl fullWidth sx={fieldStyle}>
          <TextField
            id="user-settings-form__form__first-name"
            {...register("firstName")}
            name="firstName"
            label={t("scenes.usersSettings.userSettings.fields.firstName")}
            helperText={errors.firstName?.message}
            variant="outlined"
            disabled={!!config?.provider}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth sx={fieldStyle}>
          <TextField
            id="user-settings-form__form__last-name"
            {...register("lastName")}
            name="lastName"
            label={t("scenes.usersSettings.userSettings.fields.lastName")}
            helperText={errors.lastName?.message}
            variant="outlined"
            disabled={!!config?.provider}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth sx={fieldStyle}>
          <TextField
            id="user-settings-form__form__organization"
            {...register("organization")}
            name="organization"
            label={t("scenes.usersSettings.userSettings.fields.organization")}
            error={!!errors.organization}
            helperText={errors.organization?.message}
            variant="outlined"
            disabled={!!config?.provider}
          />
        </FormControl>
      </Grid>
      {!config && (
        <Fragment>
          <Grid item xs={6}>
            <FormControl fullWidth sx={fieldStyle}>
              <TextField
                id="user-settings-form__form__password"
                {...register("password", {
                  required: t("commons.validation.required")
                })}
                name="password"
                label={t("scenes.usersSettings.userSettings.fields.password")}
                error={!!errors.password}
                helperText={errors.password?.message}
                variant="outlined"
                required
                type={showPassword ? undefined : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                inputProps={{
                  autoComplete: "new-password"
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth sx={fieldStyle}>
              <TextField
                id="user-settings-form__form__password-confirm"
                {...register("passwordConfirm", {
                  required: t("commons.validation.required"),
                  validate: val =>
                    val === watch("password") || t("scenes.usersSettings.userSettings.validation.passwordsMustBeEqual")
                })}
                name="passwordConfirm"
                label={t("scenes.usersSettings.userSettings.fields.passwordConfirm")}
                error={!!errors.passwordConfirm}
                helperText={errors.passwordConfirm?.message}
                variant="outlined"
                required
                type={showPasswordConfirm ? undefined : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}>
                        {showPasswordConfirm ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                inputProps={{
                  autoComplete: "new-password"
                }}
              />
            </FormControl>
          </Grid>
          {isAnonymous && (
            <Grid item>
              <FormControl fullWidth error={!!errors.disclaimerAccepted}>
                <FormControlLabel
                  label={
                    <Fragment>
                      {t("scenes.usersSettings.userSettings.fields.disclaimerAccepted.label")}
                      <Box
                        component="span"
                        onClick={ev => {
                          setIsDisclaimerModalOpen(true);
                          ev.preventDefault();
                        }}
                        sx={theme => ({
                          marginLeft: theme.spacing(1),
                          color: theme.palette.secondary.main,
                          textDecoration: "underline"
                        })}
                      >
                        {t("scenes.usersSettings.userSettings.fields.disclaimerAccepted.link")}
                      </Box>
                    </Fragment>
                  }
                  control={
                    <Checkbox
                      id="user-settings-form__form__disclaimer-accepted"
                      name="disclaimerAccepted"
                      required
                      checked={watch("disclaimerAccepted")}
                      onChange={(e, value) => setValue("disclaimerAccepted", value)}
                    />
                  }
                />
                {errors.disclaimerAccepted?.message && (
                  <FormHelperText id="my-helper-text">{errors.disclaimerAccepted?.message}</FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}
          <Dialog
            id="user-settings-form__form__disclaimer-modal"
            open={isDisclaimerModalOpen}
            fullWidth
            maxWidth="md"
            onClose={() => setIsDisclaimerModalOpen(false)}
          >
            <CustomDialogTitle onClose={() => setIsDisclaimerModalOpen(false)}>
              {t("scenes.usersSettings.userSettings.disclaimerModal.title")}
            </CustomDialogTitle>
            <DialogContent>
              <SanitizedHTML html={hub.hub.disclaimer} style={{maxHeight: 460}} allowTarget />
            </DialogContent>
            <DialogActions>
              <Button
                id="user-settings-form__form__disclaimer-modal__btn-close"
                onClick={() => setIsDisclaimerModalOpen(false)}
              >
                {t("commons.confirm.close")}
              </Button>
            </DialogActions>
          </Dialog>
        </Fragment>
      )}
    </Grid>
  );
});

const mapStateToProps = state => ({
  config: state.usersConfig.user,
  userErrors: state.usersConfig.userErrors,
  hub: state.hub,
  appConfig: state.appConfig,
  languages: state.app.languages,
  language: state.app.language
});

const mapDispatchToProps = dispatch => ({
  fetchConfig: nodeId => dispatch(fetchUsersConfigUser(nodeId)),
  sendConfigCreate: (config, isAnonymous) => dispatch(sendUsersConfigUserCreate(config, isAnonymous)),
  sendConfig: config => dispatch(sendUsersConfigUserEdit(config)),
  clearConfig: () => dispatch(clearUsersConfigUser())
});

const UserSettingsForm = (
  {
    config,
    userId,
    fetchConfig,
    sendConfigCreate,
    sendConfig,
    clearConfig,
    userErrors,
    isAnonymous,
    hub,
    appConfig,
    languages,
    language
  },
  ref
) => {
  const [needConfig, setNeedConfig] = useState(userId >= 0);

  useEffect(() => {
    if (needConfig) {
      setNeedConfig(false);
      fetchConfig(userId);
    }
  }, [config, needConfig, setNeedConfig, fetchConfig, userId]);

  return (
    (userId === -1 || config) && (
      <Fragment>
        <UserErrors errors={userErrors} />
        <Form
          config={userId === -1 ? undefined : config}
          ref={ref}
          onSubmit={userId === -1 ? config => sendConfigCreate(config, isAnonymous) : sendConfig}
          onCancel={clearConfig}
          isAnonymous={isAnonymous}
          hub={hub}
          types={appConfig.user.typeOptions}
          languages={languages}
          language={language}
        />
      </Fragment>
    )
  );
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true}),
  forwardRef
)(UserSettingsForm);
