import YoutubeSearchedForIcon from "@mui/icons-material/YoutubeSearchedFor";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import {useTranslation} from "react-i18next";

const ChartZoomReset = ({id = undefined, className = undefined, sx = {}, color = "primary", onClick}) => {
  const {t} = useTranslation();

  return (
    <Tooltip title={t("components.chart.zoomReset.title")}>
      <IconButton
        id={id}
        className={className}
        sx={sx}
        aria-label={t("components.chart.zoomReset.label")}
        onClick={onClick}
      >
        <YoutubeSearchedForIcon color={color} />
      </IconButton>
    </Tooltip>
  );
};

export default ChartZoomReset;
