import React, {forwardRef, Fragment, useEffect, useImperativeHandle, useState} from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import {Box} from "@mui/material";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {compose} from "redux";
import CustomDialogTitle from "../../../custom-dialog-title";
import MaterialTable from "../../../material-table";
import {
  clearDataflowCache,
  createDataflowCache,
  deleteAllArtefactsCache,
  deleteAllDataflowCache,
  deleteArtefactCache,
  deleteCatalogCache,
  deleteDataflowCache,
  fetchDataflowCache,
  updateDataflowCache
} from "../../../../state/cache/cacheActions";
import {deleteDetailLevels} from "../../../../state/detailLevel/detailLevelActions";
import {secondsToHms} from "../../../../utils/formatters";
import {isValidIntegerInInclusiveRange} from "../../../../utils/validator";

const DELETE_TYPE_CATALOG = "catalog";
const DELETE_TYPE_DATAFLOWS = "dataflows";
const DELETE_TYPE_ARTEFACTS = "artefacts";
const DELETE_TYPE_ARTEFACT = "artefact";
const DELETE_TYPE_DATAFLOW = "dataflow";

const mapStateToProps = state => ({
  config: state.cacheConfig,
  geoglossaryBaseUrl: state.config.externalServices?.geoglossary,
  isMultiViewerTheme: state.app.themeConfig.isMultiViewer
});

const mapDispatchToProps = dispatch => ({
  fetchCache: nodeId => dispatch(fetchDataflowCache(nodeId)),
  clearCache: () => dispatch(clearDataflowCache()),
  updateCache: (nodeId, cacheId, ttl) => dispatch(updateDataflowCache(nodeId, cacheId, ttl)),
  createCache: (nodeId, data) => dispatch(createDataflowCache(nodeId, data)),
  deleteFromCache: (nodeId, dataflowId) => dispatch(deleteDataflowCache(nodeId, dataflowId)),
  deleteAllFromCache: nodeId => dispatch(deleteAllDataflowCache(nodeId)),
  deleteCatalogCache: nodeId => dispatch(deleteCatalogCache(nodeId)),
  deleteArtefactCacheAll: (nodeId, preserveDataflows) => dispatch(deleteAllArtefactsCache(nodeId, preserveDataflows)),
  deleteArtefactCache: (nodeId, dataflowId) =>
    dispatch(deleteArtefactCache(nodeId, "Dataflow", dataflowId, "Descendants")),
  deleteDetailLevels: (nodeId, dataflows, baseUrl) => dispatch(deleteDetailLevels(nodeId, dataflows, baseUrl))
});

const CacheSettingsForm = (
  {
    config,
    geoglossaryBaseUrl,
    isMultiViewerTheme,
    nodeId,
    fetchCache,
    createCache,
    updateCache,
    clearCache,
    deleteFromCache,
    deleteAllFromCache,
    deleteCatalogCache,
    deleteArtefactCacheAll,
    deleteArtefactCache,
    deleteDetailLevels
  },
  ref
) => {
  const {t} = useTranslation();

  const [needConfig, setNeedConfig] = useState(nodeId !== null && (!config || config.length === 0));
  const [hideEmptyDataflowCache, setEmptyDataflowCacheHidden] = useState(true);
  const [emptyRowsCounter, setEmptyRowsCounter] = useState(0);
  const [rowsCounter, setRowsCounter] = useState(0);
  const [isDeleteConfirmVisible, setDeleteConfirmVisibility] = useState(false);
  const [deleteType, setDeleteType] = useState(null);
  const [dataflowId, setDataflowId] = useState(null);

  useEffect(() => {
    if (!config || config.length === 0) {
      setRowsCounter(0);
      setEmptyRowsCounter(0);
      return;
    }
    setRowsCounter(config.length);
    setEmptyRowsCounter(config.filter(val => val?.cacheSize === 0).length);
  }, [config]);

  useEffect(() => {
    if (needConfig) {
      setNeedConfig(false);
      fetchCache(nodeId);
    }
  }, [config, fetchCache, nodeId, needConfig, setNeedConfig]);

  useImperativeHandle(ref, () => ({
    submit(f) {
      f();
    },
    cancel(f) {
      clearCache();
      f();
    }
  }));

  const handleDeleteConfirmOpen = (type, dataflowId) => {
    setDeleteType(type);
    setDataflowId(dataflowId);
    setDeleteConfirmVisibility(true);
  };

  const handleDeleteConfirmClose = () => {
    setDeleteConfirmVisibility(false);
    setDeleteType(null);
    setDataflowId(null);
  };

  const handleDeleteConfirmSubmit = () => {
    switch (deleteType) {
      case DELETE_TYPE_CATALOG:
        deleteCatalogCache(nodeId);
        break;
      case DELETE_TYPE_DATAFLOWS:
        deleteAllFromCache(nodeId);
        if (geoglossaryBaseUrl && isMultiViewerTheme) {
          deleteDetailLevels(nodeId, [], geoglossaryBaseUrl);
        }
        break;
      case DELETE_TYPE_DATAFLOW:
        deleteFromCache(nodeId, dataflowId);
        if (geoglossaryBaseUrl && isMultiViewerTheme) {
          deleteDetailLevels(nodeId, [dataflowId], geoglossaryBaseUrl);
        }
        break;
      case DELETE_TYPE_ARTEFACTS:
        deleteArtefactCacheAll(nodeId, true);
        break;
      case DELETE_TYPE_ARTEFACT:
        deleteArtefactCache(nodeId, dataflowId);
        break;

      default:
        break;
    }
    handleDeleteConfirmClose();
  };

  const getDeleteConfirmDialogContent = () => {
    switch (deleteType) {
      case DELETE_TYPE_CATALOG:
        return t("scenes.nodesSettings.cacheSettings.modals.deleteCacheConfirm.catalog.content");
      case DELETE_TYPE_DATAFLOWS:
        return t("scenes.nodesSettings.cacheSettings.modals.deleteCacheConfirm.dataflows.content");
      case DELETE_TYPE_DATAFLOW:
        return t("scenes.nodesSettings.cacheSettings.modals.deleteDataflowCache.title");
      case DELETE_TYPE_ARTEFACTS:
        return t("scenes.nodesSettings.cacheSettings.modals.deleteCacheConfirm.structures.content");
      case DELETE_TYPE_ARTEFACT:
        return t("scenes.nodesSettings.cacheSettings.modals.deleteCacheConfirm.artefact.content");
      default:
        return "";
    }
  };

  const getDeleteConfirmDialogTitle = () => {
    switch (deleteType) {
      case DELETE_TYPE_CATALOG:
        return t("scenes.nodesSettings.cacheSettings.modals.deleteCacheConfirm.catalog.title");
      case DELETE_TYPE_DATAFLOWS:
        return t("scenes.nodesSettings.cacheSettings.modals.deleteCacheConfirm.dataflows.title");
      case DELETE_TYPE_DATAFLOW:
        return t("scenes.nodeSettings.cacheSettings.table.actions.deleteRow");
      case DELETE_TYPE_ARTEFACTS:
        return t("scenes.nodesSettings.cacheSettings.modals.deleteCacheConfirm.structures.title");
      case DELETE_TYPE_ARTEFACT:
        return t("scenes.nodesSettings.cacheSettings.modals.deleteCacheConfirm.artefact.title");
      default:
        return "";
    }
  };

  return (
    <Fragment>
      <Paper
        variant="outlined"
        sx={{
          padding: "24px",
          marginBottom: "16px"
        }}
      >
        <FormControl id="delete-catalog-cache-btn">
          <Button
            size="large"
            variant="contained"
            color="primary"
            onClick={() => handleDeleteConfirmOpen(DELETE_TYPE_CATALOG)}
          >
            {t("scenes.nodeSettings.cacheSettings.deleteCatalogCache")}
          </Button>
        </FormControl>
      </Paper>
      <Paper
        variant="outlined"
        sx={{
          padding: "24px",
          marginBottom: "16px"
        }}
        style={{height: "100%"}}
      >
        <Grid container sx={{height: "100%"}}>
          <Grid item xs={12} container spacing={2}>
            <Grid item>
              <FormControl id="delete-dataflows-cache-btn">
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={() => handleDeleteConfirmOpen(DELETE_TYPE_DATAFLOWS)}
                >
                  {t("scenes.nodeSettings.cacheSettings.deleteAllDataflowCache")}
                </Button>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl id="delete-structures-cache-btn">
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={() => handleDeleteConfirmOpen(DELETE_TYPE_ARTEFACTS)}
                >
                  {t("scenes.nodeSettings.cacheSettings.deleteAllStructuresCache")}
                </Button>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <FormControl
              sx={{
                paddingLeft: "8px",
                marginTop: "16px"
              }}
            >
              <FormControlLabel
                label={t("scenes.nodeSettings.cacheSettings.hideEmptyEntries")}
                control={
                  <Checkbox
                    id="hide-empty-dataflow-cache"
                    name="active"
                    required
                    disabled={emptyRowsCounter === 0 || rowsCounter === 0}
                    checked={hideEmptyDataflowCache}
                    onChange={(e, value) => setEmptyDataflowCacheHidden(value)}
                  />
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sx={{height: "100%"}}>
            <MaterialTable
              idKey={"dataflow-cache"}
              onRowEdit={(newData, oldData, index) =>
                new Promise((resolve, reject) => {
                  if ((newData.ttl || "").length > 0 && !isValidIntegerInInclusiveRange(newData.ttl, -1)) {
                    // invalid ttl
                    window.error.show(t("scenes.nodeSettings.cacheSettings.invalidTtl.label"));
                    reject();
                    return;
                  }

                  if (newData.ttl === oldData.ttl) {
                    // no update is needed here
                    resolve();
                    return;
                  }

                  const dataUpdate = [...config];
                  //const index = oldData.tableData.id;
                  dataUpdate[index] = newData;

                  if (!newData.id || newData.id === "00000000-0000-0000-0000-000000000000") {
                    createCache(nodeId, newData);
                  } else {
                    updateCache(nodeId, newData.id, newData.ttl);
                  }

                  resolve();
                })
              }
              columns={[
                {
                  getClassName: () => "cache-settings-form__dataflow-title",
                  title: t("scenes.nodeSettings.cacheSettings.table.dataflowTitle"),
                  field: "title",
                  editDisabled: true
                },
                {
                  getClassName: () => "cache-settings-form__dataflow-validity",
                  title: t("scenes.nodeSettings.cacheSettings.table.dataflowValidity"),
                  field: "ttl",
                  render: rowData => secondsToHms(rowData.ttl, t)
                },
                {
                  getClassName: () => "cache-settings-form__dataflow-files",
                  title: t("scenes.nodeSettings.cacheSettings.table.dataflowFiles"),
                  field: "cachedDataflow",
                  editDisabled: true
                },
                {
                  getClassName: () => "cache-settings-form__dataflow-size",
                  title: t("scenes.nodeSettings.cacheSettings.table.dataflowSize"),
                  field: "cacheSize",
                  editDisabled: true,
                  render: rowData => rowData.cacheSize + " Kb"
                },
                {
                  getClassName: () => "cache-settings-form__dataflow-access",
                  title: t("scenes.nodeSettings.cacheSettings.table.dataflowAccess"),
                  field: "cachedDataAccess",
                  editDisabled: true
                }
              ]}
              data={config?.filter(val => !hideEmptyDataflowCache || val.cacheSize > 0) || []}
              actions={[
                {
                  className: "cache-settings-form__dataflow-and-structure-delete",
                  icon: <DeleteIcon />,
                  tooltip: t("scenes.nodeSettings.cacheSettings.table.actions.deleteRow"),
                  onClick: (_, row) => {
                    handleDeleteConfirmOpen(DELETE_TYPE_DATAFLOW, row?.dataflowId);
                  }
                },
                rowData => {
                  return {
                    icon: (
                      <Box
                        className="cache-settings-form__structure-delete"
                        sx={theme => ({
                          ...theme.typography.button
                        })}
                      >
                        {t("scenes.nodeSettings.cacheSettings.table.actions.deleteOnlyStructure")}
                      </Box>
                    ),
                    tooltip: t("scenes.nodeSettings.cacheSettings.table.actions.deleteOnlyStructure"),
                    onClick: (_, row) => {
                      const datasetId = (row?.dataflowId || "").split("+").join(",");
                      handleDeleteConfirmOpen(DELETE_TYPE_ARTEFACT, datasetId);
                    }
                  };
                }
              ]}
            />
          </Grid>
        </Grid>
      </Paper>
      <Dialog
        id="settings-select__nodes-settings-form__cache-setting-form__delete-confirm-dialog"
        fullWidth
        open={isDeleteConfirmVisible}
        onClose={handleDeleteConfirmClose}
      >
        <CustomDialogTitle onClose={handleDeleteConfirmClose}>{getDeleteConfirmDialogTitle()}</CustomDialogTitle>
        <DialogContent>{getDeleteConfirmDialogContent()}</DialogContent>
        <DialogActions>
          <Button
            id="settings-select__nodes-settings-form__cache-setting-form__delete-confirm-dialog__cancel-button"
            onClick={handleDeleteConfirmClose}
            color="primary"
          >
            {t("commons.confirm.cancel")}
          </Button>
          <Button
            id="settings-select__nodes-settings-form__cache-setting-form__delete-confirm-dialog__confirm-button"
            onClick={handleDeleteConfirmSubmit}
          >
            {t("commons.confirm.confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true}),
  forwardRef
)(CacheSettingsForm);
