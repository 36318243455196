import React from "react";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import _ from "lodash";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import CustomDialogTitle from "../../custom-dialog-title";
import FullscreenDialog from "../../fullscreen-dialog";
import ItemContainer from "../../item-container";
import PreviewProvider from "../../item-container/PreviewContext";
import {clearOtherConfigItemContainersDataset} from "../../../state/otherConfig/otherConfigActions";
import {otherConfigSelector} from "../../../state/otherConfig/otherConfigSelectors";
import {ITEMCONTAINER_ELEM_FILTER_DIMENSION_KEY} from "../../../utils/itemContainers";

const getStaticItemContainer = itemContainer => {
  if (!itemContainer) {
    return null;
  }
  const staticItemContainer = _.cloneDeep(itemContainer);
  staticItemContainer.item.forEach(row => {
    row.forEach(col => {
      col[ITEMCONTAINER_ELEM_FILTER_DIMENSION_KEY] = null;
    });
  });

  return staticItemContainer;
};

function PreviewDialog({asDashboard, isPreviewVisible, setPreviewVisibility}) {
  const {itemContainer} = useSelector(otherConfigSelector);
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const clearDatasets = () => dispatch(clearOtherConfigItemContainersDataset());
  return (
    <FullscreenDialog
      open={isPreviewVisible}
      onClose={() => setPreviewVisibility(false)}
      sx={{
        "& .MuiDialogTitle-root, & .MuiDialogContent-root, & .MuiDialogActions-root": {
          background: "#f5f5f5"
        }
      }}
    >
      <CustomDialogTitle onClose={() => setPreviewVisibility(false)}>
        {asDashboard
          ? t("scenes.dashboardsSettings.modals.previewDashboard.title")
          : t("scenes.customPagesSettings.modals.previewCustomPage.title")}
      </CustomDialogTitle>
      <DialogContent>
        <PreviewProvider>
          <ItemContainer itemContainer={getStaticItemContainer(itemContainer)} />
        </PreviewProvider>
      </DialogContent>
      <DialogActions>
        <Button
          id="user-item-container-settings-form__preview-dialog__close-btn"
          onClick={() => {
            setPreviewVisibility(false);
            clearDatasets();
          }}
        >
          {t("commons.confirm.close")}
        </Button>
      </DialogActions>
    </FullscreenDialog>
  );
}

export default PreviewDialog;
