export const PUBLIC_HUB_EXTRAS = [
  "MapLayer",
  "TreePageSize",
  "HeaderLogoSmallURL",
  "HeaderLogoAlt",
  "HeaderLogoHref",
  "headerLogoTitle",
  "DisableArtefactsCache",
  "CustomColors",
  "Footer",
  "Registration",
  "Access"
];
export const PUBLIC_NODE_EXTRAS = [
  "HiddenAttributes",
  "DownloadFormats",
  "DefaultLastNPeriods",
  "QueryInfo",
  "TimePeriodRangeStart",
  "TimePeriodRangeEnd",
  "TimePeriodFormatYear",
  "TimePeriodFormatMonth",
  "TimePeriodFormatDay",
  "TimePeriodFormatQuarter",
  "TimePeriodFormatSemester",
  "HideLabelOnCategoryWithImage",
  "HierarchyOnlyAttributes",
  "HideHierarchyOnlyRows",
  "SupportedLanguages",
  "DefaultLanguage",
  "ChartsSettings",
  "ShowHubDashboards",
  "CustomColors"
];

const getParsedExtras = extrasList => {
  const parsedExtras = {};

  (extrasList ?? []).forEach(({key, value}) => {
    let parsedValue;
    try {
      parsedValue = JSON.parse(value);
    } catch (e) {
      parsedValue = value;
    }
    parsedExtras[key] = parsedValue;
  });

  return parsedExtras;
};

const getSerializedExtras = (extrasDictionary, publicKeys = []) => {
  const serializedDictionary = [];

  Object.keys(extrasDictionary ?? {}).forEach(key => {
    const value = extrasDictionary[key];
    if (value !== null && value !== undefined) {
      serializedDictionary.push({
        key: key,
        value: JSON.stringify(value),
        isPublic: publicKeys.includes(key)
      });
    }
  });

  return serializedDictionary;
};

const EXTRAS = {
  parse: getParsedExtras,
  serialize: getSerializedExtras
};

export default EXTRAS;
