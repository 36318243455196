import React, {Fragment, useEffect, useMemo, useState} from "react";
import {Paper, useTheme} from "@mui/material";
import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TextField from "@mui/material/TextField";
import _ from "lodash";
import {useTranslation} from "react-i18next";
import {v4 as uuidv4} from "uuid";
import ChartJsonStatWrapper from "../../chart/ChartJsonStatWrapper";
import {
  CHART_DIM_ORDER_SELECTOR_VALUE_ASC,
  CHART_DIM_ORDER_SELECTOR_VALUE_DEFAULT,
  CHART_DIM_ORDER_SELECTOR_VALUE_DESC,
  CHART_SORTING_VALUE_DEFAULT
} from "../../chart/constants";
import CustomEmpty from "../../custom-empty";
import I18nTextField from "../../i18n-text-field";
import Map from "../../map/single-viewer/Map";
import {
  TABLE_SETTINGS_CELLS_HEADER_MERGE_SIDE_MERGE,
  TABLE_SETTINGS_CELLS_HEADER_MERGE_SIDE_SPLIT,
  TABLE_SETTINGS_CELLS_HEADER_SPLIT_SIDE_MERGE,
  TABLE_SETTINGS_CELLS_HEADER_SPLIT_SIDE_SPLIT,
  TABLE_SETTINGS_CELLS_HEADER_UND_SIDE_UND
} from "../../table-settings-form/constants";
import Table from "../../table/single-viewer";
import {
  TEMPORAL_DIM_ORDER_SELECTOR_VALUE_ASC,
  TEMPORAL_DIM_ORDER_SELECTOR_VALUE_DESC,
  TEMPORAL_DIM_ORDER_SELECTOR_VALUE_UNSET
} from "../../temporal-dim-order-selector/constants";
import {isChartLayoutCartesianByType, validateTemplateDefaultView} from "../constant";
import {CRITERIA_FILTER_TYPE_PERIODS} from "../../../utils/criteria";
import {getDimensionLabel, getDimensionValueLabel, VARIATION_DIMENSION_KEY} from "../../../utils/dataset";
import {localizeI18nObj} from "../../../utils/i18n";
import {isValidIntegerInInclusiveRange} from "../../../utils/validator";
import {
  getChartSettingsFromViewTemplateLayouts,
  getMapSettingsFromViewTemplateLayouts
} from "../../../utils/viewTemplate";
import themeConfig from "../../../theme-config/config.json";

const $ = window.jQuery;

const fieldStyle = {
  margin: "8px 0",
  "& .MuiTextField-root": {
    margin: "0px"
  }
};

const viewerStyle = {
  width: "100%",
  height: "100%",
  position: "relative"
};

const paperStyle = {
  padding: "16px"
};

const cardHeaderStyle = {
  padding: "0 0 8px 0"
};

const handleStyle = () => {
  const alertHeight = $("#template-builder__alert").outerHeight(true) || 0;
  const tabsHeight = $("#template-builder__tabs").outerHeight(true) || 0;
  $("#template-builder__tab-content").height(`calc(100% - ${alertHeight + tabsHeight}px)`);
};

function SingleViewerPlusTemplateBuilder(props) {
  const {
    defaultLanguage,
    languages,
    nodeId,
    template,
    onChange,
    viewers,
    jsonStat,
    labelFormat,
    tableLayout,
    chartLayoutCartesian,
    chartLayoutRadial,
    mapLayout,
    criteria,
    timeDim,
    isTableEnabled,
    isMapEnabled,
    isChartEnabled
  } = props;

  const theme = useTheme();

  const {t} = useTranslation();

  const [tabId, setTabId] = useState(0);

  const [mapId] = useState("map__" + uuidv4());

  const [chartType, setChartType] = useState("bar");

  const cardStyle = {
    padding: theme.spacing(2)
  };

  useEffect(() => {
    window.addEventListener("resize", handleStyle);
    return () => window.removeEventListener("resize", handleStyle);
  }, []);

  useEffect(() => {
    handleStyle();
  });

  const chartLayout = useMemo(() => {
    const isChartCartesian = isChartLayoutCartesianByType(chartType);
    return isChartCartesian ? chartLayoutCartesian : chartLayoutRadial;
  }, [chartType, chartLayoutCartesian, chartLayoutRadial]);

  if (!template) {
    return <span />;
  }

  const decodeHeaderSideCellsMode = () => {
    if (!template.layouts.splitHeaderCells && template.layouts.splitSideCells) {
      return TABLE_SETTINGS_CELLS_HEADER_MERGE_SIDE_SPLIT;
    } else if (template.layouts.splitHeaderCells && !template.layouts.splitSideCells) {
      return TABLE_SETTINGS_CELLS_HEADER_SPLIT_SIDE_MERGE;
    } else if (template.layouts.splitHeaderCells && template.layouts.splitSideCells) {
      return TABLE_SETTINGS_CELLS_HEADER_SPLIT_SIDE_SPLIT;
    } else if (template.layouts.splitHeaderCells === null && template.layouts.splitSideCells === null) {
      return TABLE_SETTINGS_CELLS_HEADER_UND_SIDE_UND;
    } else {
      return TABLE_SETTINGS_CELLS_HEADER_MERGE_SIDE_MERGE;
    }
  };

  const encodeHeaderSideCellsModeToTemplate = value => {
    const tmpTemplate = {
      ...template,
      layouts: {
        ...template.layouts
      }
    };
    switch (value) {
      case TABLE_SETTINGS_CELLS_HEADER_MERGE_SIDE_MERGE:
        tmpTemplate.layouts.splitHeaderCells = false;
        tmpTemplate.layouts.splitSideCells = false;
        break;
      case TABLE_SETTINGS_CELLS_HEADER_MERGE_SIDE_SPLIT:
        tmpTemplate.layouts.splitHeaderCells = false;
        tmpTemplate.layouts.splitSideCells = true;
        break;
      case TABLE_SETTINGS_CELLS_HEADER_SPLIT_SIDE_MERGE:
        tmpTemplate.layouts.splitHeaderCells = true;
        tmpTemplate.layouts.splitSideCells = false;
        break;
      case TABLE_SETTINGS_CELLS_HEADER_SPLIT_SIDE_SPLIT:
        tmpTemplate.layouts.splitHeaderCells = true;
        tmpTemplate.layouts.splitSideCells = true;
        break;
      default:
        tmpTemplate.layouts.splitHeaderCells = null;
        tmpTemplate.layouts.splitSideCells = null;
        break;
    }
    return tmpTemplate;
  };

  const hiddenDimensions = _.uniq([
    ...(jsonStat?.id || []).filter(dim => dim !== VARIATION_DIMENSION_KEY),
    ...(template?.hiddenDimensions || [])
  ]);

  const tableLockedDimensions = _.uniq([
    ...(jsonStat?.id || []).filter(dim => dim !== VARIATION_DIMENSION_KEY),
    ...(template?.layouts?.tableLockedDimensions || [])
  ]);

  const chartLockedDimensionsCartesian = _.uniq([
    ...(jsonStat?.id || []).filter(dim => dim !== VARIATION_DIMENSION_KEY),
    ...(template?.layouts?.chartLockedDimensionsCartesian || [])
  ]);

  const chartLockedDimensionsRadial = _.uniq([
    ...(jsonStat?.id || []).filter(dim => dim !== VARIATION_DIMENSION_KEY),
    ...(template?.layouts?.chartLockedDimensionsRadial || [])
  ]);

  const mapSettings = getMapSettingsFromViewTemplateLayouts(template.layouts);
  const chartSettings = getChartSettingsFromViewTemplateLayouts(template.layouts);

  const isTimeDimInTable = !(tableLayout?.filters || []).includes(timeDim);
  const isTimeDimInChart = !(chartLayout?.filters || []).includes(timeDim);

  const cartesianSecondaryDim = chartLayoutCartesian?.secondaryDim?.toString() || "";
  const radialSecondaryDim = chartLayoutRadial?.secondaryDim?.toString() || "";
  const cartesianSecondaryDimValues = chartLayoutCartesian?.secondaryDimValues || [];
  const radialSecondaryDimValues = chartLayoutRadial?.secondaryDimValues || [];

  const getDimLabel = dimensionId => (jsonStat ? getDimensionLabel(jsonStat, null, dimensionId, t) : null);

  const getDimValueLabel = (dimensionId, valueId) =>
    jsonStat ? getDimensionValueLabel(jsonStat, null, dimensionId, valueId, t) : null;

  // If there is a secondary dimension and the selected order is different
  // from default, I set the first value of the secondary dimension as preselected.
  const handleSortingOptionCartesian = order => {
    let value = template.layouts.chartObsSortingValueCartesian;
    if (order === CHART_DIM_ORDER_SELECTOR_VALUE_DEFAULT) {
      value = CHART_SORTING_VALUE_DEFAULT;
    } else {
      if (value === CHART_SORTING_VALUE_DEFAULT && cartesianSecondaryDimValues.length > 0) {
        value = cartesianSecondaryDimValues[0];
      }
    }
    onChange({
      ...template,
      layouts: {
        ...template.layouts,
        chartObsSortingOrderCartesian: order,
        chartObsSortingValueCartesian: value
      }
    });
  };
  const handleSortingOptionRadial = order => {
    let value = template.layouts.obsSortingValueRadial;
    if (order === CHART_DIM_ORDER_SELECTOR_VALUE_DEFAULT) {
      value = CHART_SORTING_VALUE_DEFAULT;
    } else {
      if (value === CHART_SORTING_VALUE_DEFAULT && radialSecondaryDimValues.length > 0) {
        value = radialSecondaryDimValues[0];
      }
    }
    onChange({
      ...template,
      layouts: {
        ...template.layouts,
        chartObsSortingOrderRadial: order,
        chartObsSortingValueRadial: value
      }
    });
  };

  return (
    <Fragment>
      {criteria?.[timeDim]?.type === CRITERIA_FILTER_TYPE_PERIODS && (
        <Alert id="template-builder__alert" severity="warning">
          {t("scenes.dataViewer.warnings.viewTemplateLastNPeriods.label")}
        </Alert>
      )}
      <Box id="template-builder__tabs">
        <Tabs
          value={tabId}
          variant="scrollable"
          scrollButtons="auto"
          onChange={(event, newValue) => {
            if (newValue === 0) {
              document.getElementById("template-builder__tab-content").style.overflowY = "auto";
            } else {
              document.getElementById("template-builder__tab-content").style.overflowY = "hidden";
            }
            setTabId(newValue);
          }}
        >
          <Tab
            id="data-viewer__template-builder__tab-options"
            key={0}
            label={t("scenes.dataViewer.templateBuilder.tabs.options.label")}
          />
          <Tab
            id="data-viewer__template-builder__tab-table"
            key={1}
            label={t("scenes.dataViewer.templateBuilder.tabs.table.label")}
          />
          <Tab
            id="data-viewer__template-builder__tab-chart"
            key={2}
            label={t("scenes.dataViewer.templateBuilder.tabs.chart.label")}
          />
          {mapLayout && <Tab key={3} label={t("scenes.dataViewer.templateBuilder.tabs.map.label")} />}
        </Tabs>
      </Box>
      <Box
        id="template-builder__tab-content"
        sx={{
          overflowY: "auto",
          overflowX: "hidden",
          height: "calc(100% - 48px)",
          paddingTop: "16px"
        }}
      >
        {tabId === 0 && (
          <Fragment>
            <Card variant="outlined">
              <CardHeader
                title={t("scenes.dataViewer.templateBuilder.tabs.options.cards.general.title")}
                titleTypographyProps={{variant: "subtitle1"}}
              />
              <Grid container spacing={3} sx={cardStyle}>
                <Grid item xs={6}>
                  <FormControl id="data-viewer__template-builder__tab-options__title" fullWidth sx={fieldStyle}>
                    <I18nTextField
                      label={t("scenes.dataViewer.templateBuilder.tabs.options.cards.general.form.title")}
                      required
                      variant="outlined"
                      value={template.title}
                      onChange={value => onChange({...template, title: value})}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl id="data-viewer__template-builder__tab-options__default-view" fullWidth sx={fieldStyle}>
                    <TextField
                      select
                      label={t("scenes.dataViewer.templateBuilder.tabs.options.cards.general.form.defaultView")}
                      defaultValue="table"
                      value={template.defaultView}
                      variant="outlined"
                      onChange={ev => onChange({...template, defaultView: ev.target.value})}
                      SelectProps={{SelectDisplayProps: {"aria-haspopup": true}}}
                      error={!validateTemplateDefaultView(template, isTableEnabled, isMapEnabled, isChartEnabled)}
                      helperText={
                        !validateTemplateDefaultView(template, isTableEnabled, isMapEnabled, isChartEnabled)
                          ? t("scenes.dataViewer.templateBuilder.tabs.options.cards.general.form.invalidDefaultView")
                          : null
                      }
                    >
                      {viewers.map(({type, title}, idx) => (
                        <MenuItem key={idx} value={type}>
                          {title}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControlLabel
                    label={t("scenes.dataViewer.templateBuilder.tabs.options.cards.general.form.enableCriteria")}
                    sx={fieldStyle}
                    control={
                      <Checkbox
                        id="data-viewer__template-builder__tab-options__enable-criteria"
                        checked={template.enableCriteria}
                        onChange={(ev, value) => onChange({...template, enableCriteria: value})}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControlLabel
                    label={t("scenes.dataViewer.templateBuilder.tabs.options.cards.general.form.enableLayout")}
                    sx={fieldStyle}
                    control={
                      <Checkbox
                        id="data-viewer__template-builder__tab-options__enable-layout"
                        checked={template.enableLayout}
                        onChange={(ev, value) => onChange({...template, enableLayout: value})}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControlLabel
                    label={t("scenes.dataViewer.templateBuilder.tabs.options.cards.general.form.enableVariation")}
                    sx={fieldStyle}
                    control={
                      <Checkbox
                        id="data-viewer__template-builder__tab-options__enable-variation"
                        checked={template.enableVariation}
                        onChange={(ev, value) => onChange({...template, enableVariation: value})}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={3} />
                {isTableEnabled && (
                  <Grid item xs={3} key="checkBoxTable">
                    <FormControlLabel
                      label={t("scenes.dataViewer.templateBuilder.tabs.options.cards.general.form.disableTable")}
                      sx={fieldStyle}
                      control={
                        <Checkbox
                          id="data-viewer__template-builder__tab-options__disable-table"
                          checked={template.layouts.disableTable || false}
                          onChange={(ev, value) =>
                            onChange({
                              ...template,
                              layouts: {
                                ...template.layouts,
                                disableTable: value
                              }
                            })
                          }
                        />
                      }
                    />
                  </Grid>
                )}
                {isChartEnabled && (
                  <Grid item xs={3} key="checkBoxChart">
                    <FormControlLabel
                      label={t("scenes.dataViewer.templateBuilder.tabs.options.cards.general.form.disableChart")}
                      sx={fieldStyle}
                      control={
                        <Checkbox
                          id="data-viewer__template-builder__tab-options__disable-chart"
                          checked={template.layouts.disableChart || false}
                          onChange={(ev, value) =>
                            onChange({
                              ...template,
                              layouts: {
                                ...template.layouts,
                                disableChart: value
                              }
                            })
                          }
                        />
                      }
                    />
                  </Grid>
                )}
                {isMapEnabled && (
                  <Grid item xs={3} key="checkBoxMap">
                    <FormControlLabel
                      label={t("scenes.dataViewer.templateBuilder.tabs.options.cards.general.form.disableMap")}
                      sx={fieldStyle}
                      control={
                        <Checkbox
                          id="data-viewer__template-builder__tab-options__disable-map"
                          checked={template.layouts.disableMap || false}
                          onChange={(ev, value) =>
                            onChange({
                              ...template,
                              layouts: {
                                ...template.layouts,
                                disableMap: value
                              }
                            })
                          }
                        />
                      }
                    />
                  </Grid>
                )}
                <Grid
                  item
                  xs={3 + ([isTableEnabled, isMapEnabled, isChartEnabled].filter(el => !el) || []).length * 3}
                />
                <Grid item xs={6}>
                  <FormControl
                    id="data-viewer__template-builder__tab-options__decimal-places"
                    fullWidth
                    sx={fieldStyle}
                  >
                    <TextField
                      label={t("scenes.dataViewer.templateBuilder.tabs.options.cards.general.form.decimalPlaces.label")}
                      value={template.decimalNumber}
                      variant="outlined"
                      type="number"
                      required
                      onChange={ev => onChange({...template, decimalNumber: ev.target.value})}
                      error={
                        (template.decimalNumber || "").length > 0 &&
                        !isValidIntegerInInclusiveRange(template.decimalNumber, 0, 20)
                      }
                      helperText={
                        (template.decimalNumber || "").length > 0 &&
                        !isValidIntegerInInclusiveRange(template.decimalNumber, 0, 20)
                          ? t("commons.validation.inRange", {min: 0, max: 20})
                          : null
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl
                    id="data-viewer__template-builder__tab-options__decimal-separator"
                    fullWidth
                    sx={fieldStyle}
                  >
                    <I18nTextField
                      select
                      label={t(
                        "scenes.dataViewer.templateBuilder.tabs.options.cards.general.form.decimalSeparator.label"
                      )}
                      value={template.decimalSeparator}
                      defaultValue="."
                      variant="outlined"
                      required
                      onChange={value => onChange({...template, decimalSeparator: value})}
                      SelectProps={{SelectDisplayProps: {"aria-haspopup": true}}}
                    >
                      <MenuItem value=".">
                        {t(
                          "scenes.dataViewer.templateBuilder.tabs.options.cards.general.form.decimalSeparator.values.dot"
                        )}
                      </MenuItem>
                      <MenuItem value=",">
                        {t(
                          "scenes.dataViewer.templateBuilder.tabs.options.cards.general.form.decimalSeparator.values.comma"
                        )}
                      </MenuItem>
                    </I18nTextField>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    id="data-viewer__template-builder__tab-options__rounding-strategy"
                    fullWidth
                    sx={fieldStyle}
                  >
                    <TextField
                      select
                      label={t(
                        "scenes.dataViewer.templateBuilder.tabs.options.cards.general.form.roundingStrategy.label"
                      )}
                      variant="outlined"
                      required
                      value={template?.roundingStrategy === undefined ? "inherit" : template.roundingStrategy}
                      defaultValue="inherit"
                      onChange={ev => onChange({...template, roundingStrategy: ev.target.value})}
                      SelectProps={{SelectDisplayProps: {"aria-haspopup": true}}}
                    >
                      <MenuItem value="inherit">
                        {t(
                          "scenes.dataViewer.templateBuilder.tabs.options.cards.general.form.roundingStrategy.values.inherit"
                        )}
                      </MenuItem>
                      <MenuItem value="NormalRounding">
                        {t(
                          "scenes.dataViewer.templateBuilder.tabs.options.cards.general.form.roundingStrategy.values.round"
                        )}
                      </MenuItem>
                      <MenuItem value="RoundingUp">
                        {t(
                          "scenes.dataViewer.templateBuilder.tabs.options.cards.general.form.roundingStrategy.values.ceil"
                        )}
                      </MenuItem>
                      <MenuItem value="Truncation">
                        {t(
                          "scenes.dataViewer.templateBuilder.tabs.options.cards.general.form.roundingStrategy.values.floor"
                        )}
                      </MenuItem>
                    </TextField>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    multiple
                    variant="outlined"
                    options={hiddenDimensions}
                    value={template.hiddenDimensions || []}
                    onChange={(e, values) => {
                      onChange({...template, hiddenDimensions: values});
                    }}
                    renderInput={params => (
                      <FormControl
                        id="data-viewer__template-builder__tab-options__hidden-dimensions"
                        fullWidth
                        sx={fieldStyle}
                      >
                        <TextField
                          {...params}
                          label={t(
                            "scenes.dataViewer.templateBuilder.tabs.options.cards.general.form.hiddenDimensions.label"
                          )}
                          placeholder={t(
                            "scenes.dataViewer.templateBuilder.tabs.options.cards.general.form.hiddenDimensions.placeholder"
                          )}
                          variant="outlined"
                        />
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    id="data-viewer__template-builder__tab-options__temporal-dim-order"
                    fullWidth
                    sx={fieldStyle}
                  >
                    <TextField
                      select
                      label={t(
                        "scenes.dataViewer.templateBuilder.tabs.options.cards.general.form.temporalDimOrder.label"
                      )}
                      value={template.layouts.temporalDimOrder || TEMPORAL_DIM_ORDER_SELECTOR_VALUE_UNSET}
                      variant="outlined"
                      onChange={ev =>
                        onChange({
                          ...template,
                          layouts: {
                            ...template.layouts,
                            temporalDimOrder:
                              ev.target.value !== TEMPORAL_DIM_ORDER_SELECTOR_VALUE_UNSET ? ev.target.value : null
                          }
                        })
                      }
                      SelectProps={{SelectDisplayProps: {"aria-haspopup": true}}}
                    >
                      <MenuItem value={TEMPORAL_DIM_ORDER_SELECTOR_VALUE_UNSET}>
                        {t(
                          "scenes.dataViewer.templateBuilder.tabs.options.cards.general.form.temporalDimOrder.values.unset"
                        )}
                      </MenuItem>
                      <MenuItem value={TEMPORAL_DIM_ORDER_SELECTOR_VALUE_ASC}>
                        {t(
                          "scenes.dataViewer.templateBuilder.tabs.options.cards.general.form.temporalDimOrder.values.asc"
                        )}
                      </MenuItem>
                      <MenuItem value={TEMPORAL_DIM_ORDER_SELECTOR_VALUE_DESC}>
                        {t(
                          "scenes.dataViewer.templateBuilder.tabs.options.cards.general.form.temporalDimOrder.values.desc"
                        )}
                      </MenuItem>
                    </TextField>
                  </FormControl>
                </Grid>
              </Grid>
            </Card>
            <Card variant="outlined" sx={{marginTop: "16px"}}>
              <CardHeader
                title={t("scenes.dataViewer.templateBuilder.tabs.options.cards.table.title")}
                titleTypographyProps={{variant: "subtitle1"}}
              />
              <Grid container spacing={3} sx={cardStyle}>
                {!themeConfig.enableOptimization && (
                  <Grid item xs={12}>
                    <FormControl
                      id="data-viewer__template-builder__tab-options__merge-split-cells"
                      fullWidth
                      sx={fieldStyle}
                    >
                      <TextField
                        select
                        fullWidth
                        label={t("components.tableSettings.mergeSplitCells.title")}
                        value={decodeHeaderSideCellsMode() || ""}
                        onChange={ev => onChange({...encodeHeaderSideCellsModeToTemplate(ev.target.value)})}
                        variant="outlined"
                      >
                        <MenuItem value={TABLE_SETTINGS_CELLS_HEADER_UND_SIDE_UND}>
                          {t("components.tableSettings.mergeSplitCells.values.unspecified")}
                        </MenuItem>
                        <MenuItem value={TABLE_SETTINGS_CELLS_HEADER_MERGE_SIDE_MERGE}>
                          {t("components.tableSettings.mergeSplitCells.values.mergeHeaderAndSide")}
                        </MenuItem>
                        <MenuItem value={TABLE_SETTINGS_CELLS_HEADER_MERGE_SIDE_SPLIT}>
                          {t("components.tableSettings.mergeSplitCells.values.mergeHeaderSplitSide")}
                        </MenuItem>
                        <MenuItem value={TABLE_SETTINGS_CELLS_HEADER_SPLIT_SIDE_MERGE}>
                          {t("components.tableSettings.mergeSplitCells.values.splitHeaderMergeSide")}
                        </MenuItem>
                        <MenuItem value={TABLE_SETTINGS_CELLS_HEADER_SPLIT_SIDE_SPLIT}>
                          {t("components.tableSettings.mergeSplitCells.values.splitHeaderAndSide")}
                        </MenuItem>
                      </TextField>
                    </FormControl>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <FormControl
                    id="data-viewer__template-builder__tab-options__table-empty-cell-placeholder"
                    fullWidth
                    sx={fieldStyle}
                  >
                    <TextField
                      label={t("scenes.dataViewer.templateBuilder.tabs.options.cards.table.form.emptyCellPlaceholder")}
                      value={template.layouts.tableEmptyChar}
                      variant="outlined"
                      required
                      onChange={ev =>
                        onChange({
                          ...template,
                          layouts: {
                            ...template.layouts,
                            tableEmptyChar: ev.target.value
                          }
                        })
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={3} key="checkBoxLockTableDimensions">
                  <FormControlLabel
                    label={t("scenes.dataViewer.templateBuilder.tabs.options.cards.table.form.lockDimensions")}
                    sx={fieldStyle}
                    control={
                      <Checkbox
                        id="data-viewer__template-builder__tab-options__lock-table-dimensions"
                        checked={template.layouts.lockTableDimensions || false}
                        onChange={(ev, value) =>
                          onChange({
                            ...template,
                            layouts: {
                              ...template.layouts,
                              lockTableDimensions: value
                            }
                          })
                        }
                      />
                    }
                  />
                </Grid>
                <Grid item xs={9}>
                  <Autocomplete
                    multiple
                    variant="outlined"
                    options={tableLockedDimensions}
                    value={template.layouts.tableLockedDimensions || []}
                    onChange={(ev, values) =>
                      onChange({
                        ...template,
                        layouts: {
                          ...template.layouts,
                          tableLockedDimensions: values
                        }
                      })
                    }
                    renderInput={params => (
                      <FormControl
                        id="data-viewer__template-builder__tab-options__table-locked-dimensions"
                        fullWidth
                        sx={fieldStyle}
                      >
                        <TextField
                          {...params}
                          label={t(
                            "scenes.dataViewer.templateBuilder.tabs.options.cards.general.form.tableLockedDimensions.label"
                          )}
                          placeholder={t(
                            "scenes.dataViewer.templateBuilder.tabs.options.cards.general.form.tableLockedDimensions.placeholder"
                          )}
                          variant="outlined"
                        />
                      </FormControl>
                    )}
                  />
                </Grid>
              </Grid>
            </Card>
            <Paper variant="outlined" sx={{...paperStyle, marginTop: "16px"}}>
              <CardHeader
                title={t("scenes.dataViewer.templateBuilder.tabs.options.cards.graph.title")}
                titleTypographyProps={{variant: "subtitle1"}}
                sx={cardHeaderStyle}
              />
              {/* Assi cartesiani */}
              <Paper variant="outlined" sx={{...paperStyle, marginTop: "16px"}}>
                <CardHeader subheader={t("components.chartSettings.header.chartCartesian")} sx={cardHeaderStyle} />
                <Box sx={fieldStyle}>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <FormControlLabel
                        label={t(
                          "scenes.dataViewer.templateBuilder.tabs.options.cards.graph.form.lockChartDimensionsRadial"
                        )}
                        sx={fieldStyle}
                        control={
                          <Checkbox
                            id="data-viewer__template-builder__tab-options__lock-chart-dimensions-cartesian"
                            checked={template.layouts.lockChartDimensionsCartesian || false}
                            onChange={(ev, value) =>
                              onChange({
                                ...template,
                                layouts: {
                                  ...template.layouts,
                                  lockChartDimensionsCartesian: value
                                }
                              })
                            }
                          />
                        }
                      />
                    </Grid>
                    <Grid item xs={9}>
                      <Autocomplete
                        multiple
                        variant="outlined"
                        options={chartLockedDimensionsCartesian}
                        value={template.layouts.chartLockedDimensionsCartesian || []}
                        onChange={(ev, values) =>
                          onChange({
                            ...template,
                            layouts: {
                              ...template.layouts,
                              chartLockedDimensionsCartesian: values
                            }
                          })
                        }
                        renderInput={params => (
                          <FormControl
                            id="data-viewer__template-builder__tab-options__chart-locked-dimensions-cartesian"
                            fullWidth
                            sx={fieldStyle}
                          >
                            <TextField
                              {...params}
                              label={t(
                                "scenes.dataViewer.templateBuilder.tabs.options.cards.general.form.graphLockedDimensions.label"
                              )}
                              placeholder={t(
                                "scenes.dataViewer.templateBuilder.tabs.options.cards.general.form.graphLockedDimensions.placeholder"
                              )}
                              variant="outlined"
                            />
                          </FormControl>
                        )}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box sx={fieldStyle}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        id="template-obs-sorting-order-cartesian__select"
                        select
                        fullWidth
                        sx={fieldStyle}
                        variant="outlined"
                        label={t("components.chartSettings.general.sortingCartesian.order.label")}
                        value={template.layouts.chartObsSortingOrderCartesian}
                        onChange={ev => handleSortingOptionCartesian(ev.target.value)}
                      >
                        <MenuItem value={CHART_DIM_ORDER_SELECTOR_VALUE_DEFAULT}>
                          {t("components.chartSettings.general.sorting.values.default")}
                        </MenuItem>
                        <MenuItem value={CHART_DIM_ORDER_SELECTOR_VALUE_ASC}>
                          {t("components.chartSettings.general.sorting.values.asc")}
                        </MenuItem>
                        <MenuItem value={CHART_DIM_ORDER_SELECTOR_VALUE_DESC}>
                          {t("components.chartSettings.general.sorting.values.desc")}
                        </MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="template-obs-sorting-value-cartesian__select"
                        select
                        fullWidth
                        sx={fieldStyle}
                        variant="outlined"
                        disabled={
                          template.layouts.chartObsSortingValueCartesian === CHART_DIM_ORDER_SELECTOR_VALUE_DEFAULT ||
                          cartesianSecondaryDimValues.length === 0
                        }
                        value={template.layouts.chartObsSortingValueCartesian}
                        label={t("components.chartSettings.general.sortingCartesian.value.label", {
                          dimLabel: cartesianSecondaryDim.length > 0 ? `(${getDimLabel(cartesianSecondaryDim)})` : ""
                        })}
                        onChange={ev =>
                          onChange({
                            ...template,
                            layouts: {
                              ...template.layouts,
                              chartObsSortingValueCartesian: ev.target.value
                            }
                          })
                        }
                      >
                        {cartesianSecondaryDimValues.map(val => (
                          <MenuItem key={val} value={val}>
                            {getDimValueLabel(cartesianSecondaryDim, val)}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
              {/* Assi radiali */}
              <Paper variant="outlined" sx={{...paperStyle, marginTop: "16px"}}>
                <CardHeader subheader={t("components.chartSettings.header.chartRadial")} sx={cardHeaderStyle} />
                <Box sx={fieldStyle}>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <FormControlLabel
                        label={t(
                          "scenes.dataViewer.templateBuilder.tabs.options.cards.graph.form.lockChartDimensionsRadial"
                        )}
                        sx={fieldStyle}
                        control={
                          <Checkbox
                            id="data-viewer__template-builder__tab-options__lock-chart-dimensions-radial"
                            checked={template.layouts.lockChartDimensionsRadial || false}
                            onChange={(ev, value) =>
                              onChange({
                                ...template,
                                layouts: {
                                  ...template.layouts,
                                  lockChartDimensionsRadial: value
                                }
                              })
                            }
                          />
                        }
                      />
                    </Grid>
                    <Grid item xs={9}>
                      <Autocomplete
                        multiple
                        variant="outlined"
                        options={chartLockedDimensionsRadial}
                        value={template.layouts.chartLockedDimensionsRadial || []}
                        onChange={(ev, values) =>
                          onChange({
                            ...template,
                            layouts: {
                              ...template.layouts,
                              chartLockedDimensionsRadial: values
                            }
                          })
                        }
                        renderInput={params => (
                          <FormControl
                            id="data-viewer__template-builder__tab-options__chart-locked-dimensions-radial"
                            fullWidth
                            sx={fieldStyle}
                          >
                            <TextField
                              {...params}
                              label={t(
                                "scenes.dataViewer.templateBuilder.tabs.options.cards.general.form.graphLockedDimensions.label"
                              )}
                              placeholder={t(
                                "scenes.dataViewer.templateBuilder.tabs.options.cards.general.form.graphLockedDimensions.placeholder"
                              )}
                              variant="outlined"
                            />
                          </FormControl>
                        )}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box sx={fieldStyle}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        id="template-obs-sorting-order-radial__select"
                        select
                        fullWidth
                        sx={fieldStyle}
                        variant="outlined"
                        label={t("components.chartSettings.general.sortingRadial.order.label")}
                        value={template.layouts.chartObsSortingOrderRadial}
                        onChange={ev => handleSortingOptionRadial(ev.target.value)}
                      >
                        <MenuItem value={CHART_DIM_ORDER_SELECTOR_VALUE_DEFAULT}>
                          {t("components.chartSettings.general.sorting.values.default")}
                        </MenuItem>
                        <MenuItem value={CHART_DIM_ORDER_SELECTOR_VALUE_ASC}>
                          {t("components.chartSettings.general.sorting.values.asc")}
                        </MenuItem>
                        <MenuItem value={CHART_DIM_ORDER_SELECTOR_VALUE_DESC}>
                          {t("components.chartSettings.general.sorting.values.desc")}
                        </MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="template-obs-sorting-value-radial__select"
                        select
                        fullWidth
                        sx={fieldStyle}
                        variant="outlined"
                        disabled={
                          template.layouts.chartObsSortingOrderRadial === CHART_DIM_ORDER_SELECTOR_VALUE_DEFAULT ||
                          radialSecondaryDimValues.length === 0
                        }
                        value={template.layouts.chartObsSortingValueRadial}
                        label={t("components.chartSettings.general.sortingRadial.value.label", {
                          dimLabel: radialSecondaryDim.length > 0 ? `(${getDimLabel(radialSecondaryDim)})` : ""
                        })}
                        onChange={ev =>
                          onChange({
                            ...template,
                            layouts: {
                              ...template.layouts,
                              chartObsSortingValueRadial: ev.target.value
                            }
                          })
                        }
                      >
                        {radialSecondaryDimValues.map(val => (
                          <MenuItem key={val} value={val}>
                            {getDimValueLabel(radialSecondaryDim, val)}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Paper>
          </Fragment>
        )}
        {tabId === 1 && jsonStat && tableLayout && (
          <Box sx={viewerStyle}>
            {isTableEnabled && !template.layouts.disableTable ? (
              <Fragment>
                <FormControl id="data-viewer__template-builder__tab-table__default-layout" fullWidth sx={fieldStyle}>
                  <TextField
                    select
                    label={t("scenes.dataViewer.templateBuilder.tabs.table.form.defaultLayout.label")}
                    value={template.layouts.tableDefaultLayout}
                    variant="outlined"
                    onChange={ev =>
                      onChange({
                        ...template,
                        layouts: {
                          ...template.layouts,
                          tableDefaultLayout: ev.target.value,
                          tableLayout: ev.target.value === "custom" ? tableLayout : null
                        }
                      })
                    }
                    SelectProps={{SelectDisplayProps: {"aria-haspopup": true}}}
                  >
                    <MenuItem value="custom">
                      {t("scenes.dataViewer.templateBuilder.tabs.table.form.defaultLayout.values.custom")}
                    </MenuItem>
                    <MenuItem value="default">
                      {t("scenes.dataViewer.templateBuilder.tabs.table.form.defaultLayout.values.default")}
                    </MenuItem>
                  </TextField>
                </FormControl>
                <Box
                  sx={{
                    width: "100%",
                    height: "calc(100% - 16px - 64px)",
                    marginTop: "16px"
                  }}
                >
                  {template.layouts.tableDefaultLayout === "custom" ? (
                    <Table
                      jsonStat={jsonStat}
                      layout={tableLayout}
                      labelFormat={labelFormat}
                      decimalSeparator={localizeI18nObj(template.decimalSeparator, defaultLanguage, languages)}
                      roundingStrategy={template.roundingStrategy}
                      decimalPlaces={template.decimalNumber}
                      emptyChar={template.layouts.tableEmptyChar}
                      invertedDims={
                        template.layouts.temporalDimOrder === TEMPORAL_DIM_ORDER_SELECTOR_VALUE_DESC && isTimeDimInTable
                          ? [timeDim]
                          : null
                      }
                    />
                  ) : (
                    <CustomEmpty
                      text={t("scenes.dataViewer.templateBuilder.tabs.table.form.defaultLayout.defaultPlaceholder")}
                    />
                  )}
                </Box>
              </Fragment>
            ) : (
              <CustomEmpty text={t("scenes.dataViewer.templateBuilder.tabs.table.disabledViewer")} />
            )}
          </Box>
        )}
        {tabId === 2 && jsonStat && chartLayout && (
          <Box sx={viewerStyle}>
            {isChartEnabled && !template.layouts.disableChart ? (
              <Fragment>
                <FormControl id="data-viewer__template-builder__tab-chart__chart-type" fullWidth sx={fieldStyle}>
                  <TextField
                    select
                    label={t("scenes.dataViewer.templateBuilder.tabs.chart.cards.form.chartType.label")}
                    defaultValue="table"
                    value={chartType}
                    variant="outlined"
                    onChange={ev => setChartType(ev.target.value)}
                    SelectProps={{SelectDisplayProps: {"aria-haspopup": true}}}
                  >
                    {viewers.slice(2).map(({type, title}, idx) => (
                      <MenuItem key={idx} value={type}>
                        {title}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
                <Box
                  sx={{
                    width: "100%",
                    height: "calc(100% - 16px - 64px)",
                    position: "relative",
                    marginTop: "16px"
                  }}
                >
                  <ChartJsonStatWrapper
                    type={chartType}
                    jsonStat={jsonStat}
                    layout={chartLayout}
                    labelFormat={labelFormat}
                    decimalSeparator={localizeI18nObj(template.decimalSeparator, defaultLanguage, languages)}
                    roundingStrategy={template.roundingStrategy}
                    decimalPlaces={template.decimalNumber}
                    chartSettings={chartSettings}
                    invertedDims={
                      template.layouts.temporalDimOrder === TEMPORAL_DIM_ORDER_SELECTOR_VALUE_DESC && isTimeDimInChart
                        ? [timeDim]
                        : null
                    }
                  />
                </Box>
              </Fragment>
            ) : (
              <CustomEmpty text={t("scenes.dataViewer.templateBuilder.tabs.chart.disabledViewer")} />
            )}
          </Box>
        )}
        {tabId === 3 && jsonStat && mapLayout && (
          <Box sx={viewerStyle}>
            {isMapEnabled && !template.layouts.disableMap ? (
              <Map
                mapId={mapId}
                nodeId={nodeId}
                jsonStat={jsonStat}
                layout={mapLayout}
                labelFormat={labelFormat}
                decimalSeparator={localizeI18nObj(template.decimalSeparator, defaultLanguage, languages)}
                roundingStrategy={template.roundingStrategy}
                decimalPlaces={template.decimalNumber}
                defaultDetailLevel={template.layouts.detailLevel}
                disableDetailLevelSelector
                initialBaseLayer={mapSettings?.baseLayer}
                defaultSettings={mapSettings}
                disableSettings
                disableBaseLayer
              />
            ) : (
              <CustomEmpty text={t("scenes.dataViewer.templateBuilder.tabs.map.disabledViewer")} />
            )}
          </Box>
        )}
      </Box>
    </Fragment>
  );
}

export default SingleViewerPlusTemplateBuilder;
