import React, {forwardRef, Fragment, useEffect, useImperativeHandle, useState} from "react";
import {Visibility} from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {compose} from "redux";
import {goToDataset} from "../../../../links";
import CustomDialogTitle from "../../../custom-dialog-title";
import MaterialTable from "../../../material-table";
import {
  clearNodeTemplatesConfig,
  deleteNodeTemplatesConfigTemplate,
  fetchNodeTemplatesConfig
} from "../../../../state/noteTemplatesConfig/nodeTemplatesConfigActions";
import {getI18nObjCustomFilterAndSearch, localizeI18nObj} from "../../../../utils/i18n";

const mapStateToProps = state => ({
  defaultLanguage: state.app.language,
  languages: state.app.languages,
  templates: state.nodeTemplatesConfig.templates
});

const mapDispatchToProps = dispatch => ({
  fetchTemplates: nodeId => dispatch(fetchNodeTemplatesConfig(nodeId)),
  deleteTemplate: (nodeId, id) => dispatch(deleteNodeTemplatesConfigTemplate(nodeId, id)),
  clearTemplates: () => dispatch(clearNodeTemplatesConfig())
});

const TemplatesSettingsForm = (
  {
    defaultLanguage,
    languages,
    nodes,
    nodeId,
    defaultNodeOpen,
    templates,
    fetchTemplates,
    deleteTemplate,
    clearTemplates,
    onTemplatesClose
  },
  ref
) => {
  const [needTemplates, setNeedTemplates] = useState(true);

  const {t} = useTranslation();

  useEffect(() => {
    if (needTemplates) {
      setNeedTemplates(false);
      fetchTemplates(nodeId);
    }
  }, [templates, needTemplates, setNeedTemplates, fetchTemplates, nodeId]);

  useImperativeHandle(ref, () => ({
    cancel(f) {
      clearTemplates();
      f();
    }
  }));

  const [deleteTemplateId, setDeleteTemplateId] = useState(null);

  return (
    templates && (
      <Fragment>
        <MaterialTable
          idKey="templatesTable"
          columns={[
            {
              field: "datasetId",
              title: t("scenes.nodeSettings.templatesSettings.table.columns.datasetId"),
              getClassName: () => "templates-settings-form__template-table-dataset-id"
            },
            {
              field: "title",
              title: t("scenes.nodeSettings.templatesSettings.table.columns.templateName"),
              render: ({title}) => localizeI18nObj(title, defaultLanguage, languages),
              customFilterAndSearch: getI18nObjCustomFilterAndSearch(defaultLanguage, languages),
              getClassName: () => "templates-settings-form__template-table-title"
            }
          ]}
          data={(templates || []).filter(({nodeId}) => nodes.find(node => node.nodeId === nodeId))}
          actions={[
            {
              className: "templates-settings-form__view-data-btn",
              icon: <Visibility />,
              tooltip: t("scenes.nodeSettings.templatesSettings.table.actions.viewData"),
              onClick: (_, {nodeId, datasetId}) => {
                const nodeCode = nodes.find(node => node.nodeId === nodeId).code.toLowerCase();
                return window.location.href.toLowerCase().includes(nodeCode) &&
                  window.location.href.toLowerCase().includes(datasetId.toLowerCase())
                  ? onTemplatesClose()
                  : goToDataset(nodeCode, [], datasetId);
              }
            },
            {
              className: "templates-settings-form__delete-template-btn",
              icon: <DeleteIcon />,
              tooltip: t("scenes.nodeSettings.templatesSettings.table.actions.deleteTemplate"),
              onClick: (_, {viewTemplateId}) => setDeleteTemplateId(viewTemplateId)
            }
          ]}
        />

        <Dialog
          id="templates-settings-form__delete-template-dialog"
          maxWidth="xs"
          open={deleteTemplateId !== null}
          onClose={() => setDeleteTemplateId(null)}
        >
          <CustomDialogTitle onClose={() => setDeleteTemplateId(null)}>
            {t("scenes.nodeSettings.templatesSettings.modals.deleteTemplate.title")}
          </CustomDialogTitle>
          <DialogContent>{t("scenes.nodeSettings.templatesSettings.modals.deleteTemplate.content")}</DialogContent>
          <DialogActions>
            <Button
              id="templates-settings-form__cancel-delete-template-btn"
              autoFocus
              onClick={() => setDeleteTemplateId(null)}
            >
              {t("commons.confirm.cancel")}
            </Button>
            <Button
              id="templates-settings-form__delete-template-btn"
              onClick={() => {
                deleteTemplate(nodeId, deleteTemplateId);
                setDeleteTemplateId(null);
              }}
            >
              {t("commons.confirm.confirm")}
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    )
  );
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true}),
  forwardRef
)(TemplatesSettingsForm);
