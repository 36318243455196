import React, {Fragment, useState} from "react";
import {Box, CardHeader, Divider, FormControl, List, ListItem, ListItemText, Paper, Tooltip} from "@mui/material";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import FileSaver from "file-saver";
import {SubmitHandler, useForm} from "react-hook-form";
import {getGeometriesErrorTranslations} from "../../../../constants/getGeometriesErrorTranslations";
import CustomDialogTitle from "../../../custom-dialog-title";
import FileDownloadIcon from "../../../custom-icons/FileDownloadIcon";
import FileInputTs from "../../../file-input-ts";
import useApi from "../../../../state/api/useApi";
import useLanguages from "../../../../state/hooks/useLanguages";
import {initRequest, RequestMethod} from "../../../../middlewares/request/requestActions";
import {getFilenameFromContentDisposition} from "./utils";

interface AdvancedConfigurationFormProps {
  nodeId: number;
  onClose: () => void;
}

interface AdvancedConfigurationFormType {
  csv: FileList;
}

const AdvancedConfigurationForm = ({nodeId, onClose}: AdvancedConfigurationFormProps) => {
  const {t} = useLanguages();
  const [fileNotSentError, setFileNotSentError] = useState(false);

  const {
    handleSubmit,
    formState: {errors},
    register
  } = useForm<AdvancedConfigurationFormType>({
    defaultValues: {
      csv: null
    }
  });

  const {call, request, error} = useApi<string, Record<string, string[]> | string>(
    initRequest("importGeometries", `Nodes/${nodeId}/Geometries`, RequestMethod.POST, undefined, t => ({
      onStart: t("scenes.nodesSettings.advancedConfigurationForm.loading")
    })),
    {
      clearOnUnmount: true,
      onSuccess: () => {
        onClose();
      },
      onError: e => {
        if (!e) {
          setFileNotSentError(true);
        }
      }
    }
  );

  const downloadCurrentGeometry = useApi(
    initRequest(
      `GeometriesDownload`,
      `Nodes/${nodeId}/Geometries/Download`,
      RequestMethod.GET,
      [],
      t => ({
        onStart: t("scenes.nodesSettings.advancedConfigurationForm.downloadingCurrentGeometry")
      }),
      undefined,
      undefined,
      (statusCode: number) => statusCode === 406,
      undefined,
      undefined,
      true
    ),
    {
      clearOnUnmount: true,
      onSuccess: (data, headers) => {
        FileSaver.saveAs(
          new Blob([data], {type: "text/plain;charset=utf-8"}),
          getFilenameFromContentDisposition(headers["content-disposition"])
        );
      }
    }
  );

  const submitHandler: SubmitHandler<AdvancedConfigurationFormType> = ({csv}) => {
    setFileNotSentError(false);
    const AdditionalSettingsFormData = new FormData();
    AdditionalSettingsFormData.append("csv", csv[0]);
    call({...request, data: AdditionalSettingsFormData});
  };

  return (
    <Dialog open={!!nodeId} disableEnforceFocus maxWidth="sm" fullWidth onClose={onClose}>
      <form onSubmit={handleSubmit(submitHandler)}>
        <CustomDialogTitle onClose={onClose}>{t("components.advancedConfigurationForm.title")}</CustomDialogTitle>
        <DialogContent>
          {(fileNotSentError || error) && <ValidationErrorsContainer errorObj={error} />}
          <Paper variant="outlined">
            <CardHeader subheader={t("components.advancedConfigurationForm.cardHeader")} />
            <Box sx={{padding: "20px", marginBottom: "1px", display: "flex", alignItems: "center", gridGap: "6px"}}>
              <FormControl fullWidth>
                <FileInputTs
                  inputProps={{
                    ...register("csv", {
                      required: true
                    }),
                    accept: ".csv"
                  }}
                  textFieldProps={{
                    label: t("components.advancedConfigurationForm.uploadLabel"),
                    error: !!errors.csv,
                    helperText: !!errors.csv && t("commons.validation.required")
                  }}
                />
              </FormControl>
              <Tooltip title={t("components.advancedConfigurationForm.geometryDownload.tooltip")}>
                <IconButton
                  onClick={() => downloadCurrentGeometry.call({...downloadCurrentGeometry.request})}
                  style={{padding: 12}}
                >
                  <FileDownloadIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Fragment>
            <Button onClick={onClose}>{t("commons.confirm.cancel")}</Button>
            <Button type="submit">{t("commons.confirm.submit")}</Button>
          </Fragment>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AdvancedConfigurationForm;

interface ValidationErrorsContainerProps {
  errorObj: Record<string, string[]> | string;
}

const ValidationErrorsContainer = ({errorObj}: ValidationErrorsContainerProps) => {
  const {t} = useLanguages();

  if (typeof errorObj === "string" || !errorObj) {
    return (
      <Alert
        style={{
          marginBottom: 16
        }}
        severity="error"
      >
        {getGeometriesErrorTranslations(t)[errorObj] || t("errors.importGeometries.generic")}
      </Alert>
    );
  }

  return (
    <Box sx={{maxHeight: "250px", marginBottom: "16px", overflow: "auto"}}>
      <Alert severity="error">
        <List dense style={{width: 450}}>
          {Object.keys(errorObj).map(key => {
            return (
              <>
                <ListItem key={key}>
                  <ListItemText
                    primary={key}
                    secondary={errorObj[key].map(e => {
                      return (
                        <>
                          {getGeometriesErrorTranslations(t)[e]}
                          <br />
                        </>
                      );
                    })}
                  />
                </ListItem>
                <Divider />
              </>
            );
          })}
        </List>
      </Alert>
    </Box>
  );
};
