import React, {Fragment, useEffect, useState} from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import GetAppIcon from "@mui/icons-material/GetApp";
import PublishIcon from "@mui/icons-material/Publish";
import {Box, Tooltip} from "@mui/material";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {v4 as uuidv4} from "uuid";
import {getFileUploadUrl} from "../../serverApi/urls";
import {addSpinnerMessage, markSpinnerMessage} from "../../state/spinner/spinnerActions";

const mapStateToProps = state => ({
  baseURL: state.config.baseURL,
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  addSpinnerMessage: (uuid, message) => dispatch(addSpinnerMessage(uuid, message)),
  markSpinnerMessage: (uuid, isError) => dispatch(markSpinnerMessage(uuid, isError))
});

export const getFileName = formData => {
  if (formData !== null) {
    const files = formData.get("files");
    if (files) {
      return files.name;
    }
  }
  return "";
};

const FileInput = ({
  label,
  value,
  onChange,
  accept = "image/*, video/*",
  baseURL,
  error,
  helperText,
  required,
  addSpinnerMessage,
  markSpinnerMessage,
  user,
  onUpload,
  enableDownload
}) => {
  const [id] = useState(uuidv4());
  const [fileName, setFileName] = useState(null);
  const {t} = useTranslation();

  useEffect(() => {
    setFileName(value?.substring(value.lastIndexOf("/") + 1) || "");
  }, [value]);

  return (
    <Fragment>
      <Box
        component="input"
        accept={accept}
        sx={{display: "none"}}
        id={id}
        type="file"
        onChange={e => {
          const file = e.target.files[0];

          let fileFormData = new FormData();
          fileFormData.append("files", file);

          if (onUpload) {
            onUpload(fileFormData);
          } else {
            const uuid = uuidv4();

            addSpinnerMessage(uuid, t("components.fileInput.messages.upload.start"));

            fetch(baseURL + getFileUploadUrl(), {
              method: "POST",
              body: fileFormData,
              headers: {
                Authorization: user.token ? `bearer ${user.token}` : undefined
              },
              credentials: "include"
            })
              .then(res => res.json())
              .then(res => {
                markSpinnerMessage(uuid);
                const filePath = res[0];
                if (onChange) {
                  onChange(filePath);
                }
                setFileName(filePath.substring(filePath.lastIndexOf("/") + 1));
              })
              .catch(() => {
                markSpinnerMessage(uuid, true);
                window.error.show(t("components.fileInput.messages.upload.error"));
              });
          }
        }}
        disabled={!!value}
      />
      <TextField
        variant="outlined"
        label={label}
        value={fileName || ""}
        InputProps={{
          startAdornment: (
            <InputAdornment
              sx={{
                marginRight: theme => theme.spacing(2)
              }}
              position="start"
            >
              <Tooltip title={t("components.fileInput.upload.title")}>
                <label htmlFor={id}>
                  <Button
                    className="file-input__upload-btn"
                    startIcon={<PublishIcon />}
                    variant="outlined"
                    disabled={!!value}
                    component="span"
                  >
                    {t("components.fileInput.upload.label")}
                  </Button>
                </label>
              </Tooltip>
            </InputAdornment>
          ),
          endAdornment: value && (
            <InputAdornment position="end">
              {enableDownload && (
                <Tooltip title={t("components.fileInput.download.title")}>
                  <IconButton
                    onClick={() => {
                      fetch(`${baseURL}${value}`)
                        .then(response => response.blob())
                        .then(blob => {
                          const blobURL = URL.createObjectURL(blob);
                          const a = document.createElement("a");
                          a.href = blobURL;
                          a.style = "display: none";
                          a.download = fileName ?? value;
                          document.body.appendChild(a);
                          a.click();
                          a.remove();
                        })
                        .catch(() => {
                          window.error.show(t("components.fileInput.messages.download.error"));
                        });
                    }}
                  >
                    <GetAppIcon />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title={t("components.fileInput.reset.title")}>
                <IconButton
                  onClick={() => {
                    setFileName("");
                    if (onUpload) {
                      onUpload(null);
                    }
                    if (onChange) {
                      onChange("");
                    }
                    const input = document.getElementById(id);
                    input.value = null;
                  }}
                >
                  <CancelIcon />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          )
        }}
        error={error}
        required={required}
        helperText={helperText}
      />
    </Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FileInput);
