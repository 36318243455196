import React, {Fragment, useCallback, useEffect, useMemo, useState} from "react";
import {Box} from "@mui/material";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import Popover from "@mui/material/Popover";
import _ from "lodash";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {v4 as uuidv4} from "uuid";
import Call from "../../../hocs/call";
import usePrevious from "../../../hooks/usePrevious";
import CustomDialogTitle from "../../custom-dialog-title";
import CustomEmpty from "../../custom-empty";
import SanitizedHTML from "../../sanitized-html";
import DimensionFilterForm from "./DimensionFilterForm";
import ObservationFilterForm from "./ObservationFilterForm";
import Scrollbars from "./Scrollbars";
import TerritorialClassificationsForm from "./TerritorialClassificationsForm";
import {getDimensionLabelFromCombinations, getDimensionValueLabelFromCombinations} from "../../../utils/dataset";
import {localizeI18nObj} from "../../../utils/i18n";
import {generateTableHtml, PAGE_COL_COUNT, PAGE_ROW_COUNT} from "./utils";
import "./style.css";

const $ = window.jQuery;

const mapStateToProps = ({app, appConfig}) => ({
  hiddenDimensionValueLabels: localizeI18nObj(appConfig.hiddenDimensionValueLabels, app.language, app.languages)
});

const PAGE_REQUEST_OFFSET_DEFAULT = 10;

let scrollToSelected = true;

function TablePaginated(props) {
  const {
    hiddenDimensionValueLabels,

    dimensions,
    dimensionLabels,
    dimensionMap,
    territoryDim,
    timeDim,
    layout,
    rowCount,
    rows,
    colCount,
    cols,
    seriesAttributeMap,
    observationAttributeMap,
    data,
    dataSize,
    fetchData,
    filters,
    onFilter,
    decimalSeparator,
    roundingStrategy,
    decimalPlaces,
    emptyChar,
    detailLevelSuffix,
    selectedTerritoryId,
    onRowClick,
    synthesisAndVariabilityMeasures,
    showArithmeticMean = false,
    showStandardDeviation = false,
    showCoefficientOfVariation = false,
    territorialClassifications,
    fetchTerritorialClassifications,
    territorialClassificationsConfig,
    setTerritorialClassificationsConfig,
    territorialClassificationsFetched,
    territorialClassificationsValues,
    fetchTerritorialClassificationsValues,
    onPageGenerated
  } = props;

  const {t} = useTranslation();

  const [uuid] = useState(uuidv4());

  const [htmlTable, setHtmlTable] = useState("");

  const [rowIdx, setRowIdx] = useState(0);
  const [colIdx, setColIdx] = useState(0);

  const pageRowCount = useMemo(() => (dataSize ? Math.min(dataSize, PAGE_ROW_COUNT) : PAGE_ROW_COUNT), [dataSize]);
  const pageColCount = useMemo(() => PAGE_COL_COUNT, []);

  const pageRequestOffset = useMemo(
    () => (dataSize ? Math.min(dataSize - pageRowCount, PAGE_REQUEST_OFFSET_DEFAULT) : 0),
    [pageRowCount, dataSize]
  );

  const [isFetchingData, setIsFetchingData] = useState(true);

  const [terrIdFilterAnchorEl, setTerrIdFilterAnchorEl] = useState(null);
  const [terrLabelFilterAnchorEl, setTerrLabelFilterAnchorEl] = useState(null);
  const [obsFilterAnchorEl, setObsFilterAnchorEl] = useState(null);
  const [obsFilterColIdx, setObsFilterColIdx] = useState(null);

  const [terrClassConfigVisibility, setTerrClassConfigVisibility] = useState(false);
  const [terrClassConfigTmp, setTerrClassConfigTmp] = useState(null);
  const prevTerritorialClassificationsConfig = usePrevious(territorialClassificationsConfig);
  const prevTerritorialClassificationsValues = usePrevious(territorialClassificationsValues);

  const prevSelectedTerritoryId = usePrevious(selectedTerritoryId);

  const prevSynthesisAndVariabilityMeasures = usePrevious(synthesisAndVariabilityMeasures);
  const prevShowArithmeticMean = usePrevious(showArithmeticMean);
  const prevShowStandardDeviation = usePrevious(showStandardDeviation);
  const prevShowCoefficientOfVariation = usePrevious(showCoefficientOfVariation);

  const [isHorizontalScrollbarVisible, setHorizontalScrollbarVisibility] = useState(false);
  const [isVerticalScrollbarVisible, setVerticalScrollbarVisibility] = useState(false);

  const [attributes, setAttributes] = useState(null);

  const popoverCommonProps = useMemo(
    () => ({
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center"
      },
      transformOrigin: {
        vertical: "top",
        horizontal: "center"
      },
      PaperProps: {
        sx: {
          width: "320px",
          padding: "8px",
          overflow: "hidden",
          "& .MuiOutlinedInput-input": {
            padding: "8px"
          },
          "& .MuiSelect-outlined": {
            paddingRight: "32px"
          }
        }
      }
    }),
    []
  );

  const isDataCompatible = useCallback(
    (rowIdx, data) => {
      if (!data) {
        return false;
      } else if (!dataSize) {
        return true;
      } else {
        return rowIdx >= data.offset && rowIdx + pageRowCount <= data.offset + dataSize;
      }
    },
    [pageRowCount, dataSize]
  );

  const getTableHtml = useCallback(
    (newRowIdx, newColIdx, newTerrClassConfig) =>
      !rows
        ? ""
        : generateTableHtml(
            uuid,
            dimensions.map(({id}) => id),
            dimensionMap,
            dimensionLabels,
            territoryDim,
            timeDim,
            layout,
            rows,
            cols,
            newRowIdx,
            newColIdx,
            pageRowCount,
            pageColCount,
            data,
            filters,
            decimalSeparator,
            roundingStrategy,
            decimalPlaces,
            emptyChar,
            detailLevelSuffix,
            selectedTerritoryId,
            synthesisAndVariabilityMeasures,
            showArithmeticMean,
            showStandardDeviation,
            showCoefficientOfVariation,
            newTerrClassConfig,
            territorialClassificationsValues,
            hiddenDimensionValueLabels,
            t,
            onPageGenerated
          ),
    [
      uuid,
      dimensions,
      dimensionMap,
      dimensionLabels,
      territoryDim,
      timeDim,
      layout,
      rows,
      cols,
      pageRowCount,
      pageColCount,
      data,
      filters,
      decimalSeparator,
      roundingStrategy,
      decimalPlaces,
      emptyChar,
      detailLevelSuffix,
      selectedTerritoryId,
      synthesisAndVariabilityMeasures,
      showArithmeticMean,
      showStandardDeviation,
      showCoefficientOfVariation,
      territorialClassificationsValues,
      hiddenDimensionValueLabels,
      t,
      onPageGenerated
    ]
  );

  const handleVerticalScroll = useCallback(
    newRowIdx => {
      setRowIdx(newRowIdx);
      if (isDataCompatible(newRowIdx, data)) {
        setIsFetchingData(false);
        setHtmlTable(getTableHtml(newRowIdx, colIdx, territorialClassificationsConfig));
      } else {
        setIsFetchingData(true);
      }
    },
    [colIdx, territorialClassificationsConfig, data, isDataCompatible, getTableHtml]
  );

  const handleHorizontalScroll = useCallback(
    newColIdx => {
      setColIdx(newColIdx);
      setIsFetchingData(false);
      setHtmlTable(getTableHtml(rowIdx, newColIdx, territorialClassificationsConfig));
    },
    [rowIdx, territorialClassificationsConfig, getTableHtml]
  );

  /* generating html table on server response */
  useEffect(() => {
    if (isFetchingData) {
      if (isDataCompatible(rowIdx, data)) {
        setIsFetchingData(false);
        setHtmlTable(getTableHtml(rowIdx, colIdx, territorialClassificationsConfig));
      } else {
        const offset = rowIdx < pageRequestOffset ? 0 : rowIdx - pageRequestOffset;
        fetchData(offset);
      }
    }
  }, [
    isFetchingData,
    data,
    rowIdx,
    colIdx,
    territorialClassificationsConfig,
    pageRequestOffset,
    isDataCompatible,
    getTableHtml,
    fetchData
  ]);

  /* generating html table on synthesis and variability measures changes */
  useEffect(() => {
    if (synthesisAndVariabilityMeasures) {
      if (
        synthesisAndVariabilityMeasures !== prevSynthesisAndVariabilityMeasures ||
        showArithmeticMean !== prevShowArithmeticMean ||
        showStandardDeviation !== prevShowStandardDeviation ||
        showCoefficientOfVariation !== prevShowCoefficientOfVariation
      ) {
        setHtmlTable(getTableHtml(rowIdx, colIdx, territorialClassificationsConfig));
      }
    }
  }, [
    synthesisAndVariabilityMeasures,
    prevSynthesisAndVariabilityMeasures,
    showArithmeticMean,
    prevShowArithmeticMean,
    showStandardDeviation,
    prevShowStandardDeviation,
    showCoefficientOfVariation,
    prevShowCoefficientOfVariation,
    getTableHtml,
    rowIdx,
    colIdx,
    territorialClassificationsConfig
  ]);

  const handleTerrClassConfigOpen = useCallback(() => {
    setTerrClassConfigVisibility(true);
    setTerrClassConfigTmp(territorialClassificationsConfig);
  }, [territorialClassificationsConfig]);

  const handleTerrClassConfigClose = useCallback(() => {
    setTerrClassConfigVisibility(false);
  }, []);

  const handleTerrClassConfigSubmit = useCallback(
    newConfig => {
      setTerrClassConfigVisibility(false);
      setTerritorialClassificationsConfig(newConfig);
    },
    [setTerritorialClassificationsConfig]
  );

  useEffect(() => {
    if (
      (territorialClassificationsConfig ?? []).length !== (prevTerritorialClassificationsConfig ?? []).length ||
      (territorialClassificationsConfig ?? []).some(
        (config, idx) => !_.isEqual(config, prevTerritorialClassificationsConfig[idx])
      )
    ) {
      const missingClassifications = (territorialClassificationsConfig ?? [])
        .map(({id}) => id)
        .filter(level => !(territorialClassificationsFetched ?? []).includes(level));
      if (missingClassifications.length > 0) {
        fetchTerritorialClassificationsValues(missingClassifications);
      } else {
        setHtmlTable(getTableHtml(rowIdx, colIdx, territorialClassificationsConfig));
      }
    }
  }, [
    prevTerritorialClassificationsConfig,
    territorialClassificationsConfig,
    territorialClassificationsFetched,
    getTableHtml,
    rowIdx,
    colIdx,
    fetchTerritorialClassificationsValues
  ]);

  useEffect(() => {
    if (!_.isEqual(prevTerritorialClassificationsValues ?? null, territorialClassificationsValues ?? null)) {
      setHtmlTable(getTableHtml(rowIdx, colIdx, territorialClassificationsConfig));
    }
  }, [
    prevTerritorialClassificationsValues,
    territorialClassificationsValues,
    getTableHtml,
    rowIdx,
    colIdx,
    territorialClassificationsConfig
  ]);

  /* scroll to selected row */
  useEffect(() => {
    if ((selectedTerritoryId ?? null) !== (prevSelectedTerritoryId ?? null)) {
      if (selectedTerritoryId && scrollToSelected) {
        const selectedRowIdx = rows.map(row => row[territoryDim]).indexOf(selectedTerritoryId);
        handleVerticalScroll(selectedRowIdx >= 0 ? selectedRowIdx : 0);
      } else {
        scrollToSelected = true;
        setHtmlTable(getTableHtml(rowIdx, colIdx, territorialClassificationsConfig));
      }
    }
  }, [
    selectedTerritoryId,
    prevSelectedTerritoryId,
    rows,
    territoryDim,
    getTableHtml,
    rowIdx,
    colIdx,
    territorialClassificationsConfig,
    handleVerticalScroll
  ]);

  /* tooltips and filters handling */
  useEffect(() => {
    /** merged header tooltip handling **/
    $(`#paginated-table__${uuid} #h-1 > th.c:not(.c-rb)`).hover(
      function () {
        const $mergedHeaderTooltip = $(`#paginated-table__${uuid} #paginated-table__tooltip__merged-header`);

        const $elem = $(this).get(0);
        const rect = $elem.getBoundingClientRect();

        const colIdx = Number($elem.id.split("-")[1]);
        const colDims = $elem.id.split("-")[3].split(",");

        const appendDimension = (dim, value) =>
          $mergedHeaderTooltip.append(
            $(`<li class="cttt" style="white-space: normal"><b>${dim}</b>: <i>${value}</i></li>`)
          );

        $mergedHeaderTooltip.empty();
        colDims.forEach(dim => {
          appendDimension(
            getDimensionLabelFromCombinations(dim, dimensionLabels),
            getDimensionValueLabelFromCombinations(dim, cols[colIdx][dim], dimensionMap)
          );
        });

        const left =
          rect.x < window.innerWidth / 4 ? rect.right + 16 : rect.left - $mergedHeaderTooltip.innerWidth() - 16;

        $mergedHeaderTooltip.css({
          visibility: "visible",
          top: rect.top - 32,
          left: left
        });
      },
      function () {
        const $mergedHeaderTooltip = $(`#paginated-table__${uuid} #paginated-table__tooltip__merged-header`);

        $mergedHeaderTooltip.attr("style", "").empty();
      }
    );

    /** attribute's tooltip handling **/
    $(`#paginated-table__${uuid} .ca .ct`)
      .hover(
        function () {
          const $attributeTooltip = $(`#paginated-table__${uuid} #paginated-table__tooltip__attribute`);
          const $mergedHeaderTooltip = $(`#paginated-table__${uuid} #paginated-table__tooltip__merged-header`);

          $mergedHeaderTooltip.css({visibility: "hidden"});

          const $elem = $(this).get(0);
          const rect = $elem.getBoundingClientRect();

          const appendAttribute = (attrLabel, attrValueLabel) =>
            $attributeTooltip.append(
              $(`<li class="cttt" style="white-space: normal"><b>${attrLabel}</b>: ${attrValueLabel}</li>`)
            );

          let attrCount = 0;
          $attributeTooltip.empty();
          if ($elem.className.includes("ctd")) {
            const valueKey = $elem.id;
            (data?.values?.[valueKey]?.attributes ?? []).forEach(({id, value}) => {
              appendAttribute(observationAttributeMap[id].label, observationAttributeMap[id].values[value]);
              attrCount++;
            });
          } else {
            const dim = $elem.id.split(":")[0];
            const dimVal = $elem.id.split(":")[1];
            (data?.dimensionsAttributes?.[dim]?.[dimVal] ?? []).forEach(({id, value}) => {
              appendAttribute(seriesAttributeMap[id].label, seriesAttributeMap[id].values[value]);
              attrCount++;
            });
          }

          const ATTRIBUTE_HEIGHT = 18;
          const left =
            rect.x < window.innerWidth / 4 ? rect.right + 16 : rect.left - $attributeTooltip.innerWidth() - 16;

          $attributeTooltip.css({
            visibility: "visible",
            top: rect.top - ATTRIBUTE_HEIGHT * attrCount - 30,
            left: left
          });
        },
        function () {
          const $attributeTooltip = $(`#paginated-table__${uuid} #paginated-table__tooltip__attribute`);
          const $mergedHeaderTooltip = $(`#paginated-table__${uuid} #paginated-table__tooltip__merged-header`);

          if ($mergedHeaderTooltip.children().length > 0) {
            $mergedHeaderTooltip.css({visibility: "visible"});
          }
          $attributeTooltip.attr("style", "").empty();
        }
      )
      .off("click")
      .click(function (ev) {
        ev.stopPropagation();

        const $elem = $(this).get(0);

        let attributes = [];
        if ($elem.className.includes("ctd")) {
          const valueKey = $elem.id;
          (data?.values?.[valueKey]?.attributes ?? []).forEach(({id, value}) => {
            attributes.push({
              attr: observationAttributeMap[id].label,
              val: observationAttributeMap[id].values[value]
            });
          });
        } else {
          const dim = $elem.id.split(":")[0];
          const dimVal = $elem.id.split(":")[1];
          (data?.dimensionsAttributes?.[dim]?.[dimVal] ?? []).forEach(({id, value}) => {
            attributes.push({
              attr: seriesAttributeMap[id].label,
              val: seriesAttributeMap[id].values[value]
            });
          });
        }

        setAttributes(attributes);
      });

    /** dimension id filters handling **/
    $(`#paginated-table__${uuid} .c.ch .table-icon.filter-dim-id`)
      .off("click")
      .click(function () {
        const $elem = $(this).get(0);
        setTerrIdFilterAnchorEl($elem);
      });

    /** dimension label filters handling **/
    $(`#paginated-table__${uuid} .c.ch .table-icon.filter-dim-label`)
      .off("click")
      .click(function () {
        const $elem = $(this).get(0);
        setTerrLabelFilterAnchorEl($elem);
      });

    /** observation filters handling **/
    $(`#paginated-table__${uuid} .c.csh .table-icon.col-filter-obs`)
      .off("click")
      .click(function () {
        const $elem = $(this).get(0);
        setObsFilterAnchorEl($elem);
        const colIdx = Number($elem.id.split("-")[1]);
        setObsFilterColIdx(colIdx);
      });

    /** hover and click on rows handling **/
    $(`#paginated-table__${uuid} tbody tr.body__row`)
      .off("click")
      .click(function () {
        if (onRowClick) {
          const $elem = $(this).get(0);
          const territoryVal = $elem.id.split("-")[1];
          const isActiveAfterClick = !$elem.className.includes("body__row--selected");
          onRowClick(territoryVal, isActiveAfterClick);
          scrollToSelected = false;
        }
      });

    /** territorial classifications setting handling **/
    $(`#paginated-table__${uuid} .c.ch .table-icon.terr-class-config`)
      .off("click")
      .click(function () {
        handleTerrClassConfigOpen();
      });

    /** territorial classification remove handling **/
    $(`#paginated-table__${uuid} .c.csh .table-icon.terr-class-level-remove`)
      .off("click")
      .click(function () {
        const $elem = $(this).get(0);
        const selectedId = Number($elem.id.split("-")[2]);
        const newConfig = (territorialClassificationsConfig ?? []).filter(({id}) => id !== selectedId);
        setTerritorialClassificationsConfig(newConfig);
      });
  }, [
    uuid,
    dimensionMap,
    dimensionLabels,
    cols,
    seriesAttributeMap,
    observationAttributeMap,
    data,
    htmlTable,
    filters,
    onRowClick,
    handleTerrClassConfigOpen,
    territorialClassificationsConfig,
    setTerritorialClassificationsConfig
  ]);

  const handleScrollbarStyle = useCallback(() => {
    const $tableContainer = $(`#paginated-table__${uuid}`);
    const $table = $(`#paginated-table__${uuid} table`);

    if ($tableContainer.length > 0 && $table.length > 0) {
      const isVerticalScrollbarVisible =
        rowIdx !== 0 || rowCount > pageRowCount || $table.height() > $tableContainer.height();
      setVerticalScrollbarVisibility(isVerticalScrollbarVisible);

      const isHorizontalScrollbarVisible =
        colIdx !== 0 || colCount > pageColCount || $table.width() > $tableContainer.width();
      setHorizontalScrollbarVisibility(isHorizontalScrollbarVisible);
    }
  }, [uuid, rowIdx, rowCount, pageRowCount, colIdx, colCount, pageColCount]);

  useEffect(() => {
    handleScrollbarStyle();
  });

  useEffect(() => {
    window.addEventListener("resize", handleScrollbarStyle);
    return () => window.removeEventListener("resize", handleScrollbarStyle);
  }, [handleScrollbarStyle]);

  return (
    <Fragment>
      <Box
        sx={{
          width: "100%",
          height: "100%"
        }}
        className={` paginated-table`}
        aria-hidden={true}
      >
        {colCount + rowCount === 0 ? (
          <CustomEmpty text={t("components.table.noDataToDisplay")} />
        ) : rowIdx === null || colIdx === null ? (
          <CustomEmpty text={t("components.table.loading") + "..."} image={<CircularProgress />} />
        ) : (
          <Scrollbars
            verticalValue={rowIdx}
            verticalMaxValue={rowCount}
            verticalTicks={rowCount - 1}
            onVerticalScroll={handleVerticalScroll}
            isVerticalScrollbarVisible={isVerticalScrollbarVisible}
            horizontalValue={colIdx}
            horizontalMaxValue={colCount}
            horizontalTicks={colCount - 1}
            onHorizontalScroll={handleHorizontalScroll}
            isHorizontalScrollbarVisible={isHorizontalScrollbarVisible}
            disabled={isFetchingData}
          >
            {isFetchingData ? (
              <CustomEmpty text={t("components.table.fetchingData") + "..."} image={<CircularProgress />} />
            ) : (
              <Box
                id={`paginated-table__${uuid}`}
                sx={{
                  width: "100%",
                  height: "100%"
                }}
                style={{overflow: "hidden"}}
                dangerouslySetInnerHTML={{__html: htmlTable}}
              />
            )}
          </Scrollbars>
        )}
      </Box>

      <Dialog open={attributes !== null} onClose={() => setAttributes(null)}>
        <CustomDialogTitle onClose={() => setAttributes(null)}>
          {t("components.table.dialogs.attributes.title")}
        </CustomDialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {(attributes || []).map(({attr, val}, idx) => (
              <Grid item key={idx} xs={12}>
                <SanitizedHTML html={`- <b>${attr}</b>: ${val}`} allowTarget />
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAttributes(null)}>{t("commons.confirm.close")}</Button>
        </DialogActions>
      </Dialog>

      <Popover
        open={terrIdFilterAnchorEl !== null}
        anchorEl={terrIdFilterAnchorEl}
        onClose={() => setTerrIdFilterAnchorEl(null)}
        {...popoverCommonProps}
      >
        <DimensionFilterForm
          filter={filters?.idTextFilter}
          onSubmit={idTextFilter => {
            setTerrIdFilterAnchorEl(null);
            if (!_.isEqual(idTextFilter ?? null, filters?.idTextFilter ?? null)) {
              setRowIdx(0);
              setColIdx(0);
              setIsFetchingData(true);
              onFilter({...filters, idTextFilter: idTextFilter});
              onRowClick(null, false);
            }
          }}
        />
      </Popover>

      <Popover
        open={terrLabelFilterAnchorEl !== null}
        anchorEl={terrLabelFilterAnchorEl}
        onClose={() => setTerrLabelFilterAnchorEl(null)}
        {...popoverCommonProps}
      >
        <DimensionFilterForm
          filter={filters?.labelTextFilter}
          onSubmit={labelTextFilter => {
            setTerrLabelFilterAnchorEl(null);
            if (!_.isEqual(labelTextFilter ?? null, filters?.labelTextFilter ?? null)) {
              setRowIdx(0);
              setColIdx(0);
              setIsFetchingData(true);
              onFilter({...filters, labelTextFilter: labelTextFilter});
              onRowClick(null, false);
            }
          }}
        />
      </Popover>

      <Popover
        open={obsFilterAnchorEl !== null}
        anchorEl={obsFilterAnchorEl}
        onClose={() => setObsFilterAnchorEl(null)}
        {...popoverCommonProps}
      >
        <ObservationFilterForm
          key={obsFilterColIdx}
          filter={filters?.observationFilters?.[obsFilterColIdx]}
          onSubmit={observationFilter => {
            setObsFilterAnchorEl(null);
            setObsFilterColIdx(null);
            if (!_.isEqual(observationFilter ?? null, filters?.observationFilters?.[obsFilterColIdx] ?? null)) {
              setRowIdx(0);
              setColIdx(0);
              setIsFetchingData(true);
              onFilter({
                ...filters,
                observationFilters: {
                  ...filters?.observationFilters,
                  [obsFilterColIdx]: observationFilter
                    ? {
                        ...observationFilter,
                        dimensionValues: cols[obsFilterColIdx]
                      }
                    : null
                }
              });
              onRowClick(null, false);
            }
          }}
        />
      </Popover>

      <Dialog open={terrClassConfigVisibility} maxWidth="md" onClose={handleTerrClassConfigClose}>
        <CustomDialogTitle onClose={handleTerrClassConfigClose}>
          {t("components.table.dialogs.territorialClassificationsConfig.title")}
        </CustomDialogTitle>
        <DialogContent>
          <Call cb={fetchTerritorialClassifications} disabled={territorialClassifications !== null}>
            <TerritorialClassificationsForm
              territorialClassifications={territorialClassifications}
              config={terrClassConfigTmp}
              setConfig={setTerrClassConfigTmp}
            />
          </Call>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleTerrClassConfigClose}>{t("commons.confirm.close")}</Button>
          {(territorialClassifications || []).length > 0 && (
            <Button onClick={() => handleTerrClassConfigSubmit(terrClassConfigTmp)}>
              {t("commons.confirm.confirm")}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default connect(mapStateToProps)(TablePaginated);
