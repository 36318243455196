import React, {Fragment, useState} from "react";
import GetAppIcon from "@mui/icons-material/GetApp";
import {Autocomplete, Box} from "@mui/material";
import Alert from "@mui/material/Alert";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import {withTranslation} from "react-i18next";
import ButtonSelect from "../button-select";
import {CHART_EXPORT_SIZE_3000X2000, CHART_EXPORT_SIZE_ORIGINAL} from "../chart/constants";
import CustomDialogTitle from "../custom-dialog-title";
import {
  LABEL_FORMAT_SELECTOR_LABEL_FORMAT_BOTH,
  LABEL_FORMAT_SELECTOR_LABEL_FORMAT_ID,
  LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME
} from "../label-format-selector/constants";
import ModuleMenuItemPlaceholder from "../module-menu-item-placeholder";
import ExportForm from "./ExportForm";
import {
  DOWNLOAD_FORMAT_CHART_IMAGE,
  DOWNLOAD_FORMAT_CHART_PDF,
  DOWNLOAD_FORMAT_CSV,
  DOWNLOAD_FORMAT_EXCEL,
  DOWNLOAD_FORMAT_MAP_IMAGE,
  DOWNLOAD_FORMAT_MAP_PDF,
  DOWNLOAD_FORMAT_SHAPEFILES,
  DOWNLOAD_REFERENCE_METADATA,
  DOWNLOAD_VIEWER_CHART,
  DOWNLOAD_VIEWER_MAP,
  DOWNLOAD_VIEWER_TABLE,
  downloadFormats,
  exportViewerCanvas,
  isDownloadFormatValid
} from "../../utils/download";

const fieldStyle = {
  marginBottom: "16px"
};

const fieldLastRowStyle = {
  marginBottom: "0px"
};

const alertStyle = {
  marginBottom: "16px"
};

const dividerStyle = {
  width: "100%",
  color: "gray",
  fontStyle: "italic",
  fontSize: "14px"
};

const COLS_SEPARATOR_COMMA = ",";
const COLS_SEPARATOR_SEMICOLON = ";";
const COLS_SEPARATOR_PIPE = "|";
const COLS_SEPARATOR_TAB = "\t";

const getColsSeparators = t => [
  {
    value: COLS_SEPARATOR_COMMA,
    label: t ? t("scenes.dataViewer.dialogs.exportCsv.colsSeparator.values.comma") : "Comma (,)"
  },
  {
    value: COLS_SEPARATOR_SEMICOLON,
    label: t ? t("scenes.dataViewer.dialogs.exportCsv.colsSeparator.values.semicolon") : "Semicolon (;)"
  },
  {
    value: COLS_SEPARATOR_PIPE,
    label: t ? t("scenes.dataViewer.dialogs.exportCsv.colsSeparator.values.pipe") : "Pipe (|)"
  },
  {
    value: COLS_SEPARATOR_TAB,
    label: t ? t("scenes.dataViewer.dialogs.exportCsv.colsSeparator.values.tab") : "Tab ( )"
  }
];

const getInitialCsvParams = (csvSeparator, defaultLabelFormat) => ({
  labelFormat: defaultLabelFormat,
  colsSeparator:
    csvSeparator &&
    getColsSeparators()
      .map(({value}) => value)
      .includes(csvSeparator)
      ? csvSeparator
      : COLS_SEPARATOR_COMMA,
  textQualifier: ""
});

const ExportButton = ({
  t,
  exportConfig,
  exportSettings,
  modulesConfig,
  icon,
  formats: nodeFormats,
  viewerIdx,
  isTableVisible,
  isMapVisible,
  isChartVisible,
  tableLayout,
  mapContainerId,
  mapId,
  mapLayout,
  mapSettings,
  chartContainerId,
  chartId,
  chartLayout,
  chartSettings,
  labelFormat,
  datasetTitle,
  submitDownload,
  getDimensionsCombinationCount,
  getIsDatasetExceedingLimit,
  showAsBottomNavigation = false,
  shapefilesUrl,
  submitMetadataDownload,
  disabled,
  hideLabelSelectorFormat = false,
  getDimensionSize,
  getLabels,
  allAttributesAsDim,
  defaultAttributesAsDim,
  ...props
}) => {
  const [exportChartFormat, setExportChartFormat] = useState(null);
  const [exportMapFormat, setExportMapFormat] = useState(null);
  const [tempSettings, setTmpSettings] = useState(null);

  const [isExportExcelVisible, setExportExcelVisibility] = useState(false);
  const [singleSheet, setSingleSheet] = useState(true);

  const [isExportCsvVisible, setExportCsvVisibility] = useState(false);
  const [filteredDataset, setFilteredDataset] = useState(true);
  const [csvParams, setCsvParams] = useState(
    getInitialCsvParams(
      exportConfig?.csvSeparator,
      hideLabelSelectorFormat ? LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME : LABEL_FORMAT_SELECTOR_LABEL_FORMAT_BOTH
    )
  );

  const [isExceededLimitVisible, setExceededLimitVisibility] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const [attributesAsDim, setAttributesAsDim] = useState([]);

  const handleExportClick = (format, extension) => {
    if (format && !disabled) {
      if (format === DOWNLOAD_FORMAT_MAP_IMAGE || format === DOWNLOAD_FORMAT_MAP_PDF) {
        handleExportMapShow(format, extension);
      } else if (format === DOWNLOAD_FORMAT_CHART_IMAGE || format === DOWNLOAD_FORMAT_CHART_PDF) {
        handleExportChartShow(format, extension);
      } else if (format === DOWNLOAD_FORMAT_SHAPEFILES) {
        window.open(shapefilesUrl);
      } else if (
        format === DOWNLOAD_FORMAT_EXCEL &&
        ((getDimensionsCombinationCount && getDimensionsCombinationCount(tableLayout?.filters) > 1) ||
          (allAttributesAsDim ?? []).length > 0)
      ) {
        handleExportExcelShow();
      } else if (format === DOWNLOAD_FORMAT_CSV) {
        handleExportCsvShow();
      } else if (format === DOWNLOAD_REFERENCE_METADATA) {
        submitMetadataDownload();
      } else {
        if (getIsDatasetExceedingLimit && getIsDatasetExceedingLimit()) {
          setExceededLimitVisibility(true);
        } else {
          submitDownload(format, extension);
        }
      }
      setAnchorEl(null);
    }
  };

  const getDefaultSettings = (format, extension, showLegend) => ({
    showTitle: true,
    title: datasetTitle,
    showFilters: true,
    extension: extension,
    isLegendVisible: showLegend,
    showLegend: showLegend,
    showDate: format === DOWNLOAD_FORMAT_MAP_PDF || format === DOWNLOAD_FORMAT_CHART_PDF ? true : undefined,
    sheetOrientation:
      format === DOWNLOAD_FORMAT_MAP_PDF
        ? exportSettings.DefaultMapSheetOrientation
        : exportSettings.DefaultGraphSheetOrientation,
    logoURL: exportSettings.logoURL
  });

  const handleExportChartShow = (format, extension) => {
    setTmpSettings(getDefaultSettings(format, extension));
    setExportChartFormat(format);
  };

  const handleExportMapShow = (format, extension) => {
    setTmpSettings(getDefaultSettings(format, extension, !mapSettings?.isLegendCollapsed));
    setExportMapFormat(format);
  };

  const handleExportChartOrMapHide = () => {
    setExportChartFormat(null);
    setExportMapFormat(null);
    setTmpSettings(null);
  };

  const handleExportChartOrMapSubmit = () => {
    const {showTitle, title, showFilters, extension, showLegend, showDate, sheetOrientation, logoURL} = tempSettings;

    if (exportChartFormat) {
      // const $chartContainer = $(`#${chartContainerId}`);

      window.ChartJs[chartId].getCanvas(
        exportChartFormat === DOWNLOAD_FORMAT_CHART_PDF ? CHART_EXPORT_SIZE_3000X2000 : CHART_EXPORT_SIZE_ORIGINAL,
        canvas => {
          exportViewerCanvas(
            canvas,
            canvas.width, // $chartContainer.width()
            canvas.height, // $chartContainer.height()
            exportChartFormat,
            `${datasetTitle}.${extension}`,
            showTitle === true ? title : null,
            showFilters === true ? chartLayout : null,
            showDate === true ? showDate : false,
            sheetOrientation,
            logoURL,
            getLabels,
            t
          );
        },
        null,
        !showLegend
      );

      setExportChartFormat(null);
      setExportMapFormat(null);
      setTmpSettings(null);
    } else if (exportMapFormat) {
      // const $mapContainer = $(`#${mapContainerId}`);

      window.LMap.getCanvas(
        mapId,
        canvas => {
          exportViewerCanvas(
            canvas,
            canvas.width, // $mapContainer.width()
            canvas.height, // $mapContainer.height()
            exportMapFormat,
            `${datasetTitle}.${extension}`,
            showTitle === true ? title : null,
            showFilters === true ? mapLayout : null,
            showDate === true ? showDate : false,
            sheetOrientation,
            logoURL,
            getLabels,
            t
          );
        },
        null,
        !showLegend
      );

      setExportChartFormat(null);
      setExportMapFormat(null);
      setTmpSettings(null);
    }
  };

  const handleExportExcelShow = () => {
    setSingleSheet(true);
    setAttributesAsDim(defaultAttributesAsDim ?? []);
    setExportExcelVisibility(true);
  };

  const handleExportExcelHide = () => {
    setExportExcelVisibility(false);
  };

  const handleExportExcelSubmit = () => {
    const additionParams = {
      exportOnlyCurrentView: singleSheet,
      attributesAsDim: attributesAsDim
    };
    submitDownload(DOWNLOAD_FORMAT_EXCEL, downloadFormats()[DOWNLOAD_FORMAT_EXCEL].extension, additionParams);
    handleExportExcelHide();
  };

  const handleExportCsvShow = () => {
    setFilteredDataset(true);
    setCsvParams(
      getInitialCsvParams(
        exportConfig?.csvSeparator,
        hideLabelSelectorFormat ? LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME : LABEL_FORMAT_SELECTOR_LABEL_FORMAT_BOTH
      )
    );
    setExportCsvVisibility(true);
  };

  const handleExportCsvHide = () => {
    setExportCsvVisibility(false);
  };

  const handleExportCsvSubmit = () => {
    const additionParams = {
      exportOnlyCurrentView: filteredDataset,
      ...csvParams
    };
    submitDownload(DOWNLOAD_FORMAT_CSV, downloadFormats()[DOWNLOAD_FORMAT_CSV].extension, additionParams);
    handleExportCsvHide();
  };

  const information = [];

  if (submitMetadataDownload || (modulesConfig.placeholders?.["export-button-menu"] || []).length > 0) {
    information.push({
      label: t("components.exportButton.sections.information"),
      sx: dividerStyle
    });
    if (submitMetadataDownload) {
      information.push({
        label: t("commons.downloadFormat.referencemetadata"),
        "data-value": {format: DOWNLOAD_REFERENCE_METADATA, extension: ""},
        format: DOWNLOAD_REFERENCE_METADATA,
        extension: ""
      });
    }
  }

  const formats = [];

  const formatsTable = (nodeFormats || []).filter(
    format => isDownloadFormatValid(format) && (downloadFormats()[format].viewers || []).includes(DOWNLOAD_VIEWER_TABLE)
  );
  if (isTableVisible && formatsTable.length > 0) {
    formats.push({
      label: t("components.exportButton.sections.table"),
      sx: dividerStyle
    });
    formatsTable.forEach(format => {
      const {label, extension} = downloadFormats(t)[format];
      formats.push({
        label: label,
        "data-value": {format, extension},
        format: format,
        extension: extension
      });
    });
  }

  const formatsMap = (nodeFormats || []).filter(
    format => isDownloadFormatValid(format) && (downloadFormats()[format].viewers || []).includes(DOWNLOAD_VIEWER_MAP)
  );
  if (isMapVisible && formatsMap.length > 0) {
    formats.push({
      label: t("components.exportButton.sections.map"),
      sx: dividerStyle
    });
    formatsMap.forEach(format => {
      const {label, extension} = downloadFormats(t)[format];
      formats.push({
        label: label,
        "data-value": {format, extension},
        format: format,
        extension: extension
      });
    });
    if (shapefilesUrl) {
      formats.push({
        label: t("components.exportButton.shapefiles"),
        "data-value": {format: DOWNLOAD_FORMAT_SHAPEFILES},
        format: DOWNLOAD_FORMAT_SHAPEFILES
      });
    }
  }

  const formatsChart = (nodeFormats || []).filter(
    format => isDownloadFormatValid(format) && (downloadFormats()[format].viewers || []).includes(DOWNLOAD_VIEWER_CHART)
  );
  if (isChartVisible && formatsChart.length > 0) {
    formats.push({
      label: t("components.exportButton.sections.chart"),
      sx: dividerStyle
    });
    formatsChart.forEach(format => {
      const {label, extension} = downloadFormats(t)[format];
      formats.push({
        label: label,
        "data-value": {format, extension},
        format: format,
        extension: extension
      });
    });
  }

  const formatsOthers = (nodeFormats || []).filter(
    format => isDownloadFormatValid(format) && !downloadFormats()[format].viewers
  );
  if (formatsOthers.length > 0) {
    formats.push({
      label: t("components.exportButton.sections.others"),
      sx: dividerStyle
    });
    formatsOthers.forEach(format => {
      const {label, extension} = downloadFormats(t)[format];
      formats.push({
        label: label,
        "data-value": {format, extension},
        format: format,
        extension: extension
      });
    });
  }

  if (formats.length === 0) {
    return <span />;
  }

  const getButtonSelectItem = (format, idx) => (
    <Box
      key={idx}
      id={`export-button__format--${format.format}`}
      sx={format.sx}
      data-value={format["data-value"]}
      aria-label={t("components.exportButton.export.format.ariaLabel", {
        datasetTitle: datasetTitle,
        formatLabel: format.label
      })}
    >
      {format.label}
    </Box>
  );

  const getMenuItem = (label, format, extension, idx) =>
    format ? (
      <MenuItem
        key={idx}
        id={`export-button__format--${format.format}`}
        onClick={() => handleExportClick(format, extension)}
      >
        {label}
      </MenuItem>
    ) : (
      <Box
        key={idx}
        sx={dividerStyle}
        style={{
          cursor: "default",
          padding: "6px 16px"
        }}
      >
        {label}
      </Box>
    );

  const layoutForCsv = viewerIdx === 0 ? tableLayout : viewerIdx === 1 ? mapLayout : chartLayout;

  return (
    <Fragment>
      {!showAsBottomNavigation ? (
        <ButtonSelect
          {...props}
          icon={icon ? icon : <GetAppIcon />}
          ariaLabel={t("components.exportButton.export.ariaLabel")}
          tooltip={t("components.exportButton.export.tooltip")}
          color="default"
          onChange={({format, extension}) => handleExportClick(format, extension)}
          disabled={disabled}
        >
          {[
            ...information.map((format, idx) => getButtonSelectItem(format, `information-${idx}`)),
            ...(modulesConfig.placeholders?.["export-button-menu"] || []).map((module, idx) => (
              <ModuleMenuItemPlaceholder isModulePlaceholder key={`module-${idx}`} module={module} />
            )),
            ...formats.map((format, idx) => getButtonSelectItem(format, `format-${idx}`))
          ]}
        </ButtonSelect>
      ) : (
        <Fragment>
          <BottomNavigation onChange={({currentTarget}) => setAnchorEl(currentTarget)} showLabels>
            <BottomNavigationAction
              label={t("components.exportButton.export.label")}
              icon={icon ? icon : <GetAppIcon />}
              role={props.role || undefined}
              disabled={disabled}
            />
          </BottomNavigation>

          <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
            {[
              ...information.map(({label, format, extension}, idx) =>
                getMenuItem(label, format, extension, `information-${idx}`)
              ),
              ...(modulesConfig.placeholders?.["export-button-menu"] || []).map((module, idx) => (
                <MenuItem key={`module-${idx}`} style={{padding: 0}} onClick={() => setAnchorEl(null)}>
                  <ModuleMenuItemPlaceholder isModulePlaceholder module={module} />
                </MenuItem>
              )),
              ...formats.map(({label, format, extension}, idx) =>
                getMenuItem(label, format, extension, `format-${idx}`)
              )
            ]}
          </Menu>
        </Fragment>
      )}

      <Dialog open={isExceededLimitVisible} maxWidth="md" onClose={() => setExceededLimitVisibility(false)}>
        <CustomDialogTitle onClose={() => setExceededLimitVisibility(false)}>
          {t("components.exportButton.modals.limitExceeded.title")}
        </CustomDialogTitle>
        <DialogContent>{t("components.exportButton.modals.limitExceeded.content")}</DialogContent>
        <DialogActions>
          <Button onClick={() => setExceededLimitVisibility(false)}>{t("commons.confirm.close")}</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        id="export-dialog__chart-or-map"
        open={!!(exportChartFormat || exportMapFormat)}
        maxWidth="md"
        onClose={handleExportChartOrMapHide}
      >
        <CustomDialogTitle onClose={handleExportChartOrMapHide}>
          {exportChartFormat === DOWNLOAD_FORMAT_CHART_IMAGE || exportMapFormat === DOWNLOAD_FORMAT_MAP_IMAGE
            ? t("components.exportButton.exportImage.title")
            : exportChartFormat === DOWNLOAD_FORMAT_CHART_PDF || exportMapFormat === DOWNLOAD_FORMAT_MAP_PDF
              ? t("components.exportButton.exportPdf.title")
              : ""}
        </CustomDialogTitle>
        <DialogContent style={{width: 640}}>
          <ExportForm settings={tempSettings} onSettingsSet={setTmpSettings} />
        </DialogContent>
        <DialogActions>
          <Button id="export-dialog__chart-or-map__cancel" onClick={handleExportChartOrMapHide}>
            {t("commons.confirm.cancel")}
          </Button>
          <Button
            id="export-dialog__chart-or-map__apply"
            autoFocus
            onClick={handleExportChartOrMapSubmit}
            color="primary"
          >
            {t("commons.confirm.apply")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        id="export-dialog__export-excel"
        open={isExportExcelVisible && !disabled}
        maxWidth="sm"
        fullWidth
        onClose={handleExportExcelHide}
      >
        <CustomDialogTitle onClose={handleExportExcelHide}>
          {t("scenes.dataViewer.dialogs.exportExcel.title")}
        </CustomDialogTitle>
        <DialogContent>
          {singleSheet === false &&
            (getIsDatasetExceedingLimit && getIsDatasetExceedingLimit() ? (
              <Alert severity="warning" sx={alertStyle}>
                {t("scenes.dataViewer.dialogs.exportExcel.warnings.limitExceeded")}
              </Alert>
            ) : getDimensionsCombinationCount &&
              getDimensionsCombinationCount(tableLayout?.filters) > exportConfig?.maxExcelSheets ? (
              <Alert severity="warning" sx={alertStyle}>
                {t("scenes.dataViewer.dialogs.exportExcel.warnings.sheetsLimit", {
                  sheetsLimit: exportConfig?.maxExcelSheets
                })}
              </Alert>
            ) : null)}
          <Grid container spacing={2}>
            {getDimensionsCombinationCount && getDimensionsCombinationCount(tableLayout?.filters) > 1 && (
              <Grid item xs={12}>
                <FormControl fullWidth sx={fieldStyle}>
                  <TextField
                    id="export-dialog__export-excel__text-field"
                    select
                    label={t("scenes.dataViewer.dialogs.exportExcel.export.label")}
                    value={singleSheet}
                    onChange={ev => setSingleSheet(ev.target.value)}
                    variant="outlined"
                    SelectProps={{"aria-haspopup": true}}
                  >
                    <MenuItem value={true}>
                      {t("scenes.dataViewer.dialogs.exportExcel.export.values.singleSheet")}
                    </MenuItem>
                    <MenuItem value={false}>
                      {t("scenes.dataViewer.dialogs.exportExcel.export.values.multiSheet")}
                    </MenuItem>
                  </TextField>
                </FormControl>
              </Grid>
            )}
            {(allAttributesAsDim ?? []).length > 0 && (
              <Grid item xs={12}>
                <FormControl fullWidth sx={[fieldStyle, fieldLastRowStyle]}>
                  <Autocomplete
                    multiple
                    variant="outlined"
                    options={allAttributesAsDim}
                    value={attributesAsDim || []}
                    onChange={(e, values) => {
                      setAttributesAsDim(values);
                    }}
                    renderInput={params => (
                      <FormControl fullWidth sx={fieldStyle}>
                        <TextField
                          {...params}
                          label={t("scenes.dataViewer.dialogs.exportExcel.export.attributesAsDim.label")}
                          placeholder={t("scenes.dataViewer.dialogs.exportExcel.export.attributesAsDim.placeholder")}
                          variant="outlined"
                        />
                      </FormControl>
                    )}
                  />
                </FormControl>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button id="export-dialog__export-excel__cancel" onClick={handleExportExcelHide}>
            {t("commons.confirm.cancel")}
          </Button>
          <Button
            id="export-dialog__export-excel__apply"
            onClick={handleExportExcelSubmit}
            disabled={singleSheet === false && getIsDatasetExceedingLimit && getIsDatasetExceedingLimit()}
          >
            {t("commons.confirm.download")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        id="export-dialog__export-csv"
        open={isExportCsvVisible && !disabled}
        maxWidth="sm"
        fullWidth
        onClose={handleExportCsvHide}
      >
        <CustomDialogTitle onClose={handleExportCsvHide}>
          {t("scenes.dataViewer.dialogs.exportCsv.title")}
        </CustomDialogTitle>
        <DialogContent>
          {(filteredDataset === false || !disabled) && getIsDatasetExceedingLimit && getIsDatasetExceedingLimit() && (
            <Alert severity="warning" sx={alertStyle}>
              {t("scenes.dataViewer.dialogs.exportCsv.warnings.limitExceeded")}
            </Alert>
          )}
          <Grid container spacing={2}>
            {((getDimensionsCombinationCount && getDimensionsCombinationCount(layoutForCsv?.filters) > 1) ||
              (layoutForCsv?.primaryDim?.[0] !== undefined &&
                layoutForCsv.primaryDimValues.length < getDimensionSize(layoutForCsv.primaryDim[0])) ||
              (layoutForCsv?.secondaryDim?.[0] !== undefined &&
                layoutForCsv.secondaryDimValues.length < getDimensionSize(layoutForCsv.secondaryDim[0]))) && (
              <Grid item xs={12}>
                <FormControl fullWidth sx={fieldStyle}>
                  <TextField
                    id="export-dialog__export-csv__text-field"
                    select
                    label={t("scenes.dataViewer.dialogs.exportCsv.export.label")}
                    value={filteredDataset}
                    onChange={ev => setFilteredDataset(ev.target.value)}
                    variant="outlined"
                    SelectProps={{"aria-haspopup": true}}
                  >
                    <MenuItem value={true}>{t("scenes.dataViewer.dialogs.exportCsv.export.values.filtered")}</MenuItem>
                    <MenuItem value={false}>{t("scenes.dataViewer.dialogs.exportCsv.export.values.full")}</MenuItem>
                  </TextField>
                </FormControl>
              </Grid>
            )}
            {!hideLabelSelectorFormat && (
              <Grid item xs={12}>
                <FormControl fullWidth sx={fieldStyle}>
                  <TextField
                    select
                    label={t("scenes.dataViewer.dialogs.exportCsv.labelFormat.label")}
                    value={csvParams?.labelFormat}
                    onChange={ev => setCsvParams({...csvParams, labelFormat: ev.target.value})}
                    variant="outlined"
                    SelectProps={{"aria-haspopup": true}}
                  >
                    <MenuItem value={LABEL_FORMAT_SELECTOR_LABEL_FORMAT_BOTH}>
                      {t("scenes.dataViewer.dialogs.exportCsv.labelFormat.values.both")}
                    </MenuItem>
                    <MenuItem value={LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME}>
                      {t("scenes.dataViewer.dialogs.exportCsv.labelFormat.values.name")}
                    </MenuItem>
                    <MenuItem value={LABEL_FORMAT_SELECTOR_LABEL_FORMAT_ID}>
                      {t("scenes.dataViewer.dialogs.exportCsv.labelFormat.values.id")}
                    </MenuItem>
                  </TextField>
                </FormControl>
              </Grid>
            )}
            <Grid item xs={6}>
              <FormControl fullWidth sx={[fieldStyle, fieldLastRowStyle]}>
                <TextField
                  select
                  label={t("scenes.dataViewer.dialogs.exportCsv.colsSeparator.label")}
                  value={csvParams?.colsSeparator}
                  onChange={ev => setCsvParams({...csvParams, colsSeparator: ev.target.value})}
                  variant="outlined"
                  SelectProps={{"aria-haspopup": true}}
                >
                  {getColsSeparators(t).map(({value, label}, idx) => (
                    <MenuItem key={idx} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth sx={[fieldStyle, fieldLastRowStyle]}>
                <TextField
                  label={t("scenes.dataViewer.dialogs.exportCsv.textQualifier.label")}
                  value={csvParams?.textQualifier || ""}
                  onChange={ev =>
                    (ev.target.value || "").length <= 1
                      ? setCsvParams({...csvParams, textQualifier: ev.target.value})
                      : null
                  }
                  variant="outlined"
                  error={csvParams?.colsSeparator === csvParams?.textQualifier}
                  helperText={
                    csvParams?.colsSeparator === csvParams?.textQualifier
                      ? t("scenes.dataViewer.dialogs.exportCsv.textQualifier.helperText")
                      : null
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button id="export-dialog__export-csv__cancel" onClick={handleExportCsvHide}>
            {t("commons.confirm.cancel")}
          </Button>
          <Button
            id="export-dialog__export-csv__confirm"
            onClick={handleExportCsvSubmit}
            disabled={
              (filteredDataset === false || disabled) && getIsDatasetExceedingLimit && getIsDatasetExceedingLimit()
            }
          >
            {t("commons.confirm.download")}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default withTranslation()(ExportButton);
